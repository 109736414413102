import { request } from "../utils/axios-utils";

const namespace = "/api/user";

export const createMessage = (
    addToast: any,
    payload: any,
    type: string,
    _id: string
) => {
    return request({
        addToast,
        url: namespace + `/message/` + type + "/" + _id,
        method: "post",
        data: payload,
    });
};
