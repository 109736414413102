import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
    capitalizeFirst,
    checkPrevious,
    returnOnlyNumbers,
} from "../../../constants/Utils";
import ICourt from "../../../interfaces/ICourt";
import IJudge from "../../../interfaces/IJudge";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import FormButton from "../../layout/forms/FormButton";
import InputAddress from "../../layout/forms/InputAddress";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";
import Textarea from "../../layout/forms/Textarea";
import InnerJudgeForm from "./InnerJudgeForm";

const CreateUpdate: React.FC<{ id?: string }> = (props) => {
    const [court, setCourt] = useState<ICourt | null>(null);

    const [label, setLabel] = useState<string>("");
    const [siret, setSiret] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [zipCode, setZipCode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [notes, setNotes] = useState<string>("");
    const [type, setType] = useState<string>("");

    const [judges, setJudges] = useState<Array<IJudge>>([]);
    const [judgeIndex, setJudgeIndex] = useState<number>(0);

    const { addToast } = useToasts();
    const history = useHistory();

    const { courtId }: { courtId?: string } = useParams();

    useEffect(() => {
        // If it is a modification, it loads the bank to modify.
        if (courtId && !court) {
            request({ addToast, url: `/api/courts/${courtId}` }).then(
                ({ data }) => {
                    setType(data.tribunal_type);
                    setCourt(data);
                    setLabel(data.label);
                    setSiret(data.siret);
                    setAddress(data.address);
                    setZipCode(data.zip_code);
                    setCity(data.city);
                    setNotes(data.notes);
                    if (data.judges && data.judges.length > 0) {
                        data.judges.forEach(function (j: any, index: any) {
                            j["id"] = `judge-${index}`;
                        });

                        setJudges(data.judges);
                        setJudgeIndex(data.judges.length);
                    }
                }
            ).catch();
        }
    }, [court]);

    const handleSelection = (suggestion: any) => {
        setAddress(suggestion.name);
        setZipCode(returnOnlyNumbers(suggestion.postcode));
        setCity(capitalizeFirst(suggestion.city));
    };

    //Add a Judge
    const addJudge = () => {
        const judgesList = [...judges];
        if (checkPrevious(judgesList, "judges")) {
            judges.forEach((j) => (j.isDeletable = false));
            judges.push({
                id: `judge-${judgeIndex}`,
                isDeletable: true,
            });
            setJudgeIndex(judgeIndex + 1);
        } else {
            addToast(
                "Remplissez d'abord les champs obligatoire du précédent juge.",
                {
                    appearance: "warning",
                }
            );
        }
    };

    //Modify a Judge
    const setJudgeValue = (judge: IJudge) => {
        let index = judges.findIndex((j) => j.id === judge.id);
        let newJudges = judges;
        newJudges[index] = judge;
        setJudges(newJudges);
    };

    //Delete a Judge
    const removeJudge = (judge: IJudge) => {
        let index = judges.findIndex((j) => j.id === judge.id);
        let newJudges = judges;
        newJudges.splice(index, 1);
        newJudges.forEach(
            (j, index) => (j.isDeletable = index === newJudges.length - 1)
        );
        setJudges(newJudges);
        setJudgeIndex(judgeIndex - 1);
    };

    /**
     * Save a court (it can be a saving as a modification).
     */
    const saveCourt = () => {
        const url = courtId
            ? `/api/courts/${courtId}/edit`
            : `/api/courts/create/${court}`;
        request({
            addToast,
            url: url,
            method: "post",
            data: {
                label: label.toUpperCase(),
                tribunal_type: type,
                address: address,
                zip_code: zipCode,
                city: city.toUpperCase(),
                judges: judges,
                siret: siret,
                notes: notes,
            },
        }).then((data) => {
            // The data has been saved.
            addToast(`Le tribunal a bien été ${courtId ? "modifié" : "créé"}`, {
                appearance: "success",
            });
            courtId
                ? history.push(`/tribunaux/${courtId}/show`)
                : history.push("/tribunaux");
        }).catch();
    };

    return (
        <>
            <div className="form_bank">
                {!courtId && !court && (
                    <SubBar title={"Ajouter un tribunal"} back="/tribunaux" />
                )}

                {courtId && court && (
                    <SubBar
                        title={`Modifier le tribunal`}
                        back={`/tribunaux/${courtId}/show`}
                    />
                )}

                <div className="page_content">
                    {courtId && !court && <Loader />}

                    {(!courtId || court) && (
                        <>
                            <form className="column">
                                <div className="row layout-xs-column">
                                    <div className="my-input-container column-item-flex-1">
                                        <div className="flex005 hide-xs" />

                                        <Select
                                            className="flex-1"
                                            id={`${courtId}-civility`}
                                            label="Type de tribunal"
                                            required
                                            value={type}
                                            needDefaultValue={true}
                                            options={[
                                                {
                                                    label: "De proximité",
                                                    value: "de proximité",
                                                },
                                                {
                                                    label: "Judiciaire",
                                                    value: "judiciaire",
                                                },
                                            ]}
                                            onChange={(e) =>
                                                setType(e.target.value)
                                            }
                                        />
                                    </div>

                                    <div className="flex005 hide-xs" />

                                    <InputComponent
                                        id="label"
                                        type="text"
                                        className="column-item-flex-1"
                                        label="Tribunal de"
                                        required
                                        value={label || ""}
                                        onChange={(e) =>
                                            setLabel(e.target.value)
                                        }
                                        maxLength={50}
                                    />

                                    <div className="flex005 hide-xs" />

                                    <InputComponent
                                        type="text"
                                        id="siret"
                                        className="column-item-flex-1"
                                        label="Siret"
                                        value={siret || ""}
                                        onChange={(e) =>
                                            setSiret(
                                                returnOnlyNumbers(
                                                    e.target.value
                                                )
                                            )
                                        }
                                        maxLength={14}
                                    />
                                </div>

                                <InputAddress
                                    handleSelection={handleSelection}
                                />

                                <Textarea
                                    id="address"
                                    label="Adresse"
                                    required
                                    value={address || ""}
                                    onChange={(e) => setAddress(e.target.value)}
                                    maxLength={100}
                                />

                                <div className="row layout-xs-column">
                                    <InputComponent
                                        type="text"
                                        id="zip_code"
                                        className="column-item-flex-1"
                                        label="Code postal"
                                        required
                                        value={zipCode || ""}
                                        onChange={(e) =>
                                            setZipCode(
                                                returnOnlyNumbers(
                                                    e.target.value
                                                )
                                            )
                                        }
                                        maxLength={5}
                                    />

                                    <div className="flex005 hide-xs" />

                                    <InputComponent
                                        type="text"
                                        id="city"
                                        className="column-item-flex-1"
                                        label="Ville"
                                        required
                                        value={city || ""}
                                        onChange={(e) =>
                                            setCity(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                </div>

                                <Textarea
                                    id="notes"
                                    label="Notes"
                                    value={notes || ""}
                                    onChange={(e) => setNotes(e.target.value)}
                                />

                                {/* <div className="block"> */}
                                <div className="my-sub-title-f">
                                    <SubBar title="Personnes associées">
                                        <Link to={"#"} onClick={addJudge}>
                                            <Icon name="add" />
                                        </Link>
                                    </SubBar>
                                </div>

                                <div className="layout-center-start column">
                                    {judges.map((judge, index) => (
                                        <div
                                            key={index}
                                            className="column full-width"
                                        >
                                            <InnerJudgeForm
                                                key={judge.id}
                                                id={judge.id}
                                                removeJudge={(judge: IJudge) =>
                                                    removeJudge(judge)
                                                }
                                                onChange={(judge: IJudge) =>
                                                    setJudgeValue(judge)
                                                }
                                                isDeletable={
                                                    judges.length - 1 === index
                                                }
                                                {...judge}
                                            />
                                        </div>
                                    ))}
                                </div>
                                {/* </div> */}

                                <FormButton
                                    onClick={saveCourt}
                                    text={(court && "Modifier") || "Valider"}
                                />
                            </form>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default CreateUpdate;
