import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IAccount from "../../../../interfaces/IAccount";
import IGroupAccounts from "../../../../interfaces/IGroupAccounts";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import { request } from "../../../../utils/axios-utils";
import ItemAccount from "../../../layout/ItemAccount";
import Loader from "../../../layout/Loader";

const AccountsMajeur: React.FC<{
    majeurId: string;
    majeur: IProtectedAdult;
}> = (props) => {
    const [groups, setGroups] = useState<Array<IGroupAccounts>>(null);
    const addToast = useToasts();

    useEffect(() => {
        if (!groups) {
            request({
                addToast,
                url: `/api/majeurs/accounts/${props.majeurId}`,
            }).then(({ data }) => {
                setGroups(data);
            }).catch();
        }
    }, [groups]);

    return (
        <>
            <div className="subBar_actions sticky top-90 layout-space-between-center row">
                <span className="subBar_actions_title">Les comptes</span>
                <div className="subBar_actions_item">
                    {!props.majeur.archived && (
                        <Link
                            to={`/form_account/${props.majeurId}/nouveau`}
                            className="column layout-center-center add-button-href"
                        >
                            <i className="material-icons">add</i>
                        </Link>
                    )}
                </div>
            </div>
            <div>
                {!groups && <Loader />}

                {groups && groups.length === 0 && (
                    <div className="layout-center-center row div-no-items">
                        Il n'y a encore de comptes bancaires renseignés.
                    </div>
                )}
                {groups &&
                    Object.keys(groups).map((group: any) => (
                        <div key={groups[group].category}>
                            <div className="titleLetter  sticky top-130">
                                {groups[group].category}
                            </div>
                            <div className="page_content">
                                {groups[group].accounts.map((accounts: any) =>
                                    accounts.map(
                                        (a: IAccount, index: number) => (
                                            <ItemAccount
                                                key={a._id}
                                                account={a}
                                                divider={
                                                    accounts.length - 1 !==
                                                    index
                                                }
                                            />
                                        )
                                    )
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
};
export default AccountsMajeur;
