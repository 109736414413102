export const countries = [
    { label: 'Andorre', value: 'AD' },
    { label: 'Émirats arabes unis', value: 'AE' },
    { label: 'Afghanistan', value: 'AF' },
    { label: 'Antigua-et-Barbuda', value: 'AG' },
    { label: 'Anguilla', value: 'AI' },
    { label: 'Albanie', value: 'AL' },
    { label: 'Arménie', value: 'AM' },
    { label: 'Antilles néerlandaises', value: 'AN' },
    { label: 'Angola', value: 'AO' },
    { label: 'Antarctique', value: 'AQ' },
    { label: 'Argentine', value: 'AR' },
    { label: 'Samoa américaines', value: 'AS' },
    { label: 'Autriche', value: 'AT' },
    { label: 'Australie', value: 'AU' },
    { label: 'Aruba', value: 'AW' },
    { label: 'Îles Åland', value: 'AX' },
    { label: 'Azerbaïdjan', value: 'AZ' },
    { label: 'Bosnie-Herzégovine', value: 'BA' },
    { label: 'Barbade', value: 'BB' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Belgique', value: 'BE' },
    { label: 'Burkina Faso', value: 'BF' },
    { label: 'Bulgarie', value: 'BG' },
    { label: 'Bahreïn', value: 'BH' },
    { label: 'Burundi', value: 'BI' },
    { label: 'Bénin', value: 'BJ' },
    { label: 'Saint-Barthélemy', value: 'BL' },
    { label: 'Bermudes', value: 'BM' },
    { label: 'Brunei', value: 'BN' },
    { label: 'Bolivie', value: 'BO' },
    { label: 'Bonaire', value: 'BQ' },
    { label: 'Brésil', value: 'BR' },
    { label: 'Bahamas', value: 'BS' },
    { label: 'Bhoutan', value: 'BT' },
    { label: 'Île Bouvet', value: 'BV' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Biélorussie', value: 'BY' },
    { label: 'Belize', value: 'BZ' },
    { label: 'Canada', value: 'CA' },
    { label: 'Îles Cocos', value: 'CC' },
    { label: 'Congo-Kinshasa', value: 'CD' },
    { label: 'République centrafricaine', value: 'CF' },
    { label: 'République du Congo', value: 'CG' },
    { label: 'Suisse', value: 'CH' },
    { label: 'Côte d’Ivoire', value: 'CI' },
    { label: 'Îles Cook', value: 'CK' },
    { label: 'Chili', value: 'CL' },
    { label: 'Cameroun', value: 'CM' },
    { label: 'Chine', value: 'CN' },
    { label: 'Colombie', value: 'CO' },
    { label: 'Costa Rica', value: 'CR' },
    { label: 'Serbie et Monténégro', value: 'CS' },
    { label: 'Cuba', value: 'CU' },
    { label: 'Cap-Vert', value: 'CV' },
    { label: 'Curaçao', value: 'CW' },
    { label: 'Île Christmas', value: 'CX' },
    { label: 'Chypre', value: 'CY' },
    { label: 'République tchèque', value: 'CZ' },
    { label: 'Allemagne', value: 'DE' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Danemark', value: 'DK' },
    { label: 'Dominique', value: 'DM' },
    { label: 'République dominicaine', value: 'DO' },
    { label: 'Algérie', value: 'DZ' },
    { label: 'Équateur', value: 'EC' },
    { label: 'Estonie', value: 'EE' },
    { label: 'Égypte', value: 'EG' },
    { label: 'Sahara occidental', value: 'EH' },
    { label: 'Érythrée', value: 'ER' },
    { label: 'Espagne', value: 'ES' },
    { label: 'Éthiopie', value: 'ET' },
    { label: 'Finlande', value: 'FI' },
    { label: 'Fidji', value: 'FJ' },
    { label: 'îles Malouines', value: 'FK' },
    { label: 'Micronésie', value: 'FM' },
    { label: 'Îles Féroé', value: 'FO' },
    { label: 'France', value: 'FR' },
    { label: 'Gabon', value: 'GA' },
    { label: 'Royaume-Uni', value: 'GB' },
    { label: 'Grenade', value: 'GD' },
    { label: 'Géorgie', value: 'GE' },
    { label: 'Guyane française', value: 'GF' },
    { label: 'Guernesey', value: 'GG' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Gibraltar', value: 'GI' },
    { label: 'Groenland', value: 'GL' },
    { label: 'Gambie', value: 'GM' },
    { label: 'Guinée', value: 'GN' },
    { label: 'Guadeloupe', value: 'GP' },
    { label: 'Guinée équatoriale', value: 'GQ' },
    { label: 'Grèce', value: 'GR' },
    { label: 'Géorgie du Sud-et-les Îles Sandwich du Sud', value: 'GS' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Guam', value: 'GU' },
    { label: 'Guinée-Bissau', value: 'GW' },
    { label: 'Guyana', value: 'GY' },
    { label: 'Hong Kong', value: 'HK' },
    { label: 'Îles Heard-et-MacDonald', value: 'HM' },
    { label: 'Honduras', value: 'HN' },
    { label: 'Croatie', value: 'HR' },
    { label: 'Haïti', value: 'HT' },
    { label: 'Hongrie', value: 'HU' },
    { label: 'Indonésie', value: 'ID' },
    { label: 'Irlande', value: 'IE' },
    { label: 'Israël', value: 'IL' },
    { label: 'Île de Man', value: 'IM' },
    { label: 'Inde', value: 'IN' },
    { label: 'Territoire britannique de l\'océan Indien', value: 'IO' },
    { label: 'Irak', value: 'IQ' },
    { label: 'Iran', value: 'IR' },
    { label: 'Islande', value: 'IS' },
    { label: 'Italie', value: 'IT' },
    { label: 'Jersey', value: 'JE' },
    { label: 'Jamaïque', value: 'JM' },
    { label: 'Jordanie', value: 'JO' },
    { label: 'Japon', value: 'JP' },
    { label: 'Kenya', value: 'KE' },
    { label: 'Kirghizistan', value: 'KG' },
    { label: 'Cambodge', value: 'KH' },
    { label: 'Kiribati', value: 'KI' },
    { label: 'Comores', value: 'KM' },
    { label: 'Saint-Christophe-et-Niévès', value: 'KN' },
    { label: 'Corée du Nord', value: 'KP' },
    { label: 'Corée du Sud', value: 'KR' },
    { label: 'Koweït', value: 'KW' },
    { label: 'Îles Caïmans', value: 'KY' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Laos', value: 'LA' },
    { label: 'Liban', value: 'LB' },
    { label: 'Sainte-Lucie', value: 'LC' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Sri Lanka', value: 'LK' },
    { label: 'Libéria', value: 'LR' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Lituanie', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Lettonie', value: 'LV' },
    { label: 'Libye', value: 'LY' },
    { label: 'Maroc', value: 'MA' },
    { label: 'Monaco', value: 'MC' },
    { label: 'Moldavie', value: 'MD' },
    { label: 'Monténégro', value: 'ME' },
    { label: 'Saint-Martin', value: 'MF' },
    { label: 'Madagascar', value: 'MG' },
    { label: 'Îles Marshall', value: 'MH' },
    { label: 'Macédoine', value: 'MK' },
    { label: 'Mali', value: 'ML' },
    { label: 'Birmanie', value: 'MM' },
    { label: 'Mongolie', value: 'MN' },
    { label: 'Macao', value: 'MO' },
    { label: 'Îles Mariannes du Nord', value: 'MP' },
    { label: 'Martinique', value: 'MQ' },
    { label: 'Mauritanie', value: 'MR' },
    { label: 'Montserrat', value: 'MS' },
    { label: 'Malte', value: 'MT' },
    { label: 'Maurice', value: 'MU' },
    { label: 'Maldives', value: 'MV' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Mexique', value: 'MX' },
    { label: 'Malaisie', value: 'MY' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Namibie', value: 'NA' },
    { label: 'Nouvelle-Calédonie', value: 'NC' },
    { label: 'Niger', value: 'NE' },
    { label: 'Île Norfolk', value: 'NF' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Nicaragua', value: 'NI' },
    { label: 'Pays-Bas', value: 'NL' },
    { label: 'Norvège', value: 'NO' },
    { label: 'Népal', value: 'NP' },
    { label: 'Nauru', value: 'NR' },
    { label: 'Niue', value: 'NU' },
    { label: 'Nouvelle-Zélande', value: 'NZ' },
    { label: 'Oman', value: 'OM' },
    { label: 'Panama', value: 'PA' },
    { label: 'Pérou', value: 'PE' },
    { label: 'Polynésie française', value: 'PF' },
    { label: 'Papouasie-Nouvelle-Guinée', value: 'PG' },
    { label: 'Philippines', value: 'PH' },
    { label: 'Pakistan', value: 'PK' },
    { label: 'Pologne', value: 'PL' },
    { label: 'Saint-Pierre-et-Miquelon', value: 'PM' },
    { label: 'Îles Pitcairn', value: 'PN' },
    { label: 'Porto Rico', value: 'PR' },
    { label: 'Territoires palestiniens', value: 'PS' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Palaos', value: 'PW' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Qatar', value: 'QA' },
    { label: 'La Réunion', value: 'RE' },
    { label: 'Roumanie', value: 'RO' },
    { label: 'Serbie', value: 'RS' },
    { label: 'Russie', value: 'RU' },
    { label: 'Rwanda', value: 'RW' },
    { label: 'Arabie saoudite', value: 'SA' },
    { label: 'Îles Salomon', value: 'SB' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Soudan', value: 'SD' },
    { label: 'Suède', value: 'SE' },
    { label: 'Singapour', value: 'SG' },
    { label: 'Sainte-Hélène', value: 'SH' },
    { label: 'Slovénie', value: 'SI' },
    { label: 'Svalbard et Île Jan Mayen', value: 'SJ' },
    { label: 'Slovaquie', value: 'SK' },
    { label: 'Sierra Leone', value: 'SL' },
    { label: 'Saint-Marin', value: 'SM' },
    { label: 'Sénégal', value: 'SN' },
    { label: 'Somalie', value: 'SO' },
    { label: 'Suriname', value: 'SR' },
    { label: 'Soudan du Sud', value: 'SS' },
    { label: 'Sao Tomé-et-Principe', value: 'ST' },
    { label: 'Salvador', value: 'SV' },
    { label: 'Saint-Martin', value: 'SX' },
    { label: 'Syrie', value: 'SY' },
    { label: 'Swaziland', value: 'SZ' },
    { label: 'Îles Turks et Caïques', value: 'TC' },
    { label: 'Tchad', value: 'TD' },
    { label: 'Terres australes et antarctiques françaises', value: 'TF' },
    { label: 'Togo', value: 'TG' },
    { label: 'Thaïlande', value: 'TH' },
    { label: 'Tadjikistan', value: 'TJ' },
    { label: 'Tokelau', value: 'TK' },
    { label: 'Timor-Leste', value: 'TL' },
    { label: 'Turkménistan', value: 'TM' },
    { label: 'Tunisie', value: 'TN' },
    { label: 'Tonga', value: 'TO' },
    { label: 'Timor oriental', value: 'TP' },
    { label: 'Turquie', value: 'TR' },
    { label: 'Trinité-et-Tobago', value: 'TT' },
    { label: 'Tuvalu', value: 'TV' },
    { label: 'Taïwan', value: 'TW' },
    { label: 'Tanzanie', value: 'TZ' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'Ouganda', value: 'UG' },
    { label: 'Îles mineures éloignées des États-Unis', value: 'UM' },
    { label: 'États-Unis', value: 'US' },
    { label: 'Uruguay', value: 'UY' },
    { label: 'Ouzbékistan', value: 'UZ' },
    { label: 'Vatican', value: 'VA' },
    { label: 'Saint-Vincent-et-les Grenadines', value: 'VC' },
    { label: 'Venezuela', value: 'VE' },
    { label: 'Îles Vierges britanniques', value: 'VG' },
    { label: 'Îles Vierges des États-Unis', value: 'VI' },
    { label: 'Vietnam', value: 'VN' },
    { label: 'Vanuatu', value: 'VU' },
    { label: 'Wallis-et-Futuna', value: 'WF' },
    { label: 'Samoa', value: 'WS' },
    { label: 'Yémen', value: 'YE' },
    { label: 'Mayotte', value: 'YT' },
    { label: 'Yougoslavie', value: 'YU' },
    { label: 'Zambie', value: 'ZM' },
    { label: 'Zaïre', value: 'ZR' },
    { label: 'Zimbabwe', value: 'ZW' },
];

