import { request } from "../utils/axios-utils";

const namespace = "/api/suivis";

export const editSuivi = (addToast: any, _id: any, payload: any) => {
    return request({
        addToast,
        url: namespace + `/${_id}/edit`,
        method: "post",
        data: payload,
    });
};

export const createSuivi = (addToast: any, _id: any, payload: any) => {
    return request({
        addToast,
        url: namespace + `/create/${_id}`,
        method: "post",
        data: payload,
    });
};
