import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Switch from "react-bootstrap-switch";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import ISwitch from "../../../interfaces/forms/ISwitch";

const InputSwitch: React.FC<ISwitch> = (props) => {
    const [switchStatus, setSwitchStatus] = useState<boolean>(
        props.value || false
    );

    const handleSwitch = () => {
        setSwitchStatus(!switchStatus);
    };

    useEffect(() => {
        setSwitchStatus(props.value);
    }, [props.value]);

    return (
        <div
            style={props.style}
            className={classNames(props.className, "my-input-container")}
            id={props.id}
        >
            <Switch
                labelText={props.label}
                bsSize="small"
                wrapperClass="wrapper"
                onText={props.onText ? props.onText : "OUI"}
                onColor={props.isSwitchMajeur ? "danger" : "success"}
                offColor={props.isSwitchMajeur ? "success" : "danger"}
                offText={props.offText ? props.offText : "NON"}
                value={switchStatus}
                onChange={() => {
                    // Classic Switch
                    if (!props.modalSwitch) {
                        handleSwitch();
                        props.onChange(!switchStatus);
                    }
                    // Switch without confirm Modal to unlock
                    else if (
                        props.modalSwitch &&
                        switchStatus &&
                        !props.allAccounts
                    ) {
                        handleSwitch();
                        props.onChange(false);
                    }
                    // Switch with confirm Modal to lock
                    else if (props.modalSwitch && !switchStatus) {
                        props.showModal();
                    }
                    // Switch to unlock in Comptability
                    else {
                        props.onChange(false);
                    }
                }}
            />
        </div>
    );
};

export default InputSwitch;
