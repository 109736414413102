import React, { useState } from "react";

import classNames from "classnames";
import Select from "react-select";
import ISelect from "../../../interfaces/forms/ISelect";

const SelectWithSearch: React.FC<ISelect> = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const onMenuOpen = () => setIsMenuOpen(true);
    const onMenuClose = () => setIsMenuOpen(false);

    return (
        <div
            className={
                props.className === "match-input-size"
                    ? ""
                    : classNames(props.className, "my-input-container")
            }
            style={props.style}
        >
            {props.label && (
                <label htmlFor={props.id}>
                    {props.label} :
                    {props.required && <span className="red_asterix"> *</span>}
                </label>
            )}

            <Select
                inputId="social_organism"
                value={props.value}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                id={props.id}
                name={props.id}
                onChange={(e: any) => props.onChange(e)}
                options={props.options.map((option: any) => ({
                    value: option.value,
                    label: option.label,
                }))}
            />
        </div>
    );
};

export default SelectWithSearch;
