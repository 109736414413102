import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import IMail from "../../interfaces/IMail";

const MailItemMajeur: React.FC<{
    mail: IMail;
    noDivider: boolean;
    handleCheck: Function;
    isChecked: boolean;
}> = (props) => (
    <div className="mail_item_majeur">
        <div className="mail_item">
            {
                <input
                    type="checkbox"
                    onChange={(event) =>
                        props.handleCheck(event.target.checked, props.mail)
                    }
                    checked={props.isChecked}
                />
            }
            <Link
                to={`/single_mail/${props.mail._id}`}
                className="layout-center-start column mail_link"
            >
                <h6>{moment(props.mail.created_at).format("DD/MM/YYYY")}</h6>
                <p>
                    {props.mail.type} :{" "}
                    {props.mail.label.replace(/undefined/g, "")}
                </p>
            </Link>
        </div>
        {!props.noDivider && <div className="divider" />}
    </div>
);

export default MailItemMajeur;
