import React from "react";

const ColorPicker = (props: {
    color: any;
    setColor: any;
    refreshUser: Function;
}) => {
    const { color, setColor, refreshUser } = props;

    return (
        <div>
            <input
                type="color"
                value={color}
                onChange={(e) => {
                    setColor(e.target.value);
                    refreshUser();
                }}
                className="border border-gray-300 rounded"
            />
            <p className="mt-2">Couleur sélectionnée: {color}</p>
        </div>
    );
};

export default ColorPicker;
