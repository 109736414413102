import React, {useState} from "react";
import ModalPayment from "./ModalPayment";

const ModalFactory: React.FC<{
    activated: Boolean;
    title: string;
    stripeReady: boolean;
    subscribeWithSepa: Function;
    updateSepa: Function;
}> = (props) => {
    const [isShowingDialog, setIsShowingDialog] = useState<boolean>(false);

    const showModal = () => {
        setIsShowingDialog(true);
    };

    return (
        <>
            <div>
                <button onClick={() => showModal()} className="valid-button">
                    {props.activated ? "Changer" : "Ajouter"}
                </button>
                {isShowingDialog && (
                    <ModalPayment
                        onCancel={() => setIsShowingDialog(false)}
                        stripeReady={props.stripeReady}
                        subscribeWithSepa={() => props.subscribeWithSepa()}
                        updateSepa={(sepa: any) => props.updateSepa(sepa)}
                        title={props.title}
                    />
                )}
            </div>
        </>
    );
};

export default ModalFactory;
