import React, { useState } from "react";
import { capitalizeFirst } from "../../../../constants/Utils";
import { request } from "../../../../utils/axios-utils";
import ButtonConfirmModalIcon from "../../../layout/modals/ButtonConfirmModalIcon";
import RightMenu from "./RightMenu";
import { useToasts } from "react-toast-notifications";

const Folder: React.FC<{
    key: string;
    view: string;
    folder: any;
    goFolder: Function;
    getFilesAndFolders: Function;
    folderRemove: boolean;
    ondragstart: any;
    ondrop: any;
    ondragover: any;
}> = (props) => {
    const [name, setName] = useState<any>();
    const [isRenaming, setIsRenaming] = useState<boolean>(false);
    const { addToast } = useToasts();

    const contextMenu = (event: any) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        const x = event.clientX;
        const y = event.clientY - 80;
        const folderId = props.folder._id;
        const rightMenu = document.getElementById(`rightMenu_${folderId}`);
        document.getElementById(`rightMenu_${folderId}`).classList.add("on");
        document.addEventListener("click", function bob() {
            document
                .getElementById(`rightMenu_${folderId}`)
                .classList.remove("on");
            this.removeEventListener("click", bob);
        });
        rightMenu.style.left = `${x}px`;
        rightMenu.style.top = `${y}px`;
    };

    const folderRemove = () => {
        request({
            addToast,
            url: `/api/folders/delete/${props.folder._id}`,
            method: "post",
            data: {},
        }).then(({ data }) => {
            props.getFilesAndFolders();
        }).catch();
    };

    const changeColor = (folderId: string, color: string) => {
        request({
            addToast,
            url: `/api/folders/color`,
            method: "post",
            data: {
                folder_id: folderId,
                color: color,
            },
        }).then(({ data }) => {
            props.getFilesAndFolders();
        }).catch();
    };

    const folderRename = (newName: string) => {
        request({
            addToast,
            url: `/api/folders/rename`,
            method: "post",
            data: {
                folder_id: props.folder._id,
                name: capitalizeFirst(newName.trim()),
            },
        }).then(({ data }) => {
            props.getFilesAndFolders();
        }).catch();
    };

    const del = props.folderRemove ? (
        <ButtonConfirmModalIcon
            className="button-delete"
            onValidate={folderRemove}
            icon="delete_forever"
            question={`Voulez vous supprimer le dossier ${props.folder.name} ?`}
            id={"delete_forever"}
        />
    ) : (
        ""
    );

    let inputName = <></>;
    if (!isRenaming) {
        inputName = (
            <p onDoubleClick={() => setIsRenaming(true)} className="name">
                {props.folder.name}
            </p>
        );
    } else {
        inputName = (
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    folderRename(name);
                    setIsRenaming(false);
                }}
            >
                <input
                    autoFocus
                    type="text"
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    onBlur={() => setIsRenaming(false)}
                    defaultValue={props.folder.name}
                />
            </form>
        );
    }

    return (
        <div>
            <div
                className={`Folder ${props.view}`}
                onDragOver={props.ondragover}
                onDrop={(e) => props.ondrop(e, props.folder._id)}
                onDragEnter={props.ondragstart}
            >
                {del}
                <i
                    draggable
                    onDragStart={(event) =>
                        props.ondragstart(event, props.folder._id)
                    }
                    /*                     onDragEnd={(event) => {
                        event.target.style.opacity = 1;
                    }} */
                    onContextMenu={(e) => contextMenu(e)}
                    onClick={() => props.goFolder(props.folder._id)}
                    className={`material-icons folder ${props.folder.color}`}
                >
                    folder
                </i>
                {inputName}
            </div>
            <RightMenu
                folderId={props.folder._id}
                changeColor={(folderId: string, color: string) =>
                    changeColor(folderId, color)
                }
            />
        </div>
    );
};
export default Folder;
