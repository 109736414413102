import moment from "moment";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
    formatTelephone,
    momentUpdateLocale,
} from "../../../../constants/Utils";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import ISocial from "../../../../interfaces/ISocial";
import { request } from "../../../../utils/axios-utils";
import ArrowBackActions from "../../../layout/ArrowBackAction";
import InputPassword from "../../../layout/InputPassword";
import ItemTwoLines from "../../../layout/ItemTwoLines";
import ItemTwoLinesLink from "../../../layout/ItemTwoLinesLink";
import Loader from "../../../layout/Loader";
import IFile from "../Cloud/IFile";

const SingleSocial: React.FC = (props) => {
    const [social, setSocial] = useState<ISocial>(null);
    const [majeur, setMajeur] = useState<IProtectedAdult>(null);

    const [password, setPassword] = useState("");
    const [clipboard, setClipboard] = useState("");
    const [copied, setCopied] = useState<boolean>(false);
    const [fileList, setFileList] = useState<Array<IFile>>(null);

    const { socialId }: { socialId?: string } = useParams();
    const { addToast } = useToasts();
    const history = useHistory();

    momentUpdateLocale();

    useEffect(() => {
        if (!social && !majeur) {
            request({ addToast, url: `/api/socials/${socialId}` })
                .then(({ data }) => {
                    setSocial(data);
                    if (data.files) {
                        request({
                            url: "/api/files/list/from-ids",
                            method: "POST",
                            data: { filesIds: data.files },
                        })
                            .then(({ data }) => {
                                setFileList(data);
                            })
                            .catch();
                    }
                    decryptPassword();
                    request({
                        addToast,
                        url: `/api/majeur/${data.majeur_id}`,
                    }).then(({ data }) => {
                        setMajeur(data);
                    });
                })
                .catch();
        }
    }, []);

    const getLink = (websiteUrl: string) => {
        return websiteUrl;
    };

    const download = (key: string) => {
        request({
            addToast,
            url: `/api/files/downloadWithKey`,
            method: "POST",
            data: { key: key },
            responseType: "blob",
        })
            .then((response) => {
                const blob = response.data;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", key.split("/").pop());
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((data) => {
                const { message } = data;
                addToast(message, { appearance: "error" });
            });
    };

    const removeSocial = () => {
        request({
            addToast,
            url: `/api/socials/${socialId}/delete`,
            method: "post",
            data: {},
        })
            .then(() => {
                window.history.back();
                addToast("La répétition a bien été supprimée.", {
                    appearance: "success",
                });
            })
            .catch();
    };

    const decryptPassword = () => {
        request({ addToast, url: `/api/socials/password/${socialId}` })
            .then(({ data }) => {
                setPassword(data);
                setClipboard(data);
            })
            .catch();
    };
    const changeVisibilityAccounts = () => {};
    const actionsArrowBack =
        majeur && !majeur.archived
            ? [
                  {
                      title: "edit",
                      icon: "edit",
                      action: () =>
                          history.push(
                              `/form_social/${majeur._id}/edit/${social._id}`
                          ),
                  },
                  {
                      title: "delete",
                      icon: "delete_forever",
                      question: "Voulez vous supprimer ce droit social ?",
                      action: removeSocial,
                  },
              ]
            : [];

    const title =
        majeur && window.screen.width > 600
            ? `${majeur.first_name} ${majeur.last_name} | `
            : "";

    return (
        <div className="single_social">
            <ArrowBackActions
                title={`${title} Droit social`}
                actions={actionsArrowBack}
                back={() => window.history.back()}
                classNameTitle={""}
            />
            <div className="page_content">
                {!social && <Loader />}
                {social && (
                    <>
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Nature"
                                content={social.nature || "Non renseigné."}
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Numéro"
                                content={social.numero || "Non renseigné."}
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Organisme"
                                content={
                                    social.contact.organisme
                                        ? social.contact.organisme
                                        : social.organisme
                                        ? social.organisme
                                        : "Non renseigné."
                                }
                                flex="1"
                            />
                        </div>
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Date de début"
                                content={
                                    social.start_date
                                        ? moment(social.start_date).format("L")
                                        : "Non renseignée."
                                }
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Date de fin"
                                content={
                                    social.end_date
                                        ? moment(social.end_date).format("L")
                                        : "Non renseignée."
                                }
                                flex="1"
                            />
                        </div>
                        {social.organisme &&
                            social.contact &&
                            !social.contact._id && (
                                <div className="column flex-1">
                                    <ItemTwoLines
                                        title="Adresse"
                                        content={
                                            social.address ||
                                            social.zip_code ||
                                            social.city
                                                ? social.address +
                                                  " " +
                                                  social.zip_code +
                                                  " " +
                                                  social.city
                                                : "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                    <div className="row layout-xs-column">
                                        <ItemTwoLines
                                            title="Telephone"
                                            content={
                                                social.phone || "Non renseigné."
                                            }
                                            flex="1"
                                        />
                                        <div className="flex005 hide-xs" />
                                        <ItemTwoLines
                                            title="Email"
                                            content={
                                                social.email || "Non renseigné."
                                            }
                                            flex="1"
                                        />
                                    </div>
                                    <ItemTwoLines
                                        title="Contact"
                                        content={
                                            social.contact.first_name ||
                                            social.contact.last_name
                                                ? social.contact.civility +
                                                  " " +
                                                  social.contact.first_name +
                                                  " " +
                                                  social.contact.last_name
                                                : "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                </div>
                            )}
                        {social.contact && social.contact._id && (
                            <div
                                className="informations_generales_container"
                                style={{ padding: "0 5px" }}
                            >
                                {social.contact.addresses &&
                                    social.contact.addresses.length > 0 && (
                                        <div className="block_container">
                                            <h5>Adresses :</h5>
                                            <div className="layout-center-start column">
                                                {social.contact.addresses.map(
                                                    (a) => (
                                                        <div
                                                            key={a.label}
                                                            className="items"
                                                        >
                                                            <h6>{a.label}.</h6>
                                                            <p className="address_zone">
                                                                {a.address}
                                                            </p>
                                                            <p>
                                                                {a.zip_code}{" "}
                                                                {a.city}
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {social.contact.phones &&
                                    social.contact.phones.length > 0 && (
                                        <div className="block_container">
                                            <h5>Téléphones :</h5>
                                            <div className="layout-center-start column">
                                                {social.contact.phones.map(
                                                    (p) => (
                                                        <div
                                                            key={p.label}
                                                            className="items"
                                                        >
                                                            <h6>{p.label}.</h6>
                                                            <p>
                                                                {formatTelephone(
                                                                    p.phone
                                                                )}
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {social.contact.emails &&
                                    social.contact.emails.length > 0 && (
                                        <div className="block_container">
                                            <h5>Emails :</h5>
                                            <div className="layout-center-start column">
                                                {social.contact.emails.map(
                                                    (e) => (
                                                        <div
                                                            key={e.label}
                                                            className="items"
                                                        >
                                                            <h6>{e.label}.</h6>
                                                            <p>{e.email}</p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                            </div>
                        )}
                        <div className="row layout-xs-column password-line">
                            <ItemTwoLinesLink
                                title="Adresse site internet"
                                content={social.websiteUrl || "Non renseigné."}
                                target="_blank"
                                link={getLink(social.websiteUrl)}
                            />
                            <div className="flex005 hide-xs" />

                            {social.login && (
                                <>
                                    <ItemTwoLines
                                        title="Login"
                                        content={
                                            social.login || "Non renseigné."
                                        }
                                    />
                                    <CopyToClipboard text={social.login}>
                                        <i
                                            style={{
                                                marginLeft: 5,
                                                marginTop: 20,
                                            }}
                                            className="material-icons pointer"
                                            onClick={changeVisibilityAccounts}
                                        >
                                            content_copy
                                        </i>
                                    </CopyToClipboard>
                                </>
                            )}
                            <div className="flex005 hide-xs" />
                            {social.login && (
                                <div className="password_field flex-1">
                                    <InputPassword
                                        id="password_social"
                                        className="flex-1"
                                        label="Mot de passe"
                                        decryptFunction={decryptPassword}
                                        value={password || ""}
                                        disabled={true}
                                        onChange={() => {}}
                                        disablePasswordGenerator
                                    />
                                    <CopyToClipboard
                                        text={clipboard}
                                        onCopy={() => {
                                            setCopied(true);
                                        }}
                                    >
                                        <i
                                            style={{
                                                marginLeft: 5,
                                                marginTop: 25,
                                            }}
                                            className="material-icons pointer"
                                            onClick={changeVisibilityAccounts}
                                        >
                                            content_copy
                                        </i>
                                    </CopyToClipboard>
                                    {copied && clipboard !== "" && (
                                        <span>Copié !</span>
                                    )}
                                    {copied && clipboard === "" && (
                                        <span>
                                            Mot de passe de l'ancienne version,
                                            merci de le ressaisir
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                        <ItemTwoLines
                            title="Alerte"
                            content={social.alarm ? "Oui" : "Non"}
                            flex="1"
                        />
                        <div className="my-input-container">
                            <label>Notes :</label>
                            {social && social.notes && (
                                <div className="notes_zone">{social.notes}</div>
                            )}
                            {!social.notes && <div>Non renseignées.</div>}
                        </div>
                        <div className="my-input-container">
                            <label>Document :</label>
                            {fileList &&
                                fileList.length > 0 &&
                                fileList.map((f) => (
                                    <div className="notes_zone layout-start-center">
                                        {f.name}{" "}
                                        <i
                                            onClick={() => download(f.key)}
                                            className="material-icons pointer"
                                        >
                                            cloud_download
                                        </i>
                                    </div>
                                ))}
                            {!fileList && <div>Non renseignées.</div>}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default SingleSocial;
