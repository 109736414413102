import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IAlert from "../../../interfaces/IAlert";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import ListElement from "../../layout/lists/ListElement";
import {momentUpdateLocale} from "../../../constants/Utils";

const Alerts: React.FC = () => {
    const [userAlerts, setUserAlerts] = useState<Array<IAlert> | null>(null);
    const [user, setUser] = useState<IUser>(null);
    const { addToast } = useToasts();

    momentUpdateLocale()

    useEffect(() => {
        if (!user && !userAlerts) {
            request({ addToast, url: `/api/user` }).then(({ data }) => {
                setUser(data);
                request({ addToast, url: `/api/alarms/${data._id}` }).then(
                    ({ data }) => {
                        setUserAlerts(data.alarms);
                    }
                );
            }).catch();
        }
        localStorage.setItem("location", "/alertes");
    }, []);

    return (
        <>
            <SubBar title="Les alertes">
                <Link to={"/alertes/nouvelle/" + null}>
                    <Icon name="add" />
                </Link>
            </SubBar>

            <div className="page_content">
                {!userAlerts && <Loader />}
                {userAlerts && userAlerts.length == 0 && (
                    <div className="layout-center-center row div-no-items">
                        Pour ajouter une alerte cliquez sur le bouton (+) en
                        haut à droite.
                    </div>
                )}
                {userAlerts &&
                    userAlerts.length > 0 &&
                    userAlerts.map((alert: IAlert) => {
                        return (
                            <div
                                className="item_three_lines_link"
                                key={alert._id}
                            >
                                <ListElement>
                                    <Link
                                        to={{
                                            pathname: `/alertes/${alert._id}`,
                                            state: "alertes",
                                        }}
                                        className="layout-center-start column"
                                    >
                                        {alert.protected_adult && (
                                            <>
                                                <h3>
                                                    Alarme :{" "}
                                                    {moment(
                                                        alert.date_alarm
                                                    ).format("L")}{" "}
                                                    | Echéance :{" "}
                                                    {moment(
                                                        alert.date_final
                                                    ).format("L")}{" "}
                                                    |{" "}
                                                    {
                                                        alert.protected_adult
                                                            .civility
                                                    }{" "}
                                                    {
                                                        alert.protected_adult
                                                            .first_name
                                                    }{" "}
                                                    {
                                                        alert.protected_adult
                                                            .last_name
                                                    }
                                                </h3>
                                                <h5>{alert.label}</h5>
                                            </>
                                        )}

                                        {!alert.protected_adult && (
                                            <>
                                                <h3>
                                                    Le{" "}
                                                    {moment(
                                                        alert.date_alarm
                                                    ).format("L")}{" "}
                                                </h3>{" "}
                                                <h5>{alert.label}</h5>
                                            </>
                                        )}
                                    </Link>
                                </ListElement>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default Alerts;
