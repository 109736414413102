import { request } from "../utils/axios-utils";

const namespace = "/api/majeurs";

export const getMajeurs = (addToast: any, _id: string) => {
    return request({ addToast, url: `/api/majeurs/${_id}` });
};

export const getListMajeurs = (addToast: any, _id: string) => {
    return request({ addToast, url: `/api/majeurs/list_majeur/${_id}` });
};

export const searchMajeur = (addToast: any, _id: string, search: string) => {
    return request({ addToast, url: `/api/majeurs/${_id}/search/${search}` });
};
