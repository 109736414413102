import Highcharts from "highcharts";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { endOfTodayDate, startOfYearDate } from "../../../constants/Utils";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import { request } from "../../../utils/axios-utils";
import Loader from "../../layout/Loader";
import NoItem from "../../layout/NoItem";
import InputDatePicker from "../../layout/forms/InputDatePicker";

const StatsMajeurs: React.FC<{ majeurId: string; majeur: IProtectedAdult }> = (
    props
) => {
    const { addToast } = useToasts();

    const [nbKms, setNbKms] = useState<number>(0);
    const [startDate, setStartDate] = useState<Date>(startOfYearDate);
    const [endDate, setEndDate] = useState<Date>(endOfTodayDate);
    const [isLoading, setIsLoading] = useState(false);

    const [statsMajeur, setStatsMajeur] = useState<any>(null);
    const [colors, setColors] = useState<Array<string>>(null);

    const drawCharts = (dataGrouped: any, colorsArray: any) => {
        dataGrouped &&
            Highcharts.chart("container_stats_protege", {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: "Temps passé pour le protégé",
                },
                tooltip: {
                    pointFormat:
                        "Temps passé : <b>{point.hours}h {point.minutes}mn</b>",
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format: "<b>{point.name}</b> : {point.hours}h {point.minutes}mn",
                            style: {
                                color: "black",
                            },
                        },
                    },
                },
                series: [
                    {
                        type: "pie",
                        name: "Time",
                        colors: colorsArray,
                        data: dataGrouped,
                    },
                ],
                credits: {
                    enabled: true,
                    text: "",
                },
            });
    };
    useEffect(() => {
        setIsLoading(true);
        if (!statsMajeur) {
            getStats(startDate, endDate);
        }
    }, []);

    const getStats = (startDate: Date, endDate: Date) => {
        request({
            addToast,
            url: `/api/statistics/majeur/suivis`,
            method: "post",
            data: {
                majeur_id: props.majeurId,
                start_date: startDate,
                end_date: endDate,
            },
        }).then(({ data }) => {
            setStatsMajeur(data.group_by_tags);
            setColors(data.colors);
            setNbKms(data.nb_kms);
            drawCharts(data.group_by_tags, data.colors);
            setIsLoading(false);
        }).catch();
    };

    return (
        <>
            <div className="stats_container">
                <div className="subBar_actions sticky top-90 layout-space-between-center row">
                    <span className="subBar_actions_title">
                        Statistiques du protégé
                    </span>
                    <div className="layout-center-center row subBar_actions_item">
                        <span style={{ color: "white", marginRight: 5 }}>
                            Du :{" "}
                        </span>
                        <InputDatePicker
                            id="start_date"
                            inputDate={moment(startDate, "DD/MM/YYYY").toDate()}
                            onChange={(d: Date) => {
                                d.setHours(0, 0, 0, 0);
                                setStartDate(d), getStats(d, endDate);
                            }}
                        />
                        <div className="flex005 hide-xs" />
                        <span style={{ color: "white", marginRight: 5 }}>
                            Au :{" "}
                        </span>
                        <InputDatePicker
                            id="end_date"
                            inputDate={moment(endDate, "DD/MM/YYYY").toDate()}
                            onChange={(d: Date) => {
                                d.setHours(23, 59, 59, 999);
                                setEndDate(d), getStats(startDate, d);
                            }}
                        />
                    </div>
                </div>
                <div className="page_content">
                    {!statsMajeur && isLoading && <Loader />}
                    {!statsMajeur && !isLoading && (
                        <NoItem text="Aucunes statistiques sur cette période." />
                    )}
                    {statsMajeur && (
                        <>
                            <div className="row layout-center-center">
                                <div
                                    id="container_stats_protege"
                                    className="container_stats statistic_chart"
                                />
                            </div>
                            <div className="row layout-center-center">
                                Nombre de kilomètres sur la période : {nbKms} Km
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
export default StatsMajeurs;
