import React from 'react';

const Help: React.FC<{ url?: string, color?: string,
  width?: number, height?: number }> = (props) => {

  const goToUrl = () => {
    window.open(props.url, '_blank');
  }

  return (
    <i 
      className="material-icons" 
      style={{
        cursor: 'pointer',
        color: props.color || 'white', 
        height: props.height || 26, 
        width: props.width || 26
      }} 
      onClick={goToUrl}>
      live_help
    </i>
  );
}

export default Help;
