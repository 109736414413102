import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    formatTelephone,
    momentUpdateLocale,
} from "../../../../constants/Utils";
import IDebt from "../../../../interfaces/IDebt";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import { request } from "../../../../utils/axios-utils";
import ArrowBackActions from "../../../layout/ArrowBackAction";
import ItemTwoLines from "../../../layout/ItemTwoLines";
import Loader from "../../../layout/Loader";
import IFile from "../Cloud/IFile";

const SingleDebt: React.FC<{}> = (props) => {
    const [debt, setDebt] = useState<IDebt>(null);
    const [fileList, setFileList] = useState<Array<IFile>>([]);

    const [majeur, setMajeur] = useState<IProtectedAdult>();

    const { debtId }: { debtId?: string } = useParams();
    const history = useHistory();

    momentUpdateLocale();

    const download = (key: string) => {
        request({
            addToast,
            url: `/api/files/downloadWithKey`,
            method: "POST",
            data: { key: key },
            responseType: "blob",
        })
            .then((response) => {
                const blob = response.data;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", key.split("/").pop());
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((data) => {
                const { message } = data;
                addToast(message, { appearance: "error" });
            });
    };

    const removeDebt = () => {
        request({
            addToast,
            url: `/api/debts/${debtId}/delete`,
            method: "post",
            data: {},
        })
            .then(() => {
                history.push(`/single_majeur/${majeur._id}/mobImmo`);
                addToast("La dette a bien été supprimée.", {
                    appearance: "success",
                });
            })
            .catch();
    };

    const actionsArrowBack =
        majeur && !majeur.archived
            ? [
                  {
                      title: "edit",
                      icon: "edit",
                      action: () =>
                          history.push(
                              `/form_debt/${majeur._id}/edit/${debtId}`
                          ),
                  },
                  {
                      title: "delete",
                      icon: "delete_forever",
                      question: "Voulez vous supprimer cette dette ?",
                      action: removeDebt,
                  },
              ]
            : [];

    useEffect(() => {
        if (!debt) {
            request({ addToast, url: `/api/debts/${debtId}` })
                .then(({ data }) => {
                    setDebt(data);
                    if (data.files) {
                        request({
                            url: "/api/files/list/from-ids",
                            method: "POST",
                            data: { filesIds: data.files },
                        })
                            .then(({ data }) => {
                                setFileList(data);
                            })
                            .catch();
                    }
                    request({
                        addToast,
                        url: `/api/majeur/${data.majeur_id}`,
                    }).then(({ data }) => {
                        setMajeur(data);
                    });
                })
                .catch();
        }
    }, []);

    const title =
        majeur && window.screen.width > 600
            ? `${majeur.first_name} ${majeur.last_name} | `
            : "";
    return (
        <div className="single_debt">
            <ArrowBackActions
                title={`${title} Dette`}
                actions={actionsArrowBack}
                back={() => window.history.back()}
                classNameTitle={
                    "arrow_back_actions sticky layout-start-center row"
                }
            />
            <div className="page_content">
                {!debt && <Loader />}

                {debt && (
                    <>
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Nature"
                                content={debt.nature}
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Label"
                                content={debt.label}
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Organisme"
                                content={debt.contact.organisme}
                                flex="1"
                            />
                        </div>
                        {debt.contact && debt.contact._id && (
                            <div
                                className="informations_generales_container"
                                style={{ padding: "0 5px" }}
                            >
                                {debt.contact.addresses &&
                                    debt.contact.addresses.length > 0 && (
                                        <div className="block_container">
                                            <h5>Adresses :</h5>
                                            <div className="layout-center-start column">
                                                {debt.contact.addresses.map(
                                                    (a) => (
                                                        <div
                                                            key={a.label}
                                                            className="items"
                                                        >
                                                            <h6>{a.label}.</h6>
                                                            <p className="address_zone">
                                                                {a.address}
                                                            </p>
                                                            <p>
                                                                {a.zip_code}{" "}
                                                                {a.city}
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {debt.contact.phones &&
                                    debt.contact.phones.length > 0 && (
                                        <div className="block_container">
                                            <h5>Téléphones :</h5>
                                            <div className="layout-center-start column">
                                                {debt.contact.phones.map(
                                                    (p) => (
                                                        <div
                                                            key={p.label}
                                                            className="items"
                                                        >
                                                            <h6>{p.label}.</h6>
                                                            <p>
                                                                {formatTelephone(
                                                                    p.phone
                                                                )}
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {debt.contact.emails &&
                                    debt.contact.emails.length > 0 && (
                                        <div className="block_container">
                                            <h5>Emails :</h5>
                                            <div className="layout-center-start column">
                                                {debt.contact.emails.map(
                                                    (e) => (
                                                        <div
                                                            key={e.label}
                                                            className="items"
                                                        >
                                                            <h6>{e.label}.</h6>
                                                            <p>{e.email}</p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                            </div>
                        )}
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Date"
                                content={
                                    debt.date
                                        ? moment(debt.date).format("L")
                                        : "Non renseignée."
                                }
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Montant"
                                content={
                                    debt.amount
                                        ? parseFloat(debt.amount)
                                            ? (
                                                  debt.amount || 0
                                              ).toLocaleString()
                                            : debt.amount
                                        : "Non renseigné."
                                }
                                flex="1"
                            />
                        </div>
                        <div className="my-input-container">
                            <label htmlFor="notes">Notes :</label>
                            {debt && debt.notes && (
                                <div className="text_zone">{debt.notes}</div>
                            )}
                            {debt && !debt.notes && <p>Non renseignées.</p>}
                        </div>
                        <div className="my-input-container">
                            <label>Document :</label>
                            {fileList &&
                                fileList.length > 0 &&
                                fileList.map((f) => (
                                    <div className="notes_zone layout-start-center">
                                        {f.name}{" "}
                                        <i
                                            onClick={() => download(f.key)}
                                            className="material-icons pointer"
                                        >
                                            cloud_download
                                        </i>
                                    </div>
                                ))}
                            {fileList && fileList.length === 0 && (
                                <div>Non renseignées.</div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default SingleDebt;
function addToast(arg0: string, arg1: { appearance: string }) {
    throw new Error("Function not implemented.");
}
