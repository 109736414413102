import React from 'react';
import { cardPics } from '../../../constants/Utils';

const CardRow: React.FC <{brand:string, exp_month:number, exp_year:number, last4:string, name:string}> = (props) => {
  
  const hiddenLine = (brand:string) => (brand === 'amex' ? '•••• •••••• •' : '•••• •••• •••• ');

  const brandLowered = props.brand.toLowerCase();

  return (
    <>
      <div>
            <img
                style={{ marginRight: '1em', verticalAlign: 'middle' }}
                src={cardPics[brandLowered]}
                alt={props.brand}
            />
            Nom : <strong>
                      <pre style={{ display: 'inline', fontSize: '1.5em' }}>
                          {props.name}
                      </pre>
                  </strong>,&nbsp;
            Expiration : <strong>
                      <pre style={{ display: 'inline', fontSize: '1.5em' }}>
                          {`${props.exp_month}/${props.exp_year}`}
                      </pre>
                  </strong>,&nbsp;
            Numéro : <strong>
                      <pre style={{ display: 'inline', fontSize: '1.5em' }}>
                          {`${hiddenLine(brandLowered)}${props.last4}`}
                      </pre>
                  </strong>
        </div>
    </>
  );
}

export default CardRow;