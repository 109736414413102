import sortBy from "lodash/sortBy";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { uploadLinkDoc } from "../../_api/S3";
import {
    CATS,
    MVT_WITH_AMOUNT,
    REPETITION_VALUES,
    capitalizeFirstLetterOnly,
} from "../../constants/Utils";
import IAccount from "../../interfaces/IAccount";
import IOperation from "../../interfaces/IOperation";
import { request } from "../../utils/axios-utils";
import InputComponent from "../layout/forms/InputComponent";
import InputDatePicker from "../layout/forms/InputDatePicker";
import ButtonConfirmModalIcon from "../layout/modals/ButtonConfirmModalIcon";
import IFile from "../pages/Majeurs/Cloud/IFile";
import UploadDocument from "../pages/Majeurs/UploadDocument";
import SelectWithSearch from "./forms/selectWithSearch";

const ItemOperation: React.FC<{
    key: string;
    itemId: string;
    operation: IOperation;
    index: number;
    closed: boolean;
    account: IAccount;
    accountsOption: any;
    listSubCategories: any;
    getOperationsList: Function;
    majeurId: string;
    fromRemoveModal: boolean;
}> = (props) => {
    const [labelLink, setLabelLink] = useState<string>("");
    const [fromCloneOperation, setfromCloneOperation] =
        useState<boolean>(false);
    const [date, setDate] = useState<Date>(null);
    const [label, setLabel] = useState<string>(null);
    const [amount, setAmount] = useState<string>(null);
    const [valueMouvement, setValueMouvement] = useState<string>(null);
    const [selectedMouvement, setSelectedMouvement] = useState<any>(null);
    const [cats, setCats] = useState<any>(null);
    const [listSubCategories, setListSubCategories] = useState<any>(null);
    const [valueCategorie, setValueCategorie] = useState<any>(null);
    const [sousCats, setSousCats] = useState<any>(null);
    const [selectedCategorie, setSelectedCategorie] = useState<any>(null);
    const [selectedAccountsTarget, setSelectedAccountsTarget] =
        useState<any>("");
    const [selectedSousCategorie, setSelectedSousCategorie] =
        useState<any>(null);
    const [valueSousCategorie, setValueSousCategorie] = useState<any>(null);
    const [valueRepetition, setValueRepetition] = useState<string>(null);

    const [filesSelected, setFilesSelected] = useState(null);
    const [files, setFiles] = useState<Array<string>>(null);
    const [fileList, setFileList] = useState<Array<IFile>>(null);

    const download = (key: string) => {
        request({
            addToast,
            url: `/api/files/downloadWithKey`,
            method: "POST",
            data: { key: key },
            responseType: "blob",
        })
            .then((response) => {
                const blob = response.data;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", key.split("/").pop());
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((data) => {
                const { message } = data;
                addToast(message, { appearance: "error" });
            });
    };

    const yearClosed = () => {
        if (props.account && props.account.hasOwnProperty("locks")) {
            let year = moment(
                props.operation.date.$date
                    ? parseInt(props.operation.date.$date.$numberLong)
                    : props.operation.date
            ).format("YYYY");

            if (year in props.account.locks) {
                return props.account.locks[year];
            }

            return false;
        }

        return false;
    };

    const isYearClosed = yearClosed();

    const [opeSettingsDone, setOpeSettingsDone] = useState<boolean>(false);

    const { addToast } = useToasts();

    // Reset modal operation to props properties
    const resetOperation = () => {
        updateItemdata();
        setfromCloneOperation(false);
    };

    // Security before updating an operation
    const verifUpdateOrDuplicateOperation = () => {
        if (!amount || !amount.toString().trim()) {
            addToast("Il manque le montant.", { appearance: "warning" });
            return false;
        }

        const newAmount = +amount.toString().replace(",", ".");
        const dateMoment = moment(date, "DD/MM/YYYY");

        if (!date) {
            addToast("Attention à la date.", { appearance: "warning" });
            return false;
        } else if (!newAmount) {
            addToast("Le montant doit être un chiffre", {
                appearance: "warning",
            });
            return false;
        } else if (newAmount === 0) {
            addToast("Le montant ne peut pas être égal à 0.", {
                appearance: "warning",
            });
            return false;
        } else if (newAmount < 0) {
            addToast("Le montant ne peut pas être négatif.", {
                appearance: "warning",
            });
            return false;
        } else if (
            !dateMoment.isValid() ||
            dateMoment.toDate().getTime() < new Date(2014, 1, 1).getTime()
        ) {
            addToast("Attention la date est inférieure à 2014.", {
                appearance: "warning",
            });
            return false;
        }
        return true;
    };

    // Update operation selected
    const updateOrDuplicateOperation = async () => {
        const newAmount = +amount.toString().replace(",", ".");

        date.setHours(date.getHours() + 1);
        let file: any[] = [];
        if (filesSelected) {
            const formData = new FormData();
            for (let i = 0; i < filesSelected.length; i++) {
                formData.append("documents[]", filesSelected[i]);
            }
            formData.append("majeur_id", props.majeurId);
            formData.append("to", "Opérations");
            await uploadLinkDoc(addToast, formData)
                .then(({ data }) => {
                    file = data.data.filesIds;
                    setFilesSelected(null);
                })
                .catch();
        } else {
            file = files ? files : props.operation.files;
        }

        const updateOperation: any = {
            _id: props.operation._id,
            mouvement: {
                name: selectedMouvement.name
                    ? selectedMouvement.name
                    : selectedMouvement.label,
                value: parseInt(valueMouvement),
            },
            date,
            label: label ? label.trim() : "",
            amount: newAmount,
            category: null,
            sub_category: null,
            cpt_cible: null,
            repetition: valueRepetition,
            files: file,
        };

        if (parseInt(valueMouvement) < 2) {
            updateOperation.category = {
                name: selectedCategorie.name
                    ? selectedCategorie.name
                    : selectedCategorie.label,
                mvt: selectedCategorie.mvt,
                value: parseInt(selectedCategorie.value),
            };
            updateOperation.sub_category = {
                name: selectedSousCategorie?.label,
            };
            updateOperation.cpt_cible = {
                _id: "",
                number: "",
                type: "",
                linked_operation_id: "",
            };
        } else {
            updateOperation.category = {
                mvt: "",
                name: "",
                value: "",
            };
            updateOperation.sub_category = {
                name: "",
            };
            updateOperation.cpt_cible = selectedAccountsTarget
                ? props.accountsOption[parseInt(selectedAccountsTarget) - 1]
                      .data
                : props.accountsOption[0].data;
        }

        if (!updateOperation.amount) {
            addToast("Il manque le montant.", { appearance: "warning" });
        } else if (newAmount < 0) {
            addToast("Le montant ne peut pas être négatif.", {
                appearance: "warning",
            });
        } else {
            modifyOrDuplicateOperation(updateOperation);
        }
    };

    // Call back to update an operation in DB
    const modifyOrDuplicateOperation = (operation: any) => {
        if (!fromCloneOperation) {
            request({
                url: "/api/operations/update",
                method: "post",
                data: { operation: operation },
            })
                .then((data) => {
                    addToast("L'opération a bien été modifiée", {
                        appearance: "success",
                    });
                    props.getOperationsList();
                })
                .catch((data) => {
                    addToast(
                        "L'opération n'a pas été modifiée, nous avons été contacté.",
                        { appearance: "error" }
                    );
                });
        } else {
            operation["majeur_id"] = props.majeurId;
            operation["account_id"] = props.account._id;

            request({
                addToast,
                url: "/api/operations/create",
                method: "post",
                data: { operation: operation },
            })
                .then((data) => {
                    addToast("L'opération a bien été créée", {
                        appearance: "success",
                    });
                    props.getOperationsList();
                })
                .catch();

            setfromCloneOperation(false);
        }
    };

    // Call back to remove the operation in DB
    const removeOperation = () => {
        request({
            addToast,
            url: `/api/operations/remove/${props.operation._id}`,
            method: "post",
            data: {},
        })
            .then((data) => {
                addToast("L'opération a bien été supprimée", {
                    appearance: "success",
                });
                props.getOperationsList();
            })
            .catch();
    };

    // Mark as "vu" or not an operation
    const vu = () => {
        request({
            addToast,
            url: `/api/operations/markAsVu/${props.operation._id}/${props.operation.vu}`,
            method: "post",
            data: {},
        })
            .then((data) => {
                props.getOperationsList();
            })
            .catch();
    };

    // Close or unclose an operation
    const openCloseOneOpe = (operation: IOperation, toClose: boolean) => {
        request({
            addToast,
            url: "/api/operations/openClosed",
            method: "post",
            data: { operation: operation, to_close: toClose },
        })
            .then((data) => {
                addToast(
                    `Opération ${toClose ? "vérouillée" : "dévérouillée"}`,
                    {
                        appearance: "success",
                    }
                );
                props.getOperationsList();
            })
            .catch();
    };

    // To reaffect operations
    const handleValidateAddedLabel = (labelLink: string) => {
        const newLabel = capitalizeFirstLetterOnly(labelLink);
        request({
            addToast,
            url: "/api/operations/reaffect",
            method: "post",
            data: {
                account_id: props.operation.account_id,
                mouvement_value: props.operation.mouvement.value,
                category_value: props.operation.category.value,
                subcategory_name: props.operation.sub_category.name,
                label_link: newLabel,
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`Le label à bien été associé et réaffecté `, {
                    appearance: "success",
                });
                setLabelLink("");
                props.getOperationsList();
            })
            .catch();
    };

    // Open the modal to reaffect operations
    const handleContextMenu = (event: any) => {
        const doc = document as any;
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        let text = "";
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (doc.selection && doc.selection.type !== "Control") {
            text = doc.selection.createRange().text;
        }

        if (text) {
            setLabelLink(text);
            document
                .getElementById(
                    `button_to_open_modal_addLabelLink_${props.itemId}`
                )
                .click();
        }
    };

    const updateMouvement = (value: any) => {
        if (value) {
            if (parseInt(value) < 2) {
                const categories = sortBy(
                    CATS.filter((c) => c.mvt === parseInt(value)),
                    "label"
                );
                const listSubCategoriesList =
                    listSubCategories[categories[0].value];
                setValueMouvement(value);
                setCats(categories);
                setValueCategorie(categories[0].value);
                setSelectedCategorie(categories[0]);
                setSousCats(listSubCategoriesList);
                setValueSousCategorie(listSubCategoriesList[0].value);
                setSelectedSousCategorie(listSubCategoriesList[0]);
            } else {
                setValueMouvement(value);
            }

            setSelectedMouvement(MVT_WITH_AMOUNT[value]);
        }
    };

    const updateCategory = (value: any) => {
        if (value) {
            setValueCategorie(value);
            setSelectedCategorie(cats.filter((c: any) => c.value === value)[0]);

            if (listSubCategories) {
                let subCats = listSubCategories[value]
                    ? sortBy(listSubCategories[value], "label")
                    : [];
                setSousCats(subCats);
                setValueSousCategorie(subCats[0].value);
                setSelectedSousCategorie(subCats[0]);
            }
        }
    };

    // Reset all values of the operation item
    const updateItemdata = () => {
        setFiles(props.operation.files);
        setDate(new Date(props.operation.date));
        setLabel(props.operation.label);
        setAmount(props.operation.amount);
        setValueMouvement(props.operation.mouvement.value.toString());
        setSelectedMouvement(props.operation.mouvement);
        setCats(
            sortBy(
                CATS.filter((c) => c.mvt === props.operation.mouvement.value),
                "label"
            )
        );
        setListSubCategories(props.listSubCategories);
        if (props.operation.files && props.operation.files.length > 0) {
            request({
                url: "/api/files/list/from-ids",
                method: "POST",
                data: { filesIds: props.operation.files },
            })
                .then(({ data }) => {
                    setFileList(data);
                })
                .catch();
        } else {
            setFilesSelected(null);
            setFiles([]);
            setFileList([]);
        }
        let categories = props.operation.category
            ? CATS.filter((c) => c.mvt === props.operation.mouvement.value)
            : null;
        if (categories && categories.length > 0) {
            setValueCategorie(
                categories
                    .filter(
                        (c: any) =>
                            c.value ===
                            props.operation.category.value.toString()
                    )[0]
                    .value.toString()
            );
            setSelectedCategorie(
                categories.filter(
                    (c: any) =>
                        c.value === props.operation.category.value.toString()
                )[0]
            );
        } else {
            setValueCategorie("");
            setSelectedCategorie("");
        }

        setSousCats(
            props.listSubCategories &&
                props.listSubCategories.length > 0 &&
                props.operation.category.value != null &&
                props.listSubCategories[props.operation.category.value]
                ? sortBy(
                      props.listSubCategories[props.operation.category.value],
                      "label"
                  )
                : []
        );
        setValueRepetition(
            props.operation.repetition ? props.operation.repetition : "_AUCUNE_"
        );

        let subCats = props.listSubCategories
            ? props.listSubCategories[props.operation.category.value]
            : null;
        if (
            subCats &&
            subCats.length > 0 &&
            props.operation.sub_category.name
        ) {
            if (props.operation.sub_category.name == "Emolument MJPM") {
                let emolumentsSubcat = subCats.filter(
                    (sc: any) => sc.label === "Emoluments MJPM"
                );
                setValueSousCategorie(
                    emolumentsSubcat.length > 0
                        ? emolumentsSubcat[0].value
                        : subCats.filter((sc: any) => sc.label === "Autres")[0]
                              .value
                );
                setSelectedSousCategorie(
                    emolumentsSubcat.length > 0
                        ? emolumentsSubcat[0]
                        : subCats.filter((sc: any) => sc.label === "Autres")[0]
                );
            } else {
                let subcat = subCats.filter(
                    (sc: any) => sc.label === props.operation.sub_category.name
                );
                setValueSousCategorie(
                    subcat.length !== 0 ? subcat[0].value : "Autres"
                );
                setSelectedSousCategorie(
                    subCats.filter(
                        (sc: any) =>
                            sc.label === props.operation.sub_category.name
                    )[0]
                );
            }
        } else {
            setValueSousCategorie("");
            setSelectedSousCategorie("");
        }

        if (props.operation.cpt_cible.number) {
            let selectedAccountValue = props.accountsOption.filter(
                (a: any) => a.data.number === props.operation.cpt_cible.number
            );
            if (selectedAccountValue.length > 0) {
                setSelectedAccountsTarget(selectedAccountValue[0].value);
            }
        }
    };

    useEffect(() => {
        if (!opeSettingsDone) {
            updateItemdata();
            setOpeSettingsDone(true);
        }
    }, [sousCats, valueSousCategorie, valueCategorie, selectedCategorie]);

    return (
        <>
            {opeSettingsDone && (
                <div
                    className={
                        "item_operation layout-start-center row " +
                        (props.operation.temp ? "temp " : "") +
                        (props.index % 2 ? "" : "odd")
                    }
                >
                    {!props.operation.temp && !props.fromRemoveModal && (
                        <input
                            type="checkbox"
                            checked={props.operation.vu}
                            onChange={() => vu()}
                        />
                    )}
                    <div className="jour">
                        {moment(props.operation.date).format("DD")}
                    </div>
                    {props.operation &&
                        props.operation.cpt_cible &&
                        props.operation.cpt_cible._id &&
                        props.operation.cpt_cible._id != "_EXTERNE_" && (
                            <div className="flex-1">
                                <p>
                                    Virement :{" "}
                                    <Link
                                        className="account_cible_link"
                                        to={`/single_account/${props.operation.cpt_cible._id}/operations`}
                                    >
                                        {props.operation.cpt_cible.type}
                                        N° {props.operation.cpt_cible.number}
                                    </Link>
                                </p>
                            </div>
                        )}
                    {props.operation &&
                        props.operation.cpt_cible &&
                        props.operation.cpt_cible._id &&
                        props.operation.cpt_cible._id == "_EXTERNE_" && (
                            <div className="flex-1">
                                <p>Virement : Compte externe</p>
                            </div>
                        )}
                    {props.operation &&
                        props.operation.category &&
                        props.operation.sub_category &&
                        props.operation.sub_category.name && (
                            <div
                                className={
                                    "flex-1 " +
                                    (props.operation.sub_category.name ===
                                        "Autres" ||
                                    props.operation.sub_category.name ===
                                        "Autre"
                                        ? "sub_category_autre"
                                        : "")
                                }
                            >
                                {props.operation.sub_category.name}
                            </div>
                        )}
                    {props.operation && props.operation.label && (
                        <div
                            onContextMenu={(e) => handleContextMenu(e)}
                            className="flex-1 operation_label row layout-start-center hide-xs"
                        >
                            {props.operation.label}
                            {props.operation.fintecture_transaction_id && "*"}
                        </div>
                    )}
                    <div
                        className={
                            "odd solde " +
                            (props.operation.mouvement.value === 1 ||
                            props.operation.mouvement.value === 3
                                ? "red"
                                : "")
                        }
                    >
                        {props.operation.mouvement.value === 1 ||
                        props.operation.mouvement.value === 3
                            ? "-"
                            : ""}{" "}
                        {parseFloat(props.operation.amount).toLocaleString(
                            "fr-FR",
                            {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }
                        ) || 0.0}{" "}
                        €
                    </div>
                    {!props.fromRemoveModal &&
                        !props.operation.closed &&
                        !props.closed &&
                        !props.operation.temp &&
                        !isYearClosed && (
                            <div className="item_operation_actions row layout-center-center">
                                {/* Update operation modal */}
                                <ButtonConfirmModalIcon
                                    id={`${props.itemId}`}
                                    beforeValidate={() =>
                                        verifUpdateOrDuplicateOperation()
                                    }
                                    onValidate={() =>
                                        updateOrDuplicateOperation()
                                    }
                                    onClose={() => resetOperation()}
                                    onOpen={() => resetOperation()}
                                    iconClassName="update-icon"
                                    icon="edit"
                                    question="Modifier l'opération"
                                >
                                    <div className="layout-space-around-center column full-width">
                                        <div className="page_content column form_add_operation">
                                            <div className="my-input-container column">
                                                <div className="my-input-container column margin-bottom">
                                                    <SelectWithSearch
                                                        id="Mouvement"
                                                        label="Mouvement"
                                                        className="flex-1"
                                                        needDefaultValue={true}
                                                        required
                                                        options={
                                                            MVT_WITH_AMOUNT
                                                        }
                                                        value={{
                                                            value: valueMouvement,
                                                            label: MVT_WITH_AMOUNT.find(
                                                                (mov: any) =>
                                                                    mov.value ===
                                                                    valueMouvement
                                                            )
                                                                ? MVT_WITH_AMOUNT.find(
                                                                      (
                                                                          mov: any
                                                                      ) =>
                                                                          mov.value ===
                                                                          valueMouvement
                                                                  ).label
                                                                : null,
                                                        }}
                                                        onChange={(e: any) => {
                                                            updateMouvement(
                                                                e.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                {parseInt(valueMouvement) >
                                                1 ? (
                                                    <div className="my-input-container column margin-bottom">
                                                        <SelectWithSearch
                                                            id="Compte_cible"
                                                            label="Compte cible"
                                                            className="flex-1"
                                                            needDefaultValue={
                                                                true
                                                            }
                                                            required
                                                            options={
                                                                props.accountsOption
                                                            }
                                                            value={{
                                                                value: selectedAccountsTarget,
                                                                label: props.accountsOption.find(
                                                                    (
                                                                        accountsOption: any
                                                                    ) =>
                                                                        accountsOption.value ===
                                                                        selectedAccountsTarget
                                                                )
                                                                    ? props.accountsOption.find(
                                                                          (
                                                                              accountsOption: any
                                                                          ) =>
                                                                              accountsOption.value ===
                                                                              selectedAccountsTarget
                                                                      ).label
                                                                    : null,
                                                            }}
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                setSelectedAccountsTarget(
                                                                    e.value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="my-input-container column margin-bottom">
                                                            <SelectWithSearch
                                                                id="Categorie"
                                                                label="Catégorie"
                                                                className="flex-1"
                                                                needDefaultValue={
                                                                    true
                                                                }
                                                                required
                                                                options={cats}
                                                                value={{
                                                                    value: valueCategorie,
                                                                    label: cats.find(
                                                                        (
                                                                            cat: any
                                                                        ) =>
                                                                            cat.value ===
                                                                            valueCategorie
                                                                    )
                                                                        ? cats.find(
                                                                              (
                                                                                  cat: any
                                                                              ) =>
                                                                                  cat.value ===
                                                                                  valueCategorie
                                                                          )
                                                                              .label
                                                                        : null,
                                                                }}
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    updateCategory(
                                                                        e.value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="my-input-container column">
                                                            <SelectWithSearch
                                                                id="Sous_Categorie"
                                                                label="Sous catégorie"
                                                                className="flex-1"
                                                                needDefaultValue={
                                                                    true
                                                                }
                                                                required
                                                                options={
                                                                    sousCats
                                                                }
                                                                value={{
                                                                    value: valueSousCategorie,
                                                                    label: sousCats.find(
                                                                        (
                                                                            sousCat: any
                                                                        ) =>
                                                                            sousCat.value ===
                                                                            valueSousCategorie
                                                                    )
                                                                        ? sousCats.find(
                                                                              (
                                                                                  sousCat: any
                                                                              ) =>
                                                                                  sousCat.value ===
                                                                                  valueSousCategorie
                                                                          )
                                                                              .label
                                                                        : null,
                                                                }}
                                                                onChange={(
                                                                    e: any
                                                                ) => {
                                                                    setValueSousCategorie(
                                                                        e.value
                                                                    );

                                                                    let sousCat =
                                                                        sousCats.find(
                                                                            (
                                                                                sousCat: any
                                                                            ) =>
                                                                                sousCat.value ===
                                                                                e.value
                                                                        );
                                                                    if (
                                                                        sousCat
                                                                    ) {
                                                                        setSelectedSousCategorie(
                                                                            sousCat
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <InputDatePicker
                                                id="theDate"
                                                label="Date"
                                                required
                                                noIcon={true}
                                                inputDate={date}
                                                onChange={(date: Date) =>
                                                    setDate(date)
                                                }
                                            />
                                            <InputComponent
                                                id="aide_min_percent"
                                                type="text"
                                                required
                                                className="my-input"
                                                label="Label"
                                                value={label}
                                                onChange={(e) =>
                                                    setLabel(e.target.value)
                                                }
                                            />
                                            <InputComponent
                                                id="amount"
                                                type="text"
                                                className="my-input"
                                                label="Montant"
                                                required
                                                value={amount}
                                                onChange={(e) =>
                                                    setAmount(
                                                        e.target.value.replace(
                                                            ",",
                                                            "."
                                                        )
                                                    )
                                                }
                                                maxLength={50}
                                            />
                                            {fromCloneOperation && (
                                                <div className="my-input-container column">
                                                    <SelectWithSearch
                                                        id="repetition"
                                                        label="Répétition"
                                                        className="flex-1"
                                                        needDefaultValue={true}
                                                        required
                                                        options={
                                                            REPETITION_VALUES
                                                        }
                                                        value={{
                                                            value: valueRepetition,
                                                            label: REPETITION_VALUES.find(
                                                                (rep: any) =>
                                                                    rep.value ===
                                                                    valueRepetition
                                                            )
                                                                ? REPETITION_VALUES.find(
                                                                      (
                                                                          rep: any
                                                                      ) =>
                                                                          rep.value ===
                                                                          valueRepetition
                                                                  ).label
                                                                : null,
                                                        }}
                                                        onChange={(e: any) => {
                                                            setValueRepetition(
                                                                e.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <UploadDocument
                                                filesSelected={filesSelected}
                                                isMultiple={false}
                                                fileList={fileList}
                                                setFilesSelected={
                                                    setFilesSelected
                                                }
                                                files={files}
                                                setFiles={setFiles}
                                                setFileList={setFileList}
                                            />
                                        </div>
                                    </div>
                                </ButtonConfirmModalIcon>

                                {/* Remove operation modal */}
                                <ButtonConfirmModalIcon
                                    id="removeOpe"
                                    iconClassName="remove-icon"
                                    onValidate={() => removeOperation()}
                                    icon="delete_forever"
                                    question={
                                        "Voulez vous supprimer l'opération du " +
                                        moment(props.operation.date).format(
                                            "DD/MM/YYYY"
                                        ) +
                                        " de " +
                                        (
                                            props.operation.amount || 0
                                        ).toLocaleString() +
                                        "€ ?"
                                    }
                                />
                                <i
                                    onClick={() => {
                                        setfromCloneOperation(true);
                                        updateItemdata();
                                        document
                                            .getElementById(
                                                `button_to_open_modal_${props.itemId}`
                                            )
                                            .click();
                                    }}
                                    className="material-icons"
                                    style={{
                                        fontSize: 16,
                                        marginLeft: 5,
                                        cursor: "pointer",
                                    }}
                                >
                                    content_copy
                                </i>
                            </div>
                        )}
                    <i
                        onClick={() => {
                            if (
                                !props.operation.files ||
                                props.operation.files.length === 0
                            ) {
                                addToast("Il n'y a aucune pièce jointe", {
                                    appearance: "warning",
                                });
                                return;
                            }
                            request({
                                url: "/api/files/list/from-ids",
                                method: "POST",
                                data: {
                                    filesIds: props.operation.files,
                                },
                            })
                                .then(({ data }) => {
                                    console.log(data[0].key);
                                    download(data[0].key);
                                })
                                .catch();
                        }}
                        className="material-icons"
                        style={{
                            fontSize: 16,
                            marginLeft: 5,
                            cursor: "pointer",
                            color:
                                props.operation.files &&
                                props.operation.files.length > 0
                                    ? "#2196F3"
                                    : "black",
                        }}
                    >
                        link
                    </i>{" "}
                    {props.operation.closed &&
                        !props.closed &&
                        !isYearClosed && (
                            <div className="item_operation_actions row layout-center-center">
                                <ButtonConfirmModalIcon
                                    id="closedOperation"
                                    iconClassName="update-icon"
                                    onValidate={() =>
                                        openCloseOneOpe(props.operation, false)
                                    }
                                    icon="lock"
                                    question={`Voulez vous dévérouiller l'opération du ${moment(
                                        props.operation.date
                                    ).format("DD/MM/YYYY")} de ${(
                                        props.operation.amount || 0
                                    ).toLocaleString()} € ?`}
                                />
                            </div>
                        )}
                    {!props.operation.closed &&
                        !props.closed &&
                        !isYearClosed && (
                            <div className="item_operation_actions row layout-center-center">
                                <ButtonConfirmModalIcon
                                    id="closedOperation"
                                    iconClassName="update-icon"
                                    onValidate={() =>
                                        openCloseOneOpe(props.operation, true)
                                    }
                                    icon="lock_open"
                                    question={`Voulez vous vérouiller l'opération du ${moment(
                                        props.operation.date
                                    ).format("DD/MM/YYYY")} de ${(
                                        props.operation.amount || 0
                                    ).toLocaleString()} € ?`}
                                />
                            </div>
                        )}
                    {isYearClosed && (
                        <div className="item_operation_actions row layout-center-center">
                            <ButtonConfirmModalIcon
                                id="closedYear"
                                iconClassName="update-icon"
                                onValidate={() => {}}
                                icon="lock"
                                question={`L'année est cloturée`}
                            />
                        </div>
                    )}
                    <div className="ope_hidden">
                        <ButtonConfirmModalIcon
                            iconClassName="none_icon"
                            id={`addLabelLink_${props.itemId}`}
                            onValidate={() =>
                                handleValidateAddedLabel(labelLink)
                            }
                            icon="lock"
                            question={`Ajouter une liaison sur : "${labelLink}" `}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
export default ItemOperation;
