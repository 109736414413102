import React from 'react';
import {useSpeechRecognition} from 'react-speech-kit';

interface SpeechInputProps {
    text: string;
    setText: React.Dispatch<React.SetStateAction<string>>;
}

const SpeechInput: React.FC<SpeechInputProps> = ({text, setText}) => {

    const {listen, listening, stop} = useSpeechRecognition({
        onResult: (result: string) => {
            setText((prevText) => `${prevText} ${result}`);
        },
        onError: () => {
            stop();
        },
    });

    const handleListen = () => {
        listen({interimResults: false})
    };

    const handleStop = () => {
        stop();
    };

    const isMobile = (): boolean => {
        return /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent);
    };


    return (
        <div>
            {(window.SpeechRecognition || window.webkitSpeechRecognition) && !isMobile() && (
                <i className={"material-icons pointer"} onClick={listening ? handleStop : handleListen}>
                    {listening ? 'mic' : 'mic_off'}
                </i>
            )}

            {!(window.SpeechRecognition || window.webkitSpeechRecognition) && !isMobile() && (
                <div className={"micTooltip"}>
                    <i className={"material-icons pointer"}>
                        mic_off
                    </i>
                    <span className={"micTooltipText"}>Fonctionnalité compatible avec Google Chrome uniquement.</span>
                </div>
            )}
        </div>
    );
};

export default SpeechInput;
