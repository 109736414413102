import axios from "axios";
import { request } from "../utils/axios-utils";

const namespace = "/api/files";

export const uploadDoc = (formData: any) => {
    return axios.post(namespace + "/createS3", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const uploadLinkDoc = (addToast: any, formData: any) => {
    return request({
        addToast,
        url: `/api/files/storeLinkS3`,
        method: "post",
        data: formData,
    });
};
