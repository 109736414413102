import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { momentUpdateLocale } from "../../../../constants/Utils";
import IMobilier from "../../../../interfaces/IMobilier";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import { request } from "../../../../utils/axios-utils";
import ArrowBackActions from "../../../layout/ArrowBackAction";
import ItemTwoLines from "../../../layout/ItemTwoLines";
import Loader from "../../../layout/Loader";
import IFile from "../Cloud/IFile";

const SingleMobilier: React.FC = (props) => {
    const [mobilier, setMobilier] = useState<IMobilier>(null);
    const [fileList, setFileList] = useState<Array<IFile>>([]);

    const [majeur, setMajeur] = useState<IProtectedAdult>();

    const { mobilierId }: { mobilierId?: string } = useParams();
    const history = useHistory();
    const { addToast } = useToasts();

    momentUpdateLocale();

    const title =
        majeur && window.screen.width > 600
            ? `${majeur.first_name} ${majeur.last_name} | `
            : "";

    const removeMobilier = () => {
        request({
            addToast,
            url: `/api/furnitures/${mobilierId}/delete`,
            method: "post",
            data: {},
        })
            .then(() => {
                history.push(`/single_majeur/${majeur._id}/mobImmo`);
                addToast("Le mobilier a bien été supprimé.", {
                    appearance: "success",
                });
            })
            .catch();
    };

    const actionsArrowBack =
        majeur && !majeur.archived
            ? [
                  {
                      title: "edit",
                      icon: "edit",
                      action: () =>
                          history.push(
                              `/form_mob_immo/${majeur._id}/edit/${mobilierId}/MOBILIER`
                          ),
                  },
                  {
                      title: "delete",
                      icon: "delete_forever",
                      question: "Voulez vous supprimer ce mobilier ?",
                      action: removeMobilier,
                  },
              ]
            : [];

    const download = (key: string) => {
        request({
            addToast,
            url: `/api/files/downloadWithKey`,
            method: "POST",
            data: { key: key },
            responseType: "blob",
        })
            .then((response) => {
                const blob = response.data;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", key.split("/").pop());
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((data) => {
                const { message } = data;
                addToast(message, { appearance: "error" });
            });
    };

    useEffect(() => {
        if (!mobilier) {
            request({ addToast, url: `/api/furnitures/${mobilierId}` }).then(
                ({ data }) => {
                    setMobilier(data);
                    if (data.files) {
                        request({
                            url: "/api/files/list/from-ids",
                            method: "POST",
                            data: { filesIds: data.files },
                        })
                            .then(({ data }) => {
                                setFileList(data);
                            })
                            .catch();
                    }
                    request({
                        addToast,
                        url: `/api/majeur/${data.majeur_id}`,
                    })
                        .then(({ data }) => {
                            setMajeur(data);
                        })
                        .catch();
                }
            );
        }
    }, []);
    return (
        <div className="single_mobilier">
            <ArrowBackActions
                title={`${title} Mobilier`}
                actions={actionsArrowBack}
                back={() => window.history.back()}
                classNameTitle={"layout-center-center row full-width"}
            />
            <div className="page_content">
                {!mobilier && <Loader />}

                {mobilier && (
                    <>
                        <ItemTwoLines title="Label" content={mobilier.label} />
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Date d'inventaire"
                                content={
                                    mobilier.date_inventaire &&
                                    mobilier.date_inventaire !== ""
                                        ? moment(
                                              mobilier.date_inventaire
                                          ).format("L")
                                        : "Non renseignée"
                                }
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Estimation"
                                content={
                                    mobilier.estimation
                                        ? parseFloat(mobilier.estimation)
                                            ? (
                                                  mobilier.estimation || 0
                                              ).toLocaleString()
                                            : mobilier.estimation
                                        : "Non renseigné."
                                }
                                flex="1"
                            />
                        </div>
                        <div className="my-input-container">
                            <label htmlFor="notes">Notes :</label>
                            {mobilier && mobilier.notes && (
                                <div className="text_zone">
                                    {mobilier.notes}
                                </div>
                            )}
                            {mobilier && !mobilier.notes && (
                                <p>Non renseignées.</p>
                            )}
                        </div>
                        <div className="my-input-container">
                            <label>Document :</label>
                            {fileList &&
                                fileList.length > 0 &&
                                fileList.map((f) => (
                                    <div className="notes_zone layout-start-center">
                                        {f.name}{" "}
                                        <i
                                            onClick={() => download(f.key)}
                                            className="material-icons pointer"
                                        >
                                            cloud_download
                                        </i>
                                    </div>
                                ))}
                            {fileList && fileList.length === 0 && (
                                <div>Non renseignées.</div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default SingleMobilier;
