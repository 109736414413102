export const ListContractsOptions = [
    {"value": "Complémentaire Santé", "label": "Complémentaire Santé"},
    {"value": "MRH", "label": "MRH"},
    {"value": "Contrat Obsèques", "label": "Contrat Obsèques"},
    {"value": "Sécurité sociale", "label": "Sécurité sociale"},
    {"value": "Impôts", "label": "Impôts"},
    {"value": "Mutuelle", "label": "Mutuelle"},
    {"value": "Pension de retraite", "label": "Pension de retraite"},
    {"value": "APL", "label": "APL"},
    {"value": "Téléphone", "label": "Téléphone"},
    {"value": "Téléphone fixe", "label": "Téléphone fixe"},
    {"value": "Téléphone mobile", "label": "Téléphone mobile"},
    {"value": "Electricité", "label": "Electricité"},
    {"value": "Eau", "label": "Eau"},
    {"value": "Aide à domicile", "label": "Aide à domicile"},
    {"value": "Portage repas", "label": "Portage repas"},
    {"value": "Assurance habitation", "label": "Assurance habitation"},
    {"value": "Assurance auto", "label": "Assurance auto"},
    {"value": "Mutuelle santé", "label": "Mutuelle santé"},
    {"value": "Téléassistance", "label": "Téléassistance"},
    {"value": "Assurance vie", "label": "Assurance vie"},
    {"value": "Contrat obseques", "label": "Contrat obseques"},
    {"value": "Aide sociale", "label": "Aide sociale"},
    {"value": "Allocations", "label": "Allocations"},
    {"value": "Energie", "label": "Energie"},
    {"value": "Syndic", "label": "Syndic"},
    {"value": "Protection juridique", "label": "Protection juridique"},
    {"value": "Aide ménagère", "label": "Aide ménagère"},
    {"value": "Assurance santé", "label": "Assurance santé"},
    {"value": "Assistance obsèques", "label": "Assistance obsèques"},
    {"value": "Gaz", "label": "Gaz"},
    {"value": "Mutuelle résiliée", "label": "Mutuelle résiliée"},
    {"value": "Sécurité Sociale", "label": "Sécurité Sociale"},
    {"value": "Transport", "label": "Transport"},
    {"value": "Responsabilité civile", "label": "Responsabilité civile"},
    {"value": "Assurance hospitalisation", "label": "Assurance hospitalisation"},
    {"value": "Habitation", "label": "Habitation"},
    {"value": "RC", "label": "RC"},
    {"value": "Assurance", "label": "Assurance"}
];
