import React from "react";

export const Accordeon = (props: {
    name: any;
    component: any;
    isOpen: any;
    setIsOpen: any;
    isFullFilled: any;
}) => {
    const { name, component, isOpen, setIsOpen, isFullFilled } = props;

    return (
        <>
            <h2 id="accordion-collapse-heading-1">
                <button
                    type="button"
                    className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 bg-gray-100 rounded-t-xl hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
                    data-accordion-target="#accordion-collapse-body-1"
                    onClick={() => setIsOpen(!isOpen)}
                    aria-expanded={isOpen}
                    aria-controls="accordion-collapse-body-1"
                >
                    <span className="font-black">{name}</span>
                    <div className="flex">
                        <img
                            className="mr-4"
                            height={20}
                            width={20}
                            src={
                                isFullFilled
                                    ? "/imgs/checked.png"
                                    : "/imgs/cancel.png"
                            }
                            alt="Logo"
                        />
                        <svg
                            data-accordion-icon
                            className={`w-3 h-3 shrink-0 ${
                                isOpen ? "rotate-180" : ""
                            }`}
                            aria-hidden={true}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                        >
                            <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 5 5 1 1 5"
                            />
                        </svg>
                    </div>
                </button>
            </h2>
            <div
                id="accordion-collapse-body-1"
                className={`${isOpen ? "" : "hidden"}`}
                aria-labelledby="accordion-collapse-heading-1"
            >
                <div className="p-5">{component}</div>
            </div>
        </>
    );
};
