import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {modules, momentUpdateLocale} from "../../../constants/Utils";
import ITemplate from "../../../interfaces/ITemplate";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import SubBar from "../../layout/SubBar";
import Modal from "../../layout/modals/Modal";

const Template: React.FC = () => {
    const [templateMail, setTemplateMail] = useState<ITemplate | null>(null);
    const [confirmDeletion, setConfirmDeletion] = useState<boolean>(false);

    const history = useHistory();
    const { addToast } = useToasts();
    const { templateId }: { templateId: string } = useParams();

    momentUpdateLocale()

    useEffect(() => {
        if (!templateMail) {
            request({ addToast, url: `/api/mails/${templateId}` })
                .then(({ data }) => {
                    setTemplateMail(data);
                })
                .catch(() => history.push("/alertes"));
        }
    }, [templateMail]);

    /**
     * Delete a template mail.
     */
    const deleteTemplateMail = () => {
        setConfirmDeletion(false);

        request({
            addToast,
            url: `/api/mails/${templateId}/delete`,
            method: "post",
            data: {},
        }).then((data) => {
            addToast(`Le courrier a bien été supprimé`, {
                appearance: "success",
            });
            history.push("/list_templates_mail");
        }).catch();
    };

    /**
     * Download the docX template.
     */
    const downloadDocxTemplate = () => {
        request({
            addToast,
            url: `/api/mails/download/${templateId}`,
            method: "post",
            data: {},
        }).then(({ data }) => {
            addToast(`Le template a bien été téléchargé.`, {
                appearance: "success",
            });
            document.location.href = data;
        }).catch();
    };

    return (
        <>
            <div className="singleTemplateMail">
                {templateMail && (
                    <SubBar
                        title={
                            (templateMail.type == "Courrier" &&
                                `Courrier ${templateMail.label}`) ||
                            `Requête ${templateMail.label}`
                        }
                        back="/list_templates_mail"
                    >
                        {templateMail.file && (
                            <Link
                                to={"#"}
                                onClick={() => downloadDocxTemplate()}
                            >
                                <i className="material-icons">file_download</i>
                            </Link>
                        )}

                        {!templateMail.file && (
                            <Link
                                to={
                                    "/list_templates_mail/" +
                                    templateId +
                                    "/modifier"
                                }
                            >
                                <Icon name="edit" />
                            </Link>
                        )}

                        <Link to="#" onClick={() => setConfirmDeletion(true)}>
                            <Icon name="delete_forever" />
                        </Link>
                    </SubBar>
                )}

                <Modal
                    message="Attention : La suppression du courrier type est définitive. Êtes vous sûr(e) ?"
                    show={confirmDeletion}
                    onValidate={deleteTemplateMail}
                />

                <div className="page_content layout-center-center column">
                    {templateMail && templateMail.body && (
                        <div className="mail_container">
                            <ReactQuill
                                readOnly
                                value={templateMail.body}
                                modules={{ ...modules, toolbar: false }}
                            />
                            <div className="layout-end-center row edited text_zone">
                                édité le :{" "}
                                {moment(templateMail.created_at).format("L")}
                            </div>
                        </div>
                    )}
                    {templateMail && templateMail.file && (
                        <div className="row layout-center-center withFile">
                            {templateMail.file.name}.docx
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Template;
