import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ICourt from "../../../interfaces/ICourt";
import IJudge from "../../../interfaces/IJudge";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import Modal from "../../layout/modals/Modal";

const Court: React.FC = () => {
    const [court, setCourt] = useState<ICourt | null>(null);
    const [confirmDeletion, setConfirmDeletion] = useState<boolean>(false);

    const history = useHistory();
    const { addToast } = useToasts();

    const { courtId }: { courtId: string } = useParams();

    useEffect(() => {
        if (!court) {
            request({ addToast, url: `/api/courts/${courtId}` })
                .then(({ data }) => {
                    setCourt(data);
                })
                .catch(() => history.push("/tribunaux"));
        }
    }, [court]);

    /*
     * Delete a Court.
     */
    const deleteCourt = () => {
        setConfirmDeletion(false);
        request({
            addToast,
            url: `/api/courts/${courtId}/delete`,
            method: "post",
            data: {},
        }).then((data) => {
            addToast(`Le tribunal a bien été supprimé`, {
                appearance: "success",
            });
            history.push("/tribunaux");
        }).catch();
    };

    return (
        <>
            <SubBar
                title={
                    (court &&
                        `Tribunal ${court.tribunal_type || ""} de ${
                            court.city
                        }`) ||
                    "Chargement..."
                }
                back="/tribunaux"
            >
                <Link to={"/tribunaux/" + courtId + "/modifier"}>
                    <Icon name="edit" />
                </Link>
                <Link to="#" onClick={() => setConfirmDeletion(true)}>
                    <Icon name="delete_forever" />
                </Link>
            </SubBar>

            <Modal
                message="Attention : La suppression du tribunal est définitive. Êtes vous sûr(e) ?"
                show={confirmDeletion}
                onValidate={deleteCourt}
            />

            <div className="page_content">
                {!court && <Loader />}

                {court && (
                    <>
                        <div className="column">
                            <div className="item_two_line">
                                <h6>Adresse</h6>
                                <p className="notes_zone">
                                    {court.address || ""}
                                </p>
                            </div>
                            <div className="divider"></div>
                        </div>

                        <div className="row layout-xs-column">
                            <div className="column flex-1">
                                <div className="item_two_line">
                                    <h6>Code postal</h6>
                                    <p className="notes_zone">
                                        {court.zip_code || ""}
                                    </p>
                                </div>
                                <div className="divider"></div>
                            </div>

                            <div className="flex005 hide-xs"></div>

                            <div className="column flex-1">
                                <div className="item_two_line">
                                    <h6>Ville</h6>
                                    <p className="notes_zone">
                                        {court.city || ""}
                                    </p>
                                </div>
                                <div className="divider"></div>
                            </div>

                            <div className="flex005 hide-xs"></div>

                            <div className="column flex-1">
                                <div className="item_two_line">
                                    <h6>Siret</h6>
                                    <p className="notes_zone">
                                        {court.siret || "Non renseigné"}
                                    </p>
                                </div>
                                <div className="divider"></div>
                            </div>
                        </div>

                        <div className="item_two_line">
                            <h6>Notes :</h6>
                            <div className="notes_zone">
                                {court.notes || "Non renseigné"}
                            </div>
                        </div>

                        {court.judges.length > 0 && (
                            <>
                                <div className="my-sub-title-f">
                                    <SubBar title="Personnes associées"></SubBar>
                                </div>
                            </>
                        )}

                        <div className="list">
                            {court.judges &&
                                court.judges.length > 0 &&
                                court.judges.map((judge: IJudge, index) => (
                                    <div
                                        key={index}
                                        className="item_three_lines"
                                    >
                                        <div className="container">
                                            <h3>
                                                {judge.type +
                                                    " : " +
                                                    judge.civility +
                                                    " " +
                                                    judge.last_name +
                                                    " " +
                                                    judge.first_name}
                                            </h3>
                                            {judge.email && (
                                                <h5>
                                                    <a
                                                        href={`mailto:${judge.email}`}
                                                    >
                                                        {"Email : " +
                                                            judge.email}
                                                    </a>
                                                </h5>
                                            )}
                                            {judge.phone && !judge.fax && (
                                                <h5>
                                                    {"Tél : " + judge.phone}
                                                </h5>
                                            )}
                                            {judge.fax && !judge.phone && (
                                                <h5>{"Fax : " + judge.fax}</h5>
                                            )}
                                            {judge.phone && judge.fax && (
                                                <h5>
                                                    {"Tél : " +
                                                        judge.phone +
                                                        " / " +
                                                        "Fax : " +
                                                        judge.fax}
                                                </h5>
                                            )}
                                        </div>
                                        {index !== court.judges.length - 1 && (
                                            <div className="divider"></div>
                                        )}
                                    </div>
                                ))}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Court;
