import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { downloadNotices, getControl } from "../../../../_api/Controle";
import { getCourts } from "../../../../_api/Court";
import { Button } from "../../../../_shared/Button";
import { DefaultDropdown } from "../../../../_shared/Dropdown";
import { InputForm } from "../../../../_shared/InputField";
import { Comments } from "../Comments/Comments";
import { HistoryDepot } from "../History";

const StatusControle = {
    SUCCESS: "Valide",
    PENDING: "EN COURS",
    FAILED: "Refus",
    REVISION: "EN ATTENTE DE JUSTIFICATIF",
};

const StatusControleButton = {
    SUCCESS: "vert",
    PENDING: "orange",
    FAILED: "red",
    REVISION: "orange",
};

function formatDate(date: any) {
    const day = String(date.getDate()).padStart(2, "0"); // Extrait le jour et le formate sur 2 chiffres
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Extrait le mois (0-11) et l'incrémente pour le format (1-12)
    const year = date.getFullYear(); // Extrait l'année
    return `${year}-${month}-${day}`; // Combine les trois parties
}

export const SingleMandat = () => {
    const { addToast } = useToasts();

    const history = useHistory();
    const { controle_id }: { controle_id?: string } = useParams();

    const [status, setStatus] = useState("");
    const [agentName, setAgentName] = useState("");
    const [agentMail, setAgentMail] = useState("");
    const [isCreatedAgent, setIsCreatedAgent] = useState(false);

    const [subRogeName, setSubRogeName] = useState("");
    const [subRogeMail, setSubRogeMail] = useState("");
    const [isCreatedSubRoge, setIsCreatedSubRoge] = useState(false);

    const [tribunal, setTribunal] = useState(null);
    const [tribunals, setTribunals] = useState([]);

    const [numeroRG, setNumeroRG] = useState("");
    const [portalis, setPortalis] = useState("");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [firstLastName, setFirstLastName] = useState("");
    const [name, setName] = useState("");

    const [createdAt, setCreatedAt] = useState(null);

    const [messages, setMessages] = useState([]);

    const [controle, setControl] = useState(null);
    const [depot, setDepot] = useState(null);

    const refreshEntity = () => {
        getControl(addToast, controle_id).then((resp: any) => {
            let data = resp.data;
            getCourts(addToast).then((resp) => {
                setTribunals(
                    resp.data.map((res: any) => {
                        return { value: res.id, label: res.city };
                    })
                );
                const trib = resp.data.find(
                    (res: { id: any }) => res.id === data.tribunal
                );
                setTribunal(trib.id);
            });

            setControl(data);
            setAgentMail(data.tutor_mail);
            setAgentName(data.tutor_name);
            setSubRogeMail(data.subroge_mail);
            setSubRogeName(data.subroge_name);
            setNumeroRG(data.rg_number);
            setCreatedAt(new Date(data.created_at));
            setPortalis(data.portalis_number);
            setStatus(data.status);
            setStartDate(formatDate(new Date(data.start_period)));
            setEndDate(formatDate(new Date(data.end_period)));
            setFirstLastName(data.name);
            setName(data.second_name);
            setIsCreatedSubRoge(data.is_created_subroge);
            setIsCreatedAgent(data.is_created_tutor);
            setMessages(data.messages);
            setDepot(data.depot);
        });
    };
    useEffect(() => {
        refreshEntity();
    }, []);

    return (
        <div className="p-4">
            <div className="flex justify-between">
                <Button
                    type={"empty"}
                    name={"Retour"}
                    action={() => {
                        history.push("/list_mandats");
                    }}
                />
                <div className="font-bold text-xl">
                    {firstLastName || "" + " " + name} - {numeroRG} - {portalis}{" "}
                </div>
                <div className="flex justify-end">
                    {!["SUCCESS", "FAILED"].includes(status) && (
                        <>
                            <div className="mr-2">
                                <Button
                                    type={"empty"}
                                    name={"Courrier d'information"}
                                    action={() => {
                                        downloadNotices(
                                            "MJPM",
                                            controle_id
                                        ).then((resp) => {
                                            const file = new Blob([resp.data], {
                                                type: "application/pdf",
                                            });

                                            const fileURL =
                                                URL.createObjectURL(file);

                                            window.open(fileURL, "_blank");

                                            URL.revokeObjectURL(fileURL);
                                        });
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <hr className="m-4" />

            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <div className="mt-2">
                        <DefaultDropdown
                            readOnly={true}
                            name={"Tribunal"}
                            options={tribunals}
                            value={tribunal}
                            onChange={(e: { target: { value: any } }) =>
                                setTribunal(e.target.value)
                            }
                        />
                    </div>
                    <hr className="m-4" />
                    <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <InputForm
                                readOnly={true}
                                name="Numéro de RG"
                                set={setNumeroRG}
                                value={numeroRG}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <InputForm
                                readOnly={true}
                                name="Numéro Portalis"
                                set={setPortalis}
                                value={portalis}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <InputForm
                                readOnly={true}
                                name="Nom et prénom"
                                set={setName}
                                value={name}
                            />
                        </div>
                        {firstLastName && (
                            <div className="w-full md:w-1/2 px-3 md:mb-0">
                                <InputForm
                                    readOnly={true}
                                    name="Nom de jeune fille"
                                    set={setFirstLastName}
                                    value={firstLastName}
                                />
                            </div>
                        )}
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <InputForm
                                readOnly={true}
                                name="Début de nomination"
                                type="date"
                                set={setStartDate}
                                value={startDate}
                            />
                        </div>
                        {/* <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <InputForm
                                readOnly={true}
                                name="Fin de controle"
                                type="date"
                                set={setEndDate}
                                value={endDate}
                            />
                        </div> */}
                    </div>{" "}
                    {agentName && <hr className="m-4" />}
                    <div className="flex flex-wrap -mx-3 mb-3">
                        {agentName && (
                            <div className="w-full md:w-1/3 px-3 md:mb-0">
                                <InputForm
                                    readOnly={true}
                                    name="Nom du tuteur/mandataire"
                                    set={setAgentName}
                                    value={agentName}
                                />
                            </div>
                        )}
                        {agentMail && (
                            <div className="w-full md:w-1/3 px-3 md:mb-0">
                                <InputForm
                                    readOnly={true}
                                    name="Email du tuteur/mandataire"
                                    type="email"
                                    set={setAgentMail}
                                    value={agentMail}
                                />
                            </div>
                        )}
                        {agentMail && (
                            <div className="w-full md:w-1/3 px-3 md:mb-0">
                                <InputForm
                                    readOnly={true}
                                    name="Compte crée"
                                    type="checkbox"
                                    set={setIsCreatedAgent}
                                    value={isCreatedAgent}
                                />
                            </div>
                        )}
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-3">
                        {subRogeName && (
                            <div className="w-full md:w-1/3 px-3 md:mb-0">
                                <InputForm
                                    readOnly={true}
                                    name="Nom du subrogé"
                                    set={setSubRogeName}
                                    value={subRogeName}
                                />
                            </div>
                        )}
                        {subRogeMail && (
                            <div className="w-full md:w-1/3 px-3 md:mb-0">
                                <InputForm
                                    readOnly={true}
                                    name="Email du subroge"
                                    type="email"
                                    set={setSubRogeMail}
                                    value={subRogeMail}
                                    required={true}
                                />
                            </div>
                        )}
                        {subRogeMail && (
                            <div className="w-full md:w-1/3 px-3 md:mb-0">
                                <InputForm
                                    readOnly={true}
                                    name="Compte crée"
                                    type="checkbox"
                                    set={setIsCreatedSubRoge}
                                    value={isCreatedSubRoge}
                                    required={true}
                                />
                            </div>
                        )}
                    </div>{" "}
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0 mt-4">
                    <div className="px-4">
                        <div className="flex justify-between mb-2">
                            <label className="text-left block uppercase tracking-wide text-atroposPrimary text-xs font-bold mb-2">
                                Historique du mandat :
                            </label>
                            {controle && (
                                <Button
                                    isDisabled={true}
                                    type={StatusControleButton[controle.status]}
                                    name={StatusControle[controle.status]}
                                />
                            )}
                        </div>
                        {controle && <HistoryDepot data={controle.history} />}
                    </div>
                </div>
            </div>
            <hr className="m-4" />

            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <div className="px-4">
                        <div className="flex justify-between mb-2">
                            <div>
                                <h2 className="text-lg lg:text-xl font-bold text-gray-900 dark:text-white">
                                    Liste des contrôles
                                </h2>
                            </div>
                            <div>
                                <Button
                                    type={"empty"}
                                    name={"Ajouter un contrôle"}
                                    action={() => {
                                        history.push(
                                            `/create_control/` + controle._id
                                        );
                                    }}
                                />
                            </div>
                        </div>

                        {controle && (
                            <div className="flex flex-wrap -mx-3 mb-3">
                                {controle.depots.length === 0 && (
                                    <>
                                        {" "}
                                        <div className="w-full px-3 md:mb-0">
                                            <div className="flex justify-center align-center">
                                                Il n'y a aucun contrôle pour
                                                l'instant.
                                            </div>
                                        </div>
                                    </>
                                )}
                                {controle.depots.map((depot: any) => {
                                    console.log(depot);
                                    return (
                                        <div
                                            className="w-full md:w-1/4 px-3 md:mb-0 cursor-pointer"
                                            onClick={() => {
                                                history.push(
                                                    "/controle/" + depot._id
                                                );
                                            }}
                                        >
                                            <div className="flex justify-center align-center">
                                                <svg
                                                    width={80}
                                                    height={80}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4Z"
                                                        fill={"#FFD700"}
                                                    />
                                                </svg>
                                            </div>
                                            <p>
                                                {new Date(
                                                    depot.start_period
                                                ).toLocaleDateString()}{" "}
                                                -{" "}
                                                {new Date(
                                                    depot.end_period
                                                ).toLocaleDateString()}{" "}
                                                -
                                            </p>
                                            <p className="text-center">
                                                {StatusControle[depot.status]}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <Comments
                        type={"CONTROL"}
                        control_id={controle_id}
                        messages={messages}
                        refreshEntity={refreshEntity}
                    />
                </div>
            </div>
        </div>
    );
};
