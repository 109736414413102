import React from "react";

const FileUpload = (props: any) => {
    const { file, set, UploadFile, type } = props;

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0]; // Récupère le premier fichier sélectionné
        set(selectedFile);
        UploadFile(selectedFile, type);
    };

    return (
        <div>
            <form>
                <label>
                    <input type="file" onChange={handleFileChange} />
                </label>
            </form>
        </div>
    );
};

export default FileUpload;
