import { request } from "../utils/axios-utils";

export const activatePowermail = (addToast: any, password: string) => {
    return request({
        addToast,
        url: `api/users/proteges/activate/${password}`,
        method: "post",
        data: {},
    });
};

export const editPasswordPowermail = (addToast: any, password: string) => {
    return request({
        addToast,
        url: "api/users/powermail/password",
        method: "post",
        data: { password: password },
    });
};
