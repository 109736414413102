import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { EditCourrierMajeur } from "../../../../_api/Courrier";
import {capitalizeFirst, modules, momentUpdateLocale} from "../../../../constants/Utils";
import IMail from "../../../../interfaces/IMail";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import { request } from "../../../../utils/axios-utils";
import ArrowBack from "../../../layout/ArrowBack";
import InputComponent from "../../../layout/forms/InputComponent";

const FormCourrier: React.FC = (props) => {
    const [mail, setMail] = useState<IMail>(null);
    const [text, setText] = useState("");

    const [newTitle, setNewTitle] = useState("");
    const [titleCourrier, setTitleCourrier] = useState("");
    const [showEditor, setShowEditor] = useState<boolean>(true);

    const { majeurId }: { majeurId?: string } = useParams();
    const { courrierId }: { courrierId: string } = useParams();

    const { addToast } = useToasts();

    const [majeur, setMajeur] = useState<IProtectedAdult>(null);

    momentUpdateLocale()

    useEffect(() => {
        if (majeurId && !majeur) {
            request({ addToast, url: `/api/majeur/${majeurId}` }).then(
                ({ data }) => {
                    setMajeur(data);
                }
            ).catch();
        }
        if (!mail) {
            /* Get Mail */
            request({ addToast, url: `/api/majeurMails/${courrierId}` }).then(
                ({ data }) => {
                    setMail(data);
                    setNewTitle(data.label);
                    setTitleCourrier(
                        data.type === "Courrier" ? "le courrier" : "la requête"
                    );
                    setText(data.body || "");
                    if (!data.body) {
                        setShowEditor(false);
                    }
                    request({
                        addToast,
                        url: `/api/majeur/${data.majeur_id}`,
                    }).then(({ data }) => {
                        setMajeur(data);
                    });
                }
            ).catch();
        }
    }, [mail]);

    const updateMail = () => {
        EditCourrierMajeur(addToast, courrierId, {
            mail: {
                body: text,
                label: capitalizeFirst(newTitle.trim()),
            },
        }).then(() => {
            addToast("Le courrier a bien été modifié.", {
                appearance: "success",
            });
            window.history.back();
        }).catch();
    };

    let title;
    if (majeur && window.screen.width > 600) {
        title = majeur.first_name + " " + majeur.last_name + " | ";
    }

    return (
        <div className="column layout-start-center update_mail">
            <ArrowBack title={title + titleCourrier} />
            {mail && (
                <div className="page_content full-width layout-center-center column">
                    <div className="label_mail">
                        <InputComponent
                            id="labelMail"
                            type="text"
                            className="my-input" //flex-1
                            label="Titre"
                            required
                            value={newTitle}
                            onChange={(e) => {
                                setNewTitle(e.target.value);
                            }}
                            maxLength={50}
                        />
                    </div>
                    <div className="update_mail_zone layout-center-center full-width column">
                        {showEditor && (
                            <div className="mail_container">
                                <ReactQuill
                                    theme="snow"
                                    value={text}
                                    onChange={setText}
                                    modules={modules}
                                    style={{
                                        backgroundColor: "#fff",
                                        color: "#000",
                                        minHeight: 300,
                                    }}
                                />
                                <div className="layout-end-center row edited">
                                    édité le :{" "}
                                    {moment(mail.created_at).format("L")}
                                </div>
                            </div>
                        )}
                        <button onClick={updateMail} className="valid-button">
                            Valider
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
export default FormCourrier;
