import React, {useState} from "react";
import ISearchBar from "../../../interfaces/forms/ISearchBar";

const SearchBar: React.FC<ISearchBar> = (props) => {
    let [value, setValue] = useState<string>(props.value);
    return (
        <div
            className={
                props.needClassName
                    ? "my-input-search-container sticky top-40 row"
                    : ""
            }
        >
            <i className="material-icons search-icon">search</i>
            <input
                id={props.id}
                className={`my-input-search flex-1 ${props.className}`}
                type={props.type}
                onChange={(e) => props.onChange(e)}
                placeholder={props.placeholder}
                value={props.value}
                autoComplete="off"
                maxLength={props.maxLength}
                {...props.inputOptions}
            />
            <i className={`material-icons clean-icon ${props.cleanIconClass}`}
               onClick={() => props.onClean()}
            >
                close
            </i>

        </div>
    );
};

export default SearchBar;
