import React, { useState } from "react";
import { Link } from "react-router-dom";
import { capitalizeFirst, returnOnlyNumbers } from "../../../constants/Utils";
import IFormAddress from "../../../interfaces/forms/IFormAddress";
import InputAddress from "../../layout/forms/InputAddress";
import InputComponent from "../../layout/forms/InputComponent";
import InputDatePicker from "../../layout/forms/InputDatePicker";
import Select from "../../layout/forms/Select";

const InnerAddressForm: React.FC<IFormAddress> = (props) => {
    const [address, setAddress] = useState<IFormAddress>(props);

    const typeOptions = [
        { value: null, label: null },
        { value: "D", label: "Domicile" },
        { value: "E", label: "Etablissement" },
    ];

    const setValue = (field: string, value: string|Date) => {
        const newAddress = { ...address, [field]: value };
        setAddress(newAddress);
        props.onChange(newAddress);
    };

    const handleSelection = (suggestion: any) => {
        const newAddress = { ...address };

        newAddress.address = suggestion.name;
        newAddress.zip_code = returnOnlyNumbers(suggestion.postcode);
        newAddress.city = capitalizeFirst(suggestion.city);

        setAddress(newAddress);
        props.onChange(newAddress);
    };

    return (
        //<div className="column full-width">
        <div className="layout-start-center row full-width">
            {props.isDeletable && (
                <div className="remove-button layout-center-center row pointer">
                    <Link to={"#"} onClick={() => props.removeAddress(address)}>
                        <i className="material-icons">delete</i>
                    </Link>
                </div>
            )}

            <div className="column full-width">
                <InputAddress handleSelection={handleSelection} />
                <div className="row layout-xs-column">
                    <InputComponent
                        type="text"
                        id={`${props.id}-type`}
                        className="flex-1"
                        label="Dénomination"
                        value={address.label || ""}
                        onChange={(e) =>
                            setValue("label", capitalizeFirst(e.target.value))
                        }
                        maxLength={60}
                    />

                    <div className="flex005 hide-xs" />

                    <Select
                        id={`first-agrement`}
                        label="Type d'adresse"
                        className={"flex-1"}
                        needDefaultValue={false}
                        required
                        options={typeOptions}
                        value={address.type || ""}
                        onChange={(e) => {
                            setValue("type", capitalizeFirst(e.target.value))
                        }}
                    />
                </div>

                <div className="flex005 hide-xs" />
                <div className="my-input-container">
                    <label>
                        Adresse : <span className="red_asterix"> *</span>
                    </label>
                    <textarea
                        className="my-textarea"
                        placeholder="Adresse"
                        value={address.address}
                        onChange={(e) => setValue("address", e.target.value)}
                    ></textarea>
                </div>

                <div className="flex005 hide-xs" />

                <div className="row layout-xs-column">
                    <InputComponent
                        type="text"
                        id={`${props.id}-zipcode`}
                        className="flex-1"
                        label="Code Postal"
                        required
                        value={address.zip_code || ""}
                        onChange={(e) =>
                            setValue(
                                "zip_code",
                                returnOnlyNumbers(e.target.value)
                            )
                        }
                        maxLength={5}
                    />

                    <div className="flex005 hide-xs" />

                    <InputComponent
                        type="text"
                        id={`${props.id}-city`}
                        className="flex-1"
                        label="Ville"
                        required
                        value={address.city || ""}
                        onChange={(e) =>
                            setValue("city", capitalizeFirst(e.target.value))
                        }
                        maxLength={25}
                    />

                    <div className="flex005 hide-xs" />

                    <InputDatePicker
                        id="date"
                        inputDate={address.date && new Date(address.date)}
                        label="Date d'entrée"
                        className="my-input flex-1"
                        onChange={(date: Date) => {
                            date.setHours(date.getHours() + 3);
                            setValue("date", date)
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default InnerAddressForm;
