import Highcharts from "highcharts";
import flattendDeep from "lodash/flattenDeep";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { yearsTable } from "../../../constants/Utils";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import Loader from "../../layout/Loader";
import NoItem from "../../layout/NoItem";
import SubBar from "../../layout/SubBar";
import Select from "../../layout/forms/Select";

const yearOptions: Array<any> = yearsTable();

const Statistics: React.FC = () => {
    const lastDefaultYearValue = yearOptions[1].value;
    const { addToast } = useToasts();

    const [selectedYear, setSelectedYear] =
        useState<number>(lastDefaultYearValue);
    const [flux, setFlux] = useState<any>(null);
    const [user, setUser] = useState<IUser>(null);
    const [isError, setIsError] = useState<boolean>(null);
    const [isTable, setIsTable] = useState<boolean>(null);

    const [statsProteges, setStatsProteges] = useState<any>(null);
    const [statsGlobal, setStatsGlobal] = useState<any>(null);
    const [chartDiv, setChartDiv] = useState<HTMLElement>(null);

    const [isLoading, setIsLoading] = useState(false);

    let peopleError: any = [];

    const OneYearFromNow = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
    ).getTime();

    useEffect(() => {
        setIsLoading(true);
        if (!user && !statsProteges && !statsGlobal) {
            request({ addToast, url: `/api/user` }).then(({ data }) => {
                setUser(data);
                setUserFlux(data, selectedYear);

                let statsP: any = null;
                let statsG: any = null;

                request({
                    addToast,
                    url: `/api/statistics/protected/${data._id}`,
                }).then((dataP: any) => {
                    setStatsProteges(dataP.data);
                    statsP = dataP.data;

                    request({
                        addToast,
                        url: `/api/statistics/global/${data._id}`,
                    }).then((dataG: any) => {
                        setStatsGlobal(dataG.data);
                        statsG = dataG.data;
                        setIsLoading(false);
                        if (statsP && statsG) {
                            drawCharts(statsP, statsG);
                        }
                    }).catch();
                });
            }).catch();
        }

        if (isError == null) {
            setIsError(
                flux &&
                    flux.table &&
                    flux.table.length > 0 &&
                    flux.nb_avants + flux.nb_ouvertures - flux.nb_fins !==
                        flux.nb_apres
            );
        }

        if (isError != null && flux) {
            peopleError = checkPeopleWHoIsWrongInTable(flux.table);
        }

        if (isTable == null) {
            setIsTable(flux && flux.table && flux.table.length > 0);
        }
    }, [user, flux, isError, isTable]);

    const setUserFlux = (user: IUser, year: number) => {
        request({
            addToast,
            url: "/api/statistics/flux",
            method: "post",
            data: {
                userId: user._id,
                year: year,
            },
        }).then(({ data }) => {
            setFlux(Array.isArray(data) ? null : data);
        }).catch();
    };

    const checkPeopleWHoIsWrongInTable = (table: any) => {
        const allPeople = table.map((tab: any) => {
            const tabAvant =
                tab.avants &&
                `${tab.avants.first_name} ${tab.avants.last_name}`;
            const tabOuvertures =
                tab.ouvertures &&
                `${tab.ouvertures.first_name} ${tab.ouvertures.last_name}`;
            const tabFins =
                tab.fins && `${tab.fins.first_name} ${tab.fins.last_name}`;
            const tabApres =
                tab.apres && `${tab.apres.first_name} ${tab.apres.last_name}`;
            return [tabAvant, tabOuvertures, tabFins, tabApres];
        });
        const people = flattendDeep(allPeople).filter((i) => i);
        const peopleError: any = [];
        people.forEach((p) => {
            const count = people.filter((item) => item === p).length;
            if (count !== 2) peopleError.push(p);
        });
        return peopleError;
    };

    const drawCharts = (statsProteges: any, statsGlobal: any) => {
        statsProteges &&
            statsGlobal &&
            (Highcharts.setOptions({
                lang: {
                    months: [
                        "Janvier",
                        "Février",
                        "Mars",
                        "Avril",
                        "Mai",
                        "Juin",
                        "Juillet",
                        "Août",
                        "Septembre",
                        "Octobre",
                        "Novembre",
                        "Décembre",
                    ],
                    weekdays: [
                        "Lundi",
                        "Mardi",
                        "Mercredi",
                        "Jeudi",
                        "Vendredi",
                        "Samedi",
                        "Dimanche",
                    ],
                },
            }),
            // Evolution du nombre de protégés
            Highcharts.chart("container_stats_protege", {
                chart: {
                    type: "spline",
                },
                title: {
                    text: "Suivi du nombre de protégés",
                },
                xAxis: {
                    type: "datetime",
                    dateTimeLabelFormats: {
                        month: "%b/%y",
                        year: "%b/%y",
                    },
                    title: {
                        text: "Date",
                    },
                },
                yAxis: {
                    title: {
                        text: "Nombre de protégés",
                    },
                    min: 0,
                    tickInterval: 5,
                },
                tooltip: {
                    headerFormat: "<b>{series.name}</b><br>",
                    pointFormat: "{point.x:%e. %b. %y}: {point.y}",
                },
                plotOptions: {
                    spline: {
                        marker: {
                            enabled: true,
                        },
                    },
                },
                series: [
                    {
                        type: "spline",
                        name: "Protégés cumulés",
                        data: statsProteges.majeur_cumul,
                    },
                ],
                credits: {
                    enabled: true,
                    text: "",
                },
            }),
            // Répartition des protégés en fonction de l'ancienneté et par mesures
            Highcharts.chart("container_stats_global_mesures", {
                chart: {
                    type: "column",
                },
                title: {
                    text: "Répartition en fonction de l'ancienneté",
                },
                xAxis: {
                    categories: statsGlobal.old_graph.id,
                    crosshair: true,
                },
                yAxis: {
                    min: 0,
                    tickInterval: 1,
                    title: {
                        text: "Nombre de protégés",
                    },
                },
                tooltip: {
                    headerFormat:
                        '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat:
                        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y}</b></td></tr>',
                    footerFormat: "</table>",
                    shared: true,
                    useHTML: true,
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                    },
                },
                series: statsGlobal.old_graph.list,
                credits: {
                    enabled: true,
                    text: "",
                },
            }),
            // Répartition des protégés en fonction de l'âge et par mesures
            Highcharts.chart("container_stats_global_age", {
                chart: {
                    type: "column",
                },
                title: {
                    text: "Répartition en fonction de l'age",
                },
                xAxis: {
                    categories: statsGlobal.age_graph.id,
                    crosshair: true,
                },
                yAxis: {
                    min: 0,
                    tickInterval: 1,
                    title: {
                        text: "Nombre",
                    },
                },
                tooltip: {
                    headerFormat:
                        '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat:
                        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y}</b></td></tr>',
                    footerFormat: "</table>",
                    shared: true,
                    useHTML: true,
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                    },
                },
                series: statsGlobal.age_graph.list,
                credits: {
                    enabled: true,
                    text: "",
                },
            }),
            // Répartition selon la protection
            Highcharts.chart("container_stats_global_protection", {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: "Répartition selon la protection",
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y}</b>",
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: false,
                        },
                        showInLegend: true,
                    },
                },
                series: [
                    {
                        type: "pie",
                        name: "Nombre",
                        colorByPoint: true,
                        data: statsGlobal.protection_graph,
                    },
                ],
                credits: {
                    enabled: true,
                    text: "",
                },
            }),
            // Répartition selon le lieu de vie
            Highcharts.chart("container_stats_lieu_current_protection", {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: "Répartition en fonction du lieu de vie",
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y}</b>",
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: false,
                        },
                        showInLegend: true,
                    },
                },
                series: [
                    {
                        type: "pie",
                        name: "Nombre",
                        colorByPoint: true,
                        data: statsGlobal.lieu_graph,
                    },
                ],
                credits: {
                    enabled: true,
                    text: "",
                },
            }));
    };

    return (
        <>
            <SubBar title={"Les statistiques"}></SubBar>

            <div className="statistiques">
                <div className="page_content">
                    {!statsProteges && !statsGlobal && isLoading && <Loader />}

                    {!statsProteges &&
                        !statsGlobal &&
                        flux &&
                        flux.length == 0 &&
                        !isLoading && (
                            <NoItem text="Aucunes statistiques disponibles." />
                        )}

                    <div
                        id="container_stats_protege"
                        className="container_stats"
                    />
                    <div style={{ marginBottom: 20 }} />
                    <div
                        id="container_stats_global_mesures"
                        className="container_stats"
                    />
                    <div style={{ marginBottom: 20 }} />
                    <div
                        id="container_stats_global_age"
                        className="container_stats"
                    />
                    <div style={{ marginBottom: 20 }} />
                    <div className="row flex-1 layout-xs-column">
                        <div
                            id="container_stats_global_protection"
                            className="container_stats"
                        />
                        <div
                            id="container_stats_lieu_current_protection"
                            className="container_stats"
                        />
                    </div>
                    <div style={{ marginBottom: 20 }} />

                    {["super-admin", "MANAGER"].includes(user?.roles[0]) && (
                        <>
                            <div
                                className="row layout-center-center"
                                style={{ marginBottom: 15 }}
                            >
                                <Select
                                    id="select"
                                    style={{ width: 150, marginLeft: 10 }}
                                    value={selectedYear}
                                    label="Tableau des entrées sorties"
                                    onChange={(e) => {
                                        setSelectedYear(e.target.value);
                                        setUserFlux(user, e.target.value);
                                    }}
                                    options={yearOptions}
                                />
                            </div>
                            {isError && (
                                <p
                                    className="row layout-center-center"
                                    style={{
                                        color: "red",
                                        fontWeight: "bold",
                                        marginBottom: 15,
                                    }}
                                >
                                    Vous avez des erreurs dans votre tableau.
                                </p>
                            )}
                            {isError && (
                                <div className="column layout-center-center">
                                    {peopleError.map(
                                        (item: any, index: number) => (
                                            <p
                                                key={`${item}_${index}`}
                                                style={{
                                                    color: "red",
                                                    fontWeight: "bold",
                                                    marginBottom: 5,
                                                }}
                                            >
                                                {item}
                                            </p>
                                        )
                                    )}
                                </div>
                            )}
                            <div className="row flex-1 layout-center-center">
                                {flux && (
                                    <table className="table_flux">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Avants : {flux.nb_avants}
                                                </th>
                                                <th>
                                                    Ouvertures :{" "}
                                                    {flux.nb_ouvertures}
                                                </th>
                                                <th>
                                                    Fermetures : {flux.nb_fins}
                                                </th>
                                                <th>
                                                    Restés : {flux.nb_apres}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isTable &&
                                                flux.table.map(
                                                    (
                                                        tab: any,
                                                        index: number
                                                    ) => (
                                                        <tr key={index}>
                                                            <td>{`${
                                                                tab.avants
                                                                    ? tab.avants
                                                                          .first_name +
                                                                      " " +
                                                                      tab.avants
                                                                          .last_name
                                                                    : ""
                                                            }`}</td>
                                                            <td>{`${
                                                                tab.ouvertures
                                                                    ? tab
                                                                          .ouvertures
                                                                          .first_name +
                                                                      " " +
                                                                      tab
                                                                          .ouvertures
                                                                          .last_name +
                                                                      " " +
                                                                      tab
                                                                          .ouvertures
                                                                          .date
                                                                    : ""
                                                            }`}</td>
                                                            <td>
                                                                {tab.fins ? (
                                                                    <span>
                                                                        {`${tab.fins.first_name} ${tab.fins.last_name} ${tab.fins.date}`}
                                                                        {!tab
                                                                            .fins
                                                                            .reason && (
                                                                            <span
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                *Raison
                                                                                non
                                                                                saisie*
                                                                            </span>
                                                                        )}
                                                                        {tab
                                                                            .fins
                                                                            .reason &&
                                                                            ` ${tab.fins.reason}`}
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </td>
                                                            <td
                                                                className={
                                                                    tab.apres &&
                                                                    tab.apres
                                                                        .dateRevisionTimeStamp <
                                                                        OneYearFromNow
                                                                        ? "alertRevision"
                                                                        : ""
                                                                }
                                                            >
                                                                <div className="column">
                                                                    {`${
                                                                        tab.apres
                                                                            ? tab
                                                                                  .apres
                                                                                  .first_name +
                                                                              " " +
                                                                              tab
                                                                                  .apres
                                                                                  .last_name
                                                                            : ""
                                                                    }`}

                                                                    <div>
                                                                        {`${
                                                                            tab.apres &&
                                                                            tab.apres.hasOwnProperty(
                                                                                "dateRevisionTimeStamp"
                                                                            ) &&
                                                                            tab
                                                                                .apres
                                                                                .dateRevisionTimeStamp !=
                                                                                null
                                                                                ? moment(
                                                                                      tab
                                                                                          .apres
                                                                                          .dateRevisionTimeStamp
                                                                                  ).format(
                                                                                      "DD/MM/YYYY"
                                                                                  )
                                                                                : ""
                                                                        }`}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                        </tbody>
                                    </table>
                                )}

                                {!flux && (
                                    <NoItem
                                        text={`Aucune entrée ou sortie pour l'année ${selectedYear}`}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
export default Statistics;
