import React, { ChangeEvent } from "react";
import { useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { request } from "../../../utils/axios-utils";

const CreateDocument: React.FC<{
    documents: number;
    refreshDocuments: () => void;
}> = (props) => {
    const { letterId }: { letterId: string } = useParams();

    const { addToast } = useToasts();

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile =
            event.target.files && event.target.files.length > 0
                ? event.target.files[0]
                : null;

        if (selectedFile && !selectedFile.name.toLowerCase().endsWith(".pdf")) {
            addToast("Le fichier doit être au format PDF.", {
                appearance: "error",
            });
            return;
        }

        const fileSizeMB = selectedFile.size / (1024 * 1024);
        if (fileSizeMB > 20) {
            addToast(`La taille du fichier ne doit pas dépasser 20 Mo.`, {
                appearance: "error",
            });
            return;
        }

        if (selectedFile) {
            const formData = new FormData();
            formData.append("document", selectedFile);
            formData.append("priority", props.documents.toString());

            request({
                url: `api/maileva/add-document/${letterId}`,
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        addToast("Document téléchargé avec succès.", {
                            appearance: "success",
                        });
                        props.refreshDocuments();
                    } else {
                        addToast("Échec du téléchargement du document.", {
                            appearance: "error",
                        });
                    }
                })
                .catch((error) => {
                    addToast("Erreur lors du téléchargement du document", {
                        appearance: "error",
                    });
                });
        } else {
            addToast("Aucun fichier sélectionné.", { appearance: "error" });
        }
    };

    return (
        <>
            <label htmlFor="file-upload" className="mailevaButtonPadding">
                <div className="custom-button">Ajouter un document</div>
            </label>
            <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                accept=".pdf"
                style={{ display: "none" }}
            />
        </>
    );
};

export default CreateDocument;
