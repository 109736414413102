import React, { useState } from "react";
import { Link } from "react-router-dom";
import { capitalizeFirst, returnOnlyNumbers } from "../../../constants/Utils";
import IFormJudge from "../../../interfaces/forms/IFormJudge";
import Divider from "../../layout/Divider";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";

const InnerJudgeForm: React.FC<IFormJudge> = (props) => {
    const [judge, setJudge] = useState<IFormJudge>(props);

    const setValue = (field: string, value: string) => {
        const newJudge = { ...judge, [field]: value };
        setJudge(newJudge);
        props.onChange(newJudge);
    };

    return (
        <div className="column full-width">
            <div className="layout-start-center row full-width">
                {props.isDeletable && (
                    <div className="remove-button layout-center-center row pointer">
                        <Link to={"#"} onClick={() => props.removeJudge(judge)}>
                            <i className="material-icons">delete</i>
                        </Link>
                    </div>
                )}

                <div className="column full-width">
                    <div className="row layout-xs-column">
                        <InputComponent
                            type="text"
                            id={`${props.id}-type`}
                            className="flex-1"
                            label="Type"
                            required
                            value={judge.type || ""}
                            onChange={(e) =>
                                setValue(
                                    "type",
                                    capitalizeFirst(e.target.value)
                                )
                            }
                            maxLength={60}
                        />

                        <div className="flex005 hide-xs" />

                        <Select
                            className="flex-1"
                            id={`${props.id}-civility`}
                            label="Civilité"
                            required
                            value={judge.civility}
                            needDefaultValue={true}
                            options={[
                                { value: "Madame", label: "Madame" },
                                { value: "Monsieur", label: "Monsieur" },
                            ]}
                            onChange={(e) =>
                                setValue("civility", e.target.value)
                            }
                        />

                        <div className="flex005 hide-xs" />

                        <InputComponent
                            type="text"
                            id={`${props.id}-first_name`}
                            className="flex-1"
                            label="Prénom"
                            required
                            value={judge.first_name || ""}
                            onChange={(e) =>
                                setValue(
                                    "first_name",
                                    capitalizeFirst(e.target.value)
                                )
                            }
                            maxLength={60}
                        />

                        <div className="flex005 hide-xs" />

                        <InputComponent
                            type="text"
                            id={`${props.id}-last_name`}
                            className="flex-1"
                            label="Nom"
                            required
                            value={judge.last_name || ""}
                            onChange={(e) =>
                                setValue(
                                    "last_name",
                                    capitalizeFirst(e.target.value)
                                )
                            }
                            maxLength={60}
                        />
                    </div>

                    <div className="row layout-xs-column">
                        <InputComponent
                            type="email"
                            id={`${props.id}-email`}
                            className="flex-1"
                            label="Email"
                            value={judge.email || ""}
                            onChange={(e) => setValue("email", e.target.value)}
                            maxLength={50}
                        />

                        <div className="flex005 hide-xs" />

                        <InputComponent
                            type="text"
                            id={`${props.id}-phone`}
                            className="flex-1"
                            label="Téléphone"
                            value={judge.phone || ""}
                            onChange={(e) =>
                                setValue(
                                    "phone",
                                    returnOnlyNumbers(e.target.value)
                                )
                            }
                            maxLength={15}
                        />

                        <div className="flex005 hide-xs" />

                        <InputComponent
                            type="text"
                            id={`${props.id}-fax`}
                            className="flex-1"
                            label="Fax"
                            value={judge.fax || ""}
                            onChange={(e) =>
                                setValue(
                                    "fax",
                                    returnOnlyNumbers(e.target.value)
                                )
                            }
                            maxLength={15}
                        />
                    </div>
                </div>
            </div>
            <Divider />
        </div>
    );
};

export default InnerJudgeForm;
