import React from "react";

const ErrorMessage: React.FC<{ text: string; }> = (props) => {
  return (
    <div className="error_message_container">
        <p>{props.text}</p>
    </div>
  );
};

export default ErrorMessage;
