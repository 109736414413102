import moment from "moment";
import React, { useEffect, useState } from "react";
import Switch from "react-bootstrap-switch";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { momentUpdateLocale } from "../../../constants/Utils";
import IContact from "../../../interfaces/IContact";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import { request } from "../../../utils/axios-utils";
import Loader from "../../layout/Loader";
import SelectWithSearch from "../../layout/forms/selectWithSearch";
import ButtonConfirmModalIcon from "../../layout/modals/ButtonConfirmModalIcon";
import Modal from "../../layout/modals/Modal";
import { useAuth } from "../Login/auth.context";

const SettingsMajeur: React.FC<{
    majeurId: string;
    majeur: IProtectedAdult;
    changeMajeur: Function;
}> = (props) => {
    const [openDeleteProtege, setOpenDeleteProtege] = useState(false);
    const [isShowingDialog, setIsShowingDialog] = useState(false);
    const [listContacts, setListContacts] = useState([]);
    const [contact, setContact] = useState<IContact>(null);
    const [isMajeurArchived, setArchivedMajeur] = useState(
        props.majeur.archived
    );
    const { currentUser } = useAuth();

    const [isCRGControl, setIsCRGControl] = useState(
        props.majeur.crg_is_controled ?? false
    );

    const { addToast } = useToasts();
    const history = useHistory();

    momentUpdateLocale();

    useEffect(() => {
        if (props.majeur && listContacts.length == 0) {
            request({ addToast, url: `/api/users/organisms` })
                .then(({ data }) => {
                    setListContacts(data);
                })
                .then(() => {
                    if (props.majeur.crg_control_id) {
                        request({
                            addToast,
                            url: `/api/contacts/${props.majeur.crg_control_id}`,
                        })
                            .then(({ data }) => {
                                setContact(data);
                            })
                            .catch();
                    }
                });
        }
    }, []);

    const selectContact = (event: any) => {
        request({ url: `/api/contacts/${event.value}` })
            .then(({ data }) => {
                setContact(data);
            })
            .then(() => {
                request({
                    url: `/api/majeurs/crg_contact`,
                    method: "post",
                    data: {
                        majeur_id: props.majeurId,
                        crg_control_id: event.value,
                    },
                }).then(() => {
                    addToast("Organisme ajouté avec succès", {
                        appearance: "success",
                    });
                });
            })
            .catch(() => {
                addToast("Une erreur est survenue", { appearance: "error" });
            });
    };

    const onValidate = () => {
        setIsShowingDialog(false);
        // Call to delete the patients & all bdd objects linked
        request({
            addToast,
            url: `/api/majeurs/delete/${props.majeurId}`,
            method: "post",
            data: {},
        })
            .then((data) => {
                addToast(`Le protégé a bien été supprimé.`, {
                    appearance: "success",
                });
                history.push("/list_majeurs");
            })
            .catch();
    };

    const checkUrgenceChange = () => {
        request({
            addToast,
            url: `/api/majeurs/archiveUnarchive`,
            method: "post",
            data: {
                majeur_id: props.majeurId,
                to_archive: !isMajeurArchived,
            },
        }).then(() => {
            addToast(
                `Le protégé a bien été ${
                    isMajeurArchived ? "désarchivé" : "archivé"
                }.`,
                {
                    appearance: "success",
                }
            );
            setArchivedMajeur(!isMajeurArchived);
            request({ addToast, url: `/api/majeur/${props.majeurId}` })
                .then(({ data }) => {
                    props.changeMajeur(data);
                })
                .catch();
        });
    };

    const checkCRGControl = () => {
        request({
            addToast,
            url: `/api/majeurs/CRGControl`,
            method: "post",
            data: {
                majeur_id: props.majeurId,
                crg_is_controled: !isCRGControl,
            },
        })
            .then(() => {
                if (isCRGControl === false) {
                    request({
                        addToast,
                        url: `/api/majeur/${props.majeurId}`,
                    })
                        .then(({ data }) => {
                            props.changeMajeur(data);
                            setContact(null);
                        })
                        .catch();
                }
                setIsCRGControl(!isCRGControl);
                addToast(
                    `Le CRG ${
                        isCRGControl
                            ? "est enregistré comme controlé"
                            : "est enregistré comme non controlé"
                    }.`,
                    {
                        appearance: "success",
                    }
                );
            })
            .catch(() => {
                addToast("Une erreur est survenue", { appearance: "error" });
            });
    };

    const createAddressMail = () => {
        request({
            addToast,
            url: `/api/majeurs/create-mail-address/${props.majeurId}`,
            method: "post",
            data: {},
        })
            .then(() => {
                addToast(
                    "L'adresse e-mail du protégé a bien été générée. Elle sera disponible dans 1 minute sur votre espace.",
                    {
                        appearance: "success",
                    }
                );
                request({
                    addToast,
                    url: `/api/majeur/${props.majeurId}`,
                })
                    .then(({ data }) => {
                        props.changeMajeur(data);
                    })
                    .catch();
            })
            .catch();
    };

    const removeAddressMail = () => {
        request({
            addToast,
            url: `/api/majeurs/remove-mail-address/${props.majeurId}`,
            method: "post",
            data: {},
        })
            .then(() => {
                addToast("L'adresse e-mail du protégé a bien été supprimée.", {
                    appearance: "success",
                });
                request({
                    addToast,
                    url: `/api/majeur/${props.majeurId}`,
                })
                    .then(({ data }) => {
                        props.changeMajeur(data);
                    })
                    .catch();
            })
            .catch();
    };

    return (
        <div className="Majeur settings_majeur">
            <div className="subBar_actions sticky top-90 layout-space-between-center row">
                <span className="subBar_actions_title">Les paramètres</span>
            </div>
            <div className="page_content column">
                {!props.majeur && <Loader />}
                {props.majeur &&
                    (props.majeur.address_mail ? (
                        <div
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            Email : {props.majeur.address_mail}
                            {currentUser.profile.powermail_extension ||
                                "@accelgerancia-tutelle.fr"}
                            <div>
                                <ButtonConfirmModalIcon
                                    id="removeOpe"
                                    iconClassName="remove-icon"
                                    onValidate={() => removeAddressMail()}
                                    icon="delete_forever"
                                    question={
                                        "Êtes-vous sûr de vouloir supprimer l'alias du protégé ? Les e-mails ne seront plus reçus sur cette adresse. Le dossier reste accessible pour suppression manuelle sur powermail."
                                    }
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <button
                                disabled={!!props.majeur.address_mail}
                                className="valid-button"
                                onClick={createAddressMail}
                            >
                                Générer une adresse email
                            </button>
                        </div>
                    ))}
                <div className="row layout-start-center">
                    <Switch
                        onChange={checkUrgenceChange}
                        labelText="Archivé"
                        bsSize="normal"
                        wrapperClass="wrapper"
                        onText="OUI"
                        onColor="success"
                        offColor="danger"
                        offText="NON"
                        value={isMajeurArchived}
                    />
                    <div style={{ marginBottom: 20, marginLeft: 20 }}>
                        {isMajeurArchived &&
                            props.majeur.current_protection?.end_date &&
                            `Date de fin de mesure le : ${moment(
                                props.majeur.current_protection?.end_date.$date
                                    ? parseInt(
                                          props.majeur.current_protection
                                              ?.end_date.$date.$numberLong
                                      )
                                    : props.majeur.current_protection?.end_date
                            ).format("L")}`}
                    </div>
                </div>
                <div className="row" style={{ alignItems: "baseline" }}>
                    <Switch
                        onChange={checkCRGControl}
                        labelText="Controle CRG"
                        bsSize="normal"
                        wrapperClass="wrapper"
                        onText="OUI"
                        onColor="success"
                        offColor="danger"
                        offText="NON"
                        value={isCRGControl}
                    />
                    <div className="width-80" style={{ marginLeft: "10px" }}>
                        {isCRGControl &&
                            listContacts.length > 0 &&
                            ((props.majeur.crg_control_id && contact) ||
                                !props.majeur.crg_control_id) && (
                                <SelectWithSearch
                                    id="select_organisme"
                                    label="Organisme"
                                    needDefaultValue={true}
                                    required
                                    options={listContacts}
                                    value={{
                                        value: contact?._id,
                                        label: contact?.organisme,
                                    }}
                                    onChange={(e) => selectContact(e)}
                                />
                            )}

                        {isCRGControl &&
                            listContacts.length <= 0 &&
                            "Vous devez ajouter un organisme dans la liste des contacts pour pouvoir controler le CRG"}

                        {/*Ajouter l'input contact*/}
                    </div>
                </div>
                <div className="row layout-start-center">
                    <input
                        type="checkbox"
                        name="archiveCheck"
                        checked={openDeleteProtege}
                        onChange={() =>
                            setOpenDeleteProtege(!openDeleteProtege)
                        }
                    />
                    <label htmlFor="archiveCheck" className="suppress_label">
                        Je souhaite supprimer ce protegé
                    </label>
                    <button
                        className={
                            "delete-button " +
                            (openDeleteProtege ? "" : "disabled")
                        }
                        disabled={!openDeleteProtege}
                        onClick={() => setIsShowingDialog(true)}
                    >
                        Supprimer le protégé
                    </button>
                </div>
            </div>
            <Modal
                show={isShowingDialog}
                onValidate={onValidate}
                onClose={() => {
                    setOpenDeleteProtege(false);
                    setIsShowingDialog(false);
                }}
                message="La suppression effacera toutes les données du majeur ainsi que sa boite e-mail. Cette opération est irreversible. Êtes vous sur de vouloir supprimer ce protégé ?"
            />
        </div>
    );
};
export default SettingsMajeur;
