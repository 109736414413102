import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { capitalizeFirst, checkPrevious } from "../../../constants/Utils";
import IAddress from "../../../interfaces/IAddress";
import IEmail from "../../../interfaces/IEmail";
import IPhone from "../../../interfaces/IPhone";
import { request } from "../../../utils/axios-utils";
import ArrowBack from "../../layout/ArrowBack";
import Divider from "../../layout/Divider";
import Icon from "../../layout/Icon";
import SubBar from "../../layout/SubBar";
import FormButton from "../../layout/forms/FormButton";
import InputComponent from "../../layout/forms/InputComponent";
import InputDatePicker from "../../layout/forms/InputDatePicker";
import Select from "../../layout/forms/Select";
import InnerAddressForm from "../Contacts/InnerAddressForm";

const CreateMajeur: React.FC = () => {
    const [civility, setCivility] = useState<string>();
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [famillyName, setFamillyName] = useState<string>("");
    const [userId, setUserId] = useState<string>("");
    const [birthPlace, setBirthPlace] = useState<string>("");

    const [birthDate, setBirthDate] = useState<Date>();

    const [addresses, setAddresses] = useState<Array<IAddress>>([]);
    const [phones, setPhones] = useState<Array<IPhone>>([]);
    const [emails, setEmails] = useState<Array<IEmail>>([]);

    const [addressIndex, setAddressIndex] = useState<number>(0);
    const [phoneIndex, setPhoneIndex] = useState<number>(0);
    const [emailIndex, setEmailIndex] = useState<number>(0);

    const history = useHistory();
    const { addToast } = useToasts();

    useEffect(() => {
        if (!userId) {
            request({ addToast, url: `/api/user` }).then(({ data }) => {
                setUserId(data._id);
            }).catch();
        }
    }, [userId]);

    /**
     * Register a new protected adult.
     */
    const saveMajeur = () => {
        const majeur: any = {
            civility: civility,
            last_name: capitalizeFirst(lastName.trim()),
            first_name: capitalizeFirst(firstName.trim()),
            familly_name: capitalizeFirst(famillyName.trim()),
            birth_date: birthDate,
            birth_place: capitalizeFirst(birthPlace.trim()),
            addresses: addresses,
            phones: phones,
            emails: emails,
        };

        request({
            addToast,
            url: "/api/majeurs/create",
            method: "post",
            data: { majeur: majeur, userId: userId },
        }).then((data) => {
            // The data has been saved.
            addToast("Le protégé a bien été créé", {
                appearance: "success",
            });
            history.push("/list_majeurs");
        }).catch();
    };

    //Add a Phone, Email or Adress
    const addSomething = (type: string) => {
        switch (type) {
            case "Address":
                const addressList = [...addresses];
                if (checkPrevious(addressList, "addresses")) {
                    addresses.forEach((p) => (p.isDeletable = false));
                    addresses.push({
                        id: `Address-${addressIndex}`,
                        isDeletable: true,
                    });
                    setAddressIndex(addressIndex + 1);
                } else {
                    addToast("Ajoutez d'abord une addresses.", {
                        appearance: "warning",
                    });
                }
                break;

            case "Phone":
                const phonesList = [...phones];
                if (checkPrevious(phonesList, "phones")) {
                    phones.forEach((p) => (p.isDeletable = false));
                    phones.push({
                        id: `phone-${phoneIndex}`,
                        isDeletable: true,
                    });
                    setPhoneIndex(phoneIndex + 1);
                } else {
                    addToast("Ajoutez d'abord un numéro de téléphone.", {
                        appearance: "warning",
                    });
                }
                break;

            case "Email":
                const emailsList = [...emails];
                if (checkPrevious(emailsList, "emails")) {
                    emails.forEach((p) => (p.isDeletable = false));
                    emails.push({
                        id: `email-${emailIndex}`,
                        isDeletable: true,
                    });
                    setEmailIndex(emailIndex + 1);
                } else {
                    addToast("Ajoutez d'abord une adresse email.", {
                        appearance: "warning",
                    });
                }
                break;

            default:
                break;
        }
    };

    //Modify a Phone, Email or Adress
    const setSomethingValue = (type: string, object: any) => {
        let index = 0;
        switch (type) {
            case "Address":
                index = addresses.findIndex((a) => a.id === object.id);
                let newAddresses = addresses;
                newAddresses[index] = object;
                setAddresses(newAddresses);
                break;

            case "Phone":
                index = phones.findIndex((p) => p.id === object.id);
                let newPhones = phones;
                newPhones[index] = object;
                setPhones(newPhones);
                break;

            case "Email":
                index = emails.findIndex((e) => e.id === object.id);
                let newEmails = emails;
                newEmails[index] = object;
                setEmails(newEmails);
                break;

            default:
                break;
        }
    };

    //Delete a Phone, Email or Adress
    const removeSomething = (type: string, object: any) => {
        let index = 0;
        switch (type) {
            case "Address":
                index = addresses.findIndex((a) => a.id === object.id);
                let newAddresses = addresses;
                newAddresses.splice(index, 1);
                newAddresses.forEach(
                    (p, index) =>
                        (p.isDeletable = index === newAddresses.length - 1)
                );
                setAddresses(newAddresses);
                setAddressIndex(addressIndex - 1);
                break;

            case "Phone":
                index = phones.findIndex((p) => p.id === object.id);
                let newPhones = phones;
                newPhones.splice(index, 1);
                newPhones.forEach(
                    (a, index) =>
                        (a.isDeletable = index === newPhones.length - 1)
                );
                setPhones(newPhones);
                setPhoneIndex(phoneIndex - 1);
                break;

            case "Email":
                index = emails.findIndex((e) => e.id === object.id);
                let newEmails = emails;
                newEmails.splice(index, 1);
                newEmails.forEach(
                    (e, index) =>
                        (e.isDeletable = index === newEmails.length - 1)
                );
                setEmails(newEmails);
                setEmailIndex(emailIndex - 1);
                break;

            default:
                break;
        }
    };

    return (
        <>
            <div className="create_majeur">
                <ArrowBack title="Ajouter un protégé" />
                <div className="page_content">
                    <form
                        className="column" /*onSubmit={this.onSubmitFormAddClient.bind(this)}*/
                    >
                        <div className="row layout-xs-column">
                            <Select
                                className="flex-1"
                                id={"civility"}
                                label="Civilité"
                                needDefaultValue={true}
                                required
                                value={civility}
                                options={[
                                    { value: "Madame", label: "Madame" },
                                    { value: "Monsieur", label: "Monsieur" },
                                ]}
                                onChange={(e) => setCivility(e.target.value)}
                            />
                            <div className="flex005 hide-xs"></div>
                            <InputComponent
                                id="label"
                                type="text"
                                className="column-item-flex-1"
                                label="Prénom"
                                required
                                value={firstName || ""}
                                onChange={(e) => setFirstName(e.target.value)}
                                maxLength={50}
                            />
                            <div className="flex005 hide-xs" />
                            <InputComponent
                                id="label"
                                type="text"
                                className="column-item-flex-1"
                                label="Nom d'usage"
                                required
                                value={lastName || ""}
                                onChange={(e) => setLastName(e.target.value)}
                                maxLength={50}
                            />
                            <div className="flex005 hide-xs" />
                            <InputComponent
                                id="famillyName"
                                type="text"
                                className="column-item-flex-1"
                                label="Nom de naissance"
                                value={famillyName}
                                onChange={(e) => setFamillyName(e.target.value)}
                                maxLength={50}
                            />
                        </div>

                        <div className="flex005 hide-xs" />

                        <div className="row layout-xs-column">
                            <InputDatePicker
                                id="label"
                                inputDate={birthDate && new Date(birthDate)}
                                label="Date de naissance "
                                className="my-input flex-1"
                                required
                                onChange={(date: Date) => setBirthDate(date)}
                            />
                            <div className="flex005 hide-xs" />
                            <InputComponent
                                id="birthPlace"
                                type="text"
                                className="column-item-flex-1"
                                required
                                label="Lieu de naissance"
                                value={birthPlace}
                                onChange={(e) => setBirthPlace(e.target.value)}
                                maxLength={50}
                            />
                        </div>

                        <div className="flex005 hide-xs" />

                        {/* Block for addresses linked */}
                        <div className="block">
                            <div className="my-sub-title-f">
                                <SubBar title="Adresses">
                                    <Link
                                        to={"#"}
                                        onClick={() => addSomething("Address")}
                                    >
                                        <Icon name="add" />
                                    </Link>
                                </SubBar>
                            </div>

                            <div className="layout-center-start column">
                                {addresses.map((address, index) => (
                                    <div
                                        key={index}
                                        className="column full-width"
                                    >
                                        <InnerAddressForm
                                            key={address.id}
                                            id={address.id}
                                            removeAddress={(
                                                address: IAddress
                                            ) =>
                                                removeSomething(
                                                    "Address",
                                                    address
                                                )
                                            }
                                            onChange={(address: IAddress) =>
                                                setSomethingValue(
                                                    "Address",
                                                    address
                                                )
                                            }
                                            isDeletable={
                                                addresses.length - 1 === index
                                            }
                                            {...address}
                                        />
                                        {addresses.length != index + 1 && (
                                            <Divider />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <FormButton
                            onClick={saveMajeur}
                            style={{ marginTop: 20 }}
                            text={"Ajouter"}
                        />
                    </form>
                </div>
            </div>
        </>
    );
};

export default CreateMajeur;
