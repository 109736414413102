import React from "react";
import IFormButton from "../../../interfaces/forms/IFormButton";

const FormButton: React.FC<IFormButton> = (props) => {
    return (
        <>
            {props.imageSrc && (
                <div className="box">
                    <a>
                        <img
                            id="logo"
                            src={props.imageSrc}
                            alt="logo"
                            onClick={props.onClick}
                        />
                    </a>
                </div>
            )}
            {!props.imageSrc && (
                <button
                    type="button"
                    style={props.style}
                    className={
                        "valid-button" + (props.disabled ? " disabled" : "")
                    }
                    disabled={props.disabled}
                    onClick={props.onClick}
                >
                    {props.text}
                </button>
            )}
        </>
    );
};

export default FormButton;
