import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IUsers from "../../../interfaces/IUsers";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import Select from "../../layout/forms/Select";
import Modal from "../../layout/modals/Modal";

const User: React.FC = () => {
    const [user, setUser] = useState<IUsers | null>(null);

    const [protectedAdultsType, setProtectedAdultsType] =
        useState<string>("_TOUS_");
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

    const [confirmDeletion, setConfirmDeletion] = useState<boolean>(false);

    const { addToast } = useToasts();
    const history = useHistory();

    const { userId }: { userId: string } = useParams();
    const refListAdmin = useRef();
    const refListUser = useRef();

    useEffect(() => {
        if (!user) {
            request({ addToast, url: `/api/users/${userId}` })
                .then(({ data }) => {
                    setUser(data);
                })
                .catch((err) => {
                    history.push("/utilisateurs");
                });
        }
    }, [user]);

    const deleterUser = () => {
        setConfirmDeletion(false);

        request({
            addToast,
            url: `/api/users/${userId}/delete`,
            method: "post",
            data: {},
        })
            .then((data) => {
                history.push("/utilisateurs");
                addToast(`L'utilisateur a bien été supprimé`, {
                    appearance: "success",
                });
            })
            .catch();
    };

    const changeAllCheck = (value: any) => {
        setIsAllChecked(value);
        // @ts-ignore
        for (let option of refListAdmin.current.options) {
            if (value) {
                option.selected = true;
            } else {
                option.selected = false;
            }
        }
    };

    const changeDropdown = (value: any) => {
        setProtectedAdultsType(value);
        setIsAllChecked(!isAllChecked);
    };

    const getSelectValuesFromMultiSelect = (select: any) => {
        var result = [];
        var options = select.current && select.current.options;
        var opt;

        for (var i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];

            if (opt.selected) {
                result.push(opt.value || opt.text);
            }
        }
        return result;
    };

    const deleteActivatedProtege = () => {
        const selectedOption = getSelectValuesFromMultiSelect(refListUser);
        if (selectedOption.length > 0)
            removeTransfertProteges(userId, selectedOption);
        else
            addToast(`Vous devez sélectionner un protégé.`, {
                appearance: "warning",
            });
    };

    const addActivatedProtege = () => {
        const selectedOption = getSelectValuesFromMultiSelect(refListAdmin);
        if (selectedOption.length > 0) {
            addtransferProteges(user._id, selectedOption);
        } else {
            addToast(`Vous devez sélectionner un protégé.`, {
                appearance: "warning",
            });
        }
    };

    const addtransferProteges = (userId: string, selectOptions: any) => {
        const payload = { selectedIDS: selectOptions };
        request({
            url: `/api/users/${userId}/add-control`,
            method: "post",
            data: { payload },
        })
            .then(({ data }) => {
                setUser(data);
            })
            .catch((err) => {
                if (err.response!.status === 404) {
                    addToast(`Aucun protégé n'existe avec cet identifiant`, {
                        appearance: "error",
                    });
                } else if (err.response!.status === 400) {
                    addToast(`Le protégé est déjà dans la liste.`, {
                        appearance: "error",
                    });
                } else {
                    history.push("/utilisateurs");
                }
            });
    };

    const removeTransfertProteges = (userId: string, selectOptions: any) => {
        const payload = { selectedIDS: selectOptions };
        request({
            url: `/api/users/${userId}/remove-control`,
            method: "post",
            data: { payload },
        })
            .then(({ data }) => {
                setUser(data);
            })
            .catch((err) => {
                if (err.response!.status === 404) {
                    addToast(`Aucun protégé n'existe avec cet identifiant`, {
                        appearance: "error",
                    });
                } else {
                    history.push("/utilisateurs");
                }
            });
    };

    let currentAdminList;
    let currentUserList;
    if (user && protectedAdultsType === "_COURANTS_") {
        currentAdminList = user.adminActiveProtected;
        currentUserList = user.activeProtected;
    } else if (user && protectedAdultsType === "_ARCHIVES_") {
        currentAdminList = user.adminArchivedProtected;
        currentUserList = user.archivedProtected;
    } else if (user && protectedAdultsType === "_TOUS_") {
        currentAdminList = user.adminArchivedProtected.concat(
            user.adminActiveProtected
        );
        currentUserList = user.activeProtected.concat(user.archivedProtected);
    }

    return (
        <>
            <SubBar
                title={
                    (user &&
                        `${user.profile.infos.first_name}  ${user.profile.infos.last_name} | Utilisateur`) ||
                    "Utilisateur"
                }
                back="/utilisateurs"
            >
                <Link to="#" onClick={() => setConfirmDeletion(true)}>
                    <Icon name="delete_forever" />
                </Link>
            </SubBar>

            <Modal
                message="Attention : La suppression de l'utilisateur est définitive. Êtes vous sûr(e) ?"
                show={confirmDeletion}
                onValidate={deleterUser}
            />

            <div className="page_content_users">
                {!user && <Loader />}

                {user && (
                    <div className="Single_User">
                        <div className="page_content row">
                            <div className="column flex-1">
                                <div className="row layout-space-between-center">
                                    <div className="row checkbox_select_all_container">
                                        <input
                                            type="checkbox"
                                            defaultChecked={false}
                                            onChange={(event) =>
                                                changeAllCheck(
                                                    event.target.checked
                                                )
                                            }
                                        />
                                        Tout sélectionner
                                    </div>
                                    <div style={{ width: 200 }}>
                                        <Select
                                            value={protectedAdultsType}
                                            onChange={(e) => {
                                                changeDropdown(e.target.value);
                                            }}
                                            options={[
                                                {
                                                    value: "_TOUS_",
                                                    label: "Tous",
                                                },
                                                {
                                                    value: "_COURANTS_",
                                                    label: "Courants",
                                                },
                                                {
                                                    value: "_ARCHIVES_",
                                                    label: "Archivés",
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>

                                <select
                                    className="select_allproteges"
                                    ref={refListAdmin}
                                    multiple
                                >
                                    {currentAdminList.map((m) => {
                                        return (
                                            <option key={m._id} value={m._id}>
                                                {m.last_name} {m.first_name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div className="column flex-25 buttons_container">
                                <button
                                    className="m-1 bg-transparent font-semibold hover:text-white py-2 px-4 border border-blue-500 rounded"
                                    onClick={addActivatedProtege}
                                >
                                    Ajouter &rArr;
                                </button>
                                <button
                                    className="m-1 bg-transparent font-semibold hover:text-white py-2 px-4 border border-blue-500 rounded"
                                    onClick={deleteActivatedProtege}
                                >
                                    &lArr; Retirer
                                </button>
                            </div>

                            <div className="column flex-1">
                                <select
                                    className="select_allproteges"
                                    ref={refListUser}
                                    multiple
                                >
                                    {currentUserList.map((m) => {
                                        return (
                                            <option key={m._id} value={m._id}>
                                                {m.last_name} {m.first_name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default User;
