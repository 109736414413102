import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import FullBarTitle from "../../layout/FullBarTitle";
import Loader from "../../layout/Loader";
import Activated from "./Activated";
import NotActivated from "./NotActivated";

const InvoicesSettings: React.FC = () => {
    const [invoices, setInvoices] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { addToast } = useToasts();
    const [stripeReady, setStripeReady] = useState<boolean>(false);
    const [stripeId, setStripeId] = useState<string>("");
    const [sourcesAvailable, setSourcesAvailable] = useState<boolean>(false);
    const [user, setUser] = useState<IUser>(null);
    const [activated, setActivated] = useState<boolean>(false);

    const [tempSepa, setTempSepa] = useState<any>(null);
    const [modifyPaymentMean, setModifyPaymentMean] = useState<any>("_NO_");

    const [cards, setCards] = useState<Array<any>>(null);
    const [sepas, setSepas] = useState<Array<any>>(null);
    useEffect(() => {
        if (!invoices && isLoading) {
            request({ addToast, url: `/api/invoices` })
                .then(({ data }) => {
                    setInvoices(data);
                    setIsLoading(false);
                })
                .catch();
        }
    }, [invoices]);

    useEffect(() => {
        if (!user) {
            request({ addToast, url: `/api/user` })
                .then(({ data }) => {
                    setUser(data);

                    if (data.abonnement) {
                        setStripeId(data.abonnement.stripeId);
                        setStripeReady(true);

                        setActivated(data.abonnement.activated);

                        if (!sourcesAvailable) {
                            getSources(data.abonnement.stripeId);
                        }
                    }
                })
                .catch();
        }
    }, [user, activated, sourcesAvailable]);

    const downloadInvoiceWithId = (id: string) => {
        request({
            addToast,
            url: `/api/invoices/${id}/download`,
            method: "post",
            data: {},
        })
            .then((data) => {
                if (data.data.path) {
                    document.location.href = data.data.path;
                }
            })
            .catch();
    };

    const getSources = (id?: string) => {
        // Back to null before...
        setCards(null);
        setSepas(null);

        let idForStripe = id ? id : stripeId;

        // Get cards and sepa from Stripe
        if (idForStripe && idForStripe != "") {
            request({
                addToast,
                url: `/api/stripe/customer/${id ? id : idForStripe}`,
            })
                .then((res) => {
                    if (res.data.cards.length > 0) setCards(res.data.cards);
                    if (res.data.sepas.length > 0) setSepas(res.data.sepas);
                })
                .catch();
        }

        setSourcesAvailable(true);
    };

    //-----------Stripe-----------//
    const subscribeWithSepa = () => {
        // Create a virtual card item on Stripe ...
        request({
            addToast,
            url: `/api/stripe/sepa/token/create`,
            method: "post",
            data: {
                iban: tempSepa.iban,
                name: tempSepa.name,
                city: tempSepa.city,
                postalCode: tempSepa.postalCode,
            },
        })
            .then(({ data }) => {
                let token = data.id;
                // ... create Stripe customer
                request({
                    addToast,
                    url: `/api/stripe/customer/create/${token}`,
                    method: "post",
                    data: {},
                })
                    .then(({ data }) => {
                        setModifyPaymentMean("_YES_");
                        addToast("Votre sepa a été ajouté avec succès !", {
                            appearance: "success",
                        });
                        setModifyPaymentMean("_DONE_");

                        // Update abonnement dashboard
                        setActivated(true);
                        getSources(data.stripe_id);
                    })
                    .catch();
            })
            .catch();
    };

    const changeSepa = () => {
        // Create a virtual card item on Stripe ...
        request({
            addToast,
            url: `/api/stripe/sepa/token/create`,
            method: "post",
            data: {
                iban: tempSepa.iban,
                name: tempSepa.name,
                city: tempSepa.city,
                postalCode: tempSepa.postalCode,
            },
        })
            .then(({ data }) => {
                let token = data.id;
                // ... create Stripe customer
                request({
                    addToast,
                    url: `/api/stripe/customer/update/${token}`,
                    method: "post",
                    data: {},
                })
                    .then(({ data }) => {
                        setModifyPaymentMean("_YES_");
                        addToast(
                            "Votre moyen de paiement a été modifié avec succès !",
                            { appearance: "success" }
                        );
                        setModifyPaymentMean("_DONE_");
                        // Update abonnement dashboard
                        getSources();
                    })
                    .catch();
            })
            .catch();

        setModifyPaymentMean("_DONE_");
    };

    const updateSepa = (sepa: any) => {
        setTempSepa(sepa);
    };

    return (
        <>
            {/* {isLoading && <Loader />} */}
            <div className="subBar_actions sticky top-90 layout-space-between-center row">
                <span className="subBar_actions_title">Votre abonnement</span>
            </div>
            {/* Stripe */}
            <div className="page_content">
                {activated ? (
                    <Activated
                        stripeReady={stripeReady}
                        subscribeWithSepa={() => changeSepa()}
                        updateSepa={(sepa: any) => updateSepa(sepa)}
                        modifyPaymentMean={modifyPaymentMean}
                        cards={cards}
                        sepas={sepas}
                        sourcesAvailable={sourcesAvailable}
                        user={user}
                    />
                ) : (
                    <NotActivated
                        stripeReady={stripeReady}
                        subscribeWithSepa={() => subscribeWithSepa()}
                        updateSepa={(sepa: any) => updateSepa(sepa)}
                        modifyPaymentMean={modifyPaymentMean}
                        user={user}
                    />
                )}
            </div>
            {!isLoading && invoices && (
                <div className="Invoices">
                    <FullBarTitle text="Liste de vos Factures" />

                    {invoices.length === 0 && (
                        <div className="layout-center-center row div-no-items">
                            Vous n'avez pas encore de facture !
                        </div>
                    )}
                    <div className="page_content">
                        {!invoices && <Loader />}

                        {invoices &&
                            invoices.length > 0 &&
                            invoices.map((i: any) => (
                                <div
                                    key={i._id}
                                    className="row invoice layout-space-between-center"
                                >
                                    <div>
                                        Facture du : <b>{i.format_date}</b>
                                    </div>
                                    <div
                                        className={
                                            "layout-center-center row update-icon"
                                        }
                                        onClick={() =>
                                            downloadInvoiceWithId(i._id)
                                        }
                                    >
                                        <i className="material-icons pointer">
                                            file_download
                                        </i>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </>
    );
};
export default InvoicesSettings;
