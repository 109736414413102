import { IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useState } from "react";
import { Button } from "../../../_shared/Button";

const SubscriptionForm = (props) => {
    const { companyName } = props;
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");

        const ibanElement = elements.getElement(IbanElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "sepa_debit",
            sepa_debit: {
                iban: ibanElement.value, // Obtenir l'IBAN de l'élément
            },
            billing_details: {
                companyName,
            },
        });

        if (error) {
            setErrorMessage(error.message);
            console.error("Error creating payment method:", error);
        } else {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/subscriptions`, // Assurez-vous que l'URL est correcte
                    {
                        payment_method_id: paymentMethod.id,
                        companyName,
                    }
                );
                setSuccessMessage("Abonnement créé avec succès !");
                console.log("Subscription created:", response.data);
            } catch (error) {
                setErrorMessage("Erreur lors de la création de l'abonnement.");
                console.error("Subscription error:", error);
            }
        }
    };

    return (
        <div className="mt-4 max-w-md p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold text-center mb-4">
                Souscription
            </h2>
            <form>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1">
                        IBAN :
                    </label>
                    <IbanElement
                        options={{
                            supportedCountries: ["SEPA"], // Assurez-vous de spécifier les pays pris en charge
                            style: {
                                base: {
                                    fontSize: "16px",
                                    color: "#333",
                                    "::placeholder": {
                                        color: "#aaa",
                                    },
                                },
                                invalid: {
                                    color: "#fa755a",
                                },
                            },
                        }}
                    />
                </div>
                {errorMessage && (
                    <div className="text-red-500 text-sm mb-4">
                        {errorMessage}
                    </div>
                )}
                {successMessage && (
                    <div className="text-green-500 text-sm mb-4">
                        {successMessage}
                    </div>
                )}
                <Button
                    type={"empty"}
                    disabled={!stripe}
                    name={"Enregistrer le moyen de paiement"}
                    action={(e) => {
                        handleSubmit(e);
                    }}
                />
            </form>
        </div>
    );
};

export default SubscriptionForm;
