import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ICourt from "../../../interfaces/ICourt";
import IMesure from "../../../interfaces/IMesure";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import ItemProtectionMesure from "../../layout/ItemProtectionMesure";
import ItemTwoLines from "../../layout/ItemTwoLines";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import {momentUpdateLocale} from "../../../constants/Utils";

const MesureMajeur: React.FC<{ majeurId: string; majeur: IProtectedAdult }> = (
    props
) => {
    const { addToast } = useToasts();

    const [mesure, setMesure] = useState<IMesure>(null);
    const [tribunaux, setTribunaux] = useState<Array<ICourt>>(null);
    const [normalOne, setNormalOne] = useState<boolean>(false);
    const [user, setUser] = useState<IUser>(null);
    const [isLoading, setIsLoading] = useState(false);

    momentUpdateLocale()

    useEffect(() => {
        setIsLoading(true);
        if (!user) {
            request({ addToast, url: `/api/user` }).then(({ data }) =>
                setUser(data)
            ).catch();
        }
        if (!tribunaux) {
            request({ addToast, url: `/api/courts` }).then(({ data }) => {
                setTribunaux(data.courts);
            }).catch();
        }
        if (!mesure) {
            request({
                addToast,
                url: `/api/majeurs/measure/${props.majeurId}`,
            }).then(({ data }) => {
                if (data) {
                    let normalProtections = _.filter(
                        data.protections,
                        function (p) {
                            return (
                                p.period !== "Ouverture" && p.period !== "Fin"
                            );
                        }
                    );

                    setNormalOne(
                        normalProtections && normalProtections.length > 0
                    );

                    setMesure(data);
                }
                setIsLoading(false);
            }).catch();
        }
    }, [user]);

    return (
        <>
            <div>
                <div className="column">
                    {!mesure && (
                        <SubBar title={"La mesure"}>
                            <Link to={`/form_mesure/${props.majeurId}`}>
                                <Icon name="add" />
                            </Link>
                        </SubBar>
                    )}

                    {mesure && !props.majeur.archived && (
                        <SubBar title={"La mesure"}>
                            <Link
                                to={`/form_mesure/${props.majeurId}/${mesure._id}`}
                            >
                                <Icon name="edit" />
                            </Link>
                        </SubBar>
                    )}

                    {!mesure && !isLoading && (
                        <div className="layout-center-center row div-no-items">
                            Ajouter la mesure en cliquant sur le +
                        </div>
                    )}
                    {!mesure && isLoading && <Loader />}
                    {mesure && user && (
                        <div>
                            <div className="page_content column">
                                <div className="row layout-xs-column">
                                    <ItemTwoLines
                                        title="Tribunal"
                                        flex={"1"}
                                        content={
                                            mesure.tribunal_id &&
                                            tribunaux &&
                                            tribunaux.length > 0
                                                ? tribunaux.find(
                                                      (t) =>
                                                          t._id ===
                                                          mesure.tribunal_id
                                                  ).city
                                                : "non renseigné."
                                        }
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="N° cabinet tribunal"
                                        flex={"1"}
                                        content={
                                            mesure.court_office_number
                                                ? mesure.court_office_number
                                                : "Non renseigné."
                                        }
                                    />
                                    <div className="flex005 hide-xs" />

                                    <ItemTwoLines
                                        title="DPT 1er Agrement"
                                        flex={"1"}
                                        content={
                                            mesure.dpt_first_argrement
                                                ? "OUI"
                                                : "NON"
                                        }
                                    />
                                </div>
                                <div className="row layout-xs-column">
                                    <ItemTwoLines
                                        title="N°RG"
                                        flex={"1"}
                                        content={
                                            mesure.n_rg
                                                ? mesure.n_rg
                                                : "Non renseigné."
                                        }
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="N° Portalis"
                                        flex={"1"}
                                        content={
                                            mesure.portalis_number
                                                ? mesure.portalis_number
                                                : "Non renseigné."
                                        }
                                    />
                                </div>
                                <div className="row layout-xs-column">
                                    <ItemTwoLines
                                        title="Date de jugement ou ordonnance de nomination"
                                        flex={"1"}
                                        content={
                                            mesure.date_initial_judgment
                                                ? moment(
                                                      mesure
                                                          .date_initial_judgment
                                                          .$date
                                                          ? parseInt(
                                                                mesure
                                                                    .date_initial_judgment
                                                                    .$date
                                                                    .$numberLong
                                                            )
                                                          : mesure.date_initial_judgment
                                                  ).format("L")
                                                : ""
                                        }
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Date de décision de la mesure en cours"
                                        flex={"1"}
                                        content={
                                            mesure.date_decision_mesure
                                                ? moment(
                                                      mesure
                                                          .date_decision_mesure
                                                          .$date
                                                          ? parseInt(
                                                                mesure
                                                                    .date_decision_mesure
                                                                    .$date
                                                                    .$numberLong
                                                            )
                                                          : mesure.date_decision_mesure
                                                  ).format("L")
                                                : ""
                                        }
                                    />
                                    {user.type === "_ASSOCIATION_" && (
                                        <div className="flex005 hide-xs" />
                                    )}
                                    {user.type === "_ASSOCIATION_" && (
                                        <ItemTwoLines
                                            title="Date de notification"
                                            flex={"1"}
                                            content={
                                                mesure.date_notification
                                                    ? moment(
                                                          mesure
                                                              .date_notification
                                                              .$date
                                                              ? parseInt(
                                                                    mesure
                                                                        .date_notification
                                                                        .$date
                                                                        .$numberLong
                                                                )
                                                              : mesure.date_notification
                                                      ).format("L")
                                                    : "Non renseignée"
                                            }
                                        />
                                    )}
                                </div>
                                <div className="row layout-xs-column">
                                    <ItemTwoLines
                                        title="Durée de la mesure"
                                        flex={"1"}
                                        nodivider
                                        dividerXs
                                        content={`${mesure.duration_mesure} mois`}
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Date de révision"
                                        flex={"1"}
                                        nodivider
                                        content={
                                            mesure.date_decision_mesure
                                                ? moment(
                                                      mesure
                                                          .date_decision_mesure
                                                          .$date
                                                          ? parseInt(
                                                                mesure
                                                                    .date_decision_mesure
                                                                    .$date
                                                                    .$numberLong
                                                            )
                                                          : mesure.date_decision_mesure
                                                  )
                                                      .add(
                                                          mesure.duration_mesure,
                                                          "months"
                                                      )
                                                      .format("L")
                                                : ""
                                        }
                                    />
                                </div>
                            </div>

                            <div
                                className="page_content"
                                style={{ paddingTop: 0 }}
                            >
                                <div
                                    className="my-sub-title-form layout-space-between-center row"
                                    style={{ marginTop: 0 }}
                                >
                                    La protection
                                </div>
                                <div className="column">
                                    {mesure.protections.length === 0 && (
                                        <div className="layout-center-center row">
                                            Vous n&#039;avez pas ajouté de
                                            protection
                                        </div>
                                    )}
                                    {mesure.protections.length !== 0 &&
                                        !normalOne && (
                                            <div className="layout-center-center row">
                                                Aucune des protections n'est en
                                                période " Normale "
                                            </div>
                                        )}
                                    {mesure.protections.length !== 0 &&
                                        normalOne &&
                                        _.sortBy(
                                            mesure.protections,
                                            "start_date"
                                        )
                                            .reverse()
                                            .map(
                                                (p, index) =>
                                                    p.period !== "Ouverture" &&
                                                    p.period !== "Fin" && (
                                                        <ItemProtectionMesure
                                                            key={moment(
                                                                p.start_date
                                                                    .$date
                                                                    ? parseInt(
                                                                          p
                                                                              .start_date
                                                                              .$date
                                                                              .$numberLong
                                                                      )
                                                                    : p.start_date
                                                            ).format()}
                                                            protection={p}
                                                            userType={user.type}
                                                            divider={
                                                                mesure
                                                                    .protections
                                                                    .length !==
                                                                index + 1
                                                            }
                                                        />
                                                    )
                                            )}
                                </div>
                            </div>

                            {mesure && mesure.notes && (
                                <div className="page_content">
                                    <div className="my-sub-title-form layout-space-between-center row">
                                        Observations
                                    </div>
                                    <div
                                        className="address_zone"
                                        style={{
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                        }}
                                    >
                                        {mesure.notes}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
export default MesureMajeur;
