import classNames from "classnames";
import React from "react";
import IInputText from "../../../interfaces/forms/IInputText";

/**
 * Create an input.
 *
 * @param props Input properties.
 */
const InputComponent: React.FC<IInputText> = (props) => {
    return (
        <div
            style={props.style}
            className={classNames(props.className, "my-input-container")}
        >
            {props.label && (
                <label htmlFor={props.id}>
                    {props.label} :
                    {props.required && <span className="red_asterix"> *</span>}
                </label>
            )}
            {props.search && (
                <i className="material-icons search-icon">search</i>
            )}
            <input
                id={props.id}
                ref={props.getRef ? (ref) => props.getRef(ref) : null}
                className={classNames("my-input", props.inputClassName)}
                type={props.type}
                placeholder={
                    props.placeHolder
                        ? props.placeHolder
                        : props.label
                            ? `${props.label}...`
                            : "..."
                }
                value={props.value}
                autoComplete="off"
                onChange={(e) => props.onChange(e)}
                //onKeyUp={(e) => props.onKeyUp(e)}
                onBlur={props.needBlur ? (e) => props.onBlur(e) : null}
                step="1"
                onFocus={props.onFocus}
                required={props.required}
                maxLength={props.maxLength}
                minLength={props.minLength}
                min={props.minLength}
                max={props.maxLength}
                {...props.inputOptions}
            />
        </div>
    );
};

export default InputComponent;
