import React, { useState } from "react";
import { Link } from "react-router-dom";
import { capitalizeFirst, returnOnlyNumbers } from "../../../constants/Utils";
import IFormPhone from "../../../interfaces/forms/IFormPhone";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";

const InnerPhoneForm: React.FC<IFormPhone> = (props) => {
    const [phone, setPhone] = useState<IFormPhone>(props);

    const typeOptions = [
        { value: null, label: null },
        { value: "Domicile", label: "Domicile" },
        { value: "Portable", label: "Portable" },
    ];

    const setValue = (field: string, value: string) => {
        const newPhone = { ...phone, [field]: value };
        setPhone(newPhone);
        props.onChange(newPhone);
    };

    return (
        <div className="column full-width">
            <div className="layout-start-center row full-width">
                {props.isDeletable && (
                    <div className="remove-button-container">
                        <div className="remove-button layout-center-center row pointer">
                            <Link
                                to={"#"}
                                onClick={() => props.removePhone(phone)}
                            >
                                <i className="material-icons">delete</i>
                            </Link>
                        </div>
                    </div>
                )}

                <div className="column full-width">
                    <Select
                        id={`phone-type-${props.id}`}
                        label="Type de téléphone"
                        className={"flex-1"}
                        needDefaultValue={false}
                        required
                        options={typeOptions}
                        value={phone.type || ""}
                        onChange={(e) => {
                            setValue("type", capitalizeFirst(e.target.value))
                        }}
                    />

                    <div className="row layout-xs-column">
                        <InputComponent
                            type="text"
                            id={`${props.id}-type`}
                            className="flex-1"
                            label="Dénomination"
                            value={phone.label || ""}
                            onChange={(e) =>
                                setValue(
                                    "label",
                                    capitalizeFirst(e.target.value)
                                )
                            }
                            maxLength={60}
                        />

                        <div className="flex005 hide-xs" />
                        <InputComponent
                            type="text"
                            id={`${props.id}-phone`}
                            className="flex-1"
                            label="Téléphone"
                            value={phone.phone || ""}
                            onChange={(e) =>
                                setValue(
                                    "phone",
                                    returnOnlyNumbers(e.target.value)
                                )
                            }
                            maxLength={10}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InnerPhoneForm;
