import React, {useState} from 'react';
import RegisterPaymentSourceModal from '../modals/RegisterPaymentSourceModal';

const ModalPayment: React.FC<{
    title: string, stripeReady: boolean, subscribeWithSepa: Function
    onCancel: Function, updateSepa: Function
}> = (props) => {

    const [tab, setTab] = useState<string>('sepa');
    const [disabledValidateButtonState, setDisabledValidateButtonState] = useState<boolean>(true);

    return (
        <>
            <div className="modal_container modal-back-space layout-center-center column">
                <div className="modal-container">
                    <h4>{props.title}</h4>

                    <RegisterPaymentSourceModal
                        onDisabled={(bool: boolean) => setDisabledValidateButtonState(bool)}
                        stripeReady={props.stripeReady}
                        updateSepa={(sepa: any) => props.updateSepa(sepa)}
                        tab={tab}
                    />

                    <div className="divider"/>
                    <div className="actions layout-center-center row">
                        { /* Cancel */}
                        <div onClick={() => {
                            props.onCancel();
                        }}>
                            Annuler
                        </div>
                        <span className="dividerV"/>
                        { /* Validate payment changing */}
                        <div
                            className={disabledValidateButtonState ? 'disabled' : ''}
                            onClick={() => {
                                if (disabledValidateButtonState === false) {
                                    props.subscribeWithSepa()
                                }
                            }}
                        >
                            Valider
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ModalPayment;
