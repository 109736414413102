import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { formatTelephone } from "../../../constants/Utils";
import IAddress from "../../../interfaces/IAddress";
import IContact from "../../../interfaces/IContact";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import Modal from "../../layout/modals/Modal";

const Contact: React.FC = () => {
    const [contact, setContact] = useState<IContact | null>(null);
    const [confirmDeletion, setConfirmDeletion] = useState<boolean>(false);

    const history = useHistory();

    const { contactId }: { contactId: string } = useParams();
    const { majeurId }: { majeurId: string } = useParams();

    const { addToast } = useToasts();

    useEffect(() => {
        if (!contact) {
            request({ addToast, url: `/api/contacts/${contactId}` })
                .then(({ data }) => {
                    setContact(data);
                })
                .catch(() =>
                    history.push(
                        majeurId ? "/single_majeur/" + majeurId : "/contacts"
                    )
                );
        }
    }, [contact]);

    /*
     * Delete a Contact.
     */
    const deleteContact = () => {
        setConfirmDeletion(false);
        request({
            addToast,
            url: `/api/contacts/${contactId}/delete/${majeurId}`,
            method: "post",
            data: {},
        }).then((data) => {
            addToast(`La contact a bien été supprimé`, {
                appearance: "success",
            });
            history.push(
                majeurId ? `/single_majeur/${majeurId}/contacts` : "/contacts"
            );
        }).catch();
    };

    return (
        <>
            <SubBar
                title={(contact && "Information du contact") || "Chargement..."}
                back={
                    !majeurId
                        ? "/contacts"
                        : `/single_majeur/${majeurId}/contacts`
                }
            >
                <Link
                    to={
                        "/contacts/" +
                        contactId +
                        "/modifier" +
                        (majeurId ? "/" + majeurId : "")
                    }
                >
                    <Icon name="edit" />
                </Link>
                <Link to="#" onClick={() => setConfirmDeletion(true)}>
                    <Icon name="delete_forever" />
                </Link>
            </SubBar>

            <Modal
                message="Attention : La suppression du contact est définitive. Êtes vous sûr(e) ?"
                show={confirmDeletion}
                onValidate={deleteContact}
            />
            <div className="single_contact">
                <div className="page_content">
                    {!contact && <Loader />}

                    {contact && (
                        <>
                            {contact.organisme && (
                                <div className="item_two_line">
                                    <h6>Organisme :</h6>
                                    <p className="notes_zone">
                                        {contact.organisme.toUpperCase()}
                                    </p>
                                </div>
                            )}

                            {(contact.first_name || contact.last_name) && (
                                <div
                                    className="row layout-xs-column"
                                    style={{ padding: 10 }}
                                >
                                    {contact.civility && `${contact.civility} `}

                                    {contact.first_name == "" &&
                                        `${contact.last_name.toUpperCase()}`}

                                    {contact.first_name != "" &&
                                        `${
                                            contact.first_name
                                                ? contact.first_name
                                                : ""
                                        } ${contact.last_name.toUpperCase()}`}
                                </div>
                            )}

                            {contact.profession && (
                                <div className="column flex-1">
                                    <div className="item_two_line">
                                        <h6>Profession :</h6>
                                        <p className="notes_zone">
                                            {contact.profession}
                                        </p>
                                    </div>
                                </div>
                            )}

                            <div className="informations_generales_container">
                                <div className="block_container">
                                    <h5>Adresses :</h5>
                                    <div className="layout-center-start column">
                                        {contact.addresses &&
                                            contact.addresses.map(
                                                (address: IAddress, index) => (
                                                    <div
                                                        key={index}
                                                        className="items"
                                                    >
                                                        <h6>
                                                            {address.label ||
                                                                ""}
                                                        </h6>
                                                        <p className="address_zone">
                                                            {address.address}
                                                        </p>
                                                        <p>
                                                            {address.zip_code}{" "}
                                                            {address.city}
                                                        </p>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                </div>
                            </div>

                            <div className="column flex-1">
                                <div className="block_container">
                                    <h5>Téléphones :</h5>
                                    <div className="layout-center-start column">
                                        {contact.phones &&
                                            contact.phones.map((tel, index) => (
                                                <div
                                                    key={index}
                                                    className="items"
                                                >
                                                    <h6>{tel.label || ""}</h6>
                                                    <p>
                                                        {formatTelephone(
                                                            tel.phone
                                                        )}
                                                    </p>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>

                            <div className="column flex-1">
                                <div className="block_container">
                                    <h5>Emails :</h5>
                                    <div className="layout-center-start column">
                                        {contact.emails &&
                                            contact.emails.map(
                                                (mail, index) => (
                                                    <div
                                                        key={index}
                                                        className="items"
                                                    >
                                                        <h6>
                                                            {mail.label || ""}
                                                        </h6>
                                                        <a
                                                            href={`mailto:${mail.email}`}
                                                        >
                                                            {mail.email}
                                                        </a>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                </div>
                            </div>

                            <div className="item_two_line">
                                <h6>Notes :</h6>
                                <div className="notes_zone">
                                    {contact.notes || "Non renseignées"}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Contact;
