import React, { useState } from "react";
import { capitalizeFirst, tagsColor } from "../../../constants/Utils";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";
import TagColor from "../../layout/TagColor";

const FormAddTags: React.FC<{ submit: Function }> = (props) => {
    const renderTagColor: any = (option: any) => (
        <TagColor color={option.value} label={option.label} />
    );

    const [selectedColor, setSelectedColor] = useState<string>(
        tagsColor[0].value
    );
    const [colorLabel, setColorLabel] = useState<string>(tagsColor[0].label);
    const [textInput, setTextInput] = useState<string>("");

    const submit = () => {
        if (textInput) {
            const tag = {
                label: capitalizeFirst(textInput.trim()),
                color: {
                    label: colorLabel,
                    value: selectedColor,
                },
            };
            props.submit(tag);
            setTextInput("");
        }
    };

    return (
        <>
            <form className="column form_add_categories">
                <InputComponent
                    id="tag"
                    className="my-input"
                    label="Nouveau tag "
                    required
                    value={textInput}
                    onChange={(e) => setTextInput(e.target.value)}
                    maxLength={50}
                />

                <Select
                    id="color"
                    value={selectedColor}
                    options={tagsColor}
                    label="Couleur "
                    required
                    onChange={(e) => {
                        setSelectedColor(e.target.value);
                        setColorLabel(e.target.selectedOptions[0].label);
                    }}
                    valueRenderer={renderTagColor}
                    optionRenderer={renderTagColor}
                />

                <button
                    type="button"
                    onClick={() => submit()}
                    className="valid-button"
                >
                    Ajouter
                </button>
            </form>
        </>
    );
};
export default FormAddTags;
