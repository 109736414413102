import React from "react";
import { Link } from "react-router-dom";
import Icon from "./Icon";

const SubBar: React.FC<{ back?: string; title?: string; classNameTitle?:string }> = (props) => {
    return (
      <div className={props.children &&
          'subBar_actions layout-space-between-center row' ||
          'arrow_back layout-start-center row'
      }>

        {props.back && <Link to={props.back}><Icon name="arrow_back" /></Link>}

        <div className={
            props.classNameTitle ? props.classNameTitle : 'subBar_actions_title layout-center-center row full-width'}
        >{props.title}</div>

        {props.children && <div className="layout-center-center row subBar_actions_item">{props.children}</div>}

      </div>
    );
};

export default SubBar;
