import React, { useState } from "react";

const AuthContext = React.createContext(null);

const AuthProvider: React.FC<{ user: any }> = (props) => {
    const [currentUser, setCurrentUser] = useState(props.user);

    return (
        <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
            {props.children}
        </AuthContext.Provider>
    );
};
export default AuthProvider;

export const useAuth = () => React.useContext(AuthContext);
