import Highcharts from "highcharts";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import { request } from "../../../utils/axios-utils";
import Loader from "../../layout/Loader";
import InputDatePicker from "../../layout/forms/InputDatePicker";

const PrevisionAccount: React.FC<{
    accountId: string;
    majeurId: string;
    majeur: IProtectedAdult;
}> = (props) => {
    const { addToast } = useToasts();

    const [startDate, setStartDate] = useState<Date>(
        localStorage.getItem("start_date")
            ? new Date(
                  moment(localStorage.getItem("start_date"), "DD/MM/YYYY")
                      .startOf("day")
                      .format()
              )
            : new Date(moment().startOf("month").startOf("day").format())
    );

    const [endDate, setEndDate] = useState<Date>(
        localStorage.getItem("end_date")
            ? new Date(
                  moment(localStorage.getItem("end_date"), "DD/MM/YYYY")
                      .endOf("day")
                      .format()
              )
            : new Date(moment().endOf("day").format())
    );

    const [budjetCamb, setBudjetCamb] = useState<any>(null);
    const [budjetCourbe, setBudjetCourbe] = useState<any>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [budgetMonthly, setBudgetMonthly] = useState<boolean>(false);

    // Creation du budjet
    const createBudjet = () => {
        request({
            addToast,
            url: "/api/operations/repetitions/createBudget",
            method: "post",
            data: {
                budget_monthly: budgetMonthly,
                account_id: props.accountId,
                start_date: startDate,
                end_date: endDate,
            },
        }).then(({ data }) => {
            document.location.href = data;
        }).catch();
    };

    // Draw statistics charts
    const drawCharts = (budjetC: any, budjetCurve: any) => {
        var colors = Highcharts.getOptions().colors,
            categories = budjetC.categories,
            data = budjetC.data,
            browserData = [],
            versionsData = [],
            i,
            j,
            dataLen = data.length,
            drillDataLen,
            brightness;

        // Build the data arrays for chart 1
        for (i = 0; i < dataLen; i += 1) {
            // add browser data
            browserData.push({
                name: categories[i],
                y: data[i].y,
                color: data[i].color,
            });
            // add version data
            drillDataLen = data[i].drilldown.data.length;
            for (j = 0; j < drillDataLen; j += 1) {
                brightness = 0.2 - j / drillDataLen / 5;
                versionsData.push({
                    name: data[i].drilldown.categories[j],
                    y: data[i].drilldown.data[j],
                    color: new Highcharts.Color(data[i].color)
                        .brighten(brightness)
                        .get(),
                });
            }
        }

        // Répartition des mouvements par catégories et sous catégories
        budjetC &&
            budjetC.categories.length > 0 &&
            budjetC.data.length > 0 &&
            browserData.length > 0 &&
            Highcharts.chart("container_graph_1", {
                chart: {
                    type: "pie",
                },
                title: {
                    text: "",
                    style: {
                        display: "none",
                    },
                },
                subtitle: {
                    text: "Par catégories et sous catégories.",
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: true,
                        },
                        shadow: true,
                        center: ["50%", "50%"],
                    },
                },
                tooltip: {
                    valueSuffix: " €",
                },
                series: [
                    {
                        type: "pie",
                        name: "Categorie",
                        data: browserData,
                        size: "60%",
                        dataLabels: {
                            formatter: () => null,
                            color: "#ffffff",
                            distance: -30,
                        },
                    },
                    {
                        type: "pie",
                        name: "Sous categorie",
                        data: versionsData,
                        size: "80%",
                        innerSize: "60%",
                        dataLabels: {
                            enabled: true,
                            formatter() {
                                // display only if larger than 1
                                return this.y > 1
                                    ? "<b>" +
                                          this.point.name +
                                          ":</b> " +
                                          (this.y || 0).toLocaleString() +
                                          " €"
                                    : null;
                            },
                        },
                    },
                ],
                credits: {
                    enabled: true,
                    text: "",
                },
            });

        // Evolution du solde
        budjetCurve &&
            budjetCurve.categorie.length > 0 &&
            budjetCurve.data.length > 0 &&
            Highcharts.chart("container_graph_2", {
                chart: {
                    type: "spline",
                },
                title: {
                    text: "",
                    style: {
                        display: "none",
                    },
                },
                xAxis: {
                    categories: budjetCurve.categorie,
                },
                yAxis: {
                    title: {
                        text: "Solde du compte",
                    },
                    plotLines: [
                        {
                            value: 0,
                            width: 1,
                            color: "#808080",
                        },
                    ],
                },
                tooltip: {
                    valueSuffix: " €",
                },
                legend: {
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                    borderWidth: 0,
                },
                series: [
                    {
                        type: "spline",
                        name: "Compte",
                        data: budjetCurve.data,
                    },
                ],
                credits: {
                    enabled: true,
                    text: "",
                },
            });
    };

    useEffect(() => {
        if (!budjetCamb) {
            setIsLoading(true);
            updateCharts();
        }
    }, [budjetCamb, budgetMonthly, startDate, endDate]);

    // Make request for charts data & draw them
    const updateCharts = () => {
        request({
            addToast,
            url: "/api/statistics/previsions",
            method: "post",
            data: {
                account_id: props.accountId,
                start_date: startDate,
                end_date: endDate,
            },
        }).then(({ data }) => {
            setIsLoading(false);
            setBudjetCamb(data.graph1);
            setBudjetCourbe(data.graph2);
            drawCharts(data.graph1, data.graph2);
        }).catch();
    };

    return (
        <>
            <div className="Previsions">
                <div className="subBar_actions sticky top-90 layout-space-between-center row no_print">
                    <span className="subBar_actions_title">
                        Les statistiques
                    </span>
                    <div className="layout-center-center row subBar_actions_item">
                        <span style={{ color: "white", marginRight: 5 }}>
                            Du :{" "}
                        </span>
                        <InputDatePicker
                            id="start_date"
                            className="my-input flex-1"
                            inputDate={startDate}
                            onChange={(date: Date) => {
                                if (date) {
                                    date.setHours(0, 0, 0, 0);
                                    setStartDate(date),
                                        localStorage.setItem(
                                            "start_date",
                                            date.toLocaleDateString("FR")
                                        );
                                    updateCharts();
                                }
                            }}
                        />
                        <div className="flex005 hide-xs" />
                        <span style={{ color: "white", marginRight: 5 }}>
                            Au :{" "}
                        </span>
                        <InputDatePicker
                            id="end_date"
                            className="my-input flex-1"
                            inputDate={endDate}
                            onChange={(date: Date) => {
                                if (date) {
                                    date.setHours(23, 59, 59, 999);
                                    setEndDate(date),
                                        localStorage.setItem(
                                            "end_date",
                                            date.toLocaleDateString("FR")
                                        );
                                    updateCharts();
                                }
                            }}
                        />
                    </div>
                </div>
                <div
                    className="page_content row layout-center-center no_print"
                    style={{ marginTop: 10 }}
                >
                    <div className="column">
                        <p className="period_budget">
                            Budget du {moment(startDate).format("DD/MM/YYYY")}{" "}
                            au {moment(endDate).format("DD/MM/YYYY")}
                        </p>
                        <div className="row layout-start-center">
                            <input
                                type="checkbox"
                                checked={budgetMonthly}
                                onChange={() =>
                                    setBudgetMonthly(!budgetMonthly)
                                }
                            />
                            <label className="checkBox" id="monthly">
                                Mensualiser sur la période (
                                {moment(endDate, "DD/MM/YYYY").diff(
                                    moment(startDate, "DD/MM/YYYY"),
                                    "months"
                                ) + 1}{" "}
                                mois)
                            </label>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="valid-button"
                        onClick={() => createBudjet()}
                    >
                        Budget
                    </button>
                </div>
                {budjetCamb && budjetCamb.categories.length > 0 && !isLoading && (
                    <div
                        style={{ visibility: "visible" }}
                        className="page_content column layout-start-center"
                    >
                        <div style={{ fontSize: "18px" }}>
                            Répartition des mouvements du{" "}
                            {moment(startDate).format("DD/MM/YYYY")} au{" "}
                            {moment(endDate).format("DD/MM/YYYY")}
                        </div>
                        <div
                            style={{ paddingTop: 0 }}
                            id="container_graph_1"
                            className="page_content chart_container layout-center-center column"
                        />
                        <div style={{ fontSize: "18px", marginTop: 15 }}>
                            Evolution du solde du{" "}
                            {moment(startDate).format("DD/MM/YYYY")} au{" "}
                            {moment(endDate).format("DD/MM/YYYY")}
                        </div>
                        <div
                            id="container_graph_2"
                            className="page_content chart_container layout-center-center column"
                        />
                    </div>
                )}
                {isLoading && <Loader />}
                {(!budjetCamb || budjetCamb.categories.length === 0) &&
                    !isLoading && (
                        <div className="row layout-center-center">
                            Pas de statistiques disponible pour la période.
                        </div>
                    )}
            </div>
        </>
    );
};
export default PrevisionAccount;
