import { trim } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import ILetters from "../../../interfaces/ILetters";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import SubBar from "../../layout/SubBar";
import FormButton from "../../layout/forms/FormButton";
import InputComponent from "../../layout/forms/InputComponent";
import InputSwitch from "../../layout/forms/InputSwitch";
import Select from "../../layout/forms/Select";

const CreateUpdateLetter: React.FC = () => {
    const [notificationEmail, setNotificationEmail] = useState<string>("");
    const [letterName, setLetterName] = useState<string>("");
    const [colorPrinting, setColorPrinting] = useState<boolean>(false);
    const [addressSheet, setAddressSheet] = useState<boolean>(false);
    const [printSenderAddress, setPrintSenderAddress] =
        useState<boolean>(false);
    const [duplexPrinting, setDuplexPrinting] = useState<boolean>(false);
    const [senderAddressLine1, setSenderAddressLine1] = useState<string>("");
    const [senderAddressLine2, setSenderAddressLine2] = useState<string>("");
    const [senderAddressLine3, setSenderAddressLine3] = useState<string>("");
    const [senderAddressLine4, setSenderAddressLine4] = useState<string>("");
    const [senderAddressLine5, setSenderAddressLine5] = useState<string>("");
    const [senderAddressLine6, setSenderAddressLine6] = useState<string>("");
    const [postageType, setPostageType] = useState<string>("ECONOMIC");
    const [letter, setLetter] = useState<ILetters>(null);
    const [user, setUser] = useState<IUser>(null);

    const { letterId }: { letterId: string } = useParams();

    const history = useHistory();

    const { addToast } = useToasts();

    useEffect(() => {
        if (!user) {
            request({ addToast, url: `/api/super_admin` })
                .then(({ data }) => {
                    setUser(data);
                    setNotificationEmail(data.emails[0].address);
                    setSenderAddressLine2(
                        data.profile.infos.civility +
                            " " +
                            data.profile.infos.first_name +
                            " " +
                            data.profile.infos.last_name
                    );
                    setSenderAddressLine4(data.profile.infos.address);
                    setSenderAddressLine6(
                        trim(data.profile.infos.zip_code) +
                            " " +
                            data.profile.infos.city
                    );
                })
                .catch();
        }

        if (letterId && !letter) {
            request({ addToast, url: `/api/maileva/letter/${letterId}` })
                .then(({ data }) => {
                    setLetterName(data.name);
                    setPostageType(data.postage_type);
                    setColorPrinting(data.color_printing);
                    setDuplexPrinting(data.duplex_printing);
                    setAddressSheet(data.optional_address_sheet);
                    setNotificationEmail(data.notification_email);
                    setPrintSenderAddress(data.print_sender_address);
                    setSenderAddressLine1(data.sender_address_line_1);
                    setSenderAddressLine2(data.sender_address_line_2);
                    setSenderAddressLine3(data.sender_address_line_3);
                    setSenderAddressLine4(data.sender_address_line_4);
                    setSenderAddressLine5(data.sender_address_line_5);
                    setSenderAddressLine6(data.sender_address_line_6);
                    setLetter(data);
                })
                .catch();
        }
    }, [letter]);

    const saveLetter = () => {
        if (
            !(senderAddressLine1 || senderAddressLine2) ||
            !senderAddressLine6 ||
            !notificationEmail
        ) {
            addToast("Veuillez remplir les champs obligatoires", {
                appearance: "error",
            });
            return;
        }

        if (senderAddressLine6 && !/^\d{5} .+$/.test(senderAddressLine6)) {
            addToast(
                "Code postal et ville doit être un ensemble de 5 numéros, un espace, une chaine de caractères (75000 Paris)",
                { appearance: "error" }
            );
            return;
        }

        if (!letterName) {
            addToast("Veuillez remplir le nom du courrier", {
                appearance: "error",
            });
            return;
        }

        let url = `/api/maileva/create-letter`;
        if (letterId) {
            url = `/api/maileva/update-letter/${letterId}`;
        }
        const _id = window.crypto.randomUUID();

        request({
            url: url,
            method: "POST",
            data: {
                letter: {
                    name: letterName,
                    custom_id: _id,
                    custom_data: _id,
                    color_printing: colorPrinting,
                    duplex_printing: duplexPrinting,
                    optional_address_sheet: addressSheet,
                    notification_email: notificationEmail,
                    print_sender_address: printSenderAddress,
                    sender_address_line_1: senderAddressLine1,
                    sender_address_line_2: senderAddressLine2,
                    sender_address_line_3: senderAddressLine3,
                    sender_address_line_4: senderAddressLine4,
                    sender_address_line_5: senderAddressLine5,
                    sender_address_line_6: senderAddressLine6,
                    sender_country_code: "FR",
                    envelope_windows_type: "SIMPLE",
                    postage_type: postageType,
                    treat_undelivered_mail: false,
                    notification_treat_undelivered_mail: [],
                },
                majeur_id: null,
            },
        })
            .then((data) => {
                history.push(
                    "/lettres/" + (letterId ?? data.data.id) + "/detail"
                );
            })
            .catch(() =>
                addToast("Une erreur est survenue", { appearance: "error" })
            );
    };

    return (
        <>
            <SubBar title={"Envoyer une lettre"} back="/lettres" />

            <div className="page_content">
                <form className="column">
                    <div className="row">
                        <div className="column flex-1">
                            <InputComponent
                                required={true}
                                id={"letter_name"}
                                type="text"
                                className="column-item-flex-1"
                                label="Nom du courrier"
                                value={letterName}
                                onChange={(e) => setLetterName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row layout-xs-column">
                        <div className="column flex-1">
                            <div className="item_two_line">
                                <Select
                                    value={postageType}
                                    label="Type d'envoi"
                                    onChange={(e) =>
                                        setPostageType(e.target.value)
                                    }
                                    required
                                    options={[
                                        {
                                            value: "ECONOMIC",
                                            label: "Economique",
                                        },
                                        { value: "FAST", label: "Rapide" },
                                        { value: "URGENT", label: "Urgent" },
                                    ]}
                                />
                            </div>
                            <div className="divider"></div>
                        </div>
                    </div>
                    <div className="row layout-xs-column">
                        <div className="column flex-1">
                            <div className="item_two_line">
                                <InputSwitch
                                    id={"color_printing"}
                                    className="column-item-flex-1"
                                    label="Impression couleur"
                                    value={colorPrinting}
                                    onChange={() =>
                                        setColorPrinting(!colorPrinting)
                                    }
                                />
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column flex-1">
                            <div className="item_two_line">
                                <InputSwitch
                                    id={"duplex_printing"}
                                    className="column-item-flex-1"
                                    label="Impression Recto/verso"
                                    value={duplexPrinting}
                                    onChange={() =>
                                        setDuplexPrinting(!duplexPrinting)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex005 hide-xs" />
                    {((letterId && letterName) || !letterId) && (
                        <FormButton
                            onClick={saveLetter}
                            style={{ marginTop: 20 }}
                            text={"Suivant"}
                        />
                    )}
                </form>
            </div>
        </>
    );
};

export default CreateUpdateLetter;
