import React, { useState } from "react";
import { Link } from "react-router-dom";
import { capitalizeFirst } from "../../../constants/Utils";
import IFormEmail from "../../../interfaces/forms/IFormEmail";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";

const InnerEmailForm: React.FC<IFormEmail> = (props) => {
    const [email, setEmail] = useState<IFormEmail>(props);

    const typeOptions = [
        { value: null, label: null },
        { value: "Principal", label: "Principal" },
        { value: "Pro", label: "Pro" },
        { value: "Contact", label: "Contact" },
    ];

    const setValue = (field: string, value: string) => {
        const newEmail = { ...email, [field]: value };
        setEmail(newEmail);
        props.onChange(newEmail);
    };

    return (
        <div className="column full-width">
            <div className="layout-start-center row full-width">
                {props.isDeletable && (
                    <div className="remove-button-container">
                        <div className="remove-button layout-center-center row pointer">
                            <Link
                                to={"#"}
                                onClick={() => props.removeEmail(email)}
                            >
                                <i className="material-icons">delete</i>
                            </Link>
                        </div>
                    </div>
                )}

                <div className="column full-width">
                    <Select
                        id={`email-type-${props.id}`}
                        label="Type d'email'"
                        className={"flex-1"}
                        needDefaultValue={false}
                        required
                        options={typeOptions}
                        value={email.type || ""}
                        onChange={(e) => {
                            setValue("type", capitalizeFirst(e.target.value))
                        }}
                    />

                    <div className="row layout-xs-column">
                        <InputComponent
                            type="text"
                            id={`${props.id}-type`}
                            className="flex-1"
                            label="Dénomination"
                            value={email.label || ""}
                            onChange={(e) =>
                                setValue(
                                    "label",
                                    capitalizeFirst(e.target.value)
                                )
                            }
                            maxLength={60}
                        />

                        <div className="flex005 hide-xs" />

                        <InputComponent
                            type="text"
                            id={`${props.id}-type`}
                            className="flex-1"
                            label="Email"
                            required
                            value={email.email || ""}
                            onChange={(e) => setValue("email", e.target.value)}
                            maxLength={60}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InnerEmailForm;
