import React, { ChangeEvent } from "react";
import IFile from "./Cloud/IFile";

const UploadDocument: React.FC<{
    filesSelected: any;
    fileList: Array<IFile>;
    setFilesSelected: Function;
    files: Array<string>;
    setFiles: Function;
    isMultiple?: boolean;
    setFileList: Function;
}> = (props) => {
    const filesSelected = props.filesSelected;
    const fileList = props.fileList;

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles =
            event.target.files && event.target.files.length > 0
                ? Array.from(event.target.files)
                : null;

        if (selectedFiles) {
            props.setFilesSelected(selectedFiles);
        }
    };

    const handleFileRemove = (file: IFile) => {
        props.setFiles(
            props.files.filter((f: string) => {
                if (f !== file._id) {
                    return f;
                }
            })
        );

        props.setFileList(
            fileList.filter((f: IFile) => {
                if (f._id !== file._id) {
                    return f;
                }
            })
        );
    };

    const handleFileSelectedRemove = (file: any) => {
        props.setFilesSelected(
            filesSelected.filter((f: any) => {
                if (f.name !== file.name) {
                    return f;
                }
            })
        );
    };

    return (
        <div>
            <div className="row layout-start-center">
                <label htmlFor="file-upload" className="custom-file-upload">
                    Ajouter un document
                </label>
            </div>

            {props.isMultiple && (
                <input
                    id="file-upload"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    accept=".pdf"
                    style={{ display: "none" }}
                />
            )}

            {!props.isMultiple && (
                <input
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                    accept=".pdf"
                    style={{ display: "none" }}
                />
            )}
            {filesSelected && "Fichiers selectionnés :"}
            <ul style={{ width: "100%", cursor: "pointer" }}>
                {filesSelected &&
                    Array.from(filesSelected).map((file: any) => (
                        <li key={file.name} className="file-item">
                            <span>{file.name}</span>
                            <i
                                onClick={() => {
                                    handleFileSelectedRemove(file);
                                }}
                                className="material-icons"
                            >
                                delete_forever
                            </i>
                        </li>
                    ))}
            </ul>

            {fileList && "Fichiers associés:"}
            <ul style={{ width: "100%", cursor: "pointer" }}>
                {fileList &&
                    fileList.map((file: IFile) => (
                        <li key={file._id} className="file-item">
                            <span>{file.name}</span>
                            <i
                                onClick={() => {
                                    handleFileRemove(file);
                                }}
                                className="material-icons"
                            >
                                delete_forever
                            </i>
                        </li>
                    ))}
                {fileList && fileList.length === 0 && !filesSelected && (
                    <li>Aucun document</li>
                )}
            </ul>
        </div>
    );
};
export default UploadDocument;
