import { Link } from "react-router-dom";
import React from "react";

export const RedirectLink = (props) => {
    const { to, name } = props;
    return (
        <Link to={to} className="inline-block align-baseline font-bold text-xs">
            {name}
        </Link>
    );
};
