import React from 'react';
import FormSepa from "../../layout/forms/FormSepa";

const RegisterPaymentSourceModal: React.FC<{ stripeReady: boolean, onDisabled: Function, updateSepa: Function, tab: string }> = (props) => {

    return (
        <>
            <div>
                <div className="row layout-space-around-center">
                    <div>
                        <p style={{textAlign: 'center', width: '100%'}}>
                            Veuillez entrer vos informations de paiement :
                        </p>
                        <div className="tab-content">
                            <div>
                                <FormSepa
                                    selected={true}
                                    onDisabled={(bool: boolean) => props.onDisabled(bool)}
                                    updateSepa={(sepa: any) => props.updateSepa(sepa)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RegisterPaymentSourceModal;
