import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { request } from "../../../../utils/axios-utils";
import ButtonConfirmModalIcon from "../../../layout/modals/ButtonConfirmModalIcon";
import IFile from "./IFile";

const FindTypeOfFile = (ext: string) => {
    if (
        ext === "pdf" ||
        ext === "PDF" ||
        ext === "doc" ||
        ext === "docx" ||
        ext === "ppt" ||
        ext === "zip" ||
        ext === "xls" ||
        ext === "xlsx" ||
        ext === "ppt" ||
        ext === "pptx"
    ) {
        return ext;
    } else if (
        ext === "jpeg" ||
        ext === "JPG" ||
        ext === "JPEG" ||
        ext === "PNG" ||
        ext === "jpg" ||
        ext === "png" ||
        ext === "gif" ||
        ext === "svg"
    ) {
        return "images";
    } else if (ext === "avi" || ext === "mp4") {
        return "video";
    } else if (ext === "csv" || ext === "CSV") {
        return "xlsx";
    }
    return "inconnu";
};
const File: React.FC<{
    rename?: Function;
    OpenToClass?: Function;
    file: IFile;
    toClass?: boolean;
    remove?: Function;
    getFilesAndFolders: Function;
    download?: Function;
    view: string;
    ondragstart: Function;
    fileRemove?: boolean;
}> = (props) => {
    const fileExtension = FindTypeOfFile(props.file.ext);
    const toClass = props.toClass;
    const [name, setName] = useState<any>();
    const [isRenaming, setIsRenaming] = useState<boolean>(false);
    console.log(toClass);
    const removeFile = () => {
        request({
            addToast,
            url: `/api/files/remove/${props.file._id}`,
            method: "post",
            data: {},
        })
            .then(({ data }) => {
                addToast(`La document a bien été supprimé.`, {
                    appearance: "success",
                });
                props.getFilesAndFolders();
            })
            .catch();
    };

    const downloadFileFromSignedUrl = () => {
        fetch(`/api/files/download/${props.file._id}`, {
            method: "GET",
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob: any) => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    props.file.name + "." + props.file.ext
                );
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((data) => {
                const { message } = data.response.data;
                addToast(message, { appearance: "error" });
            });
    };

    const renameFile = (newName: string) => {
        request({
            addToast,
            url: `/api/files/rename`,
            method: "post",
            data: {
                file_id: props.file._id,
                file_name: newName,
            },
        })
            .then(({ data }) => {
                addToast(`La document a bien été renommé.`, {
                    appearance: "success",
                });
                props.getFilesAndFolders();
            })
            .catch();
    };

    const { addToast } = useToasts();
    let inputName = <></>;
    if (!isRenaming) {
        inputName = <p className="name">{props.file.name}</p>;
    } else {
        inputName = (
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    renameFile(name);
                    setIsRenaming(false);
                }}
            >
                <input
                    autoFocus
                    type="text"
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    onBlur={() => setIsRenaming(false)}
                    defaultValue={props.file.name}
                />
            </form>
        );
    }

    return (
        <div>
            <div
                className="Files LINEAR layout-center-space-between"
                draggable
                onDragStart={(event) => {
                    props.ondragstart(event, props.file._id);
                }}
            >
                <div className="row layout-start-center">
                    <img
                        src={`/imgs/icons/icons_cloud/${fileExtension}.svg`}
                        alt="extension"
                    />
                    {inputName}
                </div>
                <div className="actions layout-center-center row">
                    <i
                        onClick={() => {
                            setIsRenaming(true);
                        }}
                        className="material-icons icon-edit-name"
                    >
                        edit
                    </i>
                    {toClass && (
                        <i
                            onClick={props.OpenToClass}
                            className="material-icons icon-donwload-file"
                        >
                            link
                        </i>
                    )}
                    <i
                        onClick={downloadFileFromSignedUrl}
                        className="material-icons icon-donwload-file"
                    >
                        cloud_download
                    </i>

                    <ButtonConfirmModalIcon
                        className="button-delete"
                        onValidate={removeFile}
                        icon="delete_forever"
                        question="Voulez vous supprimer ce fichier ?"
                        id={"delete_forever"}
                    />
                </div>
            </div>
        </div>
    );
};
export default File;
