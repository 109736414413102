import React, { useState } from "react";
import ILinkedLabelWithCategory from "../../../interfaces/ILinkedLabelWithCategory";
import InputComponent from "./InputComponent";

const ListLinkLabelsWithCategories: React.FC<{
    remove?: Function;
    list: Array<any>;
    withAmount?: boolean;
    editLinkedLabelWithCategory?: Function;
    updateLinkedLabelWithCategory?: Function;
    linkedLabelWithCategoryInEdit?: ILinkedLabelWithCategory;
    cancel?: Function;
}> = (props) => {
    const [newLinkedLabelWithCategoryValue, setLinkedLabelWithCategoryValue] =
        useState<string>(null);
    const [newAmountValue, setNewAmountValue] = useState<number>(null);

    return (
        <>
            <div
                className="sub_caterogires_container"
                style={{ minHeight: 263 }}
            >
                {props.list.map((l: any, index: number) => {
                    return (
                        !props.linkedLabelWithCategoryInEdit &&
                        l !== props.linkedLabelWithCategoryInEdit && (
                            <div
                                key={index}
                                className={`item_sub_category layout-space-between-center row ${
                                    !(index % 2) ? "even" : ""
                                }`}
                            >
                                <span>
                                    {(props.withAmount &&
                                        `${l.label} ${(
                                            l.amount || 0
                                        ).toLocaleString()} €`) ||
                                        l.label}
                                </span>
                                <div>
                                    <i
                                        onClick={() => {
                                            props.editLinkedLabelWithCategory(
                                                l
                                            );
                                            setLinkedLabelWithCategoryValue(
                                                l.label
                                            );
                                            setNewAmountValue(l.amount);
                                        }}
                                        className="material-icons button-icon"
                                    >
                                        edit
                                    </i>
                                    <i
                                        onClick={() => {
                                            props.remove(
                                                (props.withAmount && l) || l
                                            );
                                        }}
                                        className="material-icons button-icon"
                                    >
                                        delete
                                    </i>
                                </div>
                            </div>
                        )
                    );
                })}
                {props.linkedLabelWithCategoryInEdit && (
                    <div className="editSubCategoryContainer">
                        <i
                            onClick={() => props.cancel()}
                            className="material-icons button-icon"
                        >
                            undo
                        </i>
                        <span className="editSubcategoryLabel">
                            Modification de la sous catégorie :{" "}
                            {props.linkedLabelWithCategoryInEdit.label}
                        </span>
                        <div
                            className="editSubcategory"
                            onKeyDown={(
                                event: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                                if (event.key === "Enter") {
                                    props.updateLinkedLabelWithCategory(
                                        newLinkedLabelWithCategoryValue,
                                        newAmountValue,
                                        (props.withAmount &&
                                            props.linkedLabelWithCategoryInEdit) ||
                                            props.linkedLabelWithCategoryInEdit
                                                ._id
                                    );
                                    setLinkedLabelWithCategoryValue(null);
                                }
                            }}
                        >
                            <div>
                                <InputComponent
                                    id="idSubCatEditing"
                                    className="my-input"
                                    required
                                    value={newLinkedLabelWithCategoryValue}
                                    onChange={(e) =>
                                        setLinkedLabelWithCategoryValue(
                                            e.target.value
                                        )
                                    }
                                    minLength={3}
                                    maxLength={50}
                                />

                                {props.withAmount && (
                                    <InputComponent
                                        id="idSubCatAmountEditing"
                                        className="my-input"
                                        required
                                        value={newAmountValue}
                                        onChange={(e) =>
                                            setNewAmountValue(e.target.value)
                                        }
                                    />
                                )}
                                <span className="subcategoryEditButton">
                                    <button
                                        type="button"
                                        className="valid-button"
                                        onClick={() => {
                                            props.updateLinkedLabelWithCategory(
                                                newLinkedLabelWithCategoryValue,
                                                newAmountValue,
                                                (props.withAmount &&
                                                    props.linkedLabelWithCategoryInEdit) ||
                                                    props
                                                        .linkedLabelWithCategoryInEdit
                                                        ._id
                                            );
                                            setLinkedLabelWithCategoryValue(
                                                null
                                            );
                                        }}
                                    >
                                        Modifier
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default ListLinkLabelsWithCategories;
