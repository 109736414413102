import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { createFile } from "../../../../_api/Cloud";
import { uploadDoc } from "../../../../_api/S3";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import { request } from "../../../../utils/axios-utils";
import DropZoneArea from "./DropzoneArea";
import File from "./File";
import Folder from "./Folder";

const ListClouds: React.FC<{ majeurId: string; majeur: IProtectedAdult }> = (
    props
) => {
    const [S3FolderUser, setS3FolderUser] = useState<string>(null);
    const [folders, setFolders] = useState<Array<any>>(null);
    const [files, setFiles] = useState<Array<any>>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [view, setView] = useState<string>("GRID");
    const [queryFiles, setQueryFiles] = useState<string>("");
    const [parentFolderId, setParentFolderId] = useState<string>("0");
    const [historyFolders, setHistoryFolders] = useState<Array<object>>([
        {
            name: "Documents",
            _id: "0",
        },
    ]);

    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadedPercent, setUploadedPercent] = useState<number>(0);

    const [folderRemove, setFolderRemove] = useState<boolean>(false);

    const { addToast } = useToasts();

    const removeFolder = () => {
        setFolderRemove(!folderRemove);
    };

    const addNewFolder = () => {
        request({
            addToast,
            url: "/api/folders/create",
            method: "post",
            data: {
                majeur_id: props.majeurId,
                parent_folder_id: parentFolderId,
            },
        })
            .then(({ data }) => {
                getFilesAndFolders(parentFolderId, queryFiles);
            })
            .catch();
    };

    const createFileOnS3 = async (objectFile: any, fileS3: any) => {
        setUploading(true);
        const fileName =
            S3FolderUser +
            "/" +
            (Math.random() + 1).toString(36).substring(7) +
            "_" +
            objectFile.name.replace(/[^a-zA-Z0-9.]/gi, "_");

        const formData = new FormData();
        formData.append("file", objectFile);
        formData.append("key", fileName);

        await uploadDoc(formData)
            .then((response) => {
                createFile(addToast, {
                    key: fileName,
                    folder_id: parentFolderId,
                    majeur_id: props.majeurId,
                    file: fileS3,
                })
                    .then(({ data }) => {
                        getFilesAndFolders(parentFolderId, queryFiles);
                        setUploading(false);
                        addToast(`Le téléchargement du fichier a réussi.`, {
                            appearance: "success",
                        });
                    })
                    .catch();
            })
            .catch((resp) => {
                console.log(resp);
                addToast(`Le téléchargement du fichier a échoué.`, {
                    appearance: "error",
                });
            });
    };

    const goFolder = (folderId: string) => {
        setParentFolderId(folderId);
        getFilesAndFolders(folderId, queryFiles);
        const folder: any = Object.keys(folders).find((key: any) => {
            return folders[key]._id === folderId;
        });

        const ids = historyFolders.map((folder: any) => {
            return folder._id;
        });

        /* Check if folders not already in  */
        if (!ids.includes(folderId)) {
            historyFolders.push({
                _id: folders[folder]._id,
                name: folders[folder].name,
            });
            setHistoryFolders(historyFolders);
        }

        getFilesAndFolders(folderId, queryFiles);
    };

    const onDropFolder = (event: any, folderId: string) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        // type & id ne retournent pas les bonnes valeurs
        const type = event.dataTransfer.getData("text").split(",")[0];
        const id = event.dataTransfer.getData("text").split(",")[1];
        if (type === "FILE") {
            moveFileToFolder(id, folderId);
        } else if (type === "FOLDER") {
            if (id !== folderId) {
                moveFolderToFolder(id, folderId);
            }
        }
    };

    const moveFileToFolder = (fileId: string, folderId: string) => {
        request({
            addToast,
            url: "/api/files/move",
            method: "post",
            data: {
                file_id: fileId,
                folder_id: folderId,
            },
        })
            .then(({ data }) => {
                addToast(`Le fichier a bien été déplacé.`, {
                    appearance: "success",
                });
                getFilesAndFolders(parentFolderId, queryFiles);
            })
            .catch();
    };

    const moveFolderToFolder = (folderIdFrom: string, folderIdTo: string) => {
        request({
            addToast,
            url: "/api/folders/move",
            method: "post",
            data: {
                folder_id_to_move: folderIdFrom,
                folder_id_destination: folderIdTo,
            },
        })
            .then(({ data }) => {
                addToast(`Le dossier a bien été déplacé.`, {
                    appearance: "success",
                });
                getFilesAndFolders(parentFolderId, queryFiles);
            })
            .catch();
    };

    const goFolderBreadcrums = (folder: any) => {
        setParentFolderId(folder._id);
        const index = historyFolders.map((x: any) => x._id).indexOf(folder._id);
        historyFolders.splice(index + 1);
        setHistoryFolders(historyFolders);
        getFilesAndFolders(folder._id, queryFiles);
    };

    useEffect(() => {
        if (!folders) {
            /* GET folders & files */
            getFilesAndFolders(parentFolderId, queryFiles);
        }
        if (!S3FolderUser) {
            request({ addToast, url: `/api/user/settings` })
                .then(({ data }) => {
                    setS3FolderUser(data.S3_folder_user);
                })
                .catch();
        }
    }, []);

    const getFilesAndFolders = (parentFolderId: string, queryFiles: string) => {
        request({
            addToast,
            url: "/api/majeurs/folders",
            method: "post",
            data: {
                majeur_id: props.majeurId,
                parent_folder_id: parentFolderId,
                query_files: queryFiles,
            },
        })
            .then(({ data }) => {
                setFolders(data.folders);
                setFiles(data.files);
            })
            .catch();
    };

    let i = -1;
    const breadCrums = historyFolders.map((folder: any, index) => {
        i += 1;
        let tiret = "";
        if (i !== 0) {
            tiret = " > ";
        }
        if (index !== historyFolders.length - 1) {
            return (
                <span key={folder._id}>
                    {tiret}{" "}
                    <span
                        className="breadcrumsItemAtive"
                        onClick={() => goFolderBreadcrums(folder)}
                        onDrop={(event) => onDropFolder(event, folder._id)}
                        onDragOver={(event) => event.preventDefault()}
                    >
                        {folder.name}
                    </span>
                </span>
            );
        }
        return (
            <span key={folder._id}>
                {tiret} {folder.name}
            </span>
        );
    }, this);

    const onDropZone = (acceptedFiles: any) => {
        acceptedFiles.map((file: any) => {
            const fileObject = {
                type: file.type,
                size: file.size,
                name: file.name,
                mimetype: file.type,
            };

            createFileOnS3(file, fileObject);
            return file;
        });
    };

    let uploadInfo = <></>;
    if (uploading) {
        uploadInfo = <span className="uploadInfo">{uploadedPercent}%</span>;
    }

    return (
        <div className="cloud">
            <div className="subBar_actions sticky top-90 layout-space-between-center row">
                <div className="my-input-search-container column">
                    <i className="material-icons search-icon">search</i>
                    <input
                        type="text"
                        onChange={(e) => {
                            setQueryFiles(e.target.value),
                                getFilesAndFolders(
                                    parentFolderId,
                                    e.target.value
                                );
                        }}
                        className="my-input-search"
                        placeholder="Recherche..."
                    />
                </div>
                <div className="layout-center-center row subBar_actions_item">
                    <div className="action-button">
                        <i
                            className="material-icons pointer actions"
                            onClick={removeFolder}
                        >
                            {folderRemove ? "delete_forever" : "delete"}
                        </i>
                    </div>
                    {!props.majeur.archived && (
                        <div className="row">
                            <div className="action-button">
                                <i
                                    onClick={addNewFolder}
                                    className="material-icons pointer actions"
                                >
                                    create_new_folder
                                </i>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {!queryFiles && (
                <div>
                    <div className="breadcrums">{breadCrums}</div>
                    <div className="divider" />
                </div>
            )}
            {!props.majeur.archived && (
                <div
                    className="sticky top-130"
                    style={{
                        zIndex: 1,
                        backgroundColor: "#FDFDFD",
                        padding: "10px",
                    }}
                >
                    <DropZoneArea onDrop={onDropZone} />
                </div>
            )}
            <div className="page_content">
                {!queryFiles && folders && (
                    <div
                        className={
                            view === "GRID"
                                ? "folder-container layout-xs-start-center layout-start-start row wrap"
                                : ""
                        }
                    >
                        {Object.keys(folders).map((keyName, i) => (
                            <Folder
                                key={folders[i]._id}
                                view={view}
                                folder={folders[i]}
                                goFolder={(folderId: string) =>
                                    goFolder(folderId)
                                }
                                getFilesAndFolders={() =>
                                    getFilesAndFolders(
                                        parentFolderId,
                                        queryFiles
                                    )
                                }
                                folderRemove={folderRemove}
                                ondragstart={(e: any, folderId: string) => {
                                    e.dataTransfer.setData(
                                        "text/plain",
                                        `FOLDER,${folderId}`
                                    );
                                    e.dataTransfer.dropEffect = "move";
                                }}
                                ondrop={(event: any, folderId: string) =>
                                    onDropFolder(event, folderId)
                                }
                                ondragover={(e: any) => {
                                    if (e && e.preventDefault) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        ))}
                    </div>
                )}
                <div className="column filesContainer">
                    {files &&
                        Object.keys(files).map((keyName, i) => {
                            return (
                                <File
                                    key={files[i]._id}
                                    view={view}
                                    file={files[i]}
                                    fileRemove={folderRemove}
                                    getFilesAndFolders={() =>
                                        getFilesAndFolders(
                                            parentFolderId,
                                            queryFiles
                                        )
                                    }
                                    ondragstart={(e: any, folderId: string) => {
                                        e.dataTransfer.setData(
                                            "text/plain",
                                            `FILE,${folderId}`
                                        );
                                        e.dataTransfer.dropEffect = "move";
                                    }}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};
export default ListClouds;
