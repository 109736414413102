import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import {
    formatnSecu,
    formatTelephone,
    handleYesNo, momentUpdateLocale,
} from "../../../constants/Utils";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import ItemTwoLines from "../../layout/ItemTwoLines";
import Loader from "../../layout/Loader";

const InfosMajeur: React.FC<{ majeur: IProtectedAdult }> = (props) => {
    momentUpdateLocale()

    return (
        <>
            {!props.majeur && <Loader />}
            {props.majeur && (
                <div className="infos_client infos_majeur">
                    <div className="subBar_actions sticky top-90 layout-space-between-center row">
                        <span className="subBar_actions_title">
                            Les infos de{" "}
                            {`${props.majeur.civility} ${
                                props.majeur.first_name
                            }
                  ${
                      props.majeur.familly_name &&
                      props.majeur.last_name !== props.majeur.familly_name
                          ? `${props.majeur.last_name} ${props.majeur.familly_name}`
                          : props.majeur.last_name
                  }`}
                        </span>
                        <div className="layout-center-center row subBar_actions_item">
                            {!props.majeur.archived && (
                                <Link
                                    to={`/update_majeur/${props.majeur._id}`}
                                    className="column"
                                >
                                    <i className="material-icons">edit</i>
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="page_content">
                        {props.majeur.addresses &&
                            props.majeur.addresses.length === 0 && (
                                <div className="page_content">
                                    Coordonnées non renseignées.
                                </div>
                            )}
                        {props.majeur.addresses &&
                            props.majeur.addresses.length > 0 && (
                                <div className="block_container">
                                    <h5>Adresses :</h5>
                                    <div className="layout-center-start column">
                                        {props.majeur.addresses.map(
                                            (a: any, index: number) => (
                                                <div
                                                    key={index}
                                                    className="items"
                                                >
                                                    <h6>{a.label}.</h6>
                                                    <p className="address_zone">
                                                        {a.address}
                                                    </p>
                                                    <p>
                                                        {a.zip_code} {a.city}
                                                    </p>
                                                    <p>
                                                        {a.date ? "Date d'entrée: " + moment(a.date).format("DD/MM/YYYY") : null}
                                                    </p>
                                                    <p>
                                                        {a.type ? "Type d'adresse: " + a.type : null}
                                                    </p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                        {props.majeur.phones && props.majeur.phones.length > 0 && (
                            <div className="block_container">
                                <h5>Téléphones :</h5>
                                <div className="layout-center-start column">
                                    {props.majeur.phones.map(
                                        (p: any, index: number) => (
                                            <div key={index} className="items">
                                                <h6>{p.label}.</h6>
                                                <p>
                                                    {formatTelephone(p.phone)}
                                                </p>
                                                <p>Type de téléphone: {p.type}</p>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                        {props.majeur.emails && props.majeur.emails.length > 0 && (
                            <div className="block_container">
                                <h5>Emails :</h5>
                                <div className="layout-center-start column">
                                    {props.majeur.emails.map(
                                        (e: any, index: number) => (
                                            <div key={index} className="items">
                                                <h6>{e.label}.</h6>
                                                <a href={`mailto:${e.email}`}>{e.email}</a>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="my-sub-title-form layout-space-between-center row">
                            Informations complementaires
                        </div>
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="N° Sécurité sociale"
                                content={
                                    props.majeur.n_secu
                                        ? formatnSecu(props.majeur.n_secu)
                                        : "Non renseigné."
                                }
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="N° Confidentiel si MSA"
                                content={
                                    props.majeur.n_confidentiel ||
                                    "Non renseigné."
                                }
                                flex="1"
                            />
                        </div>

                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="N° Fiscal"
                                content={ props.majeur.fiscal_number || "Non renseigné." }
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Nationalité"
                                content={
                                    props.majeur.nationality || "Non renseigné."
                                }
                                flex="1"
                            />
                        </div>

                        <div className="row layout-xs-column">
                            <div className="row flex-1">
                                <ItemTwoLines
                                    title="Date de naissance"
                                    content={
                                        props.majeur.birth_date
                                            ? moment(
                                                  props.majeur.birth_date.$date
                                                      ? parseInt(
                                                            props.majeur
                                                                .birth_date
                                                                .$date
                                                                .$numberLong
                                                        )
                                                      : props.majeur.birth_date
                                              ).format("L")
                                            : "Non renseigné."
                                    }
                                    flex="75"
                                />
                                <div className="flex005 hide-xs" />
                                <ItemTwoLines
                                    title="Age"
                                    content={
                                        props.majeur.birth_date
                                            ? `${moment().diff(
                                                  props.majeur.birth_date.$date
                                                      ? parseInt(
                                                            props.majeur
                                                                .birth_date
                                                                .$date
                                                                .$numberLong
                                                        )
                                                      : props.majeur.birth_date,
                                                  "years"
                                              )} ans`
                                            : "-"
                                    }
                                    flex="25"
                                />
                            </div>
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Né(e) à"
                                content={
                                    props.majeur.birth_location ||
                                    "Non renseigné."
                                }
                                flex="1"
                            />
                        </div>
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Situation familiale"
                                content={
                                    props.majeur.family_status ||
                                    "Non renseigné."
                                }
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Seul(e)"
                                content={
                                    handleYesNo(props.majeur.seul) ||
                                    "Non renseigné."
                                }
                                flex="1"
                            />
                        </div>

                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="N° GIR"
                                content={props.majeur.n_gir || "Non renseigné."}
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Animaux"
                                content={
                                    handleYesNo(props.majeur.animaux) ||
                                    "Non renseigné."
                                }
                                flex="1"
                            />
                        </div>
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Télé alarm"
                                content={
                                    handleYesNo(props.majeur.telealarm) ||
                                    "Non renseigné."
                                }
                                flex="1"
                                nodivider
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Religion"
                                content={
                                    props.majeur.religion || "Non renseigné."
                                }
                                flex="1"
                                nodivider={true}
                            />
                        </div>
                        <div className="my-sub-title-form layout-space-between-center row">
                            Observations
                        </div>
                        {!props.majeur.notes && (
                            <div className="layout-center-center row div-no-items">
                                Pas encore d'observations.
                            </div>
                        )}
                        {props.majeur.notes && (
                            <div className="block_container">
                                <div className="text_zone">
                                    {props.majeur.notes}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
export default InfosMajeur;
