import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getSettingsCRG } from "../../../../../_api/User";
import { Accordeon } from "../../../../../_shared/Accordeon";
import { Button } from "../../../../../_shared/Button";
import { InputForm } from "../../../../../_shared/InputField";
import { ComptesBancaires } from "./Comptes";
import { Depenses } from "./Depenses";
import { DettesEtCreances } from "./Dettes";
import { Documents } from "./Documents";
import { Immobilier } from "./Immobiliers";
import { Revenus } from "./Revenu";

function formatDate(date: any) {
    const day = String(date.getDate()).padStart(2, "0"); // Extrait le jour et le formate sur 2 chiffres
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Extrait le mois (0-11) et l'incrémente pour le format (1-12)
    const year = date.getFullYear(); // Extrait l'année
    return `${year}-${month}-${day}`; // Combine les trois parties
}

export const DepotCRG = (props: { readOnly?: any; data?: any }) => {
    const { readOnly, data } = props;

    const [numeroRG, setNumeroRG] = useState(
        data ? data.control.rg_number : null
    );
    const [portalis, setNumeroPortalis] = useState(
        data ? data.control.portalis_number : null
    );
    const [startDate, setStartDate] = useState(
        data ? formatDate(new Date(data.start_period)) : null
    );
    const [endDate, setEndDate] = useState(
        data ? formatDate(new Date(data.end_period)) : null
    );

    const { addToast } = useToasts();
    const { depot_id }: { depot_id?: string } = useParams();

    const [isOpenDocuments, setIsOpenDocuments] = useState(false);
    const [isOpenRevenus, setIsOpenRevenus] = useState(false);
    const [isOpenExpense, setIsOpenExpenses] = useState(false);
    const [isOpenLivret, setIsOpenLivret] = useState(false);
    const [isOpenDebt, setIsOpenDebt] = useState(false);
    const [isOpenImmobilier, setIsOpenImmobilier] = useState(false);

    const [isValidatedDocuments, setIsValidatedDocuments] = useState(false);
    const [isValidatedRevenus, setIsValidatedRevenus] = useState(false);
    const [isValidatedDepenses, setIsValidatedDepenses] = useState(false);
    const [isValidatedLivret, setIsValidatedLivret] = useState(false);
    const [isValidatedDebts, setIsValidatedDebts] = useState(false);
    const [isValidatedImmo, setIsValidatedImmo] = useState(false);

    const [isValidatedForm, setIsValidatedForm] = useState(false);
    const [followID, setFollowID] = useState("");
    const close = (name: any, isSubmit = false) => {
        setIsOpenDocuments(false);
        setIsOpenRevenus(false);
        setIsOpenExpenses(false);
        setIsOpenLivret(false);
        setIsOpenDebt(false);
        setIsOpenImmobilier(false);

        switch (name) {
            case "documents":
                setIsOpenDocuments(!isOpenDocuments);
                if (isSubmit) {
                    setIsValidatedDocuments(true);
                }
                break;
            case "revenus":
                setIsOpenRevenus(!isOpenRevenus);
                if (isSubmit) {
                    setIsValidatedRevenus(true);
                }
                break;
            case "depenses":
                setIsOpenExpenses(!isOpenExpense);
                if (isSubmit) {
                    setIsValidatedDepenses(true);
                }
                break;
            case "livrets":
                setIsOpenLivret(!isOpenLivret);
                if (isSubmit) {
                    setIsValidatedLivret(true);
                }
                break;
            case "dettes":
                setIsOpenDebt(!isOpenDebt);
                if (isSubmit) {
                    setIsValidatedDebts(true);
                }
                break;
            case "immo":
                setIsOpenImmobilier(!isOpenImmobilier);
                if (isSubmit) {
                    setIsValidatedImmo(true);
                }
                break;
            case "finish":
                if (isSubmit) {
                    setIsValidatedImmo(true);
                }
        }
    };

    const generateRandomID = (length = 10) => {
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }

        return result;
    };

    const validateForm = () => {
        if (
            isValidatedDocuments &&
            isValidatedRevenus &&
            isValidatedDepenses &&
            isValidatedLivret &&
            isValidatedDebts &&
            isValidatedImmo
        ) {
            setIsValidatedForm(true);
            let _id = generateRandomID();
            setFollowID(_id);
        } else {
            addToast("Toutes les sections doivent être remplis.", {
                appearance: "error",
            });
        }
    };

    const [settings, setSettings] = useState(null);

    useEffect(() => {
        getSettingsCRG(addToast, depot_id).then((resp) => {
            setSettings(resp.data.data);
        });
    }, []);

    return (
        <div className="w-100">
            {!data && (
                <header
                    style={
                        settings?.color && { backgroundColor: settings.color }
                    }
                    className={
                        "w-100 h-24 bg-atroposPrimary flex justify-between"
                    }
                >
                    <div className="p-2">
                        {settings && (
                            <img
                                src={settings.logo}
                                alt="LOGO"
                                height={100}
                                width={175}
                                className="p-2 cursor-pointer"
                            />
                        )}
                    </div>
                    <div className="text-white text-sm p-2">
                        <span className="block h-12 font-semibold">
                            {settings && settings.phone}
                        </span>
                        <span className="block h-12 font-semibold">
                            {settings && settings.email}
                        </span>
                    </div>
                </header>
            )}
            <div className="w-100 flex justify-center">
                <div className="w-4/5 mt-8">
                    {!isValidatedForm && (
                        <>
                            {!data && (
                                <>
                                    <h2 className="text-center text-atroposPrimary">
                                        Contrôle de comptes au service des
                                        majeurs protégés
                                    </h2>
                                    <hr className="m-4" />
                                </>
                            )}
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full md:w-1/2 px-3 md:mb-0">
                                    <InputForm
                                        name="Numéro de RG"
                                        set={setNumeroRG}
                                        value={numeroRG}
                                        required={true}
                                    />
                                </div>
                                <div className="w-full md:w-1/2 px-3 md:mb-0">
                                    <InputForm
                                        name="Numéro Portalis"
                                        set={setNumeroPortalis}
                                        value={portalis}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full md:w-1/2 px-3 md:mb-0">
                                    <InputForm
                                        name="Début de la période"
                                        type="date"
                                        set={setStartDate}
                                        value={startDate}
                                        required={true}
                                    />
                                </div>
                                <div className="w-full md:w-1/2 px-3 md:mb-0">
                                    <InputForm
                                        name="Fin de la période"
                                        type="date"
                                        set={setEndDate}
                                        value={endDate}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <Accordeon
                                name={"Documents"}
                                component={
                                    <Documents
                                        onClose={() => close("documents", true)}
                                    />
                                }
                                isOpen={isOpenDocuments}
                                setIsOpen={() => close("documents")}
                                isFullFilled={isValidatedDocuments}
                            />
                            <Accordeon
                                name={"Revenus"}
                                component={
                                    <Revenus
                                        onClose={() => close("revenus", true)}
                                    />
                                }
                                isOpen={isOpenRevenus}
                                setIsOpen={() => close("revenus")}
                                isFullFilled={isValidatedRevenus}
                            />
                            <Accordeon
                                name={"Dépenses"}
                                component={
                                    <Depenses
                                        onClose={() => close("depenses", true)}
                                    />
                                }
                                isOpen={isOpenExpense}
                                setIsOpen={() => close("depenses")}
                                isFullFilled={isValidatedDepenses}
                            />
                            <Accordeon
                                name={"Livret et compte bancaires"}
                                isOpen={isOpenLivret}
                                setIsOpen={() => close("livrets")}
                                component={
                                    <ComptesBancaires
                                        onClose={() => close("livrets", true)}
                                    />
                                }
                                isFullFilled={isValidatedLivret}
                            />
                            <Accordeon
                                name={"Dettes et créances"}
                                isOpen={isOpenDebt}
                                setIsOpen={() => close("dettes")}
                                isFullFilled={isValidatedDebts}
                                component={
                                    <DettesEtCreances
                                        onClose={() => close("dettes", true)}
                                    />
                                }
                            />
                            <Accordeon
                                name={"Immobilier"}
                                component={
                                    <Immobilier
                                        onClose={() => close("finish", true)}
                                    />
                                }
                                isOpen={isOpenImmobilier}
                                setIsOpen={() => close("immo")}
                                isFullFilled={isValidatedImmo}
                            />
                            {!data && (
                                <div className="mt-12 mb-12 flex justify-end">
                                    <Button
                                        type={"empty"}
                                        name={"Envoyer mes documents"}
                                        action={() => {
                                            validateForm();
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {isValidatedForm && (
                        <div className="flex justify-center">
                            <div className="w-1/2 text-center rounded-md">
                                <h3>
                                    <img
                                        src={"/imgs/checked.png"}
                                        alt="Success"
                                        className="mx-auto p-10"
                                        height={200}
                                        width={200}
                                    />
                                    Vos informations ont bien été envoyés aux
                                    vérificateurs de CRG.
                                </h3>
                                <p>
                                    Votre numéro de suivi est le suivant :{" "}
                                    {followID}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
