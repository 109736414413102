export const MailevaTraduction = {
    'DRAFT': 'Brouillon',
    'PENDING': 'En attente',
    'PROCESSING': 'En cours de traitement',
    'PROCESSED': 'Traité',
    'REJECTED': 'Rejeté',
    'ECONOMIC': 'Economique (J+4)',
    'FAST': 'Courant (J+3)',
    'URGENT': 'Important (J+2)',
    'ACCEPTED': 'Accepté',
}
