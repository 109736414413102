import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getMajeurs } from "../../../_api/Majeurs";
import IGroupMajeurs from "../../../interfaces/IGroupMajeurs";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import Icon from "../../layout/Icon";
import ItemMajeur from "../../layout/ItemMajeur";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import InputSwitch from "../../layout/forms/InputSwitch";
import SearchBar from "../../layout/forms/SearchBar";
import { useAuth } from "../../pages/Login/auth.context";

const ListMajeurs: React.FC = () => {
    const { currentUser } = useAuth();
    const history = useHistory();

    const [archivedProtege, setisArchived] = useState<boolean>(false);
    const [groups, setGroups] = useState<Array<IGroupMajeurs>>([]);
    const [groupsTemp, setGroupsTemp] = useState<Array<IGroupMajeurs>>([]);

    const [searchValue, setSearchValue] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { addToast } = useToasts();

    useEffect(() => {
        setIsLoading(true);
        getMajeurs(addToast, currentUser._id)
            .then(({ data }) => {
                if (currentUser.roles[0] === "FAMILLE" && data.length > 0) {
                    history.push("/single_majeur/" + data[0]._id);
                }
                setGroups(data);
                setGroupsTemp(data);
                setIsLoading(false);
            })
            .catch();
    }, []);

    //Refresh of majeurs displayed by search input
    const refreshMajeurs = (search: string) => {
        setSearchValue(search);
        setIsLoading(true);
        const statusMajeur = archivedProtege ? "archived" : "activated";

        if (search) {
            let result = [];

            groups.filter((group) => {
                let groupCopy = {
                    ...group,
                };
                groupCopy[statusMajeur] = [...group[statusMajeur]];

                const res = groupCopy[statusMajeur].filter((majeur) => {
                    const textMajeur = (
                        majeur.last_name +
                        majeur.first_name +
                        majeur.familly_name
                    ).toUpperCase();

                    const searchVal = textMajeur
                        .normalize("NFD")
                        .replace(/\p{Diacritic}/gu, "")
                        .includes(search.toUpperCase());
                    return searchVal;
                });
                if (res.length) {
                    groupCopy[statusMajeur] = res;
                    result.push(groupCopy);
                    return groupCopy;
                }
            });
            setGroupsTemp(result);
        } else {
            setGroupsTemp(groups);
        }
        setIsLoading(false);
    };

    let sumArchived = 0;
    let sumActive = 0;

    groupsTemp.map((group) => {
        sumActive = sumActive + group.activated.length;
        sumArchived = sumArchived + group.archived.length;
    });

    return (
        <>
            <SubBar title="Vos protégés">
                {!archivedProtege && currentUser.roles[0] !== "FAMILLE" && (
                    <Link to={`/create_majeur`}>
                        <Icon name="add" />
                    </Link>
                )}
            </SubBar>
            <div className="my-input-search-container sticky row">
                <div className={"full-width"}>
                    <div className="">
                        <SearchBar
                            id="searchBar"
                            type="text"
                            needClassName={false}
                            className={"full-width"}
                            placeholder="Recherche..."
                            value={searchValue}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                                refreshMajeurs(e.target.value);
                            }}
                            onClean={() => {
                                setSearchValue("");
                                refreshMajeurs("");
                            }}
                            cleanIconClass="clean_icon_with_buttons"
                            maxLength={50}
                        />
                    </div>
                </div>
                <div className="flex005" />
                <InputSwitch
                    id="isArchivedProtege"
                    className="column-item-flex-1"
                    onChange={() => setisArchived(!archivedProtege)}
                    label="Protégés"
                    isSwitchMajeur={true}
                    onText="Archivés"
                    offText="Courants"
                    value={archivedProtege}
                />
            </div>

            <div>
                {isLoading && <Loader />}

                {!isLoading && sumActive === 0 && !archivedProtege && (
                    <div className="layout-center-center row div-no-items">
                        Aucun protégé n'a été ajouté pour ce compte.
                    </div>
                )}
                {!isLoading && sumArchived === 0 && archivedProtege && (
                    <div className="layout-center-center row div-no-items">
                        Il n'y a pas encore de protégés archivés.
                    </div>
                )}
                {!isLoading && groupsTemp && groupsTemp.length > 0 && (
                    <div className="list">
                        {groupsTemp.map((g, index) => (
                            <div key={index}>
                                {archivedProtege && g.archived.length > 0 && (
                                    <>
                                        <div className="titleLetter sticky top-40">
                                            {g.letter}
                                        </div>

                                        <div className="page_content">
                                            {g.archived.map(
                                                (
                                                    majeur: IProtectedAdult,
                                                    index
                                                ) => (
                                                    <ItemMajeur
                                                        key={majeur._id}
                                                        majeur={majeur}
                                                        index={index}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </>
                                )}
                                {!archivedProtege && g.activated.length > 0 && (
                                    <>
                                        <div className="titleLetter sticky top-40">
                                            {g.letter}
                                        </div>

                                        <div className="page_content">
                                            {g.activated.map(
                                                (
                                                    majeur: IProtectedAdult,
                                                    index
                                                ) => (
                                                    <div
                                                        id={majeur._id}
                                                        key={majeur._id}
                                                    >
                                                        <ItemMajeur
                                                            key={majeur._id}
                                                            majeur={majeur}
                                                            index={index}
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};
export default ListMajeurs;
