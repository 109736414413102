import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IAlert from "../../../interfaces/IAlert";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import Modal from "../../layout/modals/Modal";

const Alert: React.FC = (props) => {
    const [alert, setAlert] = useState<IAlert | null>(null);
    const [confirmDeletion, setConfirmDeletion] = useState<boolean>(false);
    const [previousLoc, setPreviousLoc] = useState<string>(null);

    const history = useHistory();
    const { addToast } = useToasts();
    const { alertId }: { alertId: string } = useParams();

    useEffect(() => {
        if (!previousLoc) getRedirection();
        if (!alert) {
            request({ addToast, url: `/api/alarm/${alertId}` })
                .then(({ data }) => {
                    setAlert(data);
                })
                .catch(() => history.push("/alertes"));
        }
    }, [alert]);

    // Delete "_" in alert.repetition.
    const formatString = (chain: string) => {
        var newChain = chain.split("_").join("");
        return capitalizeFirstLetter(newChain);
    };

    // Format to "Aaaa".
    const capitalizeFirstLetter = (chain: string) => {
        if (typeof chain !== "string") return "";
        return chain.charAt(0).toUpperCase() + chain.slice(1).toLowerCase();
    };

    /**
     * Delete an alert.
     */
    const deleteAlert = () => {
        setConfirmDeletion(false);
        request({
            addToast,
            url: `/api/alarms/${alertId}/delete`,
            method: "post",
            data: {},
        }).then((data) => {
            addToast(`L'alerte a bien été supprimée`, {
                appearance: "success",
            });
            history.push(previousLoc);
        }).catch();
    };

    const getRedirection = () => {
        setPreviousLoc(localStorage.getItem("location"));
    };

    return (
        <>
            <SubBar
                title={
                    alert &&
                    ((alert.protected_adult &&
                        `${alert.protected_adult.first_name}  ${alert.protected_adult.last_name} | Alerte`) ||
                        `${alert.label} | Alerte`)
                }
                back={previousLoc}
            >
                <Link to={"/alertes/" + alertId + "/modifier"}>
                    <Icon name="edit" />
                </Link>
                <Link to="#" onClick={() => setConfirmDeletion(true)}>
                    <Icon name="delete_forever" />
                </Link>
            </SubBar>

            <Modal
                message="Attention : La suppression de l'alarme est définitive. Êtes vous sûr(e) ?"
                show={confirmDeletion}
                onValidate={deleteAlert}
            />

            <div className="page_content">
                {!alert && <Loader />}

                {alert && (
                    <>
                        <div className="row layout-center-center single_alarms">
                            {alert.done && (
                                <span className="alarm_done">
                                    Cette alerte a été effectuée !
                                </span>
                            )}
                            {!alert.done && (
                                <span className="alarm_not_done">
                                    Cette alerte est en cours !
                                </span>
                            )}
                        </div>

                        <div className="column">
                            <div className="item_two_line">
                                <h6>Objet</h6>
                                <p className="notes_zone">{alert.label}</p>
                            </div>
                            <div className="divider"></div>
                        </div>

                        <div className="row layout-xs-column">
                            <div className="column flex-1">
                                <div className="item_two_line">
                                    <h6>Date d'échéance</h6>
                                    <p className="notes_zone">
                                        {alert.final_date}
                                    </p>
                                </div>
                                <div className="divider"></div>
                            </div>
                            <div className="flex005 hide-xs"></div>
                            <div className="column flex-1">
                                <div className="item_two_line">
                                    <h6>Date d'alerte</h6>
                                    <p className="notes_zone">
                                        {alert.start_date}
                                    </p>
                                </div>
                                <div className="divider"></div>
                            </div>
                        </div>

                        <div className="row layout-xs-column">
                            <div className="column flex-1">
                                <div className="item_two_line">
                                    <h6>
                                        Répétition si l'alarme a été effectuée
                                    </h6>
                                    <p className="notes_zone">
                                        {formatString(alert.repetition)}
                                    </p>
                                </div>
                                <div className="divider"></div>
                            </div>

                            <div className="flex005 hide-xs"></div>

                            <div className="column flex-1">
                                <div className="item_two_line">
                                    <h6>Prévenir par email</h6>
                                    <p className="notes_zone">
                                        {alert.sending_email
                                            ? "Oui"
                                            : "Non" || "Non renseigné"}
                                    </p>
                                </div>
                                <div className="divider"></div>
                            </div>
                        </div>

                        <div className="item_two_line">
                            <h6>Notes :</h6>
                            <div className="notes_zone">
                                {alert.notes || "Non renseigné"}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Alert;
