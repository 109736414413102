import { request } from "../utils/axios-utils";

const namespace = "/api/documents";

export const getExcelProtectedAdultList = (
    addToast: any,
    ids: Array<any>,
    filters: Array<any>
) => {
    return request({
        addToast,
        url: namespace + "/adults-list",
        method: "post",
        data: {
            majorsId: ids,
            filters: filters,
        },
    });
};
