import { request } from "../utils/axios-utils";

export const createDebt = (addToast: any, majeurId: any, payload: any) => {
    return request({
        addToast,
        url: `/api/debts/create/${majeurId}`,
        method: "post",
        data: payload,
    });
};

export const editDebt = (addToast: any, debtId: any, payload: any) => {
    return request({
        addToast,
        url: `/api/debts/${debtId}/edit`,
        method: "post",
        data: payload,
    });
};
