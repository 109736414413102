import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import shortid from "shortid";
import { capitalizeFirst } from "../../../constants/Utils";
import IUsers from "../../../interfaces/IUsers";
import { request } from "../../../utils/axios-utils";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import FormButton from "../../layout/forms/FormButton";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";

const CreateUpdateUser: React.FC<{ id?: string }> = (props) => {
    const [user, setUser] = useState<IUsers | null>(null);
    const history = useHistory();
    const { addToast } = useToasts();
    const userJson = sessionStorage.getItem("user");
    const currentUser = JSON.parse(userJson);
    // One hook for each form field.
    const [firstName, setfirstName] = useState<string>("");
    const [lastName, setlastName] = useState<string>("");
    const [email, setEmail] = useState<any>();

    const [civility, setCivility] = useState<string>();

    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");

    const [result, setResult] = useState<number>(0);

    const [sponsorId, setSponsorId] = useState<string>("");
    const { userId }: { userId?: string } = useParams();

    useEffect(() => {
        // If it is a modification, it loads the users to modify.
        if (userId && !user) {
            request({ addToast, url: `/api/users/${userId}` })
                .then(({ data }) => {
                    setUser(data);
                })
                .catch();
        }
    }, [user]);

    const rolesList = [];
    if (currentUser.roles[0] === "INVOICE_ACCOUNT") {
        rolesList.push(
            {
                value: "MANAGER",
                label: "Chef de service",
            },
            {
                value: "ACCOUNTANT",
                label: "Service de comptabilité",
            }
        );
    }
    if (currentUser.roles[0] === "MANAGER") {
        rolesList.push({
            value: "REP_MJPM",
            label: "Délégués MJPM",
        });
    }
    if (currentUser.roles[0] === "super-admin") {
        rolesList.push(
            {
                value: "user",
                label: "MJPM",
            },
            {
                value: "FAMILLE",
                label: "Famille ",
            },
            {
                value: "CRG_VERIF",
                label: "Vérificateur de CRG",
            }
        );
    }

    const [roles, setRoles] = useState<any>(rolesList[0].value);

    const validateForm = () => {
        const url = userId ? `/api/users/${userId}/edit` : `/api/users/create`;
        if (password !== password2) {
            addToast("Le mot de passe de confirmation n'est pas bon.", {
                appearance: "error",
            });
        }

        if (checkPassStrength(password)) {
            const payload = {
                _id: String,
                service: { password: password },
                email: email,
                roles: roles,
                type: currentUser.type,
                profile: {
                    infos: {
                        first_name: capitalizeFirst(firstName.trim()),
                        last_name: capitalizeFirst(lastName.trim()),
                        civility: civility,
                        sponsor_id: shortid.generate(),
                        address1: String,
                        zip_code: String,
                        birth_date: Date,
                        city: String,
                        address2: String,
                        family_name: String,
                        phone: String,
                        mobile: String,
                        fax: String,
                    },
                    status: {
                        status: String,
                        n_sirene: String,
                        lieu: String,
                        code_ape: String,
                        entite: String,
                        debut_activite: Date,
                        departement: String,
                        n_argument: String,
                        region: String,
                    },
                    condition_exercice: {
                        seul: String,
                        estimation_etp: String,
                        secretaire: String,
                        nb_secritaire: String,
                        etp_secretaires: String,
                        nb_departement_agrement: String,
                        DepartementPremierAgrement: String,
                    },
                    formation: {
                        annee_obtention_cnc: String,
                        nb_heure_formation: String,
                        nv_qualification: String,
                        nb_niveauI: String,
                        nb_niveauII: String,
                        nb_niveauIII: String,
                        nb_niveauIV: String,
                        nb_niveauV: String,
                        nb_niveauVI: String,
                    },
                },
            };

            request({
                addToast,
                url: url,
                method: "post",
                data: { payload },
            })
                .then(({ data }) => {
                    history.push("/utilisateurs");
                })
                .catch();
        }
    };

    const checkPassStrength = (pass: string) => {
        var score = scorePassword(pass);
        if (score > 80) return true;
        else if (score > 60) return true;
        else {
            addToast("Mot de passe trop faible !", { appearance: "error" });
            return false;
        }
    };

    const scorePassword = (pass: string) => {
        setPassword(pass);

        var score = 0;
        if (!pass) return score;

        // award every unique letter until 5 repetitions
        var letters: any = {};
        for (var i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        const variations: any = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        };

        let variationCount: number = 0;
        for (var check in variations) {
            variationCount += variations[check] == true ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        setResult(score);
        return score;
    };

    return (
        <>
            <div className="create_user">
                <SubBar
                    title={
                        !userId
                            ? "Ajouter un utilisateur"
                            : "Modifier un utilisateur"
                    }
                    back="/utilisateurs"
                />

                <div className="page_content">
                    {userId && !user && <Loader />}

                    {(!userId || user) && (
                        <form className="column">
                            <div className="row layout-xs-column">
                                <div className="my-input-container flex-1">
                                    <Select
                                        value={civility}
                                        onChange={(e) =>
                                            setCivility(e.target.value)
                                        }
                                        needDefaultValue={true}
                                        label="Civilité"
                                        required
                                        options={[
                                            {
                                                label: "Monsieur",
                                                value: "Monsieur",
                                            },
                                            {
                                                label: "Madame",
                                                value: "Madame",
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="hide-xs flex005"></div>
                                <div className="my-input-container column-item-flex-1">
                                    <InputComponent
                                        id="prenom"
                                        type="text"
                                        className="column-item-flex-1"
                                        label="Prénom"
                                        required
                                        value={firstName}
                                        onChange={(e) =>
                                            setfirstName(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                </div>
                                <div className="hide-xs flex005"></div>

                                <div className="my-input-container column-item-flex-1">
                                    <InputComponent
                                        id="lastName"
                                        type="text"
                                        className="column-item-flex-1"
                                        required
                                        label="Nom"
                                        value={lastName}
                                        onChange={(e) =>
                                            setlastName(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                </div>
                            </div>
                            <div className="row layout-xs-column">
                                <div className="my-input-container column-item-flex-1">
                                    <InputComponent
                                        id="email"
                                        type="email"
                                        label="Email"
                                        className="column-item-flex-1"
                                        required
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                </div>
                                <div className="hide-xs flex005"></div>
                                <div className="my-input-container flex-1">
                                    {currentUser && (
                                        <Select
                                            value={roles}
                                            onChange={(e) =>
                                                setRoles(e.target.value)
                                            }
                                            label="Rôle"
                                            required
                                            options={rolesList}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="row layout-xs-column">
                                <div className="my-input-container column-item-flex-1">
                                    <InputComponent
                                        id="password_user"
                                        type="password"
                                        className="column-item-flex-1"
                                        required
                                        label="Mot de passe"
                                        value={password}
                                        onChange={(e) => {
                                            scorePassword(e.target.value);
                                        }}
                                        maxLength={50}
                                    />
                                    <div id="resultPassword">
                                        Niveau de sécurité du mot de passe :{" "}
                                        <span
                                            className={
                                                result <= 60
                                                    ? "red"
                                                    : result <= 80
                                                    ? "orange"
                                                    : "green"
                                            }
                                        >
                                            {result}%
                                        </span>
                                    </div>
                                </div>
                                <div className="hide-xs flex005"></div>

                                <div className="my-input-container column-item-flex-1">
                                    <InputComponent
                                        id="password2"
                                        type="password"
                                        className="column-item-flex-1"
                                        label="Confirmation de mot de passe"
                                        required
                                        value={password2}
                                        onChange={(e) =>
                                            setPassword2(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                </div>
                            </div>
                            <FormButton
                                onClick={validateForm}
                                text={(!user && "Valider") || "Modifier"}
                            />
                        </form>
                    )}
                </div>
            </div>
        </>
    );
};

export default CreateUpdateUser;
