import moment from "moment";
import React, { useEffect, useState } from "react";
import Switch from "react-bootstrap-switch";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IMesure from "../../../interfaces/IMesure";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import IProtection from "../../../interfaces/IProtection";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import ArrowBack from "../../layout/ArrowBack";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import InputComponent from "../../layout/forms/InputComponent";
import InputDatePicker from "../../layout/forms/InputDatePicker";
import Select from "../../layout/forms/Select";
import Textarea from "../../layout/forms/Textarea";
import InnerProtectionForm from "./InnerProtectionForm";

const CreateUpdate: React.FC = () => {
    const [protections, setProtections] = useState<Array<IProtection>>([]);

    const [protectionIndex, setProtectionIndex] = useState<number>(0);

    const [mesure, setMesure] = useState<IMesure | null>(null);

    const [user, setUser] = useState<IUser>(null);

    const [majeur, setMajeur] = useState<IProtectedAdult>(null);

    const [listTribunauxOptions, setListTribunauxOptions] =
        useState<Array<any>>(null);

    const { mesureId }: { mesureId?: string } = useParams();
    const { majeurId }: { majeurId?: string } = useParams();

    const { addToast } = useToasts();

    /* FORM DATA */
    const [notes, setNotes] = useState<string>("");
    const [alertChecked, setAlertChecked] = useState<boolean>(true);
    const [selectedTribunal, setSelectedTribunal] = useState<string>(null);

    const [selectedAgrementBool, setSelectedAgrementBool] = useState<string>();

    const [judgmentDate, setJudgmentDate] = useState<Date>(null);
    const [decisionDate, setDecisionDate] = useState<Date>(null);
    const [notificationDate, setNotificationDate] = useState<Date>(null);

    const [alertTiming, setAlertTiming] = useState<string>("6");
    const [mesureDuration, setMesureDuration] = useState<string>("0");
    const [numberCabinet, setNumberCabinet] = useState<string>("");
    const [numberRG, setNumberRG] = useState<string>("");
    const [numberPortalis, setNumberPortalis] = useState<string>("");

    const history = useHistory();

    useEffect(() => {
        // Get actual user
        if (!user) {
            request({ addToast, url: `/api/user` }).then(({ data }) => {
                setUser(data);
            }).catch();
        }

        // Get the Protected Adult
        if (!majeur) {
            request({ addToast, url: `/api/majeur/${majeurId}` }).then(
                ({ data }) => {
                    setMajeur(data);
                }
            ).catch();
        }

        // Get list of tribunals select options
        if (!listTribunauxOptions) {
            request({ addToast, url: `/api/measures/tribunals-options` }).then(
                ({ data }) => {
                    setListTribunauxOptions(data);
                    //setSelectedTribunal(data[0].value);
                }
            ).catch();
        }

        // If it is a modification, it loads the alert to modify.
        if (mesureId && !mesure) {
            request({ addToast, url: `/api/majeurs/measure/${majeurId}` }).then(
                ({ data }) => {
                    if (data !== null) {
                        setMesure(data);

                        if (data.protections && data.protections.length > 0) {
                            data.protections.forEach(function (
                                p: any,
                                index: any
                            ) {
                                p["id"] = `protec-${index}`;
                            });

                            setProtections(data.protections);
                            setProtectionIndex(data.protections.length);
                        }

                        setAlertChecked(
                            data.alarm === undefined ? true : data.alarm
                        );
                        setAlertTiming(
                            data.alarm_timing
                                ? data.alarm_timing.toString()
                                : "6"
                        );
                        setNotes(data.notes);
                        setMesureDuration(data.duration_mesure.toString());
                        setNumberCabinet(data.court_office_number);
                        setNumberRG(data.n_rg);
                        setNumberPortalis(data.portalis_number || "");
                        setSelectedTribunal(data.tribunal_id);
                        setSelectedAgrementBool(
                            data.dpt_first_argrement.toString()
                        );

                        setJudgmentDate(
                            new Date(
                                moment(
                                    data.date_initial_judgment.$date
                                        ? parseInt(
                                              data.date_initial_judgment.$date
                                                  .$numberLong
                                          )
                                        : data.date_initial_judgment
                                ).format()
                            )
                        );
                        setDecisionDate(
                            new Date(
                                moment(
                                    data.date_decision_mesure.$date
                                        ? parseInt(
                                              data.date_decision_mesure.$date
                                                  .$numberLong
                                          )
                                        : data.date_decision_mesure
                                ).format()
                            )
                        );
                        setNotificationDate(
                            new Date(
                                data.date_notification
                                    ? moment(
                                          data.date_notification.$date
                                              ? parseInt(
                                                    data.date_notification.$date
                                                        .$numberLong
                                                )
                                              : data.date_notification
                                      ).format()
                                    : moment().format()
                            )
                        );
                    }
                }
            ).catch();
        }
    }, []);

    const verifForm = (userType: string) => {
        // Some additional verifications to do here in addition to the "ValidateStoreMeasureRequest.php"
        let result = true;
        protections.forEach((p, index) => {
            const startDate = p.start_date
                ? new Date(
                      moment(
                          p.start_date.$date
                              ? parseInt(p.start_date.$date.$numberLong)
                              : p.start_date
                      ).format()
                  )
                : null;

            const endDate = p.end_date
                ? new Date(
                      moment(
                          p.end_date.$date
                              ? parseInt(p.end_date.$date.$numberLong)
                              : p.end_date
                      ).format()
                  )
                : null;

            let endDatePreviousProtection = null;
            if (index !== 0) {
                endDatePreviousProtection = protections[index - 1].end_date
                    ? new Date(
                          moment(
                              protections[index - 1].end_date.$date
                                  ? parseInt(
                                        protections[index - 1].end_date.$date
                                            .$numberLong
                                    )
                                  : protections[index - 1].end_date
                          ).format()
                      )
                    : null;
            }

            if (!p.start_date) {
                addToast("Vous devez ajouter une date de début !", {
                    appearance: "warning",
                });
                result = false;
            } else if (userType !== "_ASSOCIATION_" && !p.period) {
                addToast("Vous devez ajouter la période !", {
                    appearance: "warning",
                });
                result = false;
            } else if (!p.nature) {
                addToast("Vous devez ajouter la nature !", {
                    appearance: "warning",
                });
                result = false;
            } else if (!p.protection) {
                addToast("Vous devez ajouter la protection !", {
                    appearance: "warning",
                });
                result = false;
            } else if (!p.location) {
                addToast("Vous devez ajouter le lieu de vie !", {
                    appearance: "warning",
                });
                result = false;
            } else if (!p.pps) {
                addToast("Vous devez ajouter la prestation sociale !", {
                    appearance: "warning",
                });
                result = false;
            } else if (
                (p.location === "E" || p.location === "E+D") &&
                !p.establishment
            ) {
                addToast("Il manque l'établissement !", {
                    appearance: "warning",
                });
                result = false;
            }
            if (p.period !== "Normale" && !p.end_date) {
                addToast("Vous devez ajouter une date de fin.", {
                    appearance: "warning",
                });
                result = false;
            } else if (
                p.period === "Fin" &&
                !p.reason &&
                protections.length - 1 === index
            ) {
                addToast("Il faut une raison à la fin de mesure.", {
                    appearance: "warning",
                });
                result = false;
            } else if (startDate && endDate && startDate > endDate) {
                addToast(
                    "Une date de début est supérieure à une date de fin.",
                    {
                        appearance: "warning",
                    }
                );
                result = false;
            } else if (
                index !== 0 &&
                p.start_date &&
                endDatePreviousProtection > startDate
            ) {
                addToast("Concordance des dates incorrectes.", {
                    appearance: "warning",
                });
                result = false;
            } else if (!endDate && p.reason) {
                addToast("Une date de fin est nécessaire s'il y a une sortie", {
                    appearance: "warning",
                });
                result = false;
            }
        });

        return result;
    };

    const submitMesure = () => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        const lastProtection = protections[protections.length - 1];
        const isLastProtectionMPF =
            lastProtection && lastProtection.nature === "MPF";
        const dateJugementInitial = judgmentDate;
        const dateDecisionMesure = decisionDate;
        if (!dateJugementInitial) {
            addToast("Il manque la date de nomination.", {
                appearance: "warning",
            });
            return;
        } else if (!dateDecisionMesure) {
            addToast("Il manque la date de de décision de mesure.", {
                appearance: "warning",
            });
            return;
        } else if (!isLastProtectionMPF && mesureDuration == "0") {
            addToast("Attention au nombre de mois de la mesure.", {
                appearance: "warning",
            });
            return;
        }

        let agrementValue = null;
        if (selectedAgrementBool) {
            agrementValue = selectedAgrementBool == "false" ? false : true;
        }

        const mesureUpdated: any = {
            // Make the request measure object here
            majeur_id: majeurId,
            tribunal_id: selectedTribunal,
            n_rg: numberRG || "",
            portalis_number: numberPortalis || "",
            court_office_number: numberCabinet || "",
            notes: notes || "",
            dpt_first_argrement: agrementValue,
            date_initial_judgment: judgmentDate,
            date_decision_mesure: decisionDate,
            duration_mesure: Number(mesureDuration) || 0,
            protections: [],
            alarm: alertChecked,
            alarm_timing: Number(alertTiming),
        };

        if (user.type === "_ASSOCIATION_") {
            mesureUpdated.date_notification = notificationDate;
        }

        protections.forEach((p, index) => {
            mesureUpdated.protections.push({
                start_date: p.start_date
                    ? new Date(
                          moment(
                              p.start_date.$date
                                  ? parseInt(p.start_date.$date.$numberLong)
                                  : p.start_date
                          ).format()
                      )
                    : null,
                end_date: p.end_date
                    ? new Date(
                          moment(
                              p.end_date.$date
                                  ? parseInt(p.end_date.$date.$numberLong)
                                  : p.end_date
                          ).format()
                      )
                    : null,
                period: p.period,
                nature: p.nature,
                protection: p.protection,
                pps: p.pps,
                location: p.location,
                establishment:
                    p.location !== "E" && p.location !== "E+D"
                        ? null
                        : p.establishment,
                reason: protections.length - 1 === index ? p.reason || "" : "",
            });
        });

        if (verifForm(user.type)) {
            if (!mesureId) {
                request({
                    addToast,
                    url: `/api/measures/create`,
                    method: "post",
                    data: { measure: mesureUpdated },
                }).then(({ data }) => {
                    addToast(`La mesure a bien été créée.`, {
                        appearance: "success",
                    });
                    history.push(`/single_majeur/${majeurId}/mesure`);
                }).catch();
            } else {
                request({
                    addToast,
                    url: `/api/measures/update/${mesureId}`,
                    method: "post",
                    data: { measure: mesureUpdated },
                }).then(({ data }) => {
                    addToast(`La mesure a bien été modifiée.`, {
                        appearance: "success",
                    });
                    history.push(`/single_majeur/${majeurId}/mesure`);
                }).catch();
            }
        }
    };

    //Add a Protection
    const addProtection = () => {
        const protectionList = [...protections];

        let index = -1;
        let startDate = judgmentDate;
        let previousPeriod = null;
        let previousNature = null;
        let previousPPS = null;
        let previousProtection = null;
        let previousLocation = null;
        let previousEstablishment = null;
        let previousReason = null;

        if (protectionList && protectionList.length > 0) {
            index = protectionList.length - 1;
        }

        if (index > -1) {
            if (!protectionList[index].start_date) {
                addToast("Vous devez ajouter une date de début !", {
                    appearance: "warning",
                });
                return;
            } else if (
                user.type !== "_ASSOCIATION_" &&
                !protectionList[index].period
            ) {
                addToast("Vous devez ajouter la période !", {
                    appearance: "warning",
                });
                return;
            } else if (!protectionList[index].nature) {
                addToast("Vous devez ajouter la nature !", {
                    appearance: "warning",
                });
                return;
            } else if (!protectionList[index].protection) {
                addToast("Vous devez ajouter la protection !", {
                    appearance: "warning",
                });
                return;
            } else if (!protectionList[index].pps) {
                addToast("Vous devez ajouter la prestation sociale !", {
                    appearance: "warning",
                });
                return;
            } else if (!protectionList[index].location) {
                addToast("Vous devez ajouter le lieu de vie !", {
                    appearance: "warning",
                });
                return;
            }
        }

        if (index > -1) {
            previousPeriod = "Normale";
            previousNature = protectionList[index].nature;
            previousProtection = protectionList[index].protection;
            previousPPS = protectionList[index].pps;
            previousLocation = protectionList[index].location;
            previousEstablishment = protectionList[index].establishment;
            previousReason = "";
            if (protectionList[index].end_date) {
                startDate = protectionList[protectionList.length - 1].end_date
                    ? new Date(
                          moment(
                              protectionList[protectionList.length - 1].end_date
                                  .$date
                                  ? parseInt(
                                        protectionList[
                                            protectionList.length - 1
                                        ].end_date.$date.$numberLong
                                    )
                                  : protectionList[protectionList.length - 1]
                                        .end_date
                          ).format()
                      )
                    : null;

                if (startDate) startDate.setDate(startDate.getDate() + 1);
            }
        }

        protections.forEach((p) => (p.isDeletable = false));
        protections.push({
            id: `protec-${protectionIndex}`,
            location: previousLocation || null,
            start_date: startDate || null,
            period: previousPeriod || "Normale",
            nature: previousNature || null,
            protection: previousProtection || null,
            pps: previousPPS || null,
            establishment: null,
            reason: previousReason || null,
        });

        setProtectionIndex(protectionIndex + 1);
    };

    //Modify a Protection
    const setProtectionValue = (protection: IProtection) => {
        let index = protections.findIndex((p) => p.id === protection.id);
        let newProtections = protections;
        newProtections[index] = protection;
    };

    //Delete a Protection
    const removeProtection = (protection: IProtection) => {
        let index = protections.findIndex((p) => p.id === protection.id);

        let newProtections = protections;
        newProtections.splice(index, 1);
        newProtections.forEach(
            (p, index) => (p.isDeletable = index === newProtections.length - 1)
        );

        setProtectionIndex(protectionIndex - 1);
    };

    const title =
        majeur && window.screen.width > 600
            ? `${majeur.first_name} ${majeur.last_name} | `
            : "";

    const boolAgrementOptions = [
        { value: "true", label: "Oui" },
        { value: "false", label: "Non" },
    ];

    return (
        <>
            <div className="update_majeur">
                <ArrowBack
                    title={`${title} ${
                        !mesureId ? "Créer" : "Modifier"
                    } la mesure`}
                    helpUrl="/pdf/TUTO_MESURE.pdf"
                />
                <div className="page_content block column">
                    {!mesure && mesureId && <Loader />}
                    {(!listTribunauxOptions ||
                        listTribunauxOptions.length === 0) &&
                        !mesureId && (
                            <div className="layout-center-center row div-no-items">
                                Vous devez d&#039;abord ajouter un tribunal.
                            </div>
                        )}
                    {listTribunauxOptions &&
                        listTribunauxOptions.length > 0 &&
                        user && (
                            <form className="column" onSubmit={submitMesure}>
                                {/* Informations sur la mesure */}
                                <div className="row layout-xs-column flex-1">
                                    <Select
                                        id="tribunal"
                                        label="Tribunal"
                                        className="flex-1"
                                        needDefaultValue={true}
                                        required
                                        options={listTribunauxOptions}
                                        value={selectedTribunal}
                                        onChange={(e) =>
                                            setSelectedTribunal(e.target.value)
                                        }
                                    />
                                    <div className="flex005 hide-xs" />
                                    <InputComponent
                                        id="cabinet-tribunal"
                                        className="flex-1"
                                        label="N° cabinet tribunal"
                                        value={numberCabinet}
                                        onChange={(e) =>
                                            setNumberCabinet(e.target.value)
                                        }
                                    />
                                    <div className="flex005 hide-xs" />
                                    <InputComponent
                                        id="number"
                                        required
                                        className="flex-1"
                                        label="N° RG"
                                        value={numberRG}
                                        onChange={(e) =>
                                            setNumberRG(e.target.value)
                                        }
                                    />
                                    <div className="flex005 hide-xs" />
                                    <InputComponent
                                        id="portalis"
                                        className="flex-1"
                                        label="N° Portalis"
                                        value={numberPortalis}
                                        onChange={(e) =>
                                            setNumberPortalis(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="row layout-xs-column flex-1">
                                    <div className="my-input-container flex-1">
                                        <Select
                                            id={`first-agrement`}
                                            label="Dpt premier agrément"
                                            needDefaultValue={true}
                                            required
                                            options={boolAgrementOptions}
                                            value={selectedAgrementBool}
                                            onChange={(e) => {
                                                // if (
                                                //     e.target.value === "false"
                                                // ) {
                                                //     console.log(e.target.value);
                                                // } else {
                                                //     console.log(e.target.value);
                                                // }

                                                setSelectedAgrementBool(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </div>

                                    <div className="flex005 hide-xs" />

                                    <InputComponent
                                        id="number"
                                        className="flex-1"
                                        type="number"
                                        label="Durée de la mesure (mois)"
                                        minLength={0}
                                        maxLength={481}
                                        value={mesureDuration}
                                        needBlur={true}
                                        onBlur={() => {
                                            let value = String(
                                                parseFloat(mesureDuration)
                                            );
                                            setMesureDuration(
                                                value !== "NaN" ? value : "0"
                                            );
                                        }}
                                        required={
                                            protections &&
                                            protections.length > 0 &&
                                            !(
                                                protections[
                                                    protections.length - 1
                                                ].nature === "MPF"
                                            )
                                        }
                                        onChange={(e) => {
                                            let value = String(
                                                parseFloat(e.target.value)
                                            );
                                            setMesureDuration(
                                                value !== "NaN" ? value : "0"
                                            );
                                        }}
                                    />

                                    {user.type === "_ASSOCIATION_" && (
                                        <div className="flex005 hide-xs" />
                                    )}
                                    {user.type === "_ASSOCIATION_" && (
                                        <InputDatePicker
                                            id="notification_mesure"
                                            label="Décision de notification"
                                            required
                                            inputDate={notificationDate}
                                            onChange={(date: Date) =>
                                                setNotificationDate(date)
                                            }
                                        />
                                    )}
                                </div>
                                <div className="row layout-xs-column flex-1">
                                    <InputDatePicker
                                        id="dateJugementInitial"
                                        label="Jugement ou ordonnance de nomination"
                                        inputDate={judgmentDate}
                                        required
                                        onChange={(date: Date) =>
                                            setJudgmentDate(date)
                                        }
                                    />
                                    <div className="flex005 hide-xs" />
                                    <InputDatePicker
                                        id="dateDecisionMesure"
                                        label="Décision de la mesure en cours"
                                        inputDate={decisionDate}
                                        required
                                        onChange={(date: Date) =>
                                            setDecisionDate(date)
                                        }
                                    />
                                </div>

                                <div
                                    className="row layout-xs-column flex-1"
                                    style={{ marginTop: 10 }}
                                >
                                    <div className="flex005 hide-xs" />
                                    <div className="flex-1 hide-xs" />

                                    <div
                                        className="row my-input-container flex-1"
                                        style={{
                                            flexDirection: "row",
                                        }}
                                    >
                                        <div
                                            style={{
                                                margin: "auto",
                                                marginLeft: "0%",
                                                marginRight: "2%",
                                            }}
                                        >
                                            <Switch
                                                onChange={() =>
                                                    setAlertChecked(
                                                        !alertChecked
                                                    )
                                                }
                                                labelText="Alerte"
                                                bsSize="small"
                                                wrapperClass="wrapper"
                                                onText="OUI"
                                                onColor="success"
                                                offColor="danger"
                                                offText="NON"
                                                value={alertChecked}
                                            />
                                        </div>

                                        {alertChecked && (
                                            <div>
                                                <InputComponent
                                                    id="number"
                                                    type="number"
                                                    label="Mois avant la date de révision"
                                                    minLength={0}
                                                    maxLength={24}
                                                    value={alertTiming}
                                                    needBlur={true}
                                                    onBlur={() => {
                                                        let value = String(
                                                            parseFloat(
                                                                alertTiming
                                                            )
                                                        );
                                                        setAlertTiming(
                                                            value !== "NaN"
                                                                ? value
                                                                : "0"
                                                        );
                                                    }}
                                                    onChange={(e) => {
                                                        let value = String(
                                                            parseFloat(
                                                                e.target.value
                                                            )
                                                        );
                                                        setAlertTiming(
                                                            value !== "NaN"
                                                                ? value
                                                                : "0"
                                                        );
                                                    }}
                                                />{" "}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Protections et notes de la mesure */}
                                <div className="my-sub-title-f">
                                    <SubBar title="Protections">
                                        <Link to={"#"} onClick={addProtection}>
                                            <Icon name="add" />
                                        </Link>
                                    </SubBar>
                                </div>
                                <div className="layout-center-start column">
                                    {protections &&
                                        protections.map((p, index) => (
                                            <div
                                                key={index}
                                                className="column full-width"
                                            >
                                                <InnerProtectionForm
                                                    key={p.id}
                                                    //index={index}
                                                    id={p.id}
                                                    user={user}
                                                    removeProtection={(
                                                        p: IProtection
                                                    ) => removeProtection(p)}
                                                    onChange={(
                                                        p: IProtection
                                                    ) => setProtectionValue(p)}
                                                    isDeletable={
                                                        protections.length -
                                                            1 ===
                                                        index
                                                    }
                                                    {...p}
                                                />
                                            </div>
                                        ))}
                                </div>
                                <Textarea
                                    id="notes"
                                    label="Notes"
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                />

                                <div className="divider" />

                                <button type="submit" className="valid-button">
                                    Valider
                                </button>
                            </form>
                        )}
                </div>
            </div>
        </>
    );
};

export default CreateUpdate;
