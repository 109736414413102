import React from "react";
import { PartnerCard } from "./Partner/PartnerCard";

const TrainingSettings: React.FC = () => {
    return (
        <div className="training">
            {" "}
            <div className="flex-1">
                <PartnerCard
                    icon={"view_headline"}
                    title={"Formation - Comptabilité du majeur"}
                    description={
                        "Conçue pour fournir les compétences nécessaires en gestion financière des personnes protégées. Elle couvre les principes comptables essentiels, la tenue des comptes et la préparation du CRG assurant une gestion transparente et efficace des ressources des majeurs protégés."
                    }
                    tags={["formation", "comptabilité", "protégé"]}
                    cta_title="Prendre rendez-vous"
                    cta_click={() =>
                        window.open(
                            "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1PdD2R1AzKPLm4umVBHNGTedO_rm33raQXOvLgbRpk4MSFi5IFf1CplBmVsGTTEf17V6QRdYzB"
                        )
                    }
                />
                <PartnerCard
                    icon={"markunread_mailbox"}
                    title={"Formation - Gestion des courriers"}
                    description={
                        "Vise à doter des compétences nécessaires pour gérer efficacement la correspondance des personnes protégées. Elle inclut des techniques pour organiser, traiter et archiver les courriers, garantissant ainsi une communication fluide et une administration rigoureuse."
                    }
                    tags={["formation", "courrier", "MJPM"]}
                    cta_title="Prendre rendez-vous"
                    cta_click={() =>
                        window.open(
                            "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1PdD2R1AzKPLm4umVBHNGTedO_rm33raQXOvLgbRpk4MSFi5IFf1CplBmVsGTTEf17V6QRdYzB"
                        )
                    }
                />
                <PartnerCard
                    icon={"notifications"}
                    title={"Formation - Remise à niveau du logiciel"}
                    description={
                        "Vise à doter des compétences nécessaires pour gérer efficacement vos protégés à travers notre logiciel. Révision des nouvelles fonctionnalités afin de les comprendre et découvrir pour une gestion optimisée."
                    }
                    tags={["formation", "logiciel", "MJPM"]}
                    cta_title="Prendre rendez-vous"
                    cta_click={() =>
                        window.open(
                            "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1PdD2R1AzKPLm4umVBHNGTedO_rm33raQXOvLgbRpk4MSFi5IFf1CplBmVsGTTEf17V6QRdYzB"
                        )
                    }
                />
            </div>
        </div>
    );
};

export default TrainingSettings;
