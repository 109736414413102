import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import IToDo from "../../../interfaces/forms/IToDo";
import { request } from "../../../utils/axios-utils";

const ListTodos: React.FC<IToDo> = (props) => {
    const [todos, setTodos] = useState<Array<IToDo>>(null);

    const { addToast } = useToasts();

    useEffect(() => {
        setTodos(props.toDos);
    }, [props.toDos]);

    /*
     * Mark or Unmark a ToDo.
     */
    const updateDone = (todoId: string) => {
        request({
            addToast,
            url: `/api/toDos/${todoId}/edit/${props.user_id}`,
            method: "post",
            data: {},
        }).then((data) => props.onChange()).catch();
    };

    /*
     * Delete a ToDo.
     */
    const remove = (todoId: string) => {
        request({
            addToast,
            url: `/api/toDos/${todoId}/delete/${props.user_id}`,
            method: "post",
            data: {},
        }).then((data) => {
            addToast(`La tâche a bien été supprimée`, {
                appearance: "success",
            });
            props.onChange();
        }).catch();
    };

    return (
        <div className="row wrap list_todos layout-center-start">
            {(todos && todos.length) > 0 &&
                todos.map((t, index) => (
                    <div
                        className="todo_item row layout-start-center"
                        key={index}
                    >
                        <input
                            className="toggle_button"
                            type="checkbox"
                            onChange={() => updateDone(t._id)}
                            checked={t.done}
                        />
                        <div className={"title " + (t.done ? "completed" : "")}>
                            {t.label}
                        </div>
                        <i
                            className="material-icons remove_button"
                            onClick={() => remove(t._id)}
                        >
                            clear
                        </i>
                    </div>
                ))}
        </div>
    );
};

export default ListTodos;
