import React, { useState } from "react";
import { Link } from "react-router-dom";
import { capitalizeFirst, returnOnlyNumbers } from "../../../constants/Utils";
import IFormAdvisor from "../../../interfaces/forms/IFormAdvisor";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";

const InnerAdvisorForm: React.FC<IFormAdvisor> = (props) => {
    const [advisor, setAdvisor] = useState<IFormAdvisor>(props);

    const setValue = (field: string, value: string) => {
        const newAdvisor = { ...advisor, [field]: value };
        setAdvisor(newAdvisor);
        props.onChange(newAdvisor);
    };

    return (
        <div className="column full-width">
            <div className="layout-start-center row full-width">
                {props.isDeletable && (
                    <div className="remove-button-container">
                        <div className="remove-button layout-center-center row pointer">
                            <Link
                                to={"#"}
                                onClick={() => props.removeAdvisor(advisor)}
                            >
                                <i className="material-icons">delete</i>
                            </Link>
                        </div>
                    </div>
                )}

                <div className="column full-width">
                    <div className="row layout-xs-column">
                        <InputComponent
                            type="text"
                            id={`${props.id}-type`}
                            className="flex-1"
                            label="Type"
                            required
                            value={advisor.type || ""}
                            onChange={(e) =>
                                setValue(
                                    "type",
                                    capitalizeFirst(e.target.value.trim())
                                )
                            }
                            maxLength={60}
                        />

                        <div className="flex005 hide-xs" />

                        <Select
                            className="flex-1"
                            id={`${props.id}-civility`}
                            label="Civilité"
                            required
                            value={advisor.civility}
                            options={[
                                { value: "Madame", label: "Madame" },
                                { value: "Monsieur", label: "Monsieur" },
                            ]}
                            needDefaultValue={true}
                            onChange={(e) =>
                                setValue("civility", e.target.value)
                            }
                        />

                        <div className="flex005 hide-xs" />

                        <InputComponent
                            type="text"
                            id={`${props.id}-first_name`}
                            className="flex-1"
                            label="Prénom"
                            required
                            value={advisor.first_name || ""}
                            onChange={(e) =>
                                setValue(
                                    "first_name",
                                    capitalizeFirst(e.target.value.trim())
                                )
                            }
                            maxLength={60}
                        />

                        <div className="flex005 hide-xs" />

                        <InputComponent
                            type="text"
                            id={`${props.id}-last_name`}
                            className="flex-1"
                            label="Nom"
                            required
                            value={advisor.last_name || ""}
                            onChange={(e) =>
                                setValue(
                                    "last_name",
                                    capitalizeFirst(e.target.value.trim())
                                )
                            }
                            maxLength={60}
                        />
                    </div>

                    <div className="row layout-xs-column">
                        <InputComponent
                            type="email"
                            id={`${props.id}-email`}
                            className="flex-1"
                            label="Email"
                            value={advisor.email || ""}
                            onChange={(e) => setValue("email", e.target.value)}
                            maxLength={25}
                        />

                        <div className="flex005 hide-xs" />

                        <InputComponent
                            type="text"
                            id={`${props.id}-phone`}
                            className="flex-1"
                            label="Téléphone"
                            value={advisor.phone || ""}
                            onChange={(e) =>
                                setValue(
                                    "phone",
                                    returnOnlyNumbers(e.target.value)
                                )
                            }
                            maxLength={15}
                        />

                        <div className="flex005 hide-xs" />

                        <InputComponent
                            type="text"
                            id={`${props.id}-fax`}
                            className="flex-1"
                            label="Fax"
                            value={advisor.fax || ""}
                            onChange={(e) =>
                                setValue(
                                    "fax",
                                    returnOnlyNumbers(e.target.value)
                                )
                            }
                            maxLength={15}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InnerAdvisorForm;
