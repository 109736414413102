import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ICourt from "../../../interfaces/ICourt";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import ListElement from "../../layout/lists/ListElement";

const Courts: React.FC = () => {
    const [userCourts, setUserCourts] = useState<Array<ICourt> | null>(null);
    const { addToast } = useToasts();

    useEffect(() => {
        if (!userCourts) {
            request({ addToast, url: `/api/courts` }).then(({ data }) =>
                setUserCourts(data.courts)
            ).catch();
        }
    }, [userCourts]);

    return (
        <>
            <SubBar title="Vos Tribunaux">
                <Link to="/tribunaux/nouveau">
                    <Icon name="add" />
                </Link>
            </SubBar>

            <div className="page_content">
                {!userCourts && <Loader />}
                {userCourts && userCourts.length == 0 && (
                    <div className="layout-center-center row div-no-items">
                        Pour ajouter un tribunal cliquez sur le bouton (+) en
                        haut à droite.
                    </div>
                )}
                {userCourts &&
                    userCourts.length > 0 &&
                    userCourts.map((court: ICourt) => (
                        <div className="item_three_lines_link" key={court._id}>
                            <ListElement>
                                <Link
                                    to={`/tribunaux/${court._id}/show`}
                                    className="layout-center-start column"
                                >
                                    <h3>
                                        Tribunal {court.tribunal_type || ""} de{" "}
                                        {court.label}
                                    </h3>
                                    <h5>
                                        {court.address} {court.zip_code}{" "}
                                        {court.city}
                                    </h5>
                                </Link>
                            </ListElement>
                        </div>
                    ))}
            </div>
        </>
    );
};

export default Courts;
