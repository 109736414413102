import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getAddress, phoneAndMailBank } from "../../../constants/Utils";
import IBank from "../../../interfaces/IBank";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import SearchBar from "../../layout/forms/SearchBar";
import ListElement from "../../layout/lists/ListElement";

const Banks: React.FC = () => {
    const [userBanks, setUserBanks] = useState<Array<IBank> | null>(null);
    const [userBanksSearched, setUserBanksSearched] =
        useState<Array<IBank> | null>(null);
    const [searchValue, setSearchValue] = useState<string>("");
    const { addToast } = useToasts();

    useEffect(() => {
        if (!userBanks) {
            request({ addToast, url: `/api/banks` }).then(({ data }) => {
                setUserBanks(data.banks);
                setUserBanksSearched(data.banks);
            }).catch();
        }
    }, []);

    //Refresh of banks displayed by search input
    const refreshBanks = (search: string) => {
        setSearchValue(search);
        let newUserBanks = userBanks.filter((a) =>
            a.name.toLowerCase().includes(search.toLowerCase())
        );
        setUserBanksSearched(newUserBanks);
    };

    return (
        <>
            <SubBar title="Les banques">
                <Link to={`/banques/nouvelle`}>
                    <Icon name="add" />
                </Link>
            </SubBar>

            <SearchBar
                id="searchBar"
                type="text"
                needClassName={true}
                placeholder="Recherche..."
                value={searchValue}
                onChange={(e: any) => refreshBanks(e.target.value)}
                onClean={() => {
                    setSearchValue("");
                    refreshBanks("");
                }}
                cleanIconClass="clean_icon_fullwidth"
                maxLength={50}
            />

            <div className="list">
                {!userBanksSearched && <Loader />}
                {userBanksSearched && userBanksSearched.length == 0 && (
                    <div className="layout-center-center row div-no-items">
                        Pour ajouter une banque, cliquez sur le bouton (+) en
                        haut à droite.
                    </div>
                )}
                {userBanksSearched &&
                    userBanksSearched.length > 0 &&
                    userBanksSearched.map((bank: IBank) => {
                        return (
                            <div key={bank._id}>
                                <div className="titleLetter sticky top-80">
                                    {bank.name.toUpperCase()}
                                </div>
                                <div className="item_three_lines_link">
                                    <ListElement nodivider="true">
                                        <div className="container">
                                            <Link
                                                to={`/banques/${bank._id}/show`}
                                                className="layout-center-start column"
                                            >
                                                <h3>
                                                    {bank.name.toUpperCase() +
                                                        " " +
                                                        bank.agency}
                                                </h3>
                                                <h5>
                                                    {getAddress(
                                                        bank.address,
                                                        bank.zip_code,
                                                        bank.city
                                                    )}
                                                </h5>
                                                <h5>
                                                    {phoneAndMailBank(bank)}
                                                </h5>
                                            </Link>
                                        </div>
                                    </ListElement>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default Banks;
