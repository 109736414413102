import React from "react";

const ItemTwoLines: React.FC  <{title: string; content: string; flex?: string; nodivider?:boolean; dividerXs?:boolean}> = (props) => {

    const isFlex = props.flex ? `flex-${props.flex}` : '';
    const nameOfClass = `column ${isFlex}`;

    return (
        <>
            <div className={nameOfClass}>
                <div className="item_two_line">
                    <h6>{props.title}</h6>
                    <p className="notes_zone">{props.content}</p>
                </div>
                {
                    (!props.nodivider) && <div className="divider" />
                }
                {
                    (props.dividerXs) && <div className="divider hide show-xs" />
                }
            </div>
        </>
    );
};

export default ItemTwoLines;
