import React, { useState } from "react";
import { Button } from "../../../../../_shared/Button";
import DropZoneArea from "../../../Majeurs/Cloud/DropzoneArea";

export const Documents = (props: { onClose: any }) => {
    const { onClose } = props;

    const checkInformation = () => {};
    const _onValidation = () => {
        checkInformation();
        onClose();
    };

    const [acceptedFile, setAcceptedFile] = useState([]);

    const onDropZone = (acceptedFiles: any) => {
        setCNI(acceptedFiles[0]);
        return;
    };

    const [cni, setCNI] = useState(null);

    return (
        <>
            <div className="flex justify-between">
                <div className="w-1/3 p-6">
                    <h3>Carte Nationale d'identité</h3>
                    <div className="mt-6 p-6 bg-slate-200 rounded-md text-center">
                        {cni ? (
                            <img
                                className="mr-2"
                                src={"/imgs/checked.png"}
                                alt="Logo"
                            />
                        ) : (
                            <DropZoneArea
                                onDrop={onDropZone}
                                accept={["application/pdf"]}
                            />
                        )}
                    </div>
                </div>
                <div className="w-1/3 p-6">
                    <h3>Carte Nationale d'identité</h3>
                    <div className="mt-6 p-6 bg-slate-200 rounded-md">
                        <DropZoneArea onDrop={onDropZone} />
                    </div>
                </div>
                <div className="w-1/3 p-6">
                    <h3>Carte Nationale d'identité</h3>
                    <div className="mt-6 p-6 bg-slate-200 rounded-md">
                        <DropZoneArea onDrop={onDropZone} />
                    </div>
                </div>
            </div>

            <div className="flex justify-end">
                <Button name={"Suivant"} action={() => _onValidation()} />
            </div>
        </>
    );
};
