import { request } from "../utils/axios-utils";

const namespace = "/api/majeurMails";

export const EditCourrierMajeur = (
    addToast: any,
    _id: string,
    payload: any
) => {
    return request({
        addToast,
        url: namespace + `/${_id}/edit`,
        method: "post",
        data: payload,
    });
};

export const CreateCourrierMajeur = (addToast: any, payload: any) => {
    return request({
        addToast,
        url: namespace + "/create",
        method: "post",
        data: payload,
    });
};
