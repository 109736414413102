import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import IMail from "../../../../interfaces/IMail";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import { request } from "../../../../utils/axios-utils";
import Loader from "../../../layout/Loader";
import MailItemMajeur from "../../../layout/MailItemMajeur";
import NoItem from "../../../layout/NoItem";
import Help from "../../Documents/Help";

const ListCourriers: React.FC<{
    majeurId: string;
    majeur: IProtectedAdult;
    setShowCourrierModal: Function;
    changeUpdate: Function;
    shouldUpdate: boolean;
}> = (props) => {
    const [groupedMails, setGroupedMails] = useState<Array<any>>();
    const { addToast } = useToasts();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedMailIds, setSelectedMailIds] = useState([]);
    const [searchMailValue, setSearchMailValue] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);

    const refreshCourrier = (search: string) => {
        setIsLoading(true);
        setSearchMailValue(search);
        if (search != "") {
            request({
                addToast,
                url: `/api/majeurMails/${props.majeurId}/${search}`,
            }).then(({ data }) => {
                setGroupedMails(data);
                setIsLoading(false);
            }).catch();
        } else {
            request({
                addToast,
                url: `/api/majeurs/mails/${props.majeurId}`,
            }).then(({ data }) => {
                setGroupedMails(data);
                setIsLoading(false);
            }).catch();
        }
    };

    const downloadSelectedMails = () => {
        request({
            addToast,
            url: `/api/majeurMails/download`,
            method: "post",
            data: { mails_Id: selectedMailIds, selected_docxs: selectedFiles },
        }).then(({ data }) => {
            if (data) {
                document.location.href = data;
            }
        }).catch();
    };

    const handleCheck = (isChecked: boolean, mail: IMail) => {
        const oldList = [...selectedMailIds];
        if (isChecked) {
            oldList.push(mail._id);
            if (mail.file) {
                request({
                    addToast,
                    url: `/api/majeurMails/download/${mail._id}`,
                    method: "post",
                    data: {},
                }).then((data) => {
                    let content = atob(data.data.docBase64);
                    let zip = new PizZip(content);
                    let doc = new Docxtemplater(zip, {
                        linebreaks: true,
                    });

                    let tags = data.data.values;

                    doc.setData(tags);

                    try {
                        doc.render();
                    } catch (error) {
                        console.error(error);
                    }

                    let output = doc.getZip().generate({
                        type: "base64",
                    });

                    setSelectedFiles((prevFiles) => [
                        ...prevFiles,
                        { id: mail._id, file: output },
                    ]);
                }).catch();
            }
        } else {
            const index = oldList.indexOf(mail._id);
            if (index !== -1) {
                oldList.splice(index, 1);
            }
            setSelectedFiles((prevFiles) =>
                prevFiles.filter((f) => f.id !== mail._id)
            );
        }
        setSelectedMailIds(oldList);
    };

    useEffect(() => {
        setIsLoading(true);

        if (!groupedMails) {
            refreshCourrier(searchMailValue);
        }

        if (props.shouldUpdate) {
            refreshCourrier(searchMailValue);
            props.changeUpdate(false);
        }
    }, [props.shouldUpdate]);

    return (
        <div className="list_mails_majeur">
            <div className="subBar_actions sticky top-90 layout-space-between-center row">
                <span className="subBar_actions_title">
                    Liste des courriers
                </span>
                <div className="subBar_actions_item">
                    {!!selectedMailIds.length && (
                        <div
                            style={{ marginLeft: 5, marginRight: 5 }}
                            className="hide-xs action-button"
                            onClick={downloadSelectedMails}
                        >
                            <i className="material-icons">file_download</i>
                        </div>
                    )}
                    <div
                        id={`button_to_open_modal_${"add_mails_modal"}`}
                        className={
                            "layout-center-center row " + "action-button"
                        }
                        onClick={() => {
                            props.setShowCourrierModal(true);
                        }}
                    >
                        <i className="material-icons pointer ">add</i>
                    </div>
                    <Help color="white" url="/pdf/TUTO_Courriers.pdf" />
                </div>
            </div>
            <div className="my-input-search-container column">
                <i className="material-icons search-icon">search</i>
                <input
                    type="text"
                    onChange={(e) => refreshCourrier(e.target.value)}
                    className="my-input-search"
                    placeholder="Recherche..."
                    defaultValue={searchMailValue}
                />
            </div>

            {!groupedMails || (isLoading && <Loader />)}
            {!isLoading && groupedMails && groupedMails.length === 0 && (
                <NoItem text="Il n'y a pas encore de courriers" />
            )}
            {groupedMails &&
                !isLoading &&
                groupedMails.map((group) => (
                    <div key={group.date}>
                        <div className="titleLetter row layout-start-center">
                            <span className="title_month">{group.date}</span>
                        </div>
                        <div className="page_content">
                            {group.mails.map((m: IMail, index: number) => (
                                <MailItemMajeur
                                    key={m._id}
                                    mail={m}
                                    noDivider={group.mails.length - 1 === index}
                                    handleCheck={handleCheck}
                                    isChecked={
                                        selectedMailIds.indexOf(m._id) !== -1
                                    }
                                />
                            ))}
                        </div>
                    </div>
                ))}
        </div>
    );
};
export default ListCourriers;
