import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
    checkPassStrength,
    identicalPassword,
    scorePassword,
} from "../../../constants/Utils";
import { request } from "../../../utils/axios-utils";

const ResetPassword: React.FC = () => {
    const { addToast } = useToasts();
    const { token }: { token: string } = useParams();

    const history = useHistory();

    const [result, setResult] = useState<number>(0);
    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
    const [identicalPasswords, setIdenticalPasswords] = useState<string>("");

    useEffect(() => {
        // Request to verify if reinitialisation token is existing and waiting for an activation
        request({ addToast, url: `/api/user/verifyResetToken/${token}` }).then(
            ({ data }) => {
                if (!data) {
                    addToast(
                        "Votre demande de changement de mot de passe n'est pas ou plus valide. Faites une nouvelle demande via la page de Login",
                        { appearance: "error" }
                    );
                } else {
                    setIsTokenValid(true);
                    addToast(
                        "Requête validée. Veuillez procéder à votre changement de mot de passe.",
                        { appearance: "success" }
                    );
                }
            }
        ).catch();
    }, [token]);

    // Check strength and equality of the two passwords before to send changing request
    const resetPassword = () => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        if (password !== password2) {
            addToast("Attention les deux mots de passe sont différents.", {
                appearance: "warning",
            });
            return;
        }

        if (!checkPassStrength(result)) {
            addToast("Mot de passe trop faible !", { appearance: "warning" });
            return;
        }

        if (isTokenValid && password != "") {
            request({
                addToast,
                url: "/api/password/change",
                method: "post",
                data: {
                    token: token,
                    password: password,
                },
            }).then(() => {
                addToast("Le mot de passe a bien été modifié", {
                    appearance: "success",
                });
                window.setTimeout(() => {
                    history.push("/login");
                }, 2000);
            }).catch();
        }
    };

    return (
        <>
            <div className="reset_password layout-start-center column">
                <img
                    id="logo"
                    src="asset('imgs/logo-accel-gerancia-blanc.svg')"
                    alt="logo"
                />
                <h1 className="welcome_message">
                    Ecrivez votre nouveau mot de passe
                </h1>
                <form
                    onSubmit={() => resetPassword()}
                    className="layout-start-center column"
                >
                    <div className="my-input-container">
                        <label htmlFor="password">
                            Nouveau mot de passe :{" "}
                            <span className="red_asterix">*</span>
                        </label>
                        <input
                            className="my-input"
                            name="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setResult(scorePassword(e.target.value));
                                setIdenticalPasswords(
                                    identicalPassword(e.target.value, password2)
                                );
                            }}
                            type="password"
                            placeholder="Mot de passe..."
                            required
                        />
                        <div className="resultPassword">
                            Niveau de sécurité du mot de passe :{" "}
                            <span
                                className={
                                    result <= 60
                                        ? "red"
                                        : result <= 80
                                        ? "orange"
                                        : "green"
                                }
                            >
                                {result}%
                            </span>
                        </div>
                    </div>
                    <div className="my-input-container">
                        <label htmlFor="password2">
                            Confirmation du mot de passe :{" "}
                            <span className="red_asterix">*</span>
                        </label>
                        <input
                            className="my-input"
                            name="password2"
                            value={password2}
                            onChange={(e) => {
                                setPassword2(e.target.value);
                                setIdenticalPasswords(
                                    identicalPassword(password, e.target.value)
                                );
                            }}
                            type="password"
                            placeholder="Confirmation mot de passe..."
                            required
                        />
                        <div className="resultPassword">
                            <span
                                className={
                                    identicalPasswords ==
                                    "Les deux mots de passe sont identiques."
                                        ? "green"
                                        : "red"
                                }
                            >
                                {identicalPasswords}
                            </span>
                        </div>
                    </div>
                    <div className="forgot layout-end-center row">
                        <p className="small-text">
                            Pour se connecter c&#039;est{" "}
                            <Link to="/login">ici</Link>
                        </p>
                    </div>
                    <button type="submit">Réinitiliser</button>
                </form>
            </div>
        </>
    );
};
export default ResetPassword;
