import React from "react";

const Loader: React.FC<{ noMargin?: boolean }> = (props) => {
    return (
        <div className="loader layout-center-center row">
            <div
                className="sk-cube-grid"
                style={
                    props.noMargin
                        ? { margin: "0px auto" }
                        : { margin: "100px auto" }
                }
            >
                {Array.from(Array(9).keys(), (i) => i + 1).map((i) => (
                    <div
                        key={`sk-cube${i}`}
                        className={`sk-cube sk-cube${i}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default Loader;
