import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import IGroupRepetitions from "../../../interfaces/IGroupRepetitions";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import { request } from "../../../utils/axios-utils";
import ItemRepetition from "../../layout/ItemRepetition";

const ListRepetitionsAccount: React.FC<{
    accountId: string;
    majeurId: string;
    majeur: IProtectedAdult;
}> = (props) => {
    const [groupRepetitions, setGroupRepetitions] =
        useState<Array<IGroupRepetitions>>(null);
    const { addToast } = useToasts();

    useEffect(() => {
        if (!groupRepetitions) {
            getRepetitions();
        }
    }, [groupRepetitions]);

    const getRepetitions = () => {
        request({
            addToast,
            url: `/api/operations/groupRepetitions/${props.accountId}`,
        }).then(({ data }) => {
            setGroupRepetitions(data);
        }).catch();
    };

    return (
        <>
            <div className="subBar_actions layout-space-between-center row">
                <span className="subBar_actions_title">
                    Listes des répétitions
                </span>
            </div>
            {(!groupRepetitions || groupRepetitions.length === 0) && (
                <div className="layout-center-center row div-no-items">
                    Il n'y a pas encore de répétitions !
                </div>
            )}
            {groupRepetitions &&
                groupRepetitions.map((g, index) => (
                    <div key={index}>
                        <div className="titleLetter">{g.category}</div>
                        <div className="page_content">
                            {g.repetitions.map((r, index2) => (
                                <ItemRepetition
                                    key={index2}
                                    repetition={r}
                                    nodivider={
                                        g.repetitions[g.repetitions.length - 1]
                                            ._id == r._id
                                    }
                                    updateRepetitions={() => getRepetitions()}
                                />
                            ))}
                        </div>
                    </div>
                ))}
        </>
    );
};
export default ListRepetitionsAccount;
