import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { assignAdult, getUnclassifiedFile } from "../../../_api/Cloud";
import { getListMajeurs } from "../../../_api/Majeurs";
import {
    activatePowermail,
    editPasswordPowermail,
} from "../../../_api/Powermail";
import { setUserScanner } from "../../../_api/User";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";
import FullBarTitle from "../../layout/FullBarTitle";
import Modal from "../../layout/modals/Modal";
import File from "../../pages/Majeurs/Cloud/File";
import { useAuth } from "../Login/auth.context";

const CloudSettings = () => {
    const [files, setFiles] = useState([]);
    const [S3FolderUser, setS3FolderUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { currentUser, setCurrentUser } = useAuth();
    const [protectedAdult, setProtectedAdult] = useState(null);
    const [currentFile, setCurrentFile] = useState(null);
    const [adultOptions, setAdultOptions] = useState([]);
    const [scanner, setScanner] = useState(currentUser.emails[0].scanner || "");
    const { addToast } = useToasts();
    const [activated, setActivated] = useState<boolean>(false);

    const [user, setUser] = useState<IUser>(null);
    const [password, setPassword] = useState<string>("");

    const [onCreatEmail, setOnCreatEmail] = useState<any>(null);
    useEffect(() => {
        if (!user) {
            request({ addToast, url: `/api/user` })
                .then(({ data }) => {
                    setUser(data);

                    if (data.abonnement) {
                        setActivated(data.abonnement.activated);
                    }
                })
                .catch();
        }
    }, [user, activated]);

    const editPassword = () => {
        if (password) {
            editPasswordPowermail(addToast, password).then((resp) => {
                addToast(resp.data);
            });
        }
    };

    //---------Emails activations---------//
    const emailProtegesActivate = () => {
        if (password) {
            setOnCreatEmail(true);

            // Check if password is ok to create link to Gerancia emails
            activatePowermail(addToast, password)
                .then((res) => {
                    setOnCreatEmail(false);
                    addToast("Les emails pour les protégés sont activés !", {
                        appearance: "success",
                    });
                })
                .catch();
        } else {
            addToast("Il vous faut un mot de passe !", {
                appearance: "warning",
            });
        }
    };
    useEffect(() => {
        if (!S3FolderUser) {
            request({ addToast, url: `/api/user/settings` })
                .then(({ data }) => {
                    setS3FolderUser(data.S3_folder_user);
                    getFilesAndFolders(data.S3_folder_user);
                    getListMajeurs(addToast, currentUser._id)
                        .then(({ data }) => {
                            let res = data.map((majeur) => {
                                return {
                                    value: majeur._id,
                                    label:
                                        majeur.last_name +
                                        " " +
                                        majeur.first_name,
                                };
                            });
                            setAdultOptions(res);
                        })
                        .catch();
                })
                .catch();
        }
    }, []);

    const updateScanner = () => {
        setUserScanner(addToast, scanner)
            .then((resp) => {
                setCurrentUser(resp.data);
            })
            .catch();
    };

    const getFilesAndFolders = (S3FolderUser: string) => {
        const fileName = S3FolderUser + "/UNCLASSIFIED/";
        getUnclassifiedFile(addToast, { key: fileName })
            .then((resp) => {
                setFiles(resp.data);
            })
            .catch();
    };

    const assignProtectedAdult = () => {
        const payload = {
            file_id: currentFile._id,
            majeur_id: protectedAdult,
        };
        assignAdult(addToast, payload).then((resp) => {
            getFilesAndFolders(S3FolderUser);
            setShowModal(false);
        });
    };

    return (
        <>
            {activated && (
                <div className="column">
                    <FullBarTitle
                        sticky="top-90"
                        text="Générer les emails pour les protégés"
                        helpUrl="/pdf/TUTO_POWERMAIL.pdf"
                    />
                    <div className="page_content">
                        {user.profile.isEmailServiceActive && (
                            <div>
                                Vous pouvez maintenant vous rendre sur
                                <a
                                    target="_blank"
                                    href={`${process.env.WEBMAIL_URL}/?_user=${
                                        user.profile.isEmailServiceActive
                                    }${
                                        user.profile.powermail_extension ||
                                        "@accelgerancia-tutelle.fr"
                                    }`}
                                    style={{ color: "blue" }}
                                >
                                    {"  "}
                                    votre boite mail
                                </a>{" "}
                                accelgerancia
                                <br />
                                <br />
                                Votre email :{" "}
                                {user.profile.isEmailServiceActive}
                                {user.profile.powermail_extension ||
                                    "@accelgerancia-tutelle.fr"}
                                et votre mot de passe
                                <br />
                                <br />
                                Les emails de vos protégés sont visibles dans
                                leur paramètres.
                                <br />
                                <br />
                                <div className="flex">
                                    <InputComponent
                                        id="objet"
                                        className="my-input flex-1"
                                        label="Edition de votre mot de passe"
                                        required
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value.trim())
                                        }
                                        maxLength={50}
                                    />
                                    <button
                                        type="button"
                                        className="valid-button"
                                        onClick={() => editPassword()}
                                    >
                                        Modifier
                                    </button>
                                </div>
                            </div>
                        )}
                        {!user.profile.isEmailServiceActive && (
                            <div className="column layout-center-center">
                                <InputComponent
                                    id="objet"
                                    className="my-input flex-1"
                                    label="Mot de passe pour votre boite email."
                                    required
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value.trim())
                                    }
                                    maxLength={50}
                                />
                                {!onCreatEmail && (
                                    <button
                                        type="button"
                                        style={{ alignSelf: "center" }}
                                        className="valid-button"
                                        onClick={() => emailProtegesActivate()}
                                    >
                                        Activer le service des emails
                                    </button>
                                )}
                                {onCreatEmail && <span>Création en cours</span>}
                            </div>
                        )}
                    </div>
                </div>
            )}
            <Modal
                show={showModal}
                onValidate={assignProtectedAdult}
                onClose={() => setShowModal(false)}
                message="Choisir un protégé"
            >
                <Select
                    className="flex-1"
                    id={"protectedAdult"}
                    label="Protégés"
                    needDefaultValue={true}
                    required
                    value={protectedAdult}
                    options={adultOptions}
                    onChange={(e) => setProtectedAdult(e.target.value)}
                />
            </Modal>
            <div className="subBar_actions layout-start-center row">
                <span className="subBar_actions_title">
                    Paramétrage de votre scanner : scanner@accelgerancia.com
                </span>
            </div>
            <div className="" style={{ padding: "10px" }}>
                <InputComponent
                    id="scanner"
                    type="text"
                    className="my-input flex-1"
                    label="Email de votre scanner "
                    required
                    value={scanner}
                    onChange={(e: any) => setScanner(e.target.value)}
                    maxLength={50}
                />
                <button
                    onClick={() => updateScanner()}
                    className="valid-button"
                >
                    Valider
                </button>
                <div className="flex005 hide-xs"></div>
            </div>
            <div className="subBar_actions layout-start-center row">
                <span className="subBar_actions_title">
                    Liste des fichiers non classés
                </span>
            </div>
            <div className="page_content cloud">
                <div className="column filesContainer">
                    {files.map((file) => {
                        return (
                            <File
                                key={file._id}
                                view={"GRID"}
                                OpenToClass={() => {
                                    setShowModal(true);
                                    setCurrentFile(file);
                                }}
                                toClass={true}
                                file={file}
                                fileRemove={() => console.log("")}
                                getFilesAndFolders={() =>
                                    getFilesAndFolders(S3FolderUser)
                                }
                            />
                        );
                    })}
                    {files.length === 0 && scanner && (
                        <div className="alert alert-info">
                            Il n'y a actuellement aucun document à rediriger.
                        </div>
                    )}
                    {files.length === 0 && !scanner && (
                        <div className="alert alert-info">
                            Veuillez paramétrer votre email de scanner pour
                            utiliser cette fonctionnalité.
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default CloudSettings;
