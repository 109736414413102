import axios from "axios";
import { request } from "../utils/axios-utils";

const namespace = "/api/user";

export const getControls = (addToast: any) => {
    return request({
        addToast,
        url: namespace + "/controls",
        method: "get",
    });
};

export const getControl = (addToast: any, controle_id: any) => {
    return request({
        addToast,
        url: namespace + "/control/" + controle_id,
        method: "get",
    });
};

export const createControl = (addToast: any, payload: any) => {
    return request({
        addToast,
        url: namespace + `/control`,
        method: "post",
        data: payload,
    });
};

export const downloadNotices = (type: string, depot_id: any) => {
    return axios.get(
        namespace + "/control/" + depot_id + "/" + type + "/download_notice",
        {
            responseType: "blob",
        }
    );
};
