export const ListSocialsOptions = [
    {"value": "Sécurité Sociale", "label": "Sécurité Sociale"},
    {"value": "Mutuelle", "label": "Mutuelle"},
    {"value": "AAH", "label": "AAH"},
    {"value": "CAF", "label": "CAF"},
    {"value": "APA", "label": "APA"},
    {"value": "Retraite", "label": "Retraite"},
    {"value": "Portage repas", "label": "Portage repas"},
    {"value": "Pension de retraite", "label": "Pension de retraite"},
    {"value": "MDPH", "label": "MDPH"},
    {"value": "CPAM", "label": "CPAM"},
    {"value": "Impots", "label": "Impots"},
    {"value": "RQTH", "label": "RQTH"},
    {"value": "APL", "label": "APL"},
    {"value": "Aide Social", "label": "Aide Social"},
    {"value": "ASH", "label": "ASH"},
    {"value": "Allocation logement", "label": "Allocation logement"},
    {"value": "PCH", "label": "PCH"},
    {"value": "Assurance Maladie", "label": "Assurance Maladie"},
    {"value": "ALD", "label": "ALD"},
    {"value": "ESAT", "label": "ESAT"},
    {"value": "SAVS", "label": "SAVS"},
    {"value": "CAF", "label": "CAF"},
    {"value": "ALS", "label": "ALS"},
    {"value": "ACS", "label": "ACS"},
    {"value": "Aide Social Hébergement", "label": "Aide Social Hébergement"},
    {"value": "Carte invalidite", "label": "Carte invalidite"},
    {"value": "Complémentaire Santé Solidaire", "label": "Complémentaire Santé Solidaire"},
    {"value": "CARSAT", "label": "CARSAT"},
    {"value": "MSA", "label": "MSA"},
    {"value": "AL", "label": "AL"},
    {"value": "RSA", "label": "RSA"},
    {"value": "SAMSAH", "label": "SAMSAH"},
    {"value": "SS", "label": "SS"},
    {"value": "CSS", "label": "CSS"},
    {"value": "APAE", "label": "APAE"},
    {"value": "Orientation Professionnelle", "label": "Orientation Professionnelle"},
    {"value": "Pension d'invalidité", "label": "Pension d'invalidité"},
    {"value": "Orientation MAS", "label": "Orientation MAS"},
    {"value": "Pole Emploi", "label": "Pole Emploi"},
];
