import classNames from "classnames";
import React from "react";
import ISelect from "../../../interfaces/forms/ISelect";

const Select: React.FC<ISelect> = (props) => {
    return (
        <div
            className={
                props.className === "match-input-size"
                    ? ""
                    : classNames(props.className, "my-input-container")
            }
            style={props.style}
        >
            {props.label && (
                <label htmlFor={props.id}>
                    {props.label} :
                    {props.required && <span className="red_asterix"> *</span>}
                </label>
            )}
            <select
                id={props.id}
                name={props.id}
                onChange={(e) => props.onChange(e)}
                className={"my-input " + props.className}
                style={{ height: 30 }}
                value={props.value || ""}
                {...props.otherOptions}
            >
                {[
                    props.needDefaultValue && (
                        <option
                            value={null}
                            label={null}
                            key={
                                Math.floor(Math.random() * (10000 - 1000)) +
                                1000
                            }
                        ></option>
                    ),
                    ...props.options.map((option: any) => {
                        return (
                            <option
                                value={option.value}
                                label={option.label}
                                key={option.value}
                            >
                                {option.label}
                            </option>
                        );
                    }),
                ]}
            </select>
        </div>
    );
};

export default Select;
