import React from "react";
import { Link } from "react-router-dom";

const ItemTwoLinesLink: React.FC<{
    title: string;
    content: string;
    link: string;
    nodivider?: boolean;
    tags?: Array<any>;
    target?: string;
    majeurId?: string;
}> = (props) => {
    return (
        <div className="item_two_line_link">
            <Link
                to={{
                    pathname: `${props.link}`,
                    state: `single_majeur/${props.majeurId}/alarms`,
                }}
                className="layout-center-start column"
            >
                <h6>{props.title}</h6>
                {props.tags && props.tags.length > 0 && (
                    <div className="tags_container row">
                        {props.tags.map((tag, index) => (
                            <div
                                className="tag"
                                style={{ backgroundColor: tag.color.value }}
                                key={index}
                            >
                                {tag.label}
                            </div>
                        ))}
                    </div>
                )}
                <p>{props.content}</p>
            </Link>
            {!props.nodivider && <div className="divider"></div>}
        </div>
    );
};
export default ItemTwoLinesLink;
