import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./auth.context";

// This component checks if a user is registered in the React context of Authentification
// before allowing him to access the application's protected routes
const ProtectedRoute: React.FC<{ exact: boolean; path: string }> = (props) => {
    const { currentUser } = useAuth();
    return (
        <Route
            exact={props.exact}
            path={props.path}
            render={({ location }) => {
                return currentUser ? (
                    props.children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                );
            }}
        />
    );
};
export default ProtectedRoute;
