import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { request } from "../../../utils/axios-utils";

const VerifyEmail: React.FC = () => {
    const { addToast } = useToasts();
    const { token }: { token: string } = useParams();
    const [isValid, setIsValid] = useState<boolean>(false);

    useEffect(() => {
        // Request to verify if email verification token is existing and waiting for an activation
        request({ addToast, url: `/api/user/verify_token/${token}` }).then(
            ({ data }) => {
                if (!data) {
                    addToast("Votre token de vérification n'est pas valide", {
                        appearance: "error",
                    });
                } else {
                    setIsValid(true);
                    addToast("Adresse email vérifiée !", {
                        appearance: "success",
                    });
                }
            }
        ).catch();
    }, [token]);

    return (
        <>
            <div className="send_email_verification layout-center-center column">
                <div
                    className="messsage_container layout-start-center column"
                    style={{ maxHeight: 300 }}
                >
                    <img
                        className="logo"
                        src="/imgs/logo-accel-gerancia-premium-bleu.jpg"
                        alt="logo"
                    />
                    <div className="message">
                        {isValid && <p>Votre email a bien été confirmé !</p>}
                        {!isValid && <p>Une erreur s&#039;est produite.</p>}
                    </div>
                    <div className="message" style={{ marginTop: 30 }}>
                        Pour vous connecter c&#039;est{" "}
                        <Link to="/login">ICI</Link>
                    </div>
                </div>
            </div>
        </>
    );
};
export default VerifyEmail;
