import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { countries } from "../../../constants/Countries";
import IContact from "../../../interfaces/IContact";
import IMailevaDocument from "../../../interfaces/IMailevaDocument";
import { request } from "../../../utils/axios-utils";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import FormButton from "../../layout/forms/FormButton";
import InputAddress from "../../layout/forms/InputAddress";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";

const CreateUpdateRecipient: React.FC = () => {
    const { letterId, recipientId }: { letterId: string; recipientId: string } =
        useParams();

    const [letterDocuments, setLetterDocuments] =
        useState<Array<IMailevaDocument> | null>(null);

    const [country, setCountry] = useState<string>("FR");
    const [adresse_line_1, setAdresse_line_1] = useState<string>("");
    const [adresse_line_2, setAdresse_line_2] = useState<string>("");
    const [adresse_line_3, setAdresse_line_3] = useState<string>("");
    const [adresse_line_4, setAdresse_line_4] = useState<string>("");
    const [adresse_line_5, setAdresse_line_5] = useState<string>("");
    const [zipCode, setZipCode] = useState<string>(null);
    const [city, setCity] = useState<string>(null);
    const [
        suggestionsAutocompletionForContact,
        setSuggestionsAutocompletionForContact,
    ] = useState<any>([]);
    const [contactAutocompletionValue, setContactAutocompletionValue] =
        useState<string>("");
    const [contactGroups, setContactGroups] = useState<any>(null);

    useEffect(() => {
        if (!letterDocuments) {
            request({ url: `/api/maileva/get-documents/${letterId}` })
                .then(({ data }) => {
                    setLetterDocuments(data.documents);
                })
                .catch(() =>
                    addToast("Impossible de récupérer les documents", {
                        appearance: "error",
                    })
                );
        }

        if (!contactGroups) {
            request({ addToast, url: `/api/contacts/contactGroups` }).then(
                ({ data }) => {
                    setContactGroups(data);
                }
            ).catch();
        }

        if (recipientId) {
            request({
                addToast,
                url: `api/maileva/get-recipient/${recipientId}/${letterId}`,
            }).then(({ data }) => {
                setCountry(data.country_code);
                setAdresse_line_1(data.address_line_1);
                setAdresse_line_2(data.address_line_2);
                setAdresse_line_3(data.address_line_3);
                setAdresse_line_4(data.address_line_4);
                setAdresse_line_5(data.address_line_5);
                setCity(data.address_line_6.split(" ")[1]);
                setZipCode(data.address_line_6.split(" ")[0]);
            }).catch();
        }
    }, [letterDocuments]);

    const history = useHistory();

    const { addToast } = useToasts();

    function saveRecipient() {
        if (!(adresse_line_1 || adresse_line_2) || !zipCode || !city) {
            addToast("Veuillez remplir les champs obligatoires", {
                appearance: "error",
            });
            return;
        }
        let url = `/api/maileva/add-recipient/${letterId}`;
        if (recipientId) {
            url = `/api/maileva/update-recipient/${letterId}/${recipientId}`;
        }
        let data = {
            country_code: country,
            address_line_1: adresse_line_1,
            address_line_2: adresse_line_2,
            address_line_3: adresse_line_3,
            address_line_4: adresse_line_4,
            address_line_5: adresse_line_5,
            address_line_6: zipCode + " " + city,
        };

        request({ url: url, method: "POST", data: data })
            .then(() => {
                if (recipientId) {
                    addToast("Le destinataire a été modifié avec succès.", {
                        appearance: "success",
                    });
                } else {
                    addToast("Le destinataire a été ajouté avec succès.", {
                        appearance: "success",
                    });
                }
                history.push(`/lettres/${letterId}/detail`);
            })
            .catch(() =>
                addToast("Erreur lors de l'ajout du destinataire", {
                    appearance: "error",
                })
            );
    }

    function handleSelection(suggestion: any) {
        setAdresse_line_4(suggestion.name);
        setCity(suggestion.city);
        setZipCode(suggestion.citycode.trim());
    }

    const renderSectionTitle = (section: any) => {
        return (
            <h5 className="section_autosuggest_contacts">
                {section.profession}
            </h5>
        );
    };

    const getSectionSuggestions = (section: any) => {
        return section.contacts;
    };

    const getSuggestionValueContactAutocompletion = (contact: any) => {
        setAdresse_line_1(contact.organisme);
        if (contact.civility && contact.last_name) {
            setAdresse_line_2(
                contact.civility +
                    " " +
                    contact.first_name +
                    " " +
                    contact.last_name
            );
        }
        if (contact.addresses[0]) {
            setAdresse_line_4(contact.addresses[0].address);
            setCity(contact.addresses[0].city);
            setZipCode(contact.addresses[0].citycode.trim());
        }

        if (contact.organisme) {
            return `${contact.organisme}`;
        }

        return `${contact.first_name} ${contact.last_name}`;
    };

    const getSuggestionsContactAutocompletion = (
        value: any,
        groupContactsByGroupName: any
    ) => {
        const escapedValue = value
            ? value.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
            : "";
        if (escapedValue === "") return [];
        const regex = new RegExp(escapedValue, "i");

        if (groupContactsByGroupName) {
            return Object.keys(groupContactsByGroupName)
                .map((groupName: any) => ({
                    profession: groupName,
                    contacts: groupContactsByGroupName[groupName].filter(
                        (contact: IContact) =>
                            contact.organisme
                                ? regex.test(contact.organisme)
                                : regex.test(
                                      contact.first_name +
                                          " " +
                                          contact.last_name
                                  )
                    ),
                }))
                .filter(
                    (filteringGroup: any) => filteringGroup.contacts.length > 0
                );
        }

        return [];
    };

    const renderSuggestionContactsGroupAutocompletion = (suggestion: any) => {
        if (suggestion.organisme) {
            return <span>{suggestion.organisme}</span>;
        }
        return (
            <span>
                {suggestion.first_name} {suggestion.last_name}
            </span>
        );
    };

    const onChangeContactAutocompletionValue = (newValue: any) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        setContactAutocompletionValue(newValue);
    };

    const onBlurBank = () => {};

    return (
        <>
            <SubBar
                title={
                    recipientId
                        ? "Modifier le destinataire"
                        : "Ajouter un destinataire"
                }
                back={"/lettres/" + letterId + "/detail"}
            />
            {recipientId && adresse_line_1 === "" && adresse_line_2 === "" && (
                <Loader />
            )}
            {(!recipientId ||
                (recipientId &&
                    (adresse_line_1 !== "" || adresse_line_2 !== ""))) && (
                <form>
                    <div className="row">
                        <div className="column flex-1">
                            <div
                                className="item_two_line"
                                style={{ paddingBottom: 0 }}
                            >
                                <div>
                                    <div className="my-input-container column">
                                        <Autosuggest
                                            multiSection
                                            renderSectionTitle={(
                                                section: any
                                            ) => renderSectionTitle(section)}
                                            getSectionSuggestions={(
                                                section: any
                                            ) => getSectionSuggestions(section)}
                                            suggestions={
                                                suggestionsAutocompletionForContact
                                            }
                                            onSuggestionsFetchRequested={(
                                                section: any
                                            ) =>
                                                setSuggestionsAutocompletionForContact(
                                                    getSuggestionsContactAutocompletion(
                                                        section.value,
                                                        contactGroups
                                                    )
                                                )
                                            }
                                            onSuggestionsClearRequested={() =>
                                                setSuggestionsAutocompletionForContact(
                                                    []
                                                )
                                            }
                                            getSuggestionValue={(
                                                contact: any
                                            ) =>
                                                getSuggestionValueContactAutocompletion(
                                                    contact
                                                )
                                            }
                                            renderSuggestion={(contact: any) =>
                                                renderSuggestionContactsGroupAutocompletion(
                                                    contact
                                                )
                                            }
                                            inputProps={{
                                                placeholder:
                                                    "Rechercher un contact...",
                                                value: contactAutocompletionValue,
                                                onChange: (
                                                    event: any,
                                                    section: any
                                                ) => {
                                                    onChangeContactAutocompletionValue(
                                                        section.newValue
                                                    );
                                                },
                                                onBlur: () => onBlurBank(),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="column flex-1">
                            <div
                                className="item_two_line"
                                style={{ paddingBottom: 0 }}
                            >
                                <InputAddress
                                    handleSelection={handleSelection}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row layout-xs-column">
                        <div className="column flex-1">
                            <div className="item_two_line">
                                <InputComponent
                                    required={!adresse_line_2}
                                    id={"adresse_line_1" + letterId}
                                    type="text"
                                    className="column-item-flex-1"
                                    label="Société"
                                    value={adresse_line_1}
                                    onChange={(e) =>
                                        setAdresse_line_1(e.target.value)
                                    }
                                />
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column flex-1">
                            <div className="item_two_line">
                                <InputComponent
                                    required={!adresse_line_1}
                                    id={"adresse_line_2" + letterId}
                                    type="text"
                                    className="column-item-flex-1"
                                    label="Civilité, Prénom, Nom"
                                    value={adresse_line_2}
                                    onChange={(e) =>
                                        setAdresse_line_2(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row layout-xs-column">
                        <div className="column flex-3">
                            <div className="item_two_line">
                                <InputComponent
                                    id={"adresse_line_4" + letterId}
                                    type="text"
                                    className="column-item-flex-1"
                                    label="N° et libellé de la voie"
                                    value={adresse_line_4}
                                    onChange={(e) =>
                                        setAdresse_line_4(e.target.value)
                                    }
                                />
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column flex-1">
                            <div className="item_two_line">
                                <InputComponent
                                    id={"adresse_line_3" + letterId}
                                    type="text"
                                    className="column-item-flex-1"
                                    label="Résidence, Bâtiement ..."
                                    value={adresse_line_3}
                                    onChange={(e) =>
                                        setAdresse_line_3(e.target.value)
                                    }
                                />
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column flex-1">
                            <div className="item_two_line">
                                <InputComponent
                                    id={"adresse_line_5" + letterId}
                                    type="text"
                                    className="column-item-flex-1"
                                    label="Lieu dit, BP..."
                                    value={adresse_line_5}
                                    onChange={(e) =>
                                        setAdresse_line_5(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row layout-xs-column">
                        <div className="column flex-1">
                            <div className="item_two_line">
                                <InputComponent
                                    required={true}
                                    id={"zipCode" + letterId}
                                    type="text"
                                    className="column-item-flex-1"
                                    label="Code postal"
                                    value={zipCode}
                                    onChange={(e) =>
                                        setZipCode(e.target.value.trim())
                                    }
                                />
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column flex-1">
                            <div className="item_two_line">
                                <InputComponent
                                    required={true}
                                    id={"city" + letterId}
                                    type="text"
                                    className="column-item-flex-1"
                                    label="Ville"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column flex-1">
                            <div className="item_two_line">
                                <Select
                                    value={country}
                                    label="Pays du destinataire"
                                    onChange={(e) => setCountry(e.target.value)}
                                    required
                                    options={countries}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="recipient-save_button">
                        <FormButton
                            onClick={saveRecipient}
                            text={"Enregistrer"}
                        />
                    </div>
                </form>
            )}
        </>
    );
};

export default CreateUpdateRecipient;
