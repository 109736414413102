import React from 'react';
import SepaRow from '../../layout/lists/SepaRow';

const SepaList: React.FC <{sepas: Array<any>}> = (props) => {

  return (
    <>
      <div>
        <p style={{ marginBottom: '1em' }}>Votre IBAN actuellement enregistrée :</p>
          {props.sepas.map(sepa => <SepaRow
              key={sepa.id}
              last4={sepa.sepa_debit.last4}
              ownerName={sepa.owner.name}
              mandateUrl={sepa.sepa_debit.mandate_url}
        />)}
      </div>
    </>
  );
}

export default SepaList;