import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IContact from "../../interfaces/IContact";

const ItemContact: React.FC<{
    contact: IContact;
    majeurId?: string;
    last: boolean;
}> = (props) => {
    const [isFamilly, setIsFamilly] = useState<boolean>(false);
    const [isUrgent, setIsUrgent] = useState<boolean>(false);

    useEffect(() => {
        if (
            props.majeurId &&
            props.contact &&
            props.contact.urgent &&
            props.contact.familly
        ) {
            if (props.contact.urgent.length > 0) {
                setIsUrgent(props.contact.urgent[0].urgent);
            }
            if (props.contact.familly.length > 0) {
                setIsFamilly(props.contact.familly[0].familly);
            }
        }
    }, [isFamilly, isUrgent]);

    return (
        <div className="item_contact">
            <Link
                to={
                    !props.contact.agency
                        ? "/contacts/" +
                          props.contact._id +
                          "/show" +
                          (props.majeurId ? "/" + props.majeurId : "")
                        : "/banques/" + props.contact._id + "/show"
                }
            >
                {props.contact.organisme && (
                    <span>{props.contact.organisme}</span>
                )}
                {!props.contact.organisme && !props.contact.agency && (
                    <span>
                        {props.contact.last_name +
                            " " +
                            props.contact.first_name}
                    </span>
                )}
                {!props.contact.organisme && props.contact.agency && (
                    <span>
                        {props.contact.name + " " + props.contact.agency}
                    </span>
                )}
                <span className="profession">
                    {props.contact.profession
                        ? "(" + props.contact.profession + ")"
                        : ""}
                </span>
                {props.majeurId &&
                    (isUrgent && isFamilly ? (
                        <span>
                            <span className="urgent">U</span> |
                            <span className="familly"> F </span>
                        </span>
                    ) : isUrgent && !isFamilly ? (
                        <span className="urgent">U </span>
                    ) : !isUrgent && isFamilly ? (
                        <span className="familly">F </span>
                    ) : (
                        ""
                    ))}
            </Link>
            {!props.last && <div className="divider" />}
        </div>
    );
};

export default ItemContact;
