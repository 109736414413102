export const ToolBarAccountItems = [
    {
        _id: 'infos',
        title: 'Infos',
        icon: 'info',
        path: 'infos',
    },
    {
        _id: 'operations',
        title: 'Opérations',
        icon: 'view_headline',
        path: 'operations',
    },
    {
        _id: 'repetitions',
        title: 'Répétitions',
        icon: 'autorenew',
        path: 'repetitions',
    },
    {
        _id: 'previsionnel',
        title: 'Prévisionnel',
        icon: 'trending_up',
        path: 'previsionnel',
    },
];
