import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { momentUpdateLocale } from "../../../../constants/Utils";
import IDebt from "../../../../interfaces/IDebt";
import IImmobilier from "../../../../interfaces/IImmobilier";
import IMobilier from "../../../../interfaces/IMobilier";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import { request } from "../../../../utils/axios-utils";
import ItemThreeLinesLink from "../../../layout/ItemThreeLinesLink";
import Loader from "../../../layout/Loader";
import NoItem from "../../../layout/NoItem";
import InputSwitch from "../../../layout/forms/InputSwitch";

const MobImmoMajeur: React.FC<{ majeurId: string; majeur: IProtectedAdult }> = (
    props
) => {
    momentUpdateLocale();

    const [mobiliers, setMobiliers] = useState<Array<IMobilier> | null>(null);
    const [immobiliers, setImmobiliers] = useState<Array<IImmobilier> | null>(
        null
    );
    const [debts, setDebts] = useState<Array<IDebt> | null>(null);
    const [majeur, setMajeur] = useState<IProtectedAdult>();
    const [isArchived, setIsArchived] = useState(false);
    const { addToast } = useToasts();

    useEffect(() => {
        if (props.majeurId && !majeur) {
            request({ addToast, url: `/api/majeur/${props.majeurId}` })
                .then(({ data }) => {
                    setMajeur(data);
                })
                .catch();
        }

        request({
            addToast,
            url: `/api/majeurs/ressources/${props.majeurId}`,
        })
            .then(({ data }) => {
                let immos = data.immos.filter((immo) => {
                    if (
                        immo.is_archived === undefined &&
                        isArchived === false
                    ) {
                        return true;
                    }
                    return !immo.is_archived === !isArchived;
                });
                let mobs = data.mobs.filter((mob) => {
                    if (mob.is_archived === undefined && isArchived === false) {
                        return true;
                    }
                    return !mob.is_archived === !isArchived;
                });
                let debts = data.debts.filter((debt) => {
                    if (
                        debt.is_archived === undefined &&
                        isArchived === false
                    ) {
                        return true;
                    }
                    return !debt.is_archived === !isArchived;
                });

                setImmobiliers(immos);
                setMobiliers(mobs);
                setDebts(debts);
            })
            .catch();
    }, [isArchived]);

    return (
        <div className="mob_immo_container">
            <div className="subBar_actions layout-space-between-center row flex-1 justify-end">
                <span className="subBar_actions_title">
                    Liste des biens et des dettes
                </span>
                <div>
                    <InputSwitch
                        id="isArchived"
                        className="column-item-flex-1"
                        onChange={() => setIsArchived(!isArchived)}
                        label="Actifs"
                        isSwitchMajeur={true}
                        onText="NON"
                        offText="OUI"
                        value={isArchived}
                    />
                </div>
            </div>
            <div className="page_content">
                {!mobiliers && !immobiliers && !debts && <Loader />}
                {mobiliers && immobiliers && debts && (
                    <>
                        <div className="mob_immo_title row layout-space-between-center">
                            <span>Biens mobiliers</span>
                            {majeur && !majeur.archived && (
                                <Link
                                    to={`/form_mob_immo/${props.majeurId}/new/MOBILIER`}
                                    className="column layout-center-center add-button-href"
                                >
                                    <i className="material-icons">add</i>
                                </Link>
                            )}
                        </div>
                        {mobiliers && mobiliers.length === 0 && (
                            <NoItem text="Vous n'avez pas encore de biens mobiliers." />
                        )}
                        {mobiliers &&
                            mobiliers.length > 0 &&
                            mobiliers.map((m) => {
                                const theDate = m.date_inventaire
                                    ? `Date : ${moment(
                                          m.date_inventaire
                                      ).format("L")}`
                                    : "";
                                const theEstimation = m.estimation
                                    ? "Estimation : " +
                                      (parseFloat(m.estimation)
                                          ? (m.estimation || 0).toLocaleString()
                                          : m.estimation)
                                    : "";
                                return (
                                    <ItemThreeLinesLink
                                        key={m._id}
                                        link={`/single_mobilier/${m._id}`}
                                        title={m.label}
                                        lastcontent={`${theDate} ${theEstimation}`}
                                    />
                                );
                            })}
                        <div className="mob_immo_title immobilier row layout-space-between-center">
                            <span>Biens immobiliers</span>
                            {majeur && !majeur.archived && (
                                <Link
                                    to={`/form_mob_immo/${props.majeurId}/new/IMMOBILIER`}
                                    className="column layout-center-center add-button-href"
                                >
                                    <i className="material-icons">add</i>
                                </Link>
                            )}
                        </div>
                        {immobiliers && immobiliers.length === 0 && (
                            <NoItem text="Vous n'avez pas encore de biens immobiliers." />
                        )}
                        {immobiliers &&
                            immobiliers.length > 0 &&
                            immobiliers.map((m) => {
                                const theDate = m.date_inventaire
                                    ? `Date : ${moment(
                                          m.date_inventaire
                                      ).format("L")}`
                                    : "";
                                const theEstimation = m.estimation
                                    ? "Estimation : " +
                                      (parseFloat(m.estimation)
                                          ? (m.estimation || 0).toLocaleString()
                                          : m.estimation)
                                    : "";
                                return (
                                    <ItemThreeLinesLink
                                        key={m._id}
                                        link={`/single_immobilier/${m._id}`}
                                        title={m.label}
                                        lastcontent={`${theDate} ${theEstimation}`}
                                    />
                                );
                            })}
                        <div className="mob_immo_title immobilier row layout-space-between-center">
                            <span>Dettes</span>
                            {majeur && !majeur.archived && (
                                <Link
                                    to={`/form_debt/${props.majeurId}/new`}
                                    className="column layout-center-center add-button-href"
                                >
                                    <i className="material-icons">add</i>
                                </Link>
                            )}
                        </div>
                        {debts && debts.length === 0 && (
                            <NoItem text="Vous n'avez pas encore de dettes." />
                        )}
                        {debts &&
                            debts.length > 0 &&
                            debts.map((m) => {
                                const theDate = m.date
                                    ? `Date : ${moment(m.date).format("L")}`
                                    : "";
                                const theEstimation = m.amount
                                    ? "Montant : " +
                                      (parseFloat(m.amount)
                                          ? (m.amount || 0).toLocaleString()
                                          : m.amount)
                                    : "";
                                return (
                                    <ItemThreeLinesLink
                                        key={m._id}
                                        link={`/single_debt/${m._id}`}
                                        title={`${m.nature} ${m.label}`}
                                        lastcontent={`${theDate} ${theEstimation}`}
                                    />
                                );
                            })}
                    </>
                )}
            </div>
        </div>
    );
};
export default MobImmoMajeur;
