import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IContract from "../../../../interfaces/IContract";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import ISocial from "../../../../interfaces/ISocial";
import { request } from "../../../../utils/axios-utils";
import ItemThreeLinesLink from "../../../layout/ItemThreeLinesLink";
import Loader from "../../../layout/Loader";
import NoItem from "../../../layout/NoItem";
import InputSwitch from "../../../layout/forms/InputSwitch";

const SocialMajeur: React.FC<{ majeurId: string; majeur: IProtectedAdult }> = (
    props
) => {
    const [socials, setSocials] = useState<Array<ISocial> | null>(null);
    const [contracts, setContracts] = useState<Array<IContract> | null>(null);
    const [isArchived, setIsArchived] = useState(false);

    const addToast = useToasts();

    useEffect(() => {
        request({
            addToast,
            url: `/api/majeurs/socials/${props.majeurId}`,
        })
            .then(({ data }) => {
                let rights = data.rights.filter((right) => {
                    if (
                        right.is_archived === undefined &&
                        isArchived === false
                    ) {
                        return true;
                    }
                    return !right.is_archived === !isArchived;
                });

                let newContracts = data.contracts.filter((contract: any) => {
                    if (
                        contract.is_archived === undefined &&
                        isArchived === false
                    ) {
                        return true;
                    }
                    return !contract.is_archived === !isArchived;
                });
                setSocials(rights);
                setContracts(newContracts);
            })
            .catch();
    }, [isArchived]);

    return (
        <>
            <div className="droit_contract_container">
                <div className="subBar_actions layout-space-between-center row flex-1 justify-end">
                    <span className="subBar_actions_title">
                        Les droits sociaux et les contrats
                    </span>
                    <div>
                        <InputSwitch
                            id="isArchived"
                            className="column-item-flex-1"
                            onChange={() => setIsArchived(!isArchived)}
                            label="Actifs"
                            isSwitchMajeur={true}
                            onText="NON"
                            offText="OUI"
                            value={isArchived}
                        />
                    </div>
                </div>
                <div className="page_content">
                    {!socials && !contracts && <Loader />}
                    {socials && contracts && (
                        <>
                            <div className="droit_contract_title immobilier row layout-space-between-center">
                                <span>Les droits sociaux</span>
                                {!props.majeur.archived && (
                                    <Link
                                        to={`/form_social/${props.majeurId}`}
                                        className="column layout-center-center add-button-href"
                                    >
                                        <i className="material-icons">add</i>
                                    </Link>
                                )}
                            </div>
                            {socials === null && <Loader />}

                            {socials.length === 0 && (
                                <NoItem text="Vous n'avez pas encore ajouté de droits sociaux." />
                            )}
                            {socials.map((d, index) => (
                                <ItemThreeLinesLink
                                    key={d._id}
                                    title={`${d.nature} - ${
                                        d?.contact?.organisme || d.organisme
                                    }`}
                                    link={`/single_social/${d._id}`}
                                    firstcontent={`Numero: ${d.numero}`}
                                    lastcontent={`${
                                        d.start_date
                                            ? "Date de début : " +
                                              moment(d.start_date).format(
                                                  "DD/MM/YYYY"
                                              )
                                            : ""
                                    }  ${
                                        d.end_date
                                            ? " - Date de fin : " +
                                              moment(d.end_date).format(
                                                  "DD/MM/YYYY"
                                              )
                                            : ""
                                    }`}
                                    nodivider={socials.length - 1 === index}
                                />
                            ))}
                            <div className="droit_contract_title contract row layout-space-between-center">
                                <span>Les contrats</span>
                                {!props.majeur.archived && (
                                    <Link
                                        to={`/form_contract/${props.majeurId}/new`}
                                        className="column layout-center-center add-button-href"
                                    >
                                        <i className="material-icons">add</i>
                                    </Link>
                                )}
                            </div>
                            {contracts === null && <Loader />}
                            {contracts.length === 0 && (
                                <NoItem text="Vous n'avez pas encore ajouté de contrats ou prestations." />
                            )}
                            {contracts.map((d, index) => (
                                <ItemThreeLinesLink
                                    key={d._id}
                                    title={`${d.nature} - ${
                                        d?.contact?.organisme || d.organisme
                                    }`}
                                    link={`/single_contract/${d._id}`}
                                    firstcontent={`Numero: ${d.numero}`}
                                    lastcontent={`${
                                        d.start_date
                                            ? "Date de début : " +
                                              moment(d.start_date).format(
                                                  "DD/MM/YYYY"
                                              )
                                            : ""
                                    }  ${
                                        d.end_date
                                            ? " - Date de fin : " +
                                              moment(d.end_date).format(
                                                  "DD/MM/YYYY"
                                              )
                                            : ""
                                    }`}
                                    nodivider={contracts.length - 1 === index}
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
export default SocialMajeur;
