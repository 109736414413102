import React from "react";

export const PartnerCard = (props: {
    title: string;
    src?: string;
    description: string;
    tags: Array;
    cta_title: string;
    icon?: string;
    cta_click?: any;
}) => {
    const { title, description, tags, src, cta_title, icon, cta_click } = props;
    const className = icon
        ? "w-full text-center"
        : "w-full text-center min-h-52";
    return (
        <div className="max-w-sm rounded overflow-hidden shadow-lg m-3">
            <div className={className}>
                {src && (
                    <img
                        className="inline text-center"
                        height={200}
                        width={200}
                        src={src}
                        alt={title}
                    />
                )}
                {icon && <i className="material-icons">{icon}</i>}
            </div>
            <div className="px-6 py-4">
                <div className="font-bold text-lg mb-2">{title}</div>
                <p className="text-gray-700 text-sm">{description}</p>
            </div>
            <div className="px-6 pt-4 pb-2">
                {tags.map((tag: string) => {
                    return (
                        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                            #{tag}
                        </span>
                    );
                })}
            </div>
            <div className="flex justify-end">
                <button
                    className="m-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                    onClick={() => cta_click()}
                >
                    {cta_title}
                </button>
            </div>
        </div>
    );
};
