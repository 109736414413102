import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import IAccount from "../../interfaces/IAccount";
import Divider from "../layout/Divider";
import {momentUpdateLocale} from "../../constants/Utils";

const ItemAccount: React.FC<{
    key: string;
    account: IAccount;
    divider: boolean;
}> = (props) => {
   momentUpdateLocale()
    return (
        <>
            <div className="item_account column">
                <div className="row layout-space-between-center item_three_lines_link">
                    <Link
                        to={`/single_account/${props.account._id}`}
                        className="layout-center-start column flex-1"
                    >
                        <h3 style={{ width: "100%" }}>
                            {`${props.account.bank_label} : N° ${props.account.number}`}
                        </h3>
                        <h5>{props.account.type} {props.account.additional_information || null}</h5>
                        {props.account.closed && (
                            <h5 style={{ color: "#da1818" }}>
                                Clôturé le{" "}
                                {moment(props.account.date_closed).format("L")}
                            </h5>
                        )}
                        {!props.account.closed && (
                            <h5
                                className={
                                    props.account.balance < 0 ? "red" : ""
                                }
                            >
                                Solde:{" "}
                                {(props.account.balance.toFixed(2) || 0).toLocaleString()}{" "}
                                €
                            </h5>
                        )}
                    </Link>

                    {!props.account.sync_type && (
                        <span className="ebics-image" />
                    )}

                    {props.account.sync_type && (
                        <img
                            className={props.account.sync_type + "-image"}
                            src={`/imgs/${props.account.sync_type}_logo.png`}
                            alt="extension"
                        />
                    )}

                    {props.account.principal && (
                        <i
                            className="material-icons"
                            style={{ color: "#f3d907" }}
                        >
                            stars
                        </i>
                    )}

                    {props.account.closed && (
                        <i
                            className="material-icons"
                            style={{ color: "#da1818" }}
                        >
                            https
                        </i>
                    )}
                </div>
                {props.divider && <Divider />}
            </div>
        </>
    );
};
export default ItemAccount;
