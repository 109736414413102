import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { uploadLinkDoc } from "../../../../_api/S3";
import { capitalizeFirst, stringToFloat } from "../../../../constants/Utils";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import { request } from "../../../../utils/axios-utils";
import ArrowBack from "../../../layout/ArrowBack";
import InputComponent from "../../../layout/forms/InputComponent";
import InputDatePicker from "../../../layout/forms/InputDatePicker";
import InputSwitch from "../../../layout/forms/InputSwitch";
import Textarea from "../../../layout/forms/Textarea";
import IFile from "../Cloud/IFile";
import UploadDocument from "../UploadDocument";

const FormImmobilier: React.FC<{}> = (props) => {
    const { mobImmoId }: { mobImmoId?: string } = useParams();
    const { type }: { type?: string } = useParams();

    const { majeurId }: { majeurId?: string } = useParams();
    const [majeur, setMajeur] = useState<IProtectedAdult>();

    const [nature, setNature] = useState("");
    const [address, setAddress] = useState<string>("");
    const [zipCode, setZipCode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [date, setDate] = useState<Date>();
    const [notes, setNotes] = useState<string>("");
    const [estimation, setEstimation] = useState<string>(null);

    const [isArchived, setIsArchived] = useState(false);

    const [filesSelected, setFilesSelected] = useState(null);
    const [files, setFiles] = useState<Array<string>>([]);
    const [fileList, setFileList] = useState<Array<IFile>>(null);
    const { addToast } = useToasts();
    const history = useHistory();

    const title =
        majeur && window.screen.width > 600
            ? `${majeur.first_name} ${majeur.last_name} | `
            : "";

    const checkform = () => {
        if (nature === "" || estimation === "") {
            addToast("Veuillez remplir les champs obligatoires.", {
                appearance: "warning",
            });
            return false;
        }
        return true;
    };

    const createImmobilierOrMobilier = (newFiles: any) => {
        if (type === "IMMOBILIER") {
            const immovable = {
                date_inventaire: date || null,
                label: capitalizeFirst(nature.trim()),
                address: address,
                zip_code: zipCode,
                city: capitalizeFirst(city.trim()),
                notes: notes || "",
                estimation: estimation,
                files: newFiles,
                is_archived: isArchived,
            };

            const route = !mobImmoId
                ? `/api/immovables/create/${majeurId}`
                : `/api/immovables/${mobImmoId}/edit`;
            request({
                addToast,
                url: route,
                method: "post",
                data: { immovable: immovable },
            })
                .then(({ data }) => {
                    mobImmoId
                        ? history.push(`/single_immobilier/${mobImmoId}`)
                        : history.push(`/single_majeur/${majeurId}/mobImmo`);
                    addToast(
                        `Le bien immobilier a bien été ${
                            mobImmoId ? "modifié" : "créé"
                        }`,
                        {
                            appearance: "success",
                        }
                    );
                })
                .catch();
        } else if (type === "MOBILIER") {
            const furniture = {
                date_inventaire: date || null,
                label: capitalizeFirst(nature.trim()),
                notes: notes || "",
                estimation: estimation,
                files: newFiles,
                is_archived: isArchived,
            };

            const route = !mobImmoId
                ? `/api/furnitures/create/${majeurId}`
                : `/api/furnitures/${mobImmoId}/edit`;
            request({
                addToast,
                url: route,
                method: "post",
                data: { furniture: furniture },
            })
                .then(({ data }) => {
                    mobImmoId
                        ? history.push(`/single_mobilier/${mobImmoId}`)
                        : history.push(`/single_majeur/${majeurId}/mobImmo`);
                    addToast(
                        `Le bien mobilier a bien été ${
                            mobImmoId ? "modifié" : "créé"
                        }`,
                        {
                            appearance: "success",
                        }
                    );
                })
                .catch();
        }
    };
    const formMobilier = (e: any) => {
        e.preventDefault();

        if (checkform()) {
            if (!filesSelected) {
                createImmobilierOrMobilier(files);
                return;
            }
        }
        const formData = new FormData();
        for (let i = 0; i < filesSelected.length; i++) {
            formData.append("documents[]", filesSelected[i]);
        }
        formData.append("majeur_id", majeurId);
        formData.append("to", "Biens");

        uploadLinkDoc(addToast, formData)
            .then(({ data }) => {
                const newFiles = [...files.concat(data.data.filesIds)];
                setFiles(newFiles);
                createImmobilierOrMobilier(newFiles);
            })
            .catch();
    };

    useEffect(() => {
        if (!majeur) {
            request({ addToast, url: `/api/majeur/${majeurId}` })
                .then(({ data }) => {
                    setMajeur(data);
                })
                .catch();
        }

        if (mobImmoId && type === "IMMOBILIER") {
            request({ addToast, url: `/api/immovables/${mobImmoId}` })
                .then(({ data }) => {
                    setNature(data.label);
                    setAddress(data.address);
                    setZipCode(data.zip_code);
                    setEstimation(data.estimation);
                    setDate(
                        data?.date_inventaire
                            ? new Date(
                                  data?.date_inventaire?.$date
                                      ? parseInt(
                                            data.date_inventaire?.$date
                                                .$numberLong
                                        )
                                      : data?.date_inventaire
                              )
                            : null
                    );
                    setNotes(data.notes);
                    setCity(data.city);
                    setFiles(data.files ?? []);
                    setIsArchived(data.is_archived || false);

                    if (data.files) {
                        request({
                            url: "/api/files/list/from-ids",
                            method: "POST",
                            data: { filesIds: data.files },
                        })
                            .then(({ data }) => {
                                setFileList(data);
                            })
                            .catch();
                    }
                })
                .catch();
        }

        if (mobImmoId && type === "MOBILIER") {
            request({ addToast, url: `/api/furnitures/${mobImmoId}` })
                .then(({ data }) => {
                    setNature(data.label);
                    setEstimation(data.estimation);
                    setDate(
                        data?.date_inventaire
                            ? new Date(
                                  data?.date_inventaire?.$date
                                      ? parseInt(
                                            data?.date_inventaire.$date
                                                .$numberLong
                                        )
                                      : data?.date_inventaire
                              )
                            : null
                    );
                    setNotes(data.notes);
                    setFiles(data.files ?? []);
                    setIsArchived(data.is_archived || false);
                    if (data.files) {
                        request({
                            url: "/api/files/list/from-ids",
                            method: "POST",
                            data: { filesIds: data.files },
                        })
                            .then(({ data }) => {
                                setFileList(data);
                            })
                            .catch();
                    }
                })
                .catch();
        }
    }, []);

    return (
        <div>
            <ArrowBack
                title={`${title} ${
                    mobImmoId === undefined ? "Ajouter" : "Modifier"
                } un bien ${type === "MOBILIER" ? "mobilier" : "immobilier"}`}
            />
            <div className="page_content">
                <form className="column" onSubmit={formMobilier}>
                    <InputComponent
                        id="nature"
                        className="flex-1"
                        label="Nature"
                        required
                        value={nature}
                        onChange={(e) => {
                            setNature(e.target.value);
                        }}
                    />
                    {type === "IMMOBILIER" && (
                        <div className="column flex-1">
                            <Textarea
                                id="address"
                                required
                                label="Adresse"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                            <div className="row layout-xs-column">
                                <InputComponent
                                    id="city"
                                    className="flex-1"
                                    label="Code postal"
                                    required
                                    value={zipCode}
                                    onChange={(e) => setZipCode(e.target.value)}
                                />
                                <div className="flex005 hide-xs" />
                                <InputComponent
                                    id="city"
                                    className="flex-1"
                                    label="Ville"
                                    required
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </div>
                        </div>
                    )}
                    <div className="row layout-xs-column">
                        <InputDatePicker
                            id="start_date"
                            label="Date"
                            inputDate={date}
                            onChange={(theDate: Date) => setDate(theDate)}
                        />
                        <div className="flex005 hide-xs" />
                        <InputComponent
                            id="number"
                            className="flex-1"
                            label="Estimation"
                            needBlur={true}
                            onBlur={() => {
                                setEstimation(
                                    String(parseFloat(estimation)) || "0"
                                );
                            }}
                            required
                            value={estimation}
                            onChange={(e) => {
                                setEstimation(stringToFloat(e.target.value));
                            }}
                        />
                    </div>
                    <Textarea
                        id="notes"
                        label="Notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    />
                    <InputSwitch
                        id="isArchived"
                        onChange={() => setIsArchived(!isArchived)}
                        label="Actifs"
                        isSwitchMajeur={true}
                        onText="NON"
                        offText="OUI"
                        value={isArchived}
                    />

                    <div className="divider" style={{ marginBottom: "20px" }} />

                    <UploadDocument
                        isMultiple={true}
                        filesSelected={filesSelected}
                        fileList={fileList}
                        setFilesSelected={setFilesSelected}
                        files={files}
                        setFiles={setFiles}
                        setFileList={setFileList}
                    />
                    <button
                        type="submit"
                        className="valid-button"
                        style={{ marginTop: 20 }}
                    >
                        {mobImmoId === "new" ? "Ajouter" : "Valider"}
                    </button>
                </form>
            </div>
        </div>
    );
};
export default FormImmobilier;
