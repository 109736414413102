import _ from "lodash";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
    checkPassStrength,
    CIVILITY_OPTIONS,
    identicalPassword,
    scorePassword,
    TYPE_USERS,
} from "../../../constants/Utils";
import { request } from "../../../utils/axios-utils";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";

const Register: React.FC = () => {
    const { addToast } = useToasts();

    const [typeSelected, setTypeSelected] = useState<any>();
    const [association, setAssociation] = useState<any>();
    const [civilitySelected, setCivilitySelected] = useState<any>();
    const [result, setResult] = useState<number>(0);
    const [label_association, setLabelAssociation] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [identicalPasswords, setIdenticalPasswords] = useState<string>("");

    // Check the register informations before to send the request
    const submitRegisterForm = () => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        // Test phone format
        const phoneValue = phone.replace(/[\.\s,:-_]/g, "");
        var numbers = /^[0-9]+$/;
        if (phoneValue.length !== 10 || !phone.match(numbers)) {
            addToast(
                "Attention au numéro de téléphone format : 00 00 00 00 00.",
                { appearance: "warning" }
            );
            return;
        }

        // Test mail address if it is already existing
        request({ addToast, url: `/api/user/${email}` }).then((data) => {
            if (data.data == true) {
                addToast("Adresse email déjà utilisée", {
                    appearance: "warning",
                });
            } else {
                // Check if the two passwords are differents
                if (password !== password2) {
                    addToast(
                        "Attention les deux mots de passe sont différents.",
                        { appearance: "warning" }
                    );
                    return;
                } else {
                    // Check password strength to finally send request
                    if (checkPassStrength(result)) {
                        const credentials = {
                            type: typeSelected,
                            label_association: label_association
                                ? label_association.trim()
                                : undefined,
                            civility: civilitySelected,
                            first_name: firstName.trim(),
                            last_name: lastName.trim(),
                            phone: phone,
                            email: email.trim().toLowerCase(),
                            password: password.trim(),
                            password2: password2.trim(),
                        };
                        submitRegistration(credentials);
                    } else {
                        addToast("Mot de passe trop faible !", {
                            appearance: "warning",
                        });
                    }
                }
            }
        }).catch();
    };

    // Request to send new account data
    const submitRegistration = (credentials: Object) => {
        request({
            addToast,
            url: "/api/register",
            method: "post",
            data: { credentials: credentials },
        }).then((data) => {
            addToast(
                "Le compte a bien été créé." /*Validez votre email grâce au lien qui vous a été envoyé pour pouvoir commencer à utiliser Accel Gerancia.",*/,
                { appearance: "success" }
            );
        }).catch();
    };

    return (
        <>
            <div className="register">
                <div className="header-container layout-center-center column">
                    <img
                        className="register_logo"
                        src="/imgs/logo-accel-gerancia-premium-bleu.jpg"
                        alt="logo-premium"
                    />
                    <h1>Inscrivez-vous gratuitement</h1>
                    <p className="small-text">
                        Pas d'engagement. 30 jours gratuits.
                    </p>
                </div>
                <div className="form-container layout-start-center column">
                    <form
                        onSubmit={() => submitRegisterForm()}
                        className="column"
                    >
                        <div className="my-input-container">
                            <label>
                                Nature : <span className="red_asterix">*</span>
                            </label>
                            <Select
                                value={typeSelected}
                                options={TYPE_USERS}
                                needDefaultValue={true}
                                required
                                onChange={(e) => {
                                    if (e.target.value) {
                                        var association = _.filter(TYPE_USERS, {
                                            value: e.target.value,
                                        });
                                        setTypeSelected(e.target.value);
                                        setAssociation(association[0].label);
                                    } else {
                                        setTypeSelected("");
                                    }
                                }}
                            />
                        </div>
                        {(typeSelected === "_ASSOCIATION_" ||
                            typeSelected === "_ETABLISSEMENT_") && (
                            <div className="my-input-container">
                                <InputComponent
                                    id="label"
                                    type="text"
                                    label={"Nom de l'" + association}
                                    required
                                    value={label_association}
                                    onChange={(e) =>
                                        setLabelAssociation(e.target.value)
                                    }
                                    maxLength={50}
                                />
                            </div>
                        )}
                        <div className="my-input-container">
                            <label>
                                Civilité :{" "}
                                <span className="red_asterix">*</span>
                            </label>
                            <Select
                                value={civilitySelected}
                                options={CIVILITY_OPTIONS}
                                needDefaultValue={true}
                                required
                                onChange={(e) =>
                                    setCivilitySelected(e.target.value)
                                }
                            />
                        </div>
                        <div className="my-input-container">
                            <label htmlFor="prenom">
                                Prénom : <span className="red_asterix">*</span>
                            </label>
                            <input
                                className="my-input"
                                name="prenom"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                type="text"
                                placeholder="Prénom..."
                                required
                            />
                        </div>
                        <div className="my-input-container">
                            <label htmlFor="nom">
                                Nom : <span className="red_asterix">*</span>
                            </label>
                            <input
                                className="my-input"
                                name="nom"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Nom..."
                                type="text"
                                required
                            />
                        </div>
                        <div className="my-input-container">
                            <InputComponent
                                id="label"
                                type="text"
                                label="Téléphone"
                                required
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                minLength={10}
                                maxLength={15}
                            />
                        </div>
                        <div className="my-input-container">
                            <label htmlFor="email">
                                Email : <span className="red_asterix">*</span>
                            </label>
                            <input
                                className="my-input"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                placeholder="Email..."
                                required
                            />
                        </div>
                        <div className="my-input-container">
                            <InputComponent
                                id="password_login"
                                type="password"
                                label="Mot de passe"
                                required
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setResult(scorePassword(e.target.value));
                                    setIdenticalPasswords(
                                        identicalPassword(
                                            e.target.value,
                                            password2
                                        )
                                    );
                                }}
                                maxLength={50}
                            />
                            <div className="resultPassword">
                                Niveau de sécurité du mot de passe :{" "}
                                <span
                                    className={
                                        result <= 60
                                            ? "red"
                                            : result <= 80
                                            ? "orange"
                                            : "green"
                                    }
                                >
                                    {result}%
                                </span>
                            </div>
                        </div>
                        <div className="my-input-container">
                            <InputComponent
                                id="password2"
                                type="password"
                                label="Confirmation du mot de passe"
                                required
                                value={password2}
                                onChange={(e) => {
                                    setPassword2(e.target.value);
                                    setIdenticalPasswords(
                                        identicalPassword(
                                            password,
                                            e.target.value
                                        )
                                    );
                                }}
                                maxLength={50}
                            />
                            <div className="resultPassword">
                                <span
                                    className={
                                        identicalPasswords ==
                                        "Les deux mots de passe sont identiques."
                                            ? "green"
                                            : "red"
                                    }
                                >
                                    {identicalPasswords}
                                </span>
                            </div>
                        </div>
                        <button type="submit">Créer un compte</button>
                        <div className="law-text layout-center-center">
                            <p>
                                En cliquant sur "Créer un compte", j'accepte les{" "}
                                <Link
                                    to="/pdf/CGU_Accel_Gerancia_Premium.pdf"
                                    target="_blank"
                                >
                                    Conditions d'utilisation
                                </Link>{" "}
                                d'Accel Gerancia.
                            </p>
                        </div>
                    </form>
                    <div className="layout-center-center row already-signin-container">
                        <Link to="/login" className="already-signin">
                            Vous avez déjà un compte ? identifiez vous
                        </Link>
                    </div>
                </div>
                <div className="temoignage-container layout-center-center row"></div>
            </div>
        </>
    );
};
export default Register;
