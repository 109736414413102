import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { MailevaTraduction } from "../../../constants/MailevaSendTypeTraduction";
import ILetters from "../../../interfaces/ILetters";
import { request } from "../../../utils/axios-utils";
import FullBarTitle from "../../layout/FullBarTitle";
import Loader from "../../layout/Loader";

const Letters: React.FC = (props) => {
    const history = useHistory();
    const { addToast } = useToasts();

    const [userLetters, setUserLetters] = useState<Array<ILetters> | null>(
        null
    );

    useEffect(() => {
        if (!userLetters) {
            request({ addToast, url: `/api/maileva/letters` })
                .then(({ data }) => {
                    setUserLetters(data);
                })
                .catch();
        }
    }, [userLetters]);

    const columns: Array<string> = [
        "Nom",
        "Statut",
        "Date d'envoi",
        "# Documents",
        "Type d'envoi",
    ];

    return (
        <>
            <FullBarTitle
                text="Lettres envoyées à travers le réseau La Poste"
                helpUrl="/pdf/TUTO_Synchronisation_Comptes_Fintecture.pdf"
                addLink="/lettres/nouvelle"
            />
            <div className="page_content">
                {!userLetters && <Loader />}
                {userLetters && userLetters.length > 0 && (
                    <>
                        <div className="maileva-table-wrapper maileva-table letters-table">
                            <table>
                                <thead>
                                    <tr>
                                        {columns &&
                                            columns.map(
                                                (columnHeader: string) => (
                                                    <th>{columnHeader}</th>
                                                )
                                            )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {userLetters &&
                                        userLetters.map((letter: ILetters) => (
                                            <tr
                                                onClick={() =>
                                                    history.push(
                                                        `/lettres/${letter.id}/detail`
                                                    )
                                                }
                                            >
                                                <td
                                                    style={{
                                                        maxWidth: "400px",
                                                        minWidth: "200px",
                                                    }}
                                                >
                                                    {letter.name}
                                                </td>
                                                <td
                                                    style={{
                                                        maxWidth: "80px",
                                                        minWidth: "80px",
                                                    }}
                                                >
                                                    {
                                                        MailevaTraduction[
                                                            letter.status as keyof typeof MailevaTraduction
                                                        ]
                                                    }
                                                </td>
                                                <td
                                                    style={{
                                                        maxWidth: "80px",
                                                        minWidth: "80px",
                                                    }}
                                                >
                                                    {letter.submission_date
                                                        ? moment(
                                                              letter.submission_date
                                                          ).format("DD/MM/YYYY")
                                                        : ""}
                                                </td>
                                                <td
                                                    style={{
                                                        maxWidth: "80px",
                                                        minWidth: "80px",
                                                    }}
                                                >
                                                    {letter.documents_count}
                                                </td>
                                                <td>
                                                    {
                                                        MailevaTraduction[
                                                            letter.postage_type as keyof typeof MailevaTraduction
                                                        ]
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    {userLetters.length === 0 ? (
                                        <tr>
                                            <td colSpan={7}>
                                                Vous n'avez pas encore utilisé
                                                l'envoie de courriers sur notre
                                                plateforme
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
                {userLetters && userLetters.length === 0 && (
                    <div className="column full-height layout-center-center">
                        <div className="item_two_line">
                            <p className="layout-center-center">
                                Vous n'avez pas encore envoyé de courrier
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Letters;
