import { isEmpty } from "lodash";
import React, { ChangeEvent, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { request } from "../../../utils/axios-utils";
import InputComponent from "./InputComponent";

const InputAddress: React.FC<{
    handleSelection: (selectedItem: any) => void;
    required?: boolean;
}> = (props) => {
    const [query, setQuery] = useState<string>("");
    const [suggestions, setSuggestions] = useState<any>([]);
    const addToast = useToasts();
    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const newQuery = event.target.value;
        setQuery(newQuery);

        try {
            request({
                addToast,
                url: "/api/address",
                params: { q: query, limit: 10 },
            }).then(({ data }) => {
                if (!isEmpty(data)) {
                    setSuggestions(
                        data.features.map((feature: any) => feature.properties)
                    );
                }
            }).catch();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <InputComponent
                type="text"
                value={query}
                onChange={handleChange}
                label="Recherche d'adresse"
                id="search-address"
            />
            <ul>
                {suggestions.map((suggestion: any, index: number) => (
                    <li
                        onClick={() => {
                            props.handleSelection(suggestion);
                            setSuggestions([]);
                        }}
                        key={index}
                        style={{
                            cursor: "pointer",
                            padding: "5px",
                            backgroundColor: "#f2f2f2",
                            borderBottom: "1px solid #ccc",
                        }}
                    >
                        {suggestion.label}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default InputAddress;
