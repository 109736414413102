export const ToolBarSettings = [
    {
        _id: 'infos',
        title: 'Infos',
        icon: 'info',
        path: 'infos',
        roles: ['super-admin', 'INVOICE_ACCOUNT', "tester"],
    },
    {
        _id: 'mails',
        title: 'Documents',
        icon: 'description',
        path: 'mails',
        roles: ['super-admin', 'MANAGER', "tester"],
    },
    {
        _id: 'compta',
        title: 'Comptabilité',
        icon: 'link',
        path: 'accountings',
        roles: ['super-admin', 'user', 'ACCOUNTANT', 'MANAGER', "tester"],
    },
    {
        _id: 'cloud',
        title: 'Scanner & Mails',
        icon: 'cloud',
        path: 'optional',
        roles: ['super-admin', 'user', 'MANAGER', "tester"],
    },
    {
        _id: 'formations',
        title: 'Formations',
        icon: 'school',
        path: 'formations',
        roles: ['super-admin', 'user', 'MANAGER', "tester", "CRG_VERIF"],
    },
    {
        _id: 'partenariat',
        title: 'Partenariat',
        icon: 'language',
        path: 'partenariat',
        roles: ['super-admin', 'user', 'MANAGER', "tester", "CRG_VERIF", "FAMILLE"],
    },
    {
        _id: 'abo',
        title: 'Abonnement',
        icon: 'update',
        path: 'abonnement',
        roles: ['super-admin', 'INVOICE_ACCOUNT', 'ACCOUNTANT', 'MANAGER', "tester"],
    },
];
