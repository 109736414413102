import React from 'react';
import Divider from "../../layout/Divider";
import IUser from "../../../interfaces/IUser";
import ModalFactory from '../../layout/modals/ModalFactory';

const NotActivated: React.FC<{
    stripeReady: boolean, subscribeWithSepa: Function,
    updateSepa: Function, modifyPaymentMean: string, user: IUser
}> = (props) => {

    return (
        <>
            <div>
                <div className="row layout-space-between-center desactivated">
                    <p className="warning">Votre Abonnement n&apos;est pas activé.</p>
                    <div style={{marginTop: 20}} className="row layout-space-between-center">
                        <ModalFactory
                            activated={false}
                            title='Activer votre abonnement'
                            stripeReady={props.stripeReady}
                            subscribeWithSepa={() => props.subscribeWithSepa()}
                            updateSepa={(sepa: any) => props.updateSepa(sepa)}
                        />
                    </div>
                </div>
                <Divider/>
            </div>
        </>
    );
}
export default NotActivated;
