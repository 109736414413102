import dateformat from "dateformat";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { MailevaPrice } from "../../../constants/MailevaPrice";
import { MailevaTraduction } from "../../../constants/MailevaSendTypeTraduction";
import ILetters from "../../../interfaces/ILetters";
import IMailevaDocument from "../../../interfaces/IMailevaDocument";
import IRecipient from "../../../interfaces/IRecipient";
import { request } from "../../../utils/axios-utils";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import FormButton from "../../layout/forms/FormButton";
import ButtonConfirmModalIcon from "../../layout/modals/ButtonConfirmModalIcon";
import CreateDocument from "./CreateDocument";

const Letter: React.FC = () => {
    const [letter, setLetter] = useState<ILetters | null>(null);
    const [pricePerRecipient, setPricePerRecipient] = useState<string>(null);
    const [recipients, setRecipients] = useState<Array<IRecipient> | null>(
        null
    );
    const [documents, setDocuments] = useState<Array<IMailevaDocument> | null>(
        null
    );
    const [pagesCount, setPagesCount] = useState<number>(null);

    const history = useHistory();
    const { addToast } = useToasts();
    const { letterId }: { letterId: string } = useParams();

    useEffect(() => {
        if (!letter) {
            getLetter();
        }
    }, [letter]);

    useEffect(() => {
        if (!recipients) {
            getRecipients();
        }
    }, [recipients]);

    useEffect(() => {
        if (!documents) {
            getDocuments();
        }
    }, [documents]);

    useEffect(() => {
        let priceForPrint: number;

        if (documents && letter) {
            let pagesCount = documents.reduce(
                (acc, curr) => acc + curr.pages_count,
                0
            );
            if (letter.print_sender_address) {
                pagesCount += 1;
            }

            if (letter.optional_address_sheet) {
                pagesCount += 1;
            }

            if (letter.duplex_printing) {
                pagesCount /= 2;
                pagesCount = Math.ceil(pagesCount);
            }

            const type = letter.postage_type;
            let index = MailevaPrice[type][0];

            //On calcule le poids du courrier
            let weight: number = pagesCount * 5;
            //On ajoute le poids de l'enveloppe
            if (pagesCount <= 5) {
                weight += MailevaPrice["ENVELOPE"]["C4"];
            } else {
                weight += MailevaPrice["ENVELOPE"]["DL"];
            }
            //On cherche le prix correspondant au poids
            const keys = Object.keys(MailevaPrice[type]).map(Number);

            for (let key of keys) {
                if (key > weight) {
                    index = key;
                    break;
                }
            }

            if (weight > keys[keys.length - 1]) {
                index = keys[keys.length - 1];
            }

            //Prix de l'impression
            if (letter.color_printing) {
                priceForPrint = MailevaPrice["PRINT"]["COLOR"];
                priceForPrint = priceForPrint * (pagesCount - 1);
                if (pagesCount <= 5) {
                    priceForPrint +=
                        MailevaPrice["PRINT"]["FIRST_PAGE_COLOR_C4"];
                } else {
                    priceForPrint +=
                        MailevaPrice["PRINT"]["FIRST_PAGE_COLOR_DL"];
                }
            } else {
                priceForPrint = MailevaPrice["PRINT"]["NB"];
                priceForPrint = priceForPrint * (pagesCount - 1);
                if (pagesCount <= 5) {
                    priceForPrint += MailevaPrice["PRINT"]["FIRST_PAGE_NB_C4"];
                } else {
                    priceForPrint += MailevaPrice["PRINT"]["FIRST_PAGE_NB_DL"];
                }
            }

            //Marge appliquée
            let profit = Number(process.env.REACT_APP_MAILEVA_PROFIT_INDUS);
            if (weight > 50) {
                profit = Number(process.env.REACT_APP_MAILEVA_PROFIT);
            }

            setPagesCount(pagesCount);
            setPricePerRecipient(
                (MailevaPrice[type][index] + profit + priceForPrint).toFixed(2)
            );
        }
    }, [letter, documents]);

    function getLetter() {
        request({ url: `/api/maileva/letter/${letterId}` })
            .then(({ data }) => {
                setLetter(data);
            })
            .catch(() =>
                addToast("Impossible de récupérer la lettre", {
                    appearance: "error",
                })
            );
    }

    function submitLetter() {
        if (!recipients || recipients.length === 0) {
            addToast("Vous devez ajouter au moins un destinataire", {
                appearance: "error",
            });
            return;
        }

        if (pagesCount > 44) {
            addToast("Votre courrier ne peut contenir plus de 44 pages", {
                appearance: "error",
            });
            return;
        }

        if (!documents || documents.length === 0) {
            addToast("Vous devez ajouter au moins un document", {
                appearance: "error",
            });
            return;
        }

        request({
            url: `/api/maileva/send-letter/${letterId}`,
            method: "POST",
            data: { price: Number(pricePerRecipient) * recipients.length },
        })
            .then(() => {
                addToast("Le courrier a été envoyé avec succès", {
                    appearance: "success",
                });
                getLetter();
            })
            .catch(() =>
                addToast("Impossible d'envoyer le courrier", {
                    appearance: "error",
                })
            );
    }

    function getRecipients() {
        request({ url: `/api/maileva/get-recipients/${letterId}` })
            .then(({ data }) => {
                if (data.recipients.length > 0) {
                    setRecipients(data.recipients);
                } else {
                    setRecipients(null);
                }
            })
            .catch(() =>
                addToast("Impossible de récupérer les destinataires", {
                    appearance: "error",
                })
            );
    }

    function removeRecipient(recipientId: string) {
        request({
            url: `/api/maileva/delete-recipient/${letterId}/${recipientId}`,
            method: "DELETE",
        })
            .then(() => {
                getLetter();
            })
            .then(() => {
                getRecipients();
                addToast("Le destinataire a été supprimé avec succès", {
                    appearance: "success",
                });
            })
            .catch(() => {
                addToast(
                    "Une erreur est survenue lors de la suppression du destinataire",
                    { appearance: "error" }
                );
            });
    }

    function removeDocument(documentId: string) {
        request({
            url: `/api/maileva/delete-document/${letterId}/${documentId}`,
            method: "DELETE",
        })
            .then(() => {
                getLetter();
            })
            .then(() => {
                getDocuments();
                addToast("Le document a été supprimé avec succès", {
                    appearance: "success",
                });
            })
            .catch(() => {
                addToast(
                    "Une erreur est survenue lors de la suppression du document",
                    { appearance: "error" }
                );
            });
    }

    function getDocuments() {
        request({ url: `/api/maileva/get-documents/${letterId}` })
            .then(({ data }) => {
                if (data.documents.length > 0) {
                    if (
                        data.documents.filter(
                            (document: IMailevaDocument) =>
                                document.pages_count === 0
                        ).length > 0
                    ) {
                        return getDocuments();
                    }
                    setDocuments(data.documents);
                } else {
                    setDocuments(null);
                }
            })
            .catch(() =>
                addToast("Impossible de récupérer les documents", {
                    appearance: "error",
                })
            );
    }

    async function refreshDocuments() {
        await getLetter();
        getDocuments();
    }

    function removeLetter() {
        request({
            url: `/api/maileva/remove-letter/${letterId}`,
            method: "POST",
        })
            .then(() => {
                addToast("Le courrier a été supprimé avec succès", {
                    appearance: "success",
                });
                history.push("/lettres");
            })
            .catch(() =>
                addToast("Impossible de supprimer le courrier", {
                    appearance: "error",
                })
            );
    }

    return (
        <>
            <SubBar
                title={
                    letter &&
                    `${
                        MailevaTraduction[
                            letter.status as keyof typeof MailevaTraduction
                        ]
                    } ${
                        letter.submission_date
                            ? "- Envoyé le " +
                              dateformat(letter.submission_date, "dd-mm-yyyy")
                            : ""
                    }`
                }
                back={
                    letter && letter.status === "DRAFT"
                        ? `/lettres/${letterId}/modifier`
                        : `/lettres`
                }
            >
                {letter && letter.status === "DRAFT" && (
                    <>
                        <ButtonConfirmModalIcon
                            id="delete-letter"
                            iconClassName="remove-icon"
                            onValidate={() => removeLetter()}
                            icon="delete_forever"
                            question={"Voulez vous supprimer la lettre ?"}
                        />
                    </>
                )}
            </SubBar>
            <div className="page_content maileva-content">
                {!letter ||
                    (letter &&
                        ((letter.documents_count > 0 && !documents) ||
                            (letter.recipients_counts.total > 0 &&
                                !recipients)) && <Loader />)}

                {letter &&
                    ((letter.documents_count > 0 &&
                        documents &&
                        letter.recipients_counts.total > 0 &&
                        recipients) ||
                        (letter.documents_count == 0 &&
                            letter.recipients_counts.total == 0) ||
                        (letter.documents_count == 0 &&
                            letter.recipients_counts.total > 0 &&
                            recipients) ||
                        (letter.recipients_counts.total == 0 &&
                            letter.documents_count > 0 &&
                            documents)) && (
                        <>
                            <div className="item_two_line">
                                <h6>Envoyé par</h6>
                                <div className={"row"}>
                                    <p className="notes_zone">
                                        {letter.sender_address_line_2 !== ""
                                            ? letter.sender_address_line_2
                                            : letter.sender_address_line_1}
                                    </p>
                                    <p className="notes_zone">
                                        {" " + letter.sender_address_line_4}
                                        {" " + letter.sender_address_line_6}
                                    </p>
                                </div>
                            </div>
                            <div className="divider"></div>
                            <div className="row layout-xs-column">
                                <div className="column flex-1 layout-space-between-start">
                                    <div className="item_two_line">
                                        <h6>Nom du courrier</h6>
                                        <p className="notes_zone">
                                            {letter.name}
                                        </p>
                                    </div>
                                    <div className="divider"></div>
                                </div>

                                <div className="flex005 hide-xs"></div>

                                <div className="column flex-1">
                                    <div className="item_two_line">
                                        <h6>Couleur de l'impression</h6>
                                        <p className="notes_zone">
                                            {letter.color_printing
                                                ? "Oui"
                                                : "Non"}
                                        </p>
                                    </div>
                                    <div className="divider"></div>
                                </div>
                            </div>

                            <div className="row layout-xs-column">
                                <div className="column flex-1">
                                    <div className="item_two_line">
                                        <h6>Nombre de pages</h6>
                                        <p className="notes_zone">
                                            {pagesCount ?? 0}
                                        </p>
                                    </div>
                                    <div className="divider"></div>
                                </div>

                                <div className="flex005 hide-xs"></div>

                                <div className="column flex-1">
                                    <div className="item_two_line">
                                        <h6>Email de notification générales</h6>
                                        <p className="notes_zone">
                                            {letter.notification_email}
                                        </p>
                                    </div>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row layout-xs-column">
                                <div className="column flex-1">
                                    <div className="item_two_line">
                                        <h6>Type d'envoi</h6>
                                        <p className="notes_zone">
                                            {
                                                MailevaTraduction[
                                                    letter.postage_type as keyof typeof MailevaTraduction
                                                ]
                                            }
                                        </p>
                                    </div>
                                    <div className="divider"></div>
                                </div>

                                <div className="flex005 hide-xs"></div>

                                <div className="column flex-1">
                                    <div className="item_two_line">
                                        <h6>Recto/Verso</h6>
                                        <p className="notes_zone">
                                            {letter.duplex_printing
                                                ? "Oui"
                                                : "Non"}
                                        </p>
                                    </div>
                                    <div className="divider"></div>
                                </div>
                            </div>

                            <div className="row layout-xs-column">
                                <div className="maileva-table-column">
                                    <div className="row maileva-table-title">
                                        <h4 className="margin-top margin-bottom">
                                            Destinataires
                                        </h4>
                                        {letter.status === "DRAFT" && (
                                            <Link
                                                to={`/lettres/${letter.id}/destinataire/ajouter`}
                                            >
                                                <div className="mailevaButtonPadding">
                                                    <div className="custom-button">
                                                        Ajouter un destinataire
                                                    </div>
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                    <div className="maileva-table-wrapper maileva-table">
                                        <div>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Nom</th>
                                                        <th className="recipients-hide-xs">
                                                            Adresse
                                                        </th>
                                                        <th>Prix</th>
                                                        {letter.status ===
                                                            "DRAFT" && (
                                                            <th className="recipients-hide-xs">
                                                                Actions
                                                            </th>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {recipients &&
                                                        recipients.map(
                                                            (
                                                                recipient: IRecipient
                                                            ) => (
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            maxWidth:
                                                                                "130px",
                                                                            minWidth:
                                                                                "100px",
                                                                        }}
                                                                        onClick={() =>
                                                                            history.push(
                                                                                `/lettres/${letterId}/destinataire/${recipient.id}/infos`
                                                                            )
                                                                        }
                                                                    >
                                                                        {recipient.address_line_1 !==
                                                                        ""
                                                                            ? recipient.address_line_1
                                                                            : recipient.address_line_2}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            maxWidth:
                                                                                "250px",
                                                                            minWidth:
                                                                                "100px",
                                                                        }}
                                                                        onClick={() =>
                                                                            history.push(
                                                                                `/lettres/${letterId}/destinataire/${recipient.id}/infos`
                                                                            )
                                                                        }
                                                                        className="recipients-hide-xs"
                                                                    >
                                                                        {
                                                                            recipient.address_line_4
                                                                        }{" "}
                                                                        {
                                                                            recipient.address_line_6
                                                                        }
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            maxWidth:
                                                                                "50px",
                                                                            minWidth:
                                                                                "50px",
                                                                        }}
                                                                        onClick={() =>
                                                                            history.push(
                                                                                `/lettres/${letterId}/destinataire/${recipient.id}/infos`
                                                                            )
                                                                        }
                                                                    >
                                                                        {(pricePerRecipient ??
                                                                            0) +
                                                                            "€"}
                                                                    </td>
                                                                    {letter.status ===
                                                                        "DRAFT" && (
                                                                        <td
                                                                            style={{
                                                                                maxWidth:
                                                                                    "50px",
                                                                                minWidth:
                                                                                    "50px",
                                                                            }}
                                                                            className="text-align-center"
                                                                        >
                                                                            <Link
                                                                                to={`/lettres/${letterId}/destinataire/${recipient.id}/modifier`}
                                                                            >
                                                                                <i className="material-icons">
                                                                                    edit
                                                                                </i>
                                                                            </Link>
                                                                            <i
                                                                                onClick={() =>
                                                                                    removeRecipient(
                                                                                        recipient.id
                                                                                    )
                                                                                }
                                                                                className="material-icons pointer text-align-center recipients-hide-xs"
                                                                            >
                                                                                delete_forever
                                                                            </i>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            )
                                                        )}

                                                    {!recipients && (
                                                        <tr>
                                                            <td
                                                                className="tableAlert"
                                                                colSpan={
                                                                    letter.status ===
                                                                    "DRAFT"
                                                                        ? 4
                                                                        : 3
                                                                }
                                                            >
                                                                Vous devez
                                                                ajouter un
                                                                destinataire
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex005 hide-xs"></div>

                                <div className="maileva-table-column">
                                    <div className="row maileva-table-title">
                                        <h4 className="margin-top margin-bottom">
                                            Documents
                                        </h4>
                                        {letter.status === "DRAFT" && (
                                            <CreateDocument
                                                documents={
                                                    documents
                                                        ? documents.length + 1
                                                        : 1
                                                }
                                                refreshDocuments={
                                                    refreshDocuments
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="maileva-table-wrapper maileva-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Nom</th>
                                                    <th>Pages</th>
                                                    <th className="recipients-hide-xs">
                                                        Taille du document
                                                    </th>
                                                    {letter.status ===
                                                        "DRAFT" && (
                                                        <th className="recipients-hide-xs">
                                                            Supprimer
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {documents &&
                                                    documents.map(
                                                        (
                                                            document: IMailevaDocument
                                                        ) => (
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        maxWidth:
                                                                            "300px",
                                                                        minWidth:
                                                                            "200px",
                                                                    }}
                                                                >
                                                                    {
                                                                        document.name
                                                                    }
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        maxWidth:
                                                                            "50px",
                                                                        minWidth:
                                                                            "50px",
                                                                    }}
                                                                >
                                                                    {
                                                                        document.pages_count
                                                                    }
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        maxWidth:
                                                                            "50px",
                                                                        minWidth:
                                                                            "50px",
                                                                    }}
                                                                    className="recipients-hide-xs"
                                                                >
                                                                    {document.converted_size
                                                                        ? (
                                                                              document.converted_size /
                                                                              1024 **
                                                                                  2
                                                                          ).toFixed(
                                                                              2
                                                                          )
                                                                        : 0}
                                                                    MB
                                                                </td>
                                                                {letter.status ===
                                                                    "DRAFT" && (
                                                                    <td
                                                                        style={{
                                                                            maxWidth:
                                                                                "50px",
                                                                            minWidth:
                                                                                "50px",
                                                                        }}
                                                                        onClick={() =>
                                                                            removeDocument(
                                                                                document.id
                                                                            )
                                                                        }
                                                                        className="material-icons pointer text-align-center recipients-hide-xs"
                                                                    >
                                                                        delete_forever
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        )
                                                    )}
                                                {!documents && (
                                                    <tr>
                                                        <td
                                                            className="tableAlert"
                                                            colSpan={4}
                                                        >
                                                            Vous devez ajouter
                                                            un document
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {letter.status === "DRAFT" && (
                                <div className="layout-end-center">
                                    <FormButton
                                        onClick={submitLetter}
                                        text={"Envoyer"}
                                    />
                                </div>
                            )}
                        </>
                    )}
            </div>
        </>
    );
};

export default Letter;
