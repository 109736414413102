import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { yearsTable } from "../../../../constants/Utils";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import IRessource from "../../../../interfaces/IRessource";
import { request } from "../../../../utils/axios-utils";
import Divider from "../../../layout/Divider";
import Loader from "../../../layout/Loader";
import Select from "../../../layout/forms/Select";

const RessourcesMajeur: React.FC<{
    majeurId: string;
    majeur: IProtectedAdult;
}> = (props) => {
    const [RessourcesCat, setRessourcesCat] = useState<string>("RevenusImp");
    const addToast = useToasts();

    const [ressources, setRessources] = useState<IRessource>(null);
    const [isLoading, setisLoading] = useState<boolean>(false);
    const [yearSelected, setYearSelected] = useState<number>(
        new Date().getFullYear() - 1
    );

    useEffect(() => {
        if (!ressources || ressources?.annee !== yearSelected) {
            /* GET ressources by YEAR */
            actualiseRessources(yearSelected);
        }
    }, [yearSelected]);

    const actualiseRessources = (year: number) => {
        request({
            addToast,
            url: `/api/majeurs/ressources/${props.majeurId}/${year}`,
        }).then(({ data }) => {
            setRessources(data);
        }).catch();
    };

    const yearOptions: Array<any> = yearsTable();

    const totalEpargne =
        (ressources?.assurance_foret ?? 0) +
        (ressources?.epargne_actions ?? 0) +
        (ressources?.co_developpement ?? 0) +
        (ressources?.epargne_logement ?? 0) +
        (ressources?.LDD ?? 0) +
        (ressources?.livret_jeune ?? 0) +
        (ressources?.PEP ?? 0) +
        (ressources?.livret_A ?? 0) +
        (ressources?.LEP ?? 0) +
        (ressources?.LES ?? 0) +
        (ressources?.capital_extension ?? 0) +
        (ressources?.codebis ?? 0) +
        (ressources?.cel ?? 0) +
        (ressources?.pel ?? 0) +
        (ressources?.csl ?? 0) +
        (ressources?.compte_a_terme ?? 0) +
        (ressources?.Perp ?? 0) +
        (ressources?.livret_bleu ?? 0);

    const totalBnpr =
        (ressources?.capitaux_non_productif_revenus ?? 0) * 0.03 +
        (ressources?.locative_immeubles_batis ?? 0) +
        (ressources?.valeur_locative_immeubles_non_batis ?? 0) +
        (ressources?.assurance_vie ?? 0) * 0.03 +
        (ressources?.interessement_participation ?? 0) * 0.03;

    const totalAllRI =
        (ressources?.aah ?? 0) +
        (ressources?.allocation_personnes_agees ?? 0) +
        (ressources?.minimum_vieillesse ?? 0) +
        (ressources?.revenu_solidarite ?? 0) +
        (ressources?.prime_activite ?? 0) +
        (ressources?.revenus_hors_france ?? 0);

    const totalRI =
        (ressources?.revenus_fonciers_bruts ?? 0) +
        (ressources?.benefices_industriels_commerciaux ?? 0) +
        (ressources?.rem_gerant_associes ?? 0) +
        (ressources?.benefices_exploitation_agricole ?? 0) +
        (ressources?.plus_values_cession ?? 0) +
        (ressources?.capitaux_mobiliers ?? 0) +
        (ressources?.bene_profession_non_commercial ?? 0) +
        (ressources?.traitement_salaires_pensions ?? 0);

    const bigTotal = totalRI + totalEpargne + totalBnpr + totalAllRI;

    return (
        <div className="ressources_majeur">
            <div className="subBar_actions sticky top-90 layout-space-between-center row">
                <span className="subBar_actions_title">Les ressources</span>
                <div className="subBar_actions_item">
                    {!props.majeur?.archived && (
                        <Link
                            to={`/update_ressources/${props.majeurId}/${yearSelected}`}
                            className="row"
                        >
                            <i className="material-icons">edit</i>
                        </Link>
                    )}
                </div>
            </div>
            <div className="ressources_container">
                <div className="switch_container layout-space-around-center row layout-xs-column page_content">
                    <Select
                        id="year option"
                        value={yearSelected}
                        options={yearOptions}
                        className={"match-input-size"}
                        onChange={(e) => {
                            setYearSelected(e.target.value);
                            actualiseRessources(e.target.value);
                        }}
                    />
                    <button
                        className={
                            "simple-multi-button " +
                            (RessourcesCat === "RevenusImp" ? "selected" : "")
                        }
                        onClick={() => setRessourcesCat("RevenusImp")}
                    >
                        Revenus Imposables
                    </button>
                    <button
                        className={
                            "simple-multi-button " +
                            (RessourcesCat === "Epargne" ? "selected" : "")
                        }
                        onClick={() => setRessourcesCat("Epargne")}
                    >
                        Epargne
                    </button>
                    <button
                        className={
                            "simple-multi-button " +
                            (RessourcesCat === "BiensNP" ? "selected" : "")
                        }
                        onClick={() => setRessourcesCat("BiensNP")}
                    >
                        Biens non productifs de revenus
                    </button>
                    <button
                        className={
                            "simple-multi-button " +
                            (RessourcesCat === "Allocations" ? "selected" : "")
                        }
                        onClick={() => setRessourcesCat("Allocations")}
                    >
                        Allocations + Revenus internationaux
                    </button>
                </div>

                {isLoading && <Loader />}

                {!isLoading && (
                    <>
                        <div className="ressources_rows_container">
                            {RessourcesCat === "RevenusImp" && (
                                <div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>
                                            Revenus Fonciers bruts :{" "}
                                            <span className="small">
                                                (déclaration 2044)
                                            </span>
                                        </span>
                                        <span>
                                            {ressources?.revenus_fonciers_bruts?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>
                                            Bénéfices industriels et commerciaux
                                            :
                                        </span>
                                        <span>
                                            {ressources?.benefices_industriels_commerciaux?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span className="hide-xs">
                                            Rémunérations allouées aux gérants
                                            et associés :
                                        </span>
                                        <span className="hide show-xs">
                                            Rém. allouées aux gérants et
                                            associés :
                                        </span>
                                        <span>
                                            {ressources?.rem_gerant_associes?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>
                                            Bénéfices de l&#039;exploitation
                                            agricole :{" "}
                                        </span>
                                        <span>
                                            {ressources?.benefices_exploitation_agricole?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>
                                            Traitements, salaires, pensions et
                                            rentes viagères :{" "}
                                        </span>
                                        <span>
                                            {ressources?.traitement_salaires_pensions?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>
                                            Bénéfices des professions non
                                            commerciales :{" "}
                                        </span>
                                        <span>
                                            {ressources?.bene_profession_non_commercial?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>
                                            Revenus des capitaux mobiliers :{" "}
                                        </span>
                                        <span>
                                            {ressources?.capitaux_mobiliers?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span className="hide-xs">
                                            Plus-values de cession à titre
                                            onéreaux de biens ou de droits de
                                            toute nature :
                                        </span>
                                        <span className="hide show-xs">
                                            Plus-values de cession... :
                                        </span>
                                        <span>
                                            {ressources?.plus_values_cession?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                </div>
                            )}
                            {RessourcesCat === "Epargne" && (
                                <div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>Livret A : </span>
                                        <span>
                                            {ressources?.livret_A?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>LEP : </span>
                                        <span>
                                            {ressources?.LEP?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>LES : </span>
                                        <span>
                                            {ressources?.LES?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>PEP : </span>
                                        <span>
                                            {ressources?.PEP?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>Livret jeune : </span>
                                        <span>
                                            {ressources?.livret_jeune?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>LDD : </span>
                                        <span>
                                            {ressources?.LDD?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>Epargne logement : </span>
                                        <span>
                                            {ressources?.epargne_logement?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>Co développement : </span>
                                        <span>
                                            {ressources?.co_developpement?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>PEA : </span>
                                        <span>
                                            {ressources?.epargne_actions?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>Assurance Forêt : </span>
                                        <span>
                                            {ressources?.assurance_foret?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>Capital extension : </span>
                                        <span>
                                            {ressources?.capital_extension?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>Codebis : </span>
                                        <span>
                                            {ressources?.codebis?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>CEL : </span>
                                        <span>
                                            {ressources?.cel?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>PEL : </span>
                                        <span>
                                            {ressources?.pel?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>CSL : </span>
                                        <span>
                                            {ressources?.csl?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>Compte à terme : </span>
                                        <span>
                                            {ressources?.compte_a_terme?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>PERP : </span>
                                        <span>
                                            {ressources?.Perp?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>Livret Bleu : </span>
                                        <span>
                                            {ressources?.livret_bleu?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                </div>
                            )}
                            {RessourcesCat === "BiensNP" && (
                                <div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>
                                            Capitaux non productif de revenu * :
                                        </span>
                                        <div
                                            style={{ padding: 0 }}
                                            className="row layout-end-center"
                                        >
                                            <span
                                                style={{
                                                    fontSize: 10,
                                                    marginRight: 10,
                                                }}
                                            >
                                                valeur réelle :{" "}
                                                {ressources?.capitaux_non_productif_revenus?.toLocaleString(
                                                    "fr-FR",
                                                    {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                    }
                                                ) || 0}{" "}
                                                €
                                            </span>
                                            <span>
                                                {ressources &&
                                                ressources.capitaux_non_productif_revenus &&
                                                !isNaN(
                                                    ressources.capitaux_non_productif_revenus
                                                )
                                                    ? (
                                                          ressources.capitaux_non_productif_revenus *
                                                          0.03
                                                      ).toLocaleString(
                                                          "fr-FR",
                                                          {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2,
                                                          }
                                                      )
                                                    : 0}{" "}
                                                €
                                            </span>
                                        </div>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>
                                            Valeur locative, Immeubles batis :
                                        </span>
                                        <span>
                                            {ressources?.locative_immeubles_batis?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>
                                            Valeur locative immeuble non batis :
                                        </span>
                                        <span>
                                            {ressources?.valeur_locative_immeubles_non_batis?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>Assurance vie * : </span>
                                        <div
                                            style={{ padding: 0 }}
                                            className="row layout-end-center"
                                        >
                                            <span
                                                style={{
                                                    fontSize: 10,
                                                    marginRight: 10,
                                                }}
                                            >
                                                valeur réelle :{" "}
                                                {ressources?.assurance_vie?.toLocaleString(
                                                    "fr-FR",
                                                    {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                    }
                                                ) || 0}{" "}
                                                €
                                            </span>
                                            <span>
                                                {ressources &&
                                                ressources.assurance_vie &&
                                                !isNaN(ressources.assurance_vie)
                                                    ? (
                                                          ressources.assurance_vie *
                                                          0.03
                                                      ).toLocaleString(
                                                          "fr-FR",
                                                          {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2,
                                                          }
                                                      )
                                                    : 0}{" "}
                                                €
                                            </span>
                                        </div>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>
                                            Interessement participative * :{" "}
                                        </span>
                                        <div
                                            style={{ padding: 0 }}
                                            className="row layout-end-center"
                                        >
                                            <span
                                                style={{
                                                    fontSize: 10,
                                                    marginRight: 10,
                                                }}
                                            >
                                                valeur réelle :{" "}
                                                {ressources?.interessement_participation?.toLocaleString(
                                                    "fr-FR",
                                                    {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                    }
                                                ) || 0}{" "}
                                                €
                                            </span>
                                            <span>
                                                {ressources &&
                                                ressources.interessement_participation &&
                                                !isNaN(
                                                    ressources.interessement_participation
                                                )
                                                    ? (
                                                          ressources.interessement_participation *
                                                          0.03
                                                      ).toLocaleString(
                                                          "fr-FR",
                                                          {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2,
                                                          }
                                                      )
                                                    : 0}{" "}
                                                €
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {RessourcesCat === "Allocations" && (
                                <div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>A.A.H. :</span>
                                        <span>
                                            {ressources?.aah?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>Allocation personnes agées</span>
                                        <span>
                                            {ressources?.allocation_personnes_agees?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>Minimum viellesse :</span>
                                        <span>
                                            {ressources?.minimum_vieillesse?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>Revenu solidarité : </span>
                                        <span>
                                            {ressources?.revenu_solidarite?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row back-color-item-odd">
                                        <span>Prime d'activité : </span>
                                        <span>
                                            {ressources?.prime_activite?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                    <div className="layout-space-between-center row">
                                        <span>Revenu hors France : </span>
                                        <span>
                                            {ressources?.revenus_hors_france?.toLocaleString(
                                                "fr-FR",
                                                {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }
                                            ) || 0}{" "}
                                            €
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <Divider />
                        <div className="page_content column layout-center-center">
                            <div className="total_container">
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <b>Revenus imposables</b>
                                    <b>
                                        {totalRI && !isNaN(totalRI)
                                            ? totalRI?.toLocaleString("fr-FR", {
                                                  maximumFractionDigits: 2,
                                                  minimumFractionDigits: 2,
                                              })
                                            : 0}{" "}
                                        €
                                    </b>
                                </div>
                                <div className="layout-space-between-center row">
                                    <b>Epargne</b>
                                    <b>
                                        {totalEpargne && !isNaN(totalEpargne)
                                            ? totalEpargne?.toLocaleString(
                                                  "fr-FR",
                                                  {
                                                      maximumFractionDigits: 2,
                                                      minimumFractionDigits: 2,
                                                  }
                                              )
                                            : 0}{" "}
                                        €
                                    </b>
                                </div>
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <b>Biens non productifs de revenus</b>
                                    <b>
                                        {totalBnpr && !isNaN(totalBnpr)
                                            ? totalBnpr?.toLocaleString(
                                                  "fr-FR",
                                                  {
                                                      maximumFractionDigits: 2,
                                                      minimumFractionDigits: 2,
                                                  }
                                              )
                                            : 0}{" "}
                                        €
                                    </b>
                                </div>
                                <div className="layout-space-between-center row">
                                    <b>Allocations + RI</b>
                                    <b>
                                        {totalAllRI && !isNaN(totalAllRI)
                                            ? totalAllRI?.toLocaleString(
                                                  "fr-FR",
                                                  {
                                                      maximumFractionDigits: 2,
                                                      minimumFractionDigits: 2,
                                                  }
                                              )
                                            : 0}{" "}
                                        €
                                    </b>
                                </div>
                                <hr />
                                <div className="layout-space-between-center row back-color-item-odd">
                                    <b>TOTAL</b>
                                    <b>
                                        {bigTotal && !isNaN(bigTotal)
                                            ? bigTotal?.toLocaleString(
                                                  "fr-FR",
                                                  {
                                                      maximumFractionDigits: 2,
                                                      minimumFractionDigits: 2,
                                                  }
                                              )
                                            : 0}{" "}
                                        €
                                    </b>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default RessourcesMajeur;
