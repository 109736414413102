import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import INews from "../../../interfaces/INews";
import { request } from "../../../utils/axios-utils";
import {momentUpdateLocale} from "../../../constants/Utils";

const News: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const { newsId }: { newsId: string } = useParams();
    const [news, setNews] = useState<any>(null);
    const { addToast } = useToasts();

    momentUpdateLocale()

    useEffect(() => {
        if (location.state) {
            setNews(location.state);
        }
        if (!newsId && !news) {
            request({ addToast, url: "api/dashboard/news" })
                .then(({ data }) => {
                    setNews(data.news);
                })
                .catch(() => history.push("/news"));
        }
    }, [news]);

    return (
        <>
            <div>
                {newsId && (
                    <div className="arrow_back layout-start-center row">
                        <Link to="/news">
                            <i className="material-icons">arrow_back</i>
                        </Link>
                        <div className="layout-center-center row full-width">
                            <h3 className="black_color"></h3>
                        </div>
                    </div>
                )}

                <div className="news">
                    {news &&
                        news.length > 0 &&
                        news.map(
                            (article: INews) =>
                                (newsId === undefined ||
                                    newsId === article._id) && (
                                    <div
                                        className="single-news"
                                        key={article._id}
                                    >
                                        <div className="my-sub-title-form">
                                            {article.title}
                                        </div>
                                        <div className="news-content">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: article.body,
                                                }}
                                            />

                                            <br />
                                            <div className="created-at">
                                                Mise en ligne le{" "}
                                                {moment(
                                                    article.updated_at
                                                ).format("L")}
                                            </div>
                                        </div>
                                    </div>
                                )
                        )}
                </div>
            </div>
        </>
    );
};

export default News;
