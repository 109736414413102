import React, { useState } from "react";
import {
    capitalizeFirst,
    CATS,
    MVT_WITHOUTH_AMOUNT,
} from "../../../constants/Utils";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";

const FormAddLabelWithSubCategories: React.FC<{
    getSubCategoriesFormLinkedLabel: Function;
    listSubCategoriesForSelect: Array<any>;
    submit: Function;
    selectedSubCategory: any;
}> = (props) => {
    const [textInput, setTextInput] = useState<string>("");
    const [amount, setAmount] = useState<string>("");
    const [isLabelFromSubCat, setIsLabelFromSubCat] = useState<boolean>(false);
    const [selectedMouvment, setSelectedMouvment] = useState<string>(
        MVT_WITHOUTH_AMOUNT[0].value
    );
    const [categories, setCategories] = useState<Array<any>>(
        CATS.filter((c) => c.mvt === 0)
    );
    const [selectedCategory, setSelectedCategory] = useState<string>(
        categories[0].value
    );
    const [selectedSubCategory, setSelectedSubCategory] = useState<string>(
        props.selectedSubCategory.value
    );
    const [selectedSubCategoryName, setSelectedSubCategoryName] =
        useState<string>(props.selectedSubCategory.label);

    const onChangeMouvement = (option: any) => {
        let newCategories = CATS.filter(
            (c) => c.mvt === parseInt(option.value)
        );
        setSelectedMouvment(option.value);
        setCategories(newCategories);
        setSelectedCategory(newCategories[0].value);
        props.getSubCategoriesFormLinkedLabel(newCategories[0].value);
    };

    const onChangeCategory = (option: any) => {
        setSelectedCategory(option.value);
        props.getSubCategoriesFormLinkedLabel(option.value);
    };

    const onChangeSubCategory = (option: any) => {
        setSelectedSubCategory(option.value);
        props.getSubCategoriesFormLinkedLabel(selectedCategory, option.label);
    };

    const submit = (e: any) => {
        e.preventDefault();
        if (textInput) {
            props.submit(
                selectedMouvment,
                selectedCategory,
                isLabelFromSubCat
                    ? selectedSubCategoryName
                    : props.selectedSubCategory.label,
                capitalizeFirst(textInput.trim())
            );
        }
    };

    return (
        <>
            <form className="column form_add_categories">
                <Select
                    id="mouvment"
                    label="Mouvement "
                    required
                    options={MVT_WITHOUTH_AMOUNT}
                    value={selectedMouvment}
                    onChange={(e) => {
                        setIsLabelFromSubCat(false);
                        onChangeMouvement(e.target.selectedOptions[0]);
                        setSelectedSubCategory(props.selectedSubCategory.value);
                    }}
                />
                <Select
                    id="category"
                    label="Categorie"
                    required
                    options={categories}
                    value={selectedCategory}
                    onChange={(e) => {
                        setIsLabelFromSubCat(false);
                        onChangeCategory(e.target.selectedOptions[0]);
                        setSelectedSubCategory(props.selectedSubCategory.value);
                    }}
                />
                <Select
                    id="subCategory"
                    label="Sous Catégorie"
                    required
                    options={props.listSubCategoriesForSelect}
                    value={selectedSubCategory}
                    onChange={(e) => {
                        setIsLabelFromSubCat(true);
                        onChangeSubCategory(e.target.selectedOptions[0]);
                        setSelectedSubCategoryName(
                            e.target.selectedOptions[0].label
                        );
                    }}
                />
                <InputComponent
                    id="label"
                    className="my-input"
                    label="Label à associer"
                    required
                    value={textInput}
                    onChange={(e) => setTextInput(e.target.value)}
                    maxLength={50}
                />
                <button
                    type="submit"
                    onClick={(e) => submit(e)}
                    className="m-2 cursor-pointer bg-transparent hover:bg-green-600 text-green-600 font-semibold hover:text-white py-2 px-4 border border-green-600 hover:border-transparent rounded"
                >
                    Ajouter
                </button>
            </form>
        </>
    );
};
export default FormAddLabelWithSubCategories;
