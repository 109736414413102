import { format, parse } from "date-fns";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AutoSuggest from "react-autosuggest";
import { useToasts } from "react-toast-notifications";
import * as XLSX from "xlsx";
import { uploadLinkDoc } from "../../../_api/S3";
import {
    CATS,
    MVT_WITH_AMOUNT,
    REPETITION_VALUES,
    stringToFloat,
} from "../../../constants/Utils";
import IAccount from "../../../interfaces/IAccount";
import IGroupOperations from "../../../interfaces/IGroupOperations";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import { request } from "../../../utils/axios-utils";
import ItemOperation from "../../layout/ItemOperation";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import InputComponent from "../../layout/forms/InputComponent";
import InputDatePicker from "../../layout/forms/InputDatePicker";
import InputSwitch from "../../layout/forms/InputSwitch";
import Select from "../../layout/forms/Select";
import SelectWithSearch from "../../layout/forms/selectWithSearch";
import ButtonConfirmModalIcon from "../../layout/modals/ButtonConfirmModalIcon";
import IFile from "../Majeurs/Cloud/IFile";
import UploadDocument from "../Majeurs/UploadDocument";

const ListOperationsAccount: React.FC<{
    accountId: string;
    account: IAccount;
    majeurId: string;
    majeur: IProtectedAdult;
    fromItem: boolean;
}> = (props) => {
    const [operationsToRemove, setOperationsToRemove] = useState<Array<string>>(
        []
    );

    const [crgLoader, setCrgLoader] = useState<boolean>(false);
    const [isPdf, setIsPdf] = useState<boolean>(false);

    const [amount, setAmount] = useState<string>("");
    const [byAmount, setByAmount] = useState<number>(null);
    const [toAmount, setToAmount] = useState<number>(null);
    const [label, setLabel] = useState<string>("");
    const [filesSelected, setFilesSelected] = useState(null);
    const [files, setFiles] = useState<Array<string>>(null);
    const [fileList, setFileList] = useState<Array<IFile>>(null);
    const [listSubCategories, setListSubCategories] = useState<any>();

    const [suggestions, setSuggestions] = useState<any>([]);
    const [suggestionLabelsOperations, setSuggestionLabelsOperations] =
        useState<Array<any>>([]);

    const [valueGestion, setValueGestion] = useState<string>("_ANNUEL_");

    const [valueMouvement, setValueMouvement] = useState<string>(
        MVT_WITH_AMOUNT[0].value
    );
    const [selectedMouvement, setSelectedMouvement] = useState<any>(
        MVT_WITH_AMOUNT[0]
    );

    const [valueRepetition, setValueRepetition] = useState<string>("_AUCUNE_");

    const [aideIncomeCat, setAideIncomeCat] = useState<any>();
    const [aideIncomeCatWithNames, setAideIncomeCatWithNames] = useState<any>();
    const [aideIncomeCatOptions, setAideIncomeCatOptions] = useState<any>();

    const [cats, setCats] = useState<any>(
        sortBy(
            CATS.filter((c) => c.mvt === 0),
            "label"
        )
    );
    const [valueCategorie, setValueCategorie] = useState<any>(
        cats ? cats[0].value : ""
    );
    const [selectedCategorie, setSelectedCategorie] = useState<any>(
        cats ? cats[0] : ""
    );

    const [sousCats, setSousCats] = useState<any>(
        listSubCategories && listSubCategories.length > 0
            ? listSubCategories[cats[0].value]
            : []
    );
    const [valueSousCategorie, setValueSousCategorie] = useState<any>(
        sousCats && sousCats.length > 0 ? sousCats[0].value : ""
    );
    const [selectedSousCategorie, setSelectedSousCategorie] = useState<any>(
        sousCats && sousCats.length > 0 ? sousCats[0] : ""
    );

    const [selectedAccountsTarget, setSelectedAccountsTarget] =
        useState<string>("");

    const [aideMinPercent, setAideMinPercent] = useState<string>(null);
    const [aideMinMoney, setAideMinMoney] = useState<string>(null);

    const [accountSelected, setAccountSelected] = useState<IAccount>(null);
    const [accountsOptions, setAccountsOptions] = useState<any>(null);

    const [selectedBankOfFileSelected, setSelectedBankOfFileSelected] =
        useState<string>("");

    const [selectedFileExtension, setSelectedFileExtension] =
        useState<string>(null);
    const [selectedFileBankSelected, setSelectedFileBankSelected] =
        useState<any>(null);

    const [date, setDate] = useState<Date>(new Date());
    const [startDate, setStartDate] = useState<Date>(
        localStorage.getItem("start_date")
            ? new Date(
                  moment(localStorage.getItem("start_date"), "DD/MM/YYYY")
                      .startOf("day")
                      .format()
              )
            : new Date(moment().startOf("month").startOf("day").format())
    );

    const [endDate, setEndDate] = useState<Date>(
        localStorage.getItem("end_date")
            ? new Date(
                  moment(localStorage.getItem("end_date"), "DD/MM/YYYY")
                      .endOf("day")
                      .format()
              )
            : new Date(moment().endOf("day").format())
    );

    const [aideStartdate, setAideStartdate] = useState<Date>();
    const [aideEndDate, setAideEndDate] = useState<Date>();

    const [checkedFilter, setCheckedFilter] = useState<string>(
        !props.fromItem
            ? "all"
            : localStorage.getItem("check_filter")
            ? localStorage.getItem("check_filter")
            : "all"
    );

    const [searchTextOperation, setSearchTextOperation] = useState<string>("");

    const [totalRecettes, setTotalRecettes] = useState<number>(0);
    const [totalDepenses, setTotalDepenses] = useState<number>(0);

    const [groups, setGroups] = useState<Array<IGroupOperations>>(null);

    const { addToast } = useToasts();

    interface Language {
        name: string;
        year: number;
    }

    const reaffectOperations = () => {
        request({
            addToast,
            url: "/api/operations/reaffect",
            method: "post",
            data: { account_id: props.accountId },
        })
            .then((data) => {
                // The data has been saved.
                addToast("Les opérations ont bien été réaffectées", {
                    appearance: "success",
                });

                getOperationsList();
            })
            .catch();
    };

    const formatDate = (date: any) => {
        return [
            date.getDate().toString().padStart(2, "0"),
            (date.getMonth() + 1).toString().padStart(2, "0"),
            date.getFullYear(),
        ].join("/");
    };

    const filterByAmount = (amount: number, byOrTo: string) => {
        if (byOrTo === "by") {
            setByAmount(amount);
            getOperationsList(null, null, null, null, amount, null);
        }

        if (byOrTo === "to") {
            setToAmount(amount);
            getOperationsList(null, null, null, null, null, amount);
        }
    };

    const Autosuggest = AutoSuggest as { new (): AutoSuggest<Language> };

    // Closed or unclosed operations
    const openCloseOperationsBetweenDates = (
        accountId: string,
        startDate: Date,
        endDate: Date,
        isClosed: boolean
    ) => {
        request({
            addToast,
            url: "/api/operations/lockOrUnlock",
            method: "post",
            data: {
                account_id: accountId,
                start_date: startDate ? moment(startDate) : undefined,
                end_date: endDate ? moment(endDate) : undefined,
                is_closed: isClosed,
            },
        })
            .then((data) => {
                addToast(
                    `Les opérations ont bien été ${
                        isClosed ? "vérouillées" : "dévérouillées"
                    }`,
                    { appearance: "success" }
                );
                getOperationsList();
            })
            .catch();
    };

    // Download CRG file
    const downloadGestion = (
        majeurId: string,
        valueGestion: string,
        isPdf: boolean
    ) => {
        setCrgLoader(true);
        request({
            addToast,
            url: "/api/crg/createExcel",
            method: "post",
            data: {
                majeur_id: majeurId,
                start_date: startDate ? moment(startDate) : undefined,
                end_date: endDate ? moment(endDate) : undefined,
                periode: valueGestion,
                isPdf: isPdf,
            },
        })
            .then((data) => {
                addToast("Le document des comptes/gestion a bien été généré.", {
                    appearance: "success",
                });
                if (data.data.path) {
                    document.location.href = data.data.path;
                }
                setCrgLoader(false);
            })
            .catch();
    };

    // Download Social Helps file
    const downloadAide = () => {
        const aideObjects: any = {};
        aideObjects.startDate = aideStartdate;
        aideObjects.endDate = aideEndDate;
        aideObjects.minMoney = aideMinMoney;
        aideObjects.minPercent = aideMinPercent;
        aideObjects.incomeCat = aideIncomeCat;
        aideObjects.incomeCatsWithNames = aideIncomeCatWithNames;

        request({
            addToast,
            url: "/api/accounts/download-socials",
            method: "post",
            data: {
                majeur_id: props.majeurId,
                aide_objects: aideObjects,
            },
        })
            .then(({ data }) => {
                addToast("Le document a bien été téléchargé.", {
                    appearance: "success",
                });

                document.location.href = data;
            })
            .catch();
    };

    // Download the operations list
    const downloadOperations = (groups: any) => {
        if (groups && groups.length > 0) {
            request({
                addToast,
                url: "/api/accounts/download-operations-list",
                method: "post",
                data: {
                    groups: groups,
                    startDate: startDate,
                    endDate: endDate,
                },
            })
                .then((data) => {
                    addToast(
                        "La liste des opérations a bien été téléchargée.",
                        {
                            appearance: "success",
                        }
                    );
                    if (data.data.path) {
                        document.location.href = data.data.path;
                    }
                })
                .catch();
        } else {
            addToast("Il n'y a pas d'opération", { appearance: "warning" });
        }
    };

    // Creation of an operation via modal from (+)
    const createOperation = async () => {
        const newAmount = +amount.replace(",", ".");

        date.setHours(date.getHours() + 1);
        let file: any[] = [];
        if (filesSelected) {
            const formData = new FormData();
            for (let i = 0; i < filesSelected.length; i++) {
                formData.append("documents[]", filesSelected[i]);
            }
            formData.append("majeur_id", props.majeurId);
            await uploadLinkDoc(addToast, formData)
                .then(({ data }) => {
                    file = data.data.filesIds;
                })
                .catch();
        }
        let operation;
        if (parseInt(valueMouvement) < 2) {
            operation = {
                majeur_id: props.majeurId,
                account_id: props.accountId,
                source: "MANUAL",
                mouvement: {
                    name: selectedMouvement.label,
                    value: parseInt(valueMouvement),
                },
                category: {
                    name: selectedCategorie.label,
                    mvt: selectedCategorie.mvt,
                    value: parseInt(selectedCategorie.value),
                },
                sub_category: { name: selectedSousCategorie.label },
                cpt_cible: {
                    _id: "",
                    number: "",
                    type: "",
                    linked_operation_id: "",
                },
                date: date,
                label: label,
                amount: newAmount,
                repetition: valueRepetition,
                files: file,
            };
        } else {
            operation = {
                majeur_id: props.majeurId,
                account_id: props.accountId,
                mouvement: {
                    name: selectedMouvement.label,
                    value: parseInt(selectedMouvement.value),
                },
                category: { name: "", mvt: "", value: "" },
                sub_category: { name: "" },
                cpt_cible:
                    accountsOptions[parseInt(selectedAccountsTarget) - 1].data,
                date: date,
                label: label,
                amount: newAmount,
                repetition: valueRepetition,
                files: file,
            };
        }

        if (!operation.amount) {
            addToast("Il manque le montant.", { appearance: "warning" });
        } else if (newAmount < 0) {
            addToast("Le montant ne peut pas être négatif.", {
                appearance: "warning",
            });
        } else {
            insertOperation(operation);
        }
    };

    // Call back to add an operation in DB
    const insertOperation = (operation: any) => {
        request({
            addToast,
            url: "/api/operations/create",
            method: "post",
            data: { operation: operation },
        })
            .then((data) => {
                addToast("L'opération a bien été créée", {
                    appearance: "success",
                });
                getOperationsList();
                resetFormAddOperation();
            })
            .catch();
    };

    // Reset modal forms
    const resetFormAddOperation = () => {
        setLabel("");
        setValueRepetition("_AUCUNE_");
        setFilesSelected(null);
        setValueMouvement(MVT_WITH_AMOUNT[0].value);
        setSelectedMouvement(MVT_WITH_AMOUNT[0]);

        const categories = sortBy(
            CATS.filter((c) => c.mvt === 0),
            "label"
        );
        const listSubCategoriesList = listSubCategories[0];

        setCats(categories);
        setValueCategorie(categories[0].value);
        setSelectedCategorie(categories[0]);

        setSousCats(listSubCategoriesList);
        setValueSousCategorie(listSubCategoriesList[0].value);
        setSelectedSousCategorie(listSubCategoriesList[0]);

        setAmount("");
    };

    // Create an operation from the file import
    const importOperationFromBank = (
        amount: any,
        majeurId: string,
        accountId: string,
        date: string,
        label: string
    ) => {
        if (amount == "NaN") return; // Security to not insert in an incorrect amount.

        const newMouvement =
            amount > 0
                ? { value: 0, name: "Recettes" }
                : { value: 1, name: "Dépenses" };
        const newCategory =
            amount > 0
                ? { name: "Autres ressources", mvt: 0, value: 5 }
                : { name: "Autres dépenses", value: 13, mvt: 1 };
        const newSubCategory = { name: "Autres" };
        const newAmount = Math.abs(amount);
        const newOperation = {
            majeur_id: majeurId,
            account_id: accountId,
            mouvement: newMouvement,
            category: newCategory,
            sub_category: newSubCategory,
            cpt_cible: {
                _id: "",
                number: "",
                type: "",
                linked_operation_id: "",
            },
            date: date,
            label: label.replace("VIREMENT DE", ""),
            amount: newAmount,
            repetition: "_AUCUNE_",
            source: "FILE",
            auto: true,
        };

        insertOperation(newOperation);
    };

    // Import of bank entries and creation of operations
    const importBankFile = () => {
        if (selectedFileBankSelected !== null) {
            if (selectedBankOfFileSelected === null) {
                addToast("Vous n'avez pas sélectionné la banque.", {
                    appearance: "error",
                });
                return;
            }

            //#region CSV
            // Fichier Alain REBOH
            if (selectedBankOfFileSelected === "CM_ALR") {
                if (
                    selectedFileExtension != "text/csv" &&
                    selectedFileExtension != "application/vnd.ms-excel"
                ) {
                    addToast(
                        "Le fichier pour la banque CM ALR doit être un fichier CSV.",
                        { appearance: "error" }
                    );
                    return;
                }

                const reader = new FileReader();

                reader.readAsBinaryString(selectedFileBankSelected);
                reader.onloadend = () => {
                    const text = reader.result;

                    let lines = (text as string).split("\n");

                    lines.forEach((line: any) => {
                        let cols = line.split(";");

                        if (cols.length >= 3) {
                            if (
                                /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/gm.test(
                                    cols[0]
                                ) &&
                                cols[1] != "" &&
                                (cols[2] != "" || cols[3] != "")
                            ) {
                                let dateOperation = cols[0];
                                let labelOperation = cols[1];
                                let debitOperation = cols[2];
                                let creditOperation = cols[3];

                                let amount =
                                    debitOperation !== ""
                                        ? parseFloat(
                                              debitOperation.replace(",", ".")
                                          )
                                        : parseFloat(
                                              creditOperation.replace(",", ".")
                                          );

                                importOperationFromBank(
                                    amount,
                                    props.majeurId,
                                    props.accountId,
                                    dateOperation,
                                    labelOperation
                                );
                            }
                        }
                    });
                };
            }

            // Caisse d'épargne
            if (selectedBankOfFileSelected === "CE") {
                if (
                    selectedFileExtension != "text/csv" &&
                    selectedFileExtension != "application/vnd.ms-excel"
                ) {
                    addToast(
                        "Le fichier de la Caisse d'épargne doit être un fichier CSV.",
                        { appearance: "error" }
                    );
                    return;
                }

                const reader = new FileReader();

                reader.readAsBinaryString(selectedFileBankSelected);
                reader.onloadend = () => {
                    const text = reader.result;
                    let lines = (text as string).split("\n");
                    lines.forEach((line: any) => {
                        let cols = line.split(";");
                        if (cols.length >= 6) {
                            if (
                                cols[0] &&
                                cols[2] != "" &&
                                (cols[3] != "" || cols[4] != "")
                            ) {
                                let dateOperation = moment(
                                    cols[0],
                                    "DD/MM/YYYY"
                                ).format("DD/MM/YYYY"); // If the date is JJ/MM/AA

                                if (
                                    /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/gm.test(
                                        cols[0]
                                    )
                                ) {
                                    dateOperation = cols[0]; // If the date is already JJ/MM/AAAA
                                }

                                let labelOperation = cols[2] + "\n" + cols[5];
                                let debitOperation = cols[3];
                                let creditOperation = cols[4];

                                let amount =
                                    debitOperation !== ""
                                        ? parseFloat(
                                              debitOperation.replace(",", ".")
                                          )
                                        : parseFloat(
                                              creditOperation.replace(",", ".")
                                          );

                                importOperationFromBank(
                                    amount,
                                    props.majeurId,
                                    props.accountId,
                                    dateOperation,
                                    labelOperation
                                );
                            }
                        }
                    });
                };
            }

            // BNP Paribas
            if (selectedBankOfFileSelected === "BNP") {
                if (
                    selectedFileExtension != "text/csv" &&
                    selectedFileExtension != "application/vnd.ms-excel"
                ) {
                    addToast(
                        "Le fichier de la BNP Paribas doit être un fichier CSV.",
                        { appearance: "error" }
                    );
                    return;
                }

                const reader = new FileReader();

                reader.readAsBinaryString(selectedFileBankSelected);
                reader.onloadend = () => {
                    const text = reader.result;

                    let lines = (text as string).split("\n");

                    lines.forEach((line: any) => {
                        let cols = line.split(";");

                        if (cols.length >= 4) {
                            if (
                                /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/gm.test(
                                    cols[0]
                                ) &&
                                cols[3] != "" &&
                                cols[4] != ""
                            ) {
                                let dateOperation = cols[0];
                                let labelOperation = cols[3];
                                let amount = parseFloat(
                                    cols[4].replace(",", ".").replace(" ", "")
                                );

                                importOperationFromBank(
                                    amount,
                                    props.majeurId,
                                    props.accountId,
                                    dateOperation,
                                    labelOperation
                                );
                            }
                        }
                    });
                };
            }

            // CIC
            if (selectedBankOfFileSelected === "CIC") {
                if (
                    selectedFileExtension != "text/csv" &&
                    selectedFileExtension != "application/vnd.ms-excel"
                ) {
                    addToast("Le fichier du CIC doit être un fichier CSV.", {
                        appearance: "error",
                    });
                    return;
                }

                const reader = new FileReader();

                reader.readAsBinaryString(selectedFileBankSelected);
                reader.onloadend = () => {
                    const text = reader.result;

                    let lines = (text as string).split("\n");

                    lines.forEach((line: any) => {
                        let cols = line.split(";");

                        if (cols.length >= 4) {
                            if (
                                /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/gm.test(
                                    cols[0]
                                ) &&
                                cols[4] != "" &&
                                (cols[2] != "" || cols[3] != "")
                            ) {
                                let dateOperation = cols[0];
                                let labelOperation = cols[4];
                                let debitOperation = cols[2];
                                let creditOperation = cols[3];

                                let amount =
                                    debitOperation !== ""
                                        ? parseFloat(
                                              debitOperation.replace(",", ".")
                                          )
                                        : parseFloat(
                                              creditOperation.replace(",", ".")
                                          );

                                importOperationFromBank(
                                    amount,
                                    props.majeurId,
                                    props.accountId,
                                    dateOperation,
                                    labelOperation
                                );
                            }
                        }
                    });
                };
            }

            // Credit Mutuel
            if (selectedBankOfFileSelected === "CM") {
                if (
                    selectedFileExtension != "text/csv" &&
                    selectedFileExtension != "application/vnd.ms-excel"
                ) {
                    addToast(
                        "Le fichier pour le Crédit Mutuel doit être un fichier CSV.",
                        { appearance: "error" }
                    );
                    return;
                }

                const reader = new FileReader();

                reader.readAsBinaryString(selectedFileBankSelected);
                reader.onloadend = () => {
                    const text = reader.result;

                    let lines = (text as string).split("\n");

                    lines.forEach((line: any) => {
                        let cols = line.split(";");

                        if (cols.length >= 5) {
                            if (
                                /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/gm.test(
                                    cols[0]
                                ) &&
                                /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/gm.test(
                                    cols[1]
                                ) &&
                                cols[4] != "" &&
                                (cols[2] != "" || cols[3] != "")
                            ) {
                                let dateOperation = cols[1];
                                let labelOperation = cols[4];
                                let debitOperation = cols[2];
                                let creditOperation = cols[3];

                                let amount =
                                    debitOperation !== ""
                                        ? parseFloat(
                                              debitOperation.replace(",", ".")
                                          )
                                        : parseFloat(
                                              creditOperation.replace(",", ".")
                                          );

                                importOperationFromBank(
                                    amount,
                                    props.majeurId,
                                    props.accountId,
                                    dateOperation,
                                    labelOperation
                                );
                            }
                        }
                    });
                };
            }

            // Banque populaire
            if (selectedBankOfFileSelected === "BPopulaire") {
                if (
                    selectedFileExtension != "text/csv" &&
                    selectedFileExtension != "application/vnd.ms-excel"
                ) {
                    addToast(
                        "Le fichier pour la Banque Populaire doit être un fichier CSV.",
                        { appearance: "error" }
                    );
                    return;
                }

                const reader = new FileReader();

                reader.readAsBinaryString(selectedFileBankSelected);
                reader.onloadend = () => {
                    const text = reader.result;

                    let lines = (text as string).split("\n");

                    lines.forEach((line: any) => {
                        let cols = line.split(";");
                        if (cols.length >= 6) {
                            if (
                                /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/gm.test(
                                    cols[0]
                                ) &&
                                cols[2] != "" &&
                                (cols[8] != "" || cols[9] != "")
                            ) {
                                let dateOperation = cols[0];
                                let labelOperation = cols[2];
                                let debitOperation = cols[8];
                                let creditOperation = cols[9];

                                let amount =
                                    debitOperation !== ""
                                        ? parseFloat(
                                              debitOperation.replace(",", ".")
                                          )
                                        : parseFloat(
                                              creditOperation.replace(",", ".")
                                          );

                                importOperationFromBank(
                                    amount,
                                    props.majeurId,
                                    props.accountId,
                                    dateOperation,
                                    labelOperation
                                );
                            }
                        }
                    });
                };
            }

            // Banque populaire
            if (selectedBankOfFileSelected === "BPopulaireALC") {
                if (
                    selectedFileExtension != "text/csv" &&
                    selectedFileExtension != "application/vnd.ms-excel"
                ) {
                    addToast(
                        "Le fichier pour la Banque Populaire doit être un fichier CSV.",
                        { appearance: "error" }
                    );
                    return;
                }

                const reader = new FileReader();

                reader.readAsBinaryString(selectedFileBankSelected);
                reader.onloadend = () => {
                    const text = reader.result;

                    let lines = (text as string).split("\n");

                    lines.forEach((line: any) => {
                        let cols = line.split(";");
                        if (cols.length >= 6) {
                            if (
                                /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/gm.test(
                                    cols[2]
                                ) &&
                                cols[2] != "" &&
                                cols[6] != ""
                            ) {
                                let dateOperation = cols[2];
                                let labelOperation = cols[3];
                                let valueOperation = cols[6];
                                let amount = parseFloat(
                                    valueOperation.replace(",", ".")
                                );

                                importOperationFromBank(
                                    amount,
                                    props.majeurId,
                                    props.accountId,
                                    dateOperation,
                                    labelOperation
                                );
                            }
                        }
                    });
                };
            }

            // Banque Postale
            if (selectedBankOfFileSelected === "BPostale") {
                if (
                    selectedFileExtension != "text/csv" &&
                    selectedFileExtension != "application/vnd.ms-excel"
                ) {
                    addToast(
                        "Le fichier pour la Banque Postale doit être un fichier CSV.",
                        { appearance: "error" }
                    );
                    return;
                }

                const reader = new FileReader();

                reader.readAsBinaryString(selectedFileBankSelected);
                reader.onloadend = () => {
                    const text = reader.result;

                    let lines = (text as string).split("\n");

                    lines.forEach((line: any) => {
                        let cols = line.split(";");

                        if (cols.length >= 3) {
                            if (
                                /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/gm.test(
                                    cols[0]
                                ) &&
                                cols[1] != "" &&
                                cols[2] != ""
                            ) {
                                let dateOperation = cols[0];
                                let labelOperation = cols[1].slice(1, -1);
                                let valueOperation = cols[2];
                                let amount = parseFloat(
                                    valueOperation.replace(",", ".")
                                );

                                importOperationFromBank(
                                    amount,
                                    props.majeurId,
                                    props.accountId,
                                    dateOperation,
                                    labelOperation
                                );
                            }
                        }
                    });
                };
            }

            // Le Crédit Lyonnais
            if (selectedBankOfFileSelected === "LCL") {
                if (
                    selectedFileExtension != "text/csv" &&
                    selectedFileExtension != "application/vnd.ms-excel"
                ) {
                    addToast(
                        "Le fichier pour le Crédit Lyonnais doit être un fichier CSV.",
                        { appearance: "error" }
                    );
                    return;
                }

                const reader = new FileReader();

                reader.readAsBinaryString(selectedFileBankSelected);
                reader.onloadend = () => {
                    const text = reader.result;

                    let lines = (text as string).split("\n");

                    lines.forEach((line: any) => {
                        let cols = line.split(";");

                        if (cols.length >= 6) {
                            if (
                                /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/gm.test(
                                    cols[0].trim()
                                ) &&
                                cols[1] != "" &&
                                (cols[4] != "" || cols[5] != "")
                            ) {
                                let dateOperation = cols[0].trim();
                                let labelOperation =
                                    cols[5].trim() != ""
                                        ? cols[5].trim()
                                        : cols[4].trim();
                                let valueOperation = cols[1].trim();
                                let amount = parseFloat(
                                    valueOperation.replace(",", ".")
                                );

                                importOperationFromBank(
                                    amount,
                                    props.majeurId,
                                    props.accountId,
                                    dateOperation,
                                    labelOperation
                                );
                            }
                        }
                    });
                };
            }

            // Société générale
            if (selectedBankOfFileSelected === "SG") {
                if (
                    selectedFileExtension != "text/csv" &&
                    selectedFileExtension != "application/vnd.ms-excel"
                ) {
                    addToast(
                        "Le fichier de la Société générale doit être un fichier CSV.",
                        { appearance: "error" }
                    );
                    return;
                }

                const reader = new FileReader();

                reader.readAsBinaryString(selectedFileBankSelected);
                reader.onloadend = () => {
                    const text = reader.result;

                    let lines = (text as string).split("\n");

                    lines.forEach((line: any) => {
                        let cols = line.split(";");

                        if (cols.length >= 4) {
                            // date_comptabilisation;libellé_complet_operation;montant_operation;devise;
                            if (
                                /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/gm.test(
                                    cols[0]
                                ) &&
                                cols[2] != "" &&
                                cols[3] != ""
                            ) {
                                let dateOperation = cols[0];
                                let labelOperation = cols[2];
                                let amount = parseFloat(
                                    cols[3].replace(",", ".")
                                );

                                importOperationFromBank(
                                    amount,
                                    props.majeurId,
                                    props.accountId,
                                    dateOperation,
                                    labelOperation
                                );
                            }
                        }
                    });
                };
            }
            //#endregion

            //#region XLS/XLSX
            // Crédit Coopératif Direct
            if (selectedBankOfFileSelected == "BFCC") {
                if (selectedFileExtension != "application/vnd.ms-excel") {
                    addToast(
                        "Le fichier du Crédit Coopératif Direct doit être un fichier Excel (XLS).",
                        { appearance: "error" }
                    );
                    return;
                }

                const reader = new FileReader();

                reader.readAsBinaryString(selectedFileBankSelected);
                reader.onload = (r: any) => {
                    const wb = XLSX.read(r.target.result, { type: "binary" });
                    const ws = wb.Sheets[wb.SheetNames[0]];

                    let line = 4;
                    do {
                        let dateOperation = get(ws, [`A${line}`, "v"], "");
                        let labelOperation =
                            get(ws, [`B${line}`, "v"], "").trim() +
                            "\n" +
                            get(ws, [`C${line}`, "v"], "").trim();
                        let debitOperation = get(ws, [`E${line}`, "v"], "");
                        let creditOperation = get(ws, [`F${line}`, "v"], "");

                        if (
                            /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/gm.test(
                                dateOperation
                            ) &&
                            labelOperation != "" &&
                            ((debitOperation != "" && debitOperation > 0) ||
                                (creditOperation != "" && creditOperation > 0))
                        ) {
                            let amount =
                                debitOperation !== "" && debitOperation > 0
                                    ? debitOperation * -1
                                    : creditOperation;

                            importOperationFromBank(
                                amount,
                                props.majeurId,
                                props.accountId,
                                dateOperation,
                                labelOperation
                            );
                        }
                        line++;
                    } while (get(ws, [`A${line}`, "v"], "").indexOf("/") > -1);
                };
            }

            // Crédit Agricole
            if (selectedBankOfFileSelected == "CA") {
                if (
                    selectedFileExtension !=
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    addToast(
                        "Le fichier du Crédit Agricole doit être un fichier Excel (XLSX).",
                        { appearance: "error" }
                    );
                    return;
                }

                const reader = new FileReader();

                reader.readAsBinaryString(selectedFileBankSelected);
                reader.onload = (r: any) => {
                    const wb = XLSX.read(r.target.result, { type: "binary" });
                    const ws = wb.Sheets[wb.SheetNames[0]];

                    let line = 11;
                    do {
                        let dateOperation = get(ws, [`A${line}`, "v"], "");
                        dateOperation = formatDate(
                            new Date(Date.UTC(0, 0, dateOperation - 1))
                        );
                        let labelOperation = get(ws, [`B${line}`, "v"], "");
                        let debitOperation = get(ws, [`C${line}`, "v"], "");
                        let creditOperation = get(ws, [`D${line}`, "v"], "");

                        if (
                            /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/gm.test(
                                dateOperation
                            ) &&
                            labelOperation != "" &&
                            (debitOperation != "" || creditOperation != "")
                        ) {
                            let amount =
                                debitOperation !== ""
                                    ? debitOperation * -1
                                    : creditOperation;

                            importOperationFromBank(
                                amount,
                                props.majeurId,
                                props.accountId,
                                dateOperation,
                                labelOperation
                            );
                        }
                        line++;
                    } while (
                        formatDate(
                            new Date(
                                Date.UTC(
                                    0,
                                    0,
                                    get(ws, [`A${line}`, "v"], "") - 1
                                )
                            )
                        ).indexOf("/") > -1 &&
                        line <= 500
                    );
                };
            }
            //#endregion

            //#region OFX
            // Import par fichier OFX pour toutes les banques
            if (selectedBankOfFileSelected == "OFX") {
                if (selectedFileExtension != "ofx") {
                    addToast("Le fichier n'est pas au format .ofx", {
                        appearance: "error",
                    });
                    return;
                }

                // console.log("Reading OFX...");
                const reader = new FileReader();

                reader.readAsBinaryString(selectedFileBankSelected);

                reader.onloadend = () => {
                    let text = reader.result;
                    const regex = /<BANKTRANLIST>(.*?)<\/BANKTRANLIST>/s;
                    const transactionsList = text.match(regex)[0];
                    const regex2 = /<STMTTRN>(.*?)<\/STMTTRN>/gs;
                    const transactions = [...transactionsList.matchAll(regex2)];

                    if (!transactions || transactions.length === 0) {
                        addToast(
                            "Le fichier OFX ne contient pas d'opérations.",
                            { appearance: "error" }
                        );
                        return;
                    }

                    transactions.forEach((transaction) => {
                        const transac = transaction[0];
                        const date_rx = /<DTPOSTED>([^<]+)/;
                        const amount_rx = /<TRNAMT>([^<]+)/;
                        let date = parse(
                            transac.match(date_rx)[1].trim(),
                            "yyyyMMdd",
                            new Date()
                        );
                        date = format(date, "dd/MM/yyyy");
                        let amount = transac.match(amount_rx)[1].trim();

                        const name_rx = /<NAME>([^<]+)/;
                        let name;
                        if (transac.match(name_rx)) {
                            name = transac.match(name_rx)[1].trim();
                        } else {
                            const memo_rx = /<MEMO>([^<]+)/;
                            name = transac.match(memo_rx)[1].trim();
                        }
                        amount = amount.replace(",", ".");
                        console.log(date, amount, name);
                        // dd / mm / yyy;
                        importOperationFromBank(
                            amount,
                            props.majeurId,
                            props.accountId,
                            date,
                            name
                        );
                    });
                };
            }
            //#endregion
        } else {
            addToast(
                "Vous n'avez pas sélectionné de fichier ou le fichier n'est pas compatible.",
                { appearance: "error" }
            );
        }

        setSelectedBankOfFileSelected(null);
        setSelectedFileBankSelected(null);
    };

    // Security & selection of the file
    const fileSelected = (event: any) => {
        if (event.target.files.length === 1) {
            let extension = event.target.files[0].type;

            // Security for Credit Lyonnais where the code is not able to detect the "XLS" generated by the bank
            if (!extension && event.target.files[0].name.slice(-4) === ".xls") {
                extension = "text/csv"; // The file is a CSV with the extension called ".xls"
            }

            // Security for OFX files
            if (!extension && event.target.files[0].name.slice(-4) === ".ofx") {
                extension = "ofx"; // The file is a CSV with the extension called ".xls"
            }

            if (
                extension ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                extension === "text/csv" ||
                extension === "application/vnd.ms-excel" ||
                extension == "ofx"
            ) {
                setSelectedFileBankSelected(event.target.files[0]);
                setSelectedFileExtension(extension);
            } else {
                setSelectedFileBankSelected(null);
                setSelectedFileExtension(null);
            }
        }
    };

    // Security before addind an operation
    const verifAddOperation = () => {
        if (!amount || !amount.trim()) {
            addToast("Il manque le montant.", { appearance: "warning" });
            return false;
        }

        const newAmount = +amount.replace(",", ".");
        const dateMoment = moment(date, "DD/MM/YYYY");

        if (!date) {
            addToast("Attention à la date.", { appearance: "warning" });
            return false;
        } else if (!newAmount) {
            addToast("Le montant doit être un chiffre", {
                appearance: "warning",
            });
            return false;
        } else if (newAmount === 0) {
            addToast("Le montant ne peut pas être égal à 0.", {
                appearance: "warning",
            });
            return false;
        } else if (newAmount < 0) {
            addToast("Le montant ne peut pas être négatif.", {
                appearance: "warning",
            });
            return false;
        } else if (
            !dateMoment.isValid() ||
            dateMoment.toDate().getTime() < new Date(2014, 1, 1).getTime()
        ) {
            addToast("Attention la date est inférieure à 2014.", {
                appearance: "warning",
            });
            return false;
        }
        return true;
    };

    const updateMouvement = (value: any) => {
        if (value) {
            if (parseInt(value) < 2) {
                const categories = sortBy(
                    CATS.filter((c) => c.mvt === parseInt(value)),
                    "label"
                );
                const listSubCategoriesList =
                    listSubCategories[categories[0].value];
                setValueMouvement(value);
                setCats(categories);
                setValueCategorie(categories[0].value);
                setSelectedCategorie(categories[0]);
                setSousCats(listSubCategoriesList);
                setValueSousCategorie(listSubCategoriesList[0].value);
                setSelectedSousCategorie(listSubCategoriesList[0]);
            } else {
                setValueMouvement(value);
            }

            setSelectedMouvement(MVT_WITH_AMOUNT[value]);
        }
    };

    const updateCategory = (value: any) => {
        if (value) {
            setValueCategorie(value);
            setSelectedCategorie(cats.filter((c: any) => c.value === value)[0]);

            let subCats = sortBy(listSubCategories[value], "label");
            setSousCats(subCats);

            setValueSousCategorie(subCats[0].value);
            setSelectedSousCategorie(subCats[0]);
        }
    };

    const handleIncomeCatChange = (elements: any) => {
        let selectedCats: any = [];
        let selectedCatsWithNames: any = [];

        for (let i = 0; i < elements.length; i++) {
            for (let k = 0; k < aideIncomeCatOptions.length; k += 1) {
                if (aideIncomeCatOptions[k].value == elements[i].value) {
                    aideIncomeCatOptions[k].selected = elements[i].selected;
                    if (aideIncomeCatOptions[k].selected == true) {
                        selectedCats.push(aideIncomeCatOptions[k].value);
                        selectedCatsWithNames.push({
                            id: aideIncomeCatOptions[k].value,
                            label: aideIncomeCatOptions[k].label,
                        });
                    }
                }
            }
        }

        setAideIncomeCat(selectedCats);
        setAideIncomeCatWithNames(selectedCatsWithNames);
    };

    /*----Autosuggest section------*/
    const getSuggestions = (value: any) => {
        const inputValue = value.value
            ? value.value.trim().toLowerCase()
            : value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const res =
            inputLength === 0
                ? []
                : suggestionLabelsOperations.filter(
                      (suggestion: any) =>
                          suggestion["value"]
                              .toLowerCase()
                              .slice(0, inputLength) === inputValue
                  );

        return res;
    };

    const onSuggestionsFetchRequested = (value: any) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const getSuggestionValue = (suggestion: any) => {
        return suggestion["value"];
    };

    const renderSuggestion = (suggestion: any) => {
        return <div>{suggestion["value"]}</div>;
    };

    const onSuggestionSelected = (e: any, { suggestionValue }: any) => {
        setLabel(e.currentTarget.innerText);
    };
    /*----end Autosuggest section------*/

    useEffect(() => {
        if (!groups) {
            setAccountSelected(props.account);
            getOperationsList();
        }

        if (!aideIncomeCatOptions) {
            // Get subcategories
            request({ addToast, url: "/api/users/subCategories" })
                .then(({ data }) => {
                    let allSubCategories = data;
                    setListSubCategories(data);

                    let subCats = allSubCategories[cats[0].value];
                    setSousCats(subCats);

                    let tempOptions: any = [];
                    let categories = sortBy(
                        CATS.filter((c) => c.mvt === 0).filter(
                            (a) =>
                                a.value == "0" ||
                                a.value == "1" ||
                                a.value == "2" ||
                                a.value == "3"
                        ),
                        "label"
                    );

                    categories.map((element: any, index: number) => {
                        element.isParent = true;
                        element.selected = false;
                        tempOptions.push(element);

                        allSubCategories[index].map(
                            (subCat: any, index2: number) => {
                                let tempSubLabel = {
                                    value: "",
                                    label: "",
                                    selected: false,
                                };
                                tempSubLabel.value = `${subCat.key}`; //cat-index
                                tempSubLabel.label = `${subCat.label}`; //label
                                tempOptions.push(tempSubLabel);
                            }
                        );
                    });

                    setAideIncomeCatOptions(tempOptions);

                    // Get accounts options & labels
                    request({
                        addToast,
                        url: `/api/users/linkedLabelsWithAmount/accountsOptions/${props.accountId}`,
                    })
                        .then(({ data }) => {
                            setAccountsOptions(data.accountsOptions);
                            setSelectedAccountsTarget(
                                data.accountsOptions[0].value
                            );
                            setSuggestionLabelsOperations(
                                props.account.labels.map((l) => ({ value: l }))
                            );
                            setSuggestions(
                                props.account.labels.map((l) => ({ value: l }))
                            );
                        })
                        .catch();
                })
                .catch();
        } else {
            setValueSousCategorie(sousCats[0].value || "");
            setSelectedSousCategorie(sousCats[0]);
        }
    }, [aideIncomeCatOptions, accountsOptions]);

    useEffect(() => {
        request({
            addToast,
            url: `/api/users/linkedLabelsWithAmount/accountsOptions/${props.accountId}`,
        })
            .then(({ data }) => {
                setAccountsOptions(data.accountsOptions);
                setSelectedAccountsTarget(data.accountsOptions[0].value);
                setSuggestionLabelsOperations(
                    props.account.labels.map((l) => ({ value: l }))
                );
                setSuggestions(props.account.labels.map((l) => ({ value: l })));
            })
            .catch();
        setAccountSelected(props.account);
        getOperationsList();
    }, [props.account, props.accountId]);

    const getOperationsList = (
        checked_filter?: string,
        search_text?: string,
        start_date?: Date,
        end_date?: Date,
        by_amount?: number,
        to_amount?: number
    ) => {
        // Get the operations to the launch of the page
        request({
            addToast,
            url: `/api/accounts/operations-list`,
            method: "post",
            data: {
                checked_filter: checked_filter ? checked_filter : checkedFilter,
                search_text:
                    search_text || search_text == ""
                        ? search_text
                        : searchTextOperation,
                account_id: props.accountId,
                start_date: start_date ? start_date : startDate,
                end_date: end_date ? end_date : endDate,
                by_amount: by_amount ? by_amount : byAmount,
                to_amount: to_amount ? to_amount : toAmount,
            },
        })
            .then(({ data }) => {
                data.groups.forEach(
                    (group: IGroupOperations) =>
                        (group.operations = group.operations.reverse())
                );
                setGroups(data.groups);
                setTotalRecettes(data.total_recettes);
                setTotalDepenses(data.total_depenses);
            })
            .catch();
    };

    // Call back to remove the operations in DB
    const removeOperations = (operationsToRemove: Array<string>) => {
        request({
            addToast,
            url: `/api/operations/multiple/remove`,
            method: "post",
            data: { operations: operationsToRemove },
        })
            .then((data) => {
                addToast("Les opérations ont bien été supprimées", {
                    appearance: "success",
                });
                getOperationsList(null, null, startDate, endDate);
            })
            .catch();
    };

    return (
        <>
            <style>
                {`
                  @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                  }
                `}
            </style>
            <div className="list_operations_account">
                <SubBar
                    title={"Liste des opérations"}
                    classNameTitle="subBar_actions_title"
                >
                    {!props.majeur.archived && (
                        <div className="row">
                            <ButtonConfirmModalIcon
                                id="unlock"
                                iconClassName="white-icon"
                                onValidate={() =>
                                    openCloseOperationsBetweenDates(
                                        props.accountId,
                                        startDate,
                                        endDate,
                                        false
                                    )
                                }
                                icon="lock_open"
                                question="Voulez vous dévérouiller les opérations sur la période ?"
                            />
                            <div style={{ width: 20 }} />
                            <ButtonConfirmModalIcon
                                id="lock"
                                iconClassName="white-icon"
                                onValidate={() =>
                                    openCloseOperationsBetweenDates(
                                        props.accountId,
                                        startDate,
                                        endDate,
                                        true
                                    )
                                }
                                icon="lock"
                                question="Voulez vous vérouiller les opérations sur la période ?"
                            />
                        </div>
                    )}
                    <div style={{ width: 30 }} />
                    <span style={{ color: "white", marginRight: 5 }}>
                        Du :{" "}
                    </span>
                    <InputDatePicker
                        id="start_date"
                        inputDate={startDate}
                        onChange={(date: Date) => {
                            if (date) {
                                date.setHours(0, 0, 0, 0);
                                setStartDate(date);
                                localStorage.setItem(
                                    "start_date",
                                    date.toLocaleDateString("FR")
                                );
                                getOperationsList(null, null, date);
                            }
                        }}
                    />
                    <div className="flex005 hide-xs" />
                    <span style={{ color: "white", marginRight: 5 }}>
                        Au :{" "}
                    </span>
                    <InputDatePicker
                        id="end_date"
                        inputDate={endDate}
                        onChange={(date: Date) => {
                            if (date) {
                                date.setHours(23, 59, 59, 999);
                                setEndDate(date);
                                localStorage.setItem(
                                    "end_date",
                                    date.toLocaleDateString("FR")
                                );
                                getOperationsList(null, null, null, date);
                            }
                        }}
                    />
                    <div style={{ width: 30 }} />

                    {/* CRG BUTTON */}
                    {crgLoader === true && (
                        <div>
                            <div
                                style={{
                                    border: "1px solid white",
                                    borderRadius: "3px",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    paddingLeft: "16px",
                                    paddingRight: "16px",
                                    marginRight: "10px",
                                }}
                            >
                                <div
                                    id={`button_to_open_modal`}
                                    className={"layout-center-center row"}
                                    onClick={() =>
                                        addToast(
                                            "Votre téléchargement est en cours.",
                                            {
                                                appearance: "warning",
                                            }
                                        )
                                    }
                                >
                                    <div
                                        className="pointer button_border"
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            borderWidth: "3px",
                                            borderStyle: "solid",
                                            borderColor: "white",
                                            borderTopColor: "transparent",
                                            borderRadius: "50%",
                                            animation:
                                                "spin 1s linear infinite",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {crgLoader === false && (
                        <ButtonConfirmModalIcon
                            onValidate={() => {
                                downloadGestion(
                                    props.majeurId,
                                    valueGestion,
                                    isPdf
                                );
                            }}
                            iconClassName="white-icon"
                            text="C.R.G"
                            question="Télécharger un compte de gestion"
                            id="crg"
                        >
                            <div className="layout-space-around-center column full-width">
                                <div className="page_content column form_add_operation">
                                    <div className="my-input-container column">
                                        <label>
                                            Détail du C.R.G :{" "}
                                            <span className="red_asterix">
                                                *
                                            </span>
                                        </label>
                                        <Select
                                            id="Gestion"
                                            value={valueGestion}
                                            required
                                            onChange={(e) =>
                                                setValueGestion(e.target.value)
                                            }
                                            options={[
                                                {
                                                    value: "_ANNUEL_",
                                                    label: "Annuel",
                                                },
                                                {
                                                    value: "_MENSUEL_",
                                                    label: "Mensuel",
                                                },
                                            ]}
                                        />
                                        <InputSwitch
                                            id="isPdf"
                                            onChange={() => setIsPdf(!isPdf)}
                                            label="Impression"
                                            isSwitchMajeur={true}
                                            onText="PDF (PJ)"
                                            offText="XLS"
                                            value={isPdf}
                                        />
                                    </div>
                                </div>
                            </div>
                        </ButtonConfirmModalIcon>
                    )}

                    {/*  BUTTON AIDES SOC */}

                    <ButtonConfirmModalIcon
                        onValidate={() => downloadAide()}
                        iconClassName="white-icon"
                        text="Aides Soc"
                        question="Télécharger le tableau d'aides sociales"
                        id="OpenAide"
                    >
                        <div className="layout-space-around-stretch column full-width center">
                            <div className="page_content column">
                                <div className="column">
                                    <div className="input-wrapper layout-space-around-center">
                                        <InputComponent
                                            id="aide_min_percent"
                                            type="text"
                                            className="my-input" //flex-1
                                            label="Argent de Vie (%)"
                                            required
                                            value={aideMinPercent}
                                            onChange={(e) => {
                                                setAideMinPercent(
                                                    e.target.value
                                                );

                                                //updateAideParams(key, value, majeurId);
                                            }}
                                            maxLength={50}
                                        />
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="input-wrapper layout-space-around-center">
                                        <InputComponent
                                            id="aide_min_money"
                                            type="text"
                                            className="my-input" //flex-1
                                            label="Argent de poche minimum (€)"
                                            required
                                            value={aideMinMoney}
                                            onChange={(e) => {
                                                setAideMinMoney(e.target.value);
                                                //updateAideParams(key, value, majeurId);
                                            }}
                                            maxLength={50}
                                        />
                                    </div>
                                </div>

                                <div className="my-input-container column">
                                    <label>
                                        Sélectionnez le type de revenus:
                                    </label>
                                    <small
                                        style={{
                                            color: "red",
                                            marginBottom: "5px",
                                        }}
                                    >
                                        Pour sélectionner ou désélectionner
                                        plusieurs revenus, appuyez sur la touche
                                        CTRL sur Windows et CMD sur Mac
                                    </small>
                                    <div className="input-wrapper">
                                        <div>
                                            <div className="my-input-container column">
                                                {aideIncomeCatOptions && (
                                                    <select
                                                        name="aide_income_cat"
                                                        id="aide_income_cat"
                                                        className="select_income_category"
                                                        multiple
                                                        onChange={(e) =>
                                                            handleIncomeCatChange(
                                                                e.target
                                                            )
                                                        }
                                                    >
                                                        {aideIncomeCatOptions.map(
                                                            (item: any) => (
                                                                <option
                                                                    key={
                                                                        item.value
                                                                    }
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.isParent ===
                                                                    true
                                                                        ? item.label
                                                                        : `--- ${item.label}`}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                )}
                                                <div
                                                    style={{
                                                        marginBottom: 10,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="my-input-container column">
                                    <label>
                                        Dates demandées:{" "}
                                        <span className="red_asterix">*</span>
                                    </label>
                                    <div className="input-wrapper layout-space-around-center">
                                        <span
                                            style={{
                                                marginRight: "5px",
                                                fontSize: "12px",
                                            }}
                                        >
                                            Du :{" "}
                                        </span>
                                        <InputDatePicker
                                            id="aide_start_date"
                                            inputDate={aideStartdate}
                                            onChange={(date: Date) => {
                                                if (date) {
                                                    setAideStartdate(date);
                                                }
                                            }}
                                        />
                                        <div style={{ width: 15 }} />
                                        <span
                                            style={{
                                                marginRight: "5px",
                                                fontSize: "12px",
                                            }}
                                        >
                                            Au :{" "}
                                        </span>
                                        <InputDatePicker
                                            id="aide_end_date"
                                            inputDate={aideEndDate}
                                            onChange={(date: Date) => {
                                                if (date) {
                                                    setAideEndDate(date);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ButtonConfirmModalIcon>

                    <div style={{ width: 10 }} />

                    <div className="remove_operations_modal_container">
                        {/*  BUTTON DELETE OPERATIONS */}
                        <ButtonConfirmModalIcon
                            onOpen={() => setOperationsToRemove([])}
                            id="removeOperations"
                            onValidate={() => {
                                removeOperations(operationsToRemove);
                            }}
                            iconClassName="white-icon"
                            icon="delete"
                            question="Supprimer les opérations"
                        >
                            <div className="layout-space-around-center row full-width margin-top">
                                <div className="row layout-center-center start_delete">
                                    <span style={{ marginRight: 5 }}>Du :</span>
                                    <InputDatePicker
                                        id="start_delete"
                                        inputDate={startDate}
                                        onChange={(date: Date) => {
                                            if (date) {
                                                date.setHours(0, 0, 0, 0);
                                                setStartDate(date);
                                                getOperationsList(
                                                    null,
                                                    null,
                                                    date
                                                );
                                            }
                                        }}
                                    />
                                </div>

                                <div className="row layout-center-center end_delete">
                                    <span style={{ marginRight: 5 }}>Au :</span>
                                    <InputDatePicker
                                        id="end_delete"
                                        inputDate={endDate}
                                        onChange={(date: Date) => {
                                            if (date) {
                                                date.setHours(23, 59, 59, 999);
                                                setEndDate(date);
                                                getOperationsList(
                                                    null,
                                                    null,
                                                    null,
                                                    date
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="list_operations_container">
                                    {groups && groups.length === 0 && (
                                        <div className="layout-center-center row div-no-items">
                                            Il n'y a pas encore d'opérations
                                            pour cette période
                                            {(
                                                props.account.balance || 0
                                            ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                            })}{" "}
                                            €
                                        </div>
                                    )}
                                    {groups &&
                                        groups.length > 0 &&
                                        groups.map((group) => (
                                            <div key={group.date}>
                                                <div className="titleLetter row layout-start-center column_align_center_xs">
                                                    <span className="title_month">
                                                        {group.date}
                                                    </span>
                                                </div>
                                                {(group &&
                                                    group.operations.map(
                                                        (operation, index) => (
                                                            <div className="column">
                                                                <div className="row margin-top margin-bottom">
                                                                    {!operation.closed && (
                                                                        <input
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                if (
                                                                                    e
                                                                                        .currentTarget
                                                                                        .checked
                                                                                ) {
                                                                                    operationsToRemove.push(
                                                                                        e
                                                                                            .target
                                                                                            .id
                                                                                    );
                                                                                } else {
                                                                                    operationsToRemove.splice(
                                                                                        operationsToRemove.indexOf(
                                                                                            e
                                                                                                .target
                                                                                                .id
                                                                                        ),
                                                                                        1
                                                                                    );
                                                                                }
                                                                            }}
                                                                            type="checkbox"
                                                                            id={
                                                                                operation._id
                                                                            }
                                                                        />
                                                                    )}
                                                                    <ItemOperation
                                                                        key={
                                                                            operation._id
                                                                        }
                                                                        majeurId={
                                                                            props.majeurId
                                                                        }
                                                                        itemId={
                                                                            operation._id
                                                                        }
                                                                        operation={
                                                                            operation
                                                                        }
                                                                        index={
                                                                            index
                                                                        }
                                                                        closed={
                                                                            accountSelected.closed
                                                                        }
                                                                        account={
                                                                            accountSelected
                                                                        }
                                                                        accountsOption={
                                                                            accountsOptions
                                                                        }
                                                                        fromRemoveModal={
                                                                            true
                                                                        }
                                                                        listSubCategories={
                                                                            listSubCategories
                                                                        }
                                                                        getOperationsList={() =>
                                                                            getOperationsList()
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    )) || <Loader />}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </ButtonConfirmModalIcon>
                    </div>

                    {(!props.majeur.archived || !props.account.closed) && (
                        <div className="row">
                            {!props.account.is_synced && (
                                <div style={{ width: 30 }} />
                            )}

                            {/*  BUTTON IMPORT BANK FILE */}
                            <ButtonConfirmModalIcon
                                id="importBankExcel"
                                onValidate={() => importBankFile()}
                                iconClassName="white-icon"
                                icon="cloud_upload"
                                question="Importer des écritures bancaires"
                            >
                                <div className="layout-space-around-center column full-width">
                                    <div className="page_content column form_add_operation">
                                        <div className="my-input-container column">
                                            <label>
                                                Sélectionner le fichier
                                            </label>
                                            <input
                                                type="file"
                                                onChange={(e) =>
                                                    fileSelected(e)
                                                }
                                                style={{
                                                    padding: "4px",
                                                    marginBottom: "20px",
                                                }}
                                            />
                                        </div>

                                        <div className="my-input-container column">
                                            <label>
                                                De quelle banque vient ce
                                                fichier?
                                            </label>
                                            <Select
                                                id="whichBank"
                                                value={
                                                    selectedBankOfFileSelected
                                                }
                                                className="flex-1"
                                                required
                                                onChange={(e) =>
                                                    setSelectedBankOfFileSelected(
                                                        e.target.value
                                                    )
                                                }
                                                options={[
                                                    {
                                                        value: "NOTHING",
                                                        label: "Sélectionner la banque...",
                                                    },
                                                    {
                                                        value: "OFX",
                                                        label: "Fichier OFX (Quelque soit la banque)",
                                                    },
                                                    {
                                                        value: "CA",
                                                        label: "Crédit Agricole",
                                                    },
                                                    {
                                                        value: "CE",
                                                        label: "Caisse d'épargne",
                                                    },
                                                    {
                                                        value: "CIC",
                                                        label: "CIC",
                                                    },
                                                    {
                                                        value: "CM",
                                                        label: "Crédit Mutuel",
                                                    },
                                                    {
                                                        value: "CM_ALR",
                                                        label: "CM ALR",
                                                    },
                                                    {
                                                        value: "BNP",
                                                        label: "BNP Paribas",
                                                    },
                                                    {
                                                        value: "BPopulaire",
                                                        label: "Banque Populaire",
                                                    },
                                                    {
                                                        value: "BPopulaireALC",
                                                        label: "Banque Populaire (Format alternatif)",
                                                    },
                                                    {
                                                        value: "BPostale",
                                                        label: "Banque Postale",
                                                    },
                                                    {
                                                        value: "LCL",
                                                        label: "Le Crédit Lyonnais (LCL)",
                                                    },
                                                    {
                                                        value: "SG",
                                                        label: "Société Générale",
                                                    },
                                                    {
                                                        value: "BFCC",
                                                        label: "Crédit Coopératif Direct",
                                                    },
                                                ]}
                                            />
                                        </div>

                                        <div className="alert alert-info">
                                            <b>Fichier requis par banque</b>
                                            <br />
                                            <br />
                                            Si votre banque vous permet
                                            d'exporter en <b>OFX</b>, merci de
                                            prioriser ce format de fichier. Dans
                                            le cas échéant, voici la liste des
                                            formats compatibles en fonction de
                                            chaque banque.
                                            <br />
                                            <br />
                                            - Crédit Agricole : Fichier Excel
                                            (.xlsx)
                                            <br />
                                            - Caisse d'épargne : Fichier CSV
                                            <br />
                                            - CIC : Fichier CSV
                                            <br />
                                            - Crédit Mutuel : Fichier CSV
                                            <br />
                                            - BNP Paribas : Fichier CSV
                                            <br />
                                            - Banque Populaire : Fichier CSV
                                            <br />
                                            - Banque Postale : Fichier CSV
                                            <br />
                                            - Société Générale : Fichier Excel
                                            (au format .CSV)
                                            <br />
                                            - Le Crédit Lyonnais : Fichier CSV
                                            <br />
                                            - Le Crédit Coopératif Direct :
                                            Fichier XLS
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </ButtonConfirmModalIcon>

                            <div style={{ width: 30 }} />

                            <div className="reassign_button">
                                <i
                                    className="material-icons pointer"
                                    onClick={() => reaffectOperations()}
                                >
                                    loop
                                </i>
                                <span className="sync_tooltip_text" id="top">
                                    Réaffecter les opérations
                                </span>
                            </div>

                            <div style={{ width: 30 }} />

                            <ButtonConfirmModalIcon
                                id="addOpe"
                                beforeValidate={() => verifAddOperation()}
                                onValidate={() => createOperation()}
                                iconClassName="white-icon"
                                onClose={() => {
                                    resetFormAddOperation();
                                }}
                                icon="add"
                                question="Ajouter une opération"
                            >
                                <div className="layout-space-around-center column full-width">
                                    <div className="page_content column form_add_operation">
                                        <div className="my-input-container column">
                                            <div>
                                                <div className="my-input-container column margin-bottom">
                                                    <SelectWithSearch
                                                        id="Mouvement"
                                                        label="Mouvement"
                                                        className="flex-1"
                                                        needDefaultValue={true}
                                                        required
                                                        options={
                                                            MVT_WITH_AMOUNT
                                                        }
                                                        value={{
                                                            value: valueMouvement,
                                                            label: MVT_WITH_AMOUNT.find(
                                                                (mov: any) =>
                                                                    mov.value ===
                                                                    valueMouvement
                                                            )
                                                                ? MVT_WITH_AMOUNT.find(
                                                                      (
                                                                          mov: any
                                                                      ) =>
                                                                          mov.value ===
                                                                          valueMouvement
                                                                  ).label
                                                                : null,
                                                        }}
                                                        onChange={(e: any) => {
                                                            updateMouvement(
                                                                e.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                {parseInt(valueMouvement) >
                                                1 ? (
                                                    <div className="my-input-container column margin-bottom">
                                                        <SelectWithSearch
                                                            id="Compte_cible"
                                                            label="Compte cible"
                                                            className="flex-1"
                                                            needDefaultValue={
                                                                true
                                                            }
                                                            required
                                                            options={
                                                                accountsOptions
                                                            }
                                                            value={{
                                                                value: selectedAccountsTarget,
                                                                label: accountsOptions.find(
                                                                    (
                                                                        accountsOption: any
                                                                    ) =>
                                                                        accountsOption.value ===
                                                                        selectedAccountsTarget
                                                                )
                                                                    ? accountsOptions.find(
                                                                          (
                                                                              accountsOption: any
                                                                          ) =>
                                                                              accountsOption.value ===
                                                                              selectedAccountsTarget
                                                                      ).label
                                                                    : null,
                                                            }}
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                setSelectedAccountsTarget(
                                                                    e.value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="my-input-container margin-bottom">
                                                            <SelectWithSearch
                                                                id="Categorie"
                                                                label="Catégorie"
                                                                className="flex-1"
                                                                needDefaultValue={
                                                                    true
                                                                }
                                                                required
                                                                options={cats}
                                                                value={{
                                                                    value: valueCategorie,
                                                                    label: cats.find(
                                                                        (
                                                                            cat: any
                                                                        ) =>
                                                                            cat.value ===
                                                                            valueCategorie
                                                                    )
                                                                        ? cats.find(
                                                                              (
                                                                                  cat: any
                                                                              ) =>
                                                                                  cat.value ===
                                                                                  valueCategorie
                                                                          )
                                                                              .label
                                                                        : null,
                                                                }}
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    updateCategory(
                                                                        e.value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="my-input-container column margin-bottom">
                                                            <SelectWithSearch
                                                                id="Sous_Categorie"
                                                                label="Sous catégorie"
                                                                className="flex-1"
                                                                needDefaultValue={
                                                                    true
                                                                }
                                                                required
                                                                options={
                                                                    sousCats
                                                                }
                                                                value={{
                                                                    value: valueSousCategorie,
                                                                    label: sousCats.find(
                                                                        (
                                                                            sousCat: any
                                                                        ) =>
                                                                            sousCat.value ===
                                                                            valueSousCategorie
                                                                    )
                                                                        ? sousCats.find(
                                                                              (
                                                                                  sousCat: any
                                                                              ) =>
                                                                                  sousCat.value ===
                                                                                  valueSousCategorie
                                                                          )
                                                                              .label
                                                                        : null,
                                                                }}
                                                                onChange={(
                                                                    e: any
                                                                ) => {
                                                                    setValueSousCategorie(
                                                                        e.value
                                                                    );

                                                                    let sousCat =
                                                                        sousCats.find(
                                                                            (
                                                                                sousCat: any
                                                                            ) =>
                                                                                sousCat.value ===
                                                                                e.value
                                                                        );
                                                                    if (
                                                                        sousCat
                                                                    ) {
                                                                        setSelectedSousCategorie(
                                                                            sousCat
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="my-input-container">
                                                <InputDatePicker
                                                    id="theDate"
                                                    label="Date"
                                                    required
                                                    noIcon={true}
                                                    inputDate={date}
                                                    onChange={(date: Date) => {
                                                        if (date) {
                                                            setDate(date);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="my-input-container">
                                                <label>Label :</label>
                                                <AutoSuggest
                                                    id="label"
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={(
                                                        value: any
                                                    ) =>
                                                        onSuggestionsFetchRequested(
                                                            value
                                                        )
                                                    }
                                                    onSuggestionsClearRequested={() =>
                                                        onSuggestionsClearRequested()
                                                    }
                                                    getSuggestionValue={(
                                                        suggestion: any
                                                    ) =>
                                                        getSuggestionValue(
                                                            suggestion
                                                        )
                                                    }
                                                    renderSuggestion={(
                                                        suggestion: any
                                                    ) =>
                                                        renderSuggestion(
                                                            suggestion
                                                        )
                                                    }
                                                    onSuggestionSelected={(
                                                        e: any,
                                                        { suggestionValue }: any
                                                    ) =>
                                                        onSuggestionSelected(
                                                            e,
                                                            suggestionValue
                                                        )
                                                    }
                                                    inputProps={{
                                                        placeholder: "Label...",
                                                        value: label,
                                                        onChange: (
                                                            e,
                                                            { newValue }
                                                        ) => {
                                                            setLabel(newValue);
                                                        },
                                                        onBlur: () =>
                                                            onSuggestionsClearRequested(),
                                                    }}
                                                />
                                            </div>
                                            <div></div>
                                            <InputComponent
                                                id="amount"
                                                type="text"
                                                label="Montant"
                                                required
                                                value={amount}
                                                needBlur={true}
                                                onBlur={() => {
                                                    setAmount(
                                                        amount !== ""
                                                            ? String(
                                                                  parseFloat(
                                                                      amount.replace(
                                                                          ",",
                                                                          "."
                                                                      )
                                                                  )
                                                              )
                                                            : "0" || "0"
                                                    );
                                                }}
                                                onChange={(e) =>
                                                    setAmount(
                                                        stringToFloat(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                                maxLength={50}
                                            />
                                            <div className="my-input-container column">
                                                <SelectWithSearch
                                                    id="repetition"
                                                    label="Répétition"
                                                    className="flex-1"
                                                    needDefaultValue={true}
                                                    required
                                                    options={REPETITION_VALUES}
                                                    value={{
                                                        value: valueRepetition,
                                                        label: REPETITION_VALUES.find(
                                                            (rep: any) =>
                                                                rep.value ===
                                                                valueRepetition
                                                        )
                                                            ? REPETITION_VALUES.find(
                                                                  (rep: any) =>
                                                                      rep.value ===
                                                                      valueRepetition
                                                              ).label
                                                            : null,
                                                    }}
                                                    onChange={(e: any) => {
                                                        setValueRepetition(
                                                            e.value
                                                        );
                                                    }}
                                                />
                                                <div
                                                    className="divider"
                                                    style={{
                                                        marginBottom: "20px",
                                                    }}
                                                />
                                                <UploadDocument
                                                    filesSelected={
                                                        filesSelected
                                                    }
                                                    isMultiple={false}
                                                    fileList={fileList}
                                                    setFilesSelected={
                                                        setFilesSelected
                                                    }
                                                    files={files}
                                                    setFiles={setFiles}
                                                    setFileList={setFileList}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ButtonConfirmModalIcon>
                        </div>
                    )}
                </SubBar>

                {/* Listing of the operations */}
                <div className="filter_container">
                    <div className="row filter-select layout-space-between-center">
                        <div
                            className="col"
                            style={{ width: "15%", alignItems: "center" }}
                        >
                            <Select
                                id="Pointées"
                                value={checkedFilter}
                                required
                                className="SelectOperations"
                                onChange={(e) => {
                                    if (e.target.value) {
                                        setCheckedFilter(e.target.value);
                                        localStorage.setItem(
                                            "check_filter",
                                            e.target.value
                                        );
                                        getOperationsList(e.target.value);
                                    }
                                }}
                                options={[
                                    { value: "all", label: "Tous" },
                                    {
                                        value: "checked",
                                        label: "Pointées seulement",
                                    },
                                    {
                                        value: "notChecked",
                                        label: "Non pointées seulement",
                                    },
                                ]}
                            />
                        </div>

                        <div
                            className="col layout-start-center"
                            style={{ width: "50%" }}
                        >
                            <InputComponent
                                id="searchTextOperation"
                                type="text"
                                className="my-input-search-container row"
                                inputClassName="my-input-search"
                                placeHolder="Recherche: tapez un label, un montant, une catégorie ou une sous-catégorie"
                                search={true}
                                required
                                value={searchTextOperation}
                                onChange={(e) => {
                                    setSearchTextOperation(
                                        e.target.value.replace(",", ".")
                                    );
                                    getOperationsList(null, e.target.value);
                                }}
                                maxLength={50}
                            />
                            <i
                                className="material-icons clean_icon"
                                onClick={() => {
                                    setSearchTextOperation("");
                                    localStorage.setItem("search_text", "");
                                    getOperationsList(null, "");
                                }}
                            >
                                close
                            </i>
                        </div>
                        <div className="row">
                            <div className="row layout-center-center">
                                <b style={{ marginRight: 5 }}>De:</b>{" "}
                                <div style={{ marginBottom: -10 }}>
                                    <InputComponent
                                        id="byAmount"
                                        type="number"
                                        placeHolder={"Montant"}
                                        value={byAmount}
                                        onChange={(e) =>
                                            filterByAmount(e.target.value, "by")
                                        }
                                    />
                                </div>{" "}
                                <b style={{ marginLeft: 5 }}>€</b>
                            </div>
                            <div className="row layout-center-center">
                                <b style={{ marginRight: 5, marginLeft: 10 }}>
                                    A:
                                </b>{" "}
                                <div style={{ marginBottom: -10 }}>
                                    <InputComponent
                                        id="toAmount"
                                        type="number"
                                        placeHolder={"Montant"}
                                        value={toAmount}
                                        onChange={(e) =>
                                            filterByAmount(e.target.value, "to")
                                        }
                                    />
                                </div>{" "}
                                <b style={{ marginLeft: 5 }}>€</b>
                            </div>
                        </div>

                        <div
                            className="layout-end-center col"
                            style={{
                                marginRight: 5,
                                marginLeft: 5,
                                width: "30%",
                            }}
                        >
                            <span>
                                <b>R</b> :{" "}
                                {totalRecettes.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                })}{" "}
                                € | <b>D</b> :{" "}
                                <span style={{ color: "red" }}>
                                    {totalDepenses.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                    })}{" "}
                                    €
                                </span>
                            </span>
                            <div
                                className="pointer"
                                style={{ margin: "0 10px" }}
                                onClick={() => downloadOperations(groups)}
                            >
                                <i className="material-icons">book</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="list_operations_container">
                    {groups && groups.length === 0 && (
                        <div className="layout-center-center row div-no-items">
                            Il n'y a pas encore d'opérations pour cette période,
                            solde :{" "}
                            {(props.account.balance || 0).toLocaleString(
                                undefined,
                                { minimumFractionDigits: 2 }
                            )}{" "}
                            €
                        </div>
                    )}
                    {groups &&
                        groups.length > 0 &&
                        groups.map((group) => (
                            <div key={group.date}>
                                <div className="titleLetter row layout-start-center column_align_center_xs">
                                    <span className="title_month">
                                        {group.date}
                                    </span>
                                    {!searchTextOperation && (
                                        <span className="title_label">
                                            Départ :{" "}
                                            {(
                                                group.solde_depart || 0
                                            ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                            })}{" "}
                                            €
                                        </span>
                                    )}
                                    <span className="title_label">
                                        Recettes :{" "}
                                        {(group.recettes || 0).toLocaleString(
                                            undefined,
                                            { minimumFractionDigits: 2 }
                                        )}{" "}
                                        €
                                    </span>
                                    <span className="title_label">
                                        Dépenses :{" "}
                                        {(group.depenses || 0).toLocaleString(
                                            undefined,
                                            { minimumFractionDigits: 2 }
                                        )}{" "}
                                        €
                                    </span>
                                    {!searchTextOperation && (
                                        <span className="title_label">
                                            Fin :{" "}
                                            {(
                                                group.solde_fin || 0
                                            ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                            })}{" "}
                                            €
                                        </span>
                                    )}
                                </div>
                                {(accountsOptions &&
                                    group &&
                                    group.operations.map((operation, index) => (
                                        <ItemOperation
                                            key={operation._id}
                                            majeurId={props.majeurId}
                                            itemId={operation._id}
                                            operation={operation}
                                            index={index}
                                            fromRemoveModal={false}
                                            closed={accountSelected.closed}
                                            account={accountSelected}
                                            accountsOption={accountsOptions}
                                            listSubCategories={
                                                listSubCategories
                                            }
                                            getOperationsList={() =>
                                                getOperationsList()
                                            }
                                        />
                                    ))) || <Loader />}
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
};
export default ListOperationsAccount;
