import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { capitalizeFirstLetterOnly } from "../../../constants/Utils";
import IFintectureBank from "../../../interfaces/IFintectureBank";
import ILinkedLabelWithCategory from "../../../interfaces/ILinkedLabelWithCategory";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import Divider from "../../layout/Divider";
import FullBarTitle from "../../layout/FullBarTitle";
import Loader from "../../layout/Loader";
import FormAddLabelWithSubCategories from "../../layout/forms/FormAddLabelWithSubCategories";
import FormAddSubCategories from "../../layout/forms/FormAddSubCategories";
import ListLinkLabelsWithCategories from "../../layout/forms/ListLinkLabelsWithCategories";
import ListSubCategories from "../../layout/forms/ListSubCategories";
import LockAccount from "../../layout/forms/LockAccount";
import Ebics from "./Ebics";
import SyncAccountOption from "./SyncAccountOption";

const AccountingsSettings: React.FC = () => {
    let searchBySelect = false;

    const { addToast } = useToasts();

    const [listOfSubCategories, setListOfSubCategories] =
        useState<Array<any>>(null);
    const [listSubCategoriesForSelect, setListSubCategoriesForSelect] =
        useState<Array<any>>(null);
    const [listOfLinkedLabels, setListOfLinkedLabels] =
        useState<Array<any>>(null);

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [locks, setLocks] = useState<Array<boolean>>(null);

    const [selectedCategory, setSelectedCategory] = useState<number>(0);
    const [selectedSubCategory, setSelectedSubCategory] = useState<any>(null);
    const [editingSubCategoryValue, setEditingSubCategoryValue] =
        useState<string>(null);

    const [
        editingLinkedLabelWithCategory,
        setEditingLinkedLabelWithCategoryValue,
    ] = useState<ILinkedLabelWithCategory>(null);
    const [syncFintectureBanks, setSyncFintectureBanks] = useState<any>(null);

    const [syncLinxoConnections, setSyncLinxoConnections] = useState<any>(null);
    const [activated, setActivated] = useState<boolean>(false);

    const [isSync, setIsSync] = useState<boolean>(false);

    const [user, setUser] = useState<IUser>(null);
    useEffect(() => {
        if (
            !listOfSubCategories &&
            !listSubCategoriesForSelect &&
            !listOfLinkedLabels
        ) {
            updateSubCategories(selectedCategory);
            updateSubCategoriesForSelect(selectedCategory);
        }
        if (!locks) {
            actualiseLocks();
        }
        if (listSubCategoriesForSelect != null && isFirstLoad) {
            updateLinkedLabels(0, listSubCategoriesForSelect[0].label);
            setIsFirstLoad(false);
        }
    }, [listOfSubCategories, listSubCategoriesForSelect, locks]);
    useEffect(() => {
        if (!user) {
            request({ addToast, url: `/api/user` })
                .then(({ data }) => {
                    setUser(data);

                    if (data.abonnement) {
                        setActivated(data.abonnement.activated);
                    }
                })
                .catch();
        }
    }, [user, activated]);
    // SubCategories functions
    const removeSubCategory = (subCategory: any) => {
        const CFsubCategory = capitalizeFirstLetterOnly(subCategory);
        request({
            addToast,
            url: `/api/subCategories/destroy`,
            method: "post",
            data: {
                category: selectedCategory,
                subCategory: CFsubCategory,
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`La sous-catégorie a bien été supprimée`, {
                    appearance: "success",
                });
                updateSubCategories(selectedCategory);
            })
            .catch();
    };

    const updateSubCategory = (
        NewSubcategoryName: any,
        subCategoryToChange: any
    ) => {
        const CFsubCategoryToChange =
            capitalizeFirstLetterOnly(subCategoryToChange);
        const CFNewSubcategoryName =
            capitalizeFirstLetterOnly(NewSubcategoryName);

        request({
            addToast,
            url: `/api/subCategories/update`,
            method: "post",
            data: {
                category: selectedCategory,
                subCategory: CFsubCategoryToChange,
                newSubcategoryName: CFNewSubcategoryName,
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`La sous-catégorie a bien été mise à jour`, {
                    appearance: "success",
                });
                updateSubCategories(selectedCategory);
                setEditingSubCategoryValue(null);
            })
            .catch();
    };

    const showEditSubcategory = (subCategory: any) => {
        setEditingSubCategoryValue(capitalizeFirstLetterOnly(subCategory));
    };

    const showEditLinkedLabelWithCategory = (
        linkedLabelWithCategory: ILinkedLabelWithCategory
    ) => {
        setEditingLinkedLabelWithCategoryValue(linkedLabelWithCategory);
    };

    const createSubCategory = (category: number, subCategory: string) => {
        const CFsubCategory = capitalizeFirstLetterOnly(subCategory);
        request({
            addToast,
            url: "/api/subCategories/store",
            method: "post",
            data: {
                category: category,
                subCategory: CFsubCategory,
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`La sous catégorie a bien été ajoutée`, {
                    appearance: "success",
                });
                updateSubCategories(category);
            })
            .catch();
    };

    const updateSubCategories = (c: number) => {
        request({ addToast, url: `/api/users/subCategories/${c}` })
            .then(({ data }) => {
                setListOfSubCategories(data);
            })
            .catch();
    };

    // Linked labels functions
    const removeLinkedLabelWithCategory = (category: any) => {
        request({
            addToast,
            url: `/api/users/linkedLabels/destroy/${category._id}`,
            method: "post",
            data: {},
        })
            .then((data) => {
                console.log(category, selectedSubCategory);
                // The data has been saved.
                addToast(`Le label a bien été supprimé`, {
                    appearance: "success",
                });
                updateSubCategories(category.category);
                updateLinkedLabels(category.category, category.sub_category);
            })
            .catch();
    };

    const updateLinkedLabelWithCategory = (
        newLinkLabelsWithCategory: any,
        amount: number,
        id: string
    ) => {
        request({
            addToast,
            url: `/api/users/linkedLabels/update/${id}`,
            method: "post",
            data: { newLinkLabelsWithCategory: newLinkLabelsWithCategory },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`Le label a bien été modifié`, {
                    appearance: "success",
                });
                updateSubCategories(editingLinkedLabelWithCategory.category);
                updateLinkedLabels(
                    editingLinkedLabelWithCategory.category,
                    editingLinkedLabelWithCategory.sub_category
                );
                setEditingLinkedLabelWithCategoryValue(null);
            })
            .catch();
    };

    const createLinkedLabel = (
        mvt: number,
        category: number,
        subCategory: string,
        label: string
    ) => {
        const newLabel = capitalizeFirstLetterOnly(label);
        request({
            addToast,
            url: "/api/users/linkedLabels/store",
            method: "post",
            data: {
                mouvement: Number(mvt),
                category: Number(category),
                sub_category: subCategory,
                label: newLabel,
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`Le label a bien été associé`, {
                    appearance: "success",
                });

                updateSubCategories(category);
                //updateLinkedLabels(category, selectedSubCategory.label);
                updateLinkedLabels(category, subCategory);
            })
            .catch();
    };

    useEffect(() => {
        if (!user) {
            request({ addToast, url: `/api/user` })
                .then(({ data }) => {
                    setUser(data);

                    if (data.abonnement) {
                        if (!syncFintectureBanks) {
                            getFintectureBanks();
                        }

                        if (!syncLinxoConnections) {
                            getLinxoConnections();
                        }
                    }
                })
                .catch();
        }
    }, [user, syncFintectureBanks, syncLinxoConnections, isSync]);

    const getSelectedLinkedSubCat = (c: string, label?: string) => {
        if (label != null) updateLinkedLabels(parseInt(c), label);
        else searchBySelect = true;
        updateSubCategoriesForSelect(parseInt(c));
    };

    const updateSubCategoriesForSelect = (c: number) => {
        request({ addToast, url: `/api/users/subCategories/${c}` })
            .then(({ data }) => {
                let subCategoriesForSelect: Array<any> = Array();
                data.forEach(function (subCat: string, index: number) {
                    subCategoriesForSelect.push({
                        label: subCat,
                        value: String(index),
                        key: index,
                    });
                });
                setListSubCategoriesForSelect(subCategoriesForSelect);
                setSelectedSubCategory(subCategoriesForSelect[0]);
                if (searchBySelect == true) {
                    setSelectedSubCategory(subCategoriesForSelect[0]);
                    updateLinkedLabels(c, subCategoriesForSelect[0].label);
                    searchBySelect = false;
                }
            })
            .catch();
    };

    const updateLinkedLabels = (category: number, label: string) => {
        request({
            addToast,
            url: `/api/users/linkedLabels/${category}/${label}`,
        })
            .then(({ data }) => {
                var resultArray = Object.keys(data.labels).map(function (
                    label
                ) {
                    let lab = data.labels[label];
                    return lab;
                });
                setListOfLinkedLabels(resultArray);
            })
            .catch();
    };

    // Other functions
    const actualiseLocks = () => {
        request({ addToast, url: `/api/accounts/locks` })
            .then(({ data }) => {
                setLocks(data);
            })
            .catch();
    };

    const getSelectedCategory = (c: string) => {
        setSelectedCategory(parseInt(c));
        updateSubCategories(parseInt(c));
    };

    //---------FINTECTURE---------//
    const removeBank = (bankId: string) => {
        request({
            addToast,
            url: `api/fintecture/remove-bank`,
            method: "post",
            data: { bank_id: bankId },
        })
            .then((res) => {
                getFintectureBanks();
            })
            .catch();
    };

    const getFintectureBanks = () => {
        request({ addToast, url: "/api/fintecture/synced-banks" })
            .then((res) => {
                if (res.data.banks !== false) {
                    setSyncFintectureBanks(res.data.banks);
                }
            })
            .catch();
    };

    const handleRenewToken = (bankId: string) => {
        request({
            addToast,
            url: `api/fintecture/renew-bank-connection/${bankId}`,
            method: "post",
            data: {},
        })
            .then((res) => {
                document.location.href = res.data;
            })
            .catch();
    };

    const handleEnableAccount = (accountId: string) => {
        request({
            addToast,
            url: `api/fintecture/enable-account`,
            method: "post",
            data: { account_id: accountId },
        })
            .then((res) => {
                getFintectureBanks();
            })
            .catch();
    };

    const goToFintectureWidget = () => {
        request({
            addToast,
            url: "api/fintecture/create-connection",
            method: "post",
            data: {},
        })
            .then((res) => {
                window.location.href = res.data;
            })
            .catch();
    };
    //----------------------------//

    //-----------LINXO-----------//
    const handleDeleteLinxoConnection = (connectionId: string) => {
        if (
            confirm(
                "Attention: La suppression est définitive. Êtes vous sûr(e) ?"
            )
        ) {
            axios
                .post(`/api/linxo/delete-connection`, {
                    connection_id: connectionId,
                })
                .then((res) => {
                    getLinxoConnections();
                });
        }
    };

    const getLinxoConnections = () => {
        axios
            .get("/api/linxo/get-connections-status")
            .then((res) => {
                setSyncLinxoConnections(res.data.connections);
            })
            .catch((data) => {
                setSyncLinxoConnections([]);
            });
    };

    const goToLinxoWidget = () => {
        axios
            .get(`/api/linxo/get-widget-session`)
            .then(({ data }) => {
                // Access to Oxlin "add connection" page

                document.location.href =
                    data._links.add_connection +
                    "&redirect_url=" +
                    process.env.REACT_APP_LINXO_REDIRECT_URL;
            })
            .catch((data) => {
                const { message } = data.response.data;
                addToast(message, { appearance: "error" });
            });
    };
    //----------------------------//

    return (
        <>
            <div className="accountings_settings">
                {activated && (
                    <div>
                        {/* Ebics */}
                        {user.abonnement.ebics_activated && (
                            <div className="column">
                                <FullBarTitle
                                    sticky="top-90"
                                    text="Télétransmissions bancaires EBICS"
                                />
                                <div className="page_content">
                                    <Ebics />
                                </div>
                            </div>
                        )}

                        {/* Fintecture */}
                        <div className="column">
                            <FullBarTitle
                                sticky="top-90"
                                text="Synchronisation des comptes avec Fintecture"
                                helpUrl="/pdf/TUTO_Synchronisation_Comptes_Fintecture.pdf"
                            />
                            <div className="column w-100">
                                {!syncFintectureBanks && (
                                    <div className="w-100 alert alert-info text-center">
                                        Connexion en chargement
                                    </div>
                                )}
                                {syncFintectureBanks &&
                                    syncFintectureBanks.length === 0 && (
                                        <div className="w-100 alert alert-info text-center">
                                            Vous n'avez pas encore de
                                            connexions...
                                        </div>
                                    )}
                                {syncFintectureBanks &&
                                    syncFintectureBanks.length > 0 &&
                                    syncFintectureBanks.map(
                                        (bank: IFintectureBank) => (
                                            <div
                                                key={bank._id}
                                                className="card_bank"
                                            >
                                                <div className="layout-space-between-center row bg-slate-200">
                                                    <div className="p-2">
                                                        <b>{bank.name}</b>
                                                        {bank.status !==
                                                        "PENDING" ? (
                                                            <span
                                                                className="import_date"
                                                                style={{
                                                                    marginLeft: 5,
                                                                }}
                                                            >
                                                                (Expire le{" "}
                                                                {
                                                                    bank.expires
                                                                        .date
                                                                }{" "}
                                                                dans{" "}
                                                                {
                                                                    bank.expires
                                                                        .in
                                                                }{" "}
                                                                jours)
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>

                                                    <div>
                                                        {bank.status ===
                                                        "READY" ? (
                                                            <i
                                                                className="material-icons"
                                                                style={{
                                                                    color: "green",
                                                                }}
                                                            >
                                                                done
                                                            </i>
                                                        ) : (
                                                            <i
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                                className="material-icons cursor-pointer"
                                                            >
                                                                sync_problem
                                                            </i>
                                                        )}
                                                        <i
                                                            className="material-icons cursor-pointer"
                                                            style={{
                                                                color: "grey",
                                                            }}
                                                            onClick={() =>
                                                                removeBank(
                                                                    bank._id
                                                                )
                                                            }
                                                        >
                                                            delete_outline
                                                        </i>
                                                    </div>
                                                </div>
                                                {(bank.status ===
                                                    "TOKEN_EXPIRED" ||
                                                    bank.status ===
                                                        "RENEWING") && (
                                                    <div
                                                        className="alert alert-danger pointer"
                                                        onClick={() =>
                                                            handleRenewToken(
                                                                bank._id
                                                            )
                                                        }
                                                    >
                                                        L'accès à votre banque à
                                                        expiré. Merci de cliquer
                                                        ici pour renouveler la
                                                        connexion !
                                                    </div>
                                                )}
                                                {bank.status !==
                                                    "TOKEN_EXPIRED" &&
                                                    bank.status !==
                                                        "RENEWING" &&
                                                    90 -
                                                        moment().diff(
                                                            bank.opened_at,
                                                            "days"
                                                        ) <
                                                        5 && (
                                                        <div
                                                            className="alert alert-danger pointer"
                                                            onClick={() =>
                                                                handleRenewToken(
                                                                    bank._id
                                                                )
                                                            }
                                                        >
                                                            L'accès à votre
                                                            banque va expirer
                                                            dans{" "}
                                                            {90 -
                                                                moment().diff(
                                                                    bank.opened_at,
                                                                    "days"
                                                                )}{" "}
                                                            jour(s). Merci de
                                                            cliquer ici pour
                                                            renouveler la
                                                            connexion !
                                                        </div>
                                                    )}
                                                {bank.status === "ERROR" && (
                                                    <div className="alert alert-danger pointer">
                                                        Nous avons rencontré une
                                                        anomalie avec cette
                                                        banque et en avons été
                                                        alerté. Nous faisons
                                                        notre maximum pour la
                                                        résoudre.
                                                    </div>
                                                )}
                                                {bank.status ===
                                                    "SYNCHRONIZING" && (
                                                    <div className="alert alert-info">
                                                        Les comptes de cette
                                                        banque sont en cours de
                                                        synchronisation... Ils
                                                        seront disponibles dans
                                                        quelques minutes.
                                                    </div>
                                                )}
                                                {bank.status != "ERROR" &&
                                                    bank.accounts.map(
                                                        (a: any) => {
                                                            if (
                                                                a.name ===
                                                                "M.OU MME DJAHNINE LOUGNON"
                                                            ) {
                                                                return null;
                                                            }
                                                            return (
                                                                <div
                                                                    key={a._id}
                                                                    className="account row m-1"
                                                                >
                                                                    <div className="content-center">
                                                                        {a.gerancia_account_id ? (
                                                                            <i
                                                                                className="material-icons"
                                                                                style={{
                                                                                    color: "green",
                                                                                }}
                                                                            >
                                                                                sync
                                                                            </i>
                                                                        ) : (
                                                                            <i
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                                className="material-icons"
                                                                            >
                                                                                sync_disabled
                                                                            </i>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            a.enabled_at
                                                                                ? "leading-9 stroke"
                                                                                : "leading-9 line-through"
                                                                        }
                                                                    >
                                                                        {a.gerancia_account_id &&
                                                                            a.gerancia_account && (
                                                                                <b>
                                                                                    {
                                                                                        a
                                                                                            .gerancia_account
                                                                                            .protected_adult
                                                                                            .first_name
                                                                                    }{" "}
                                                                                    {
                                                                                        a
                                                                                            .gerancia_account
                                                                                            .protected_adult
                                                                                            .last_name
                                                                                    }{" "}
                                                                                    -
                                                                                    Compte{" "}
                                                                                    {
                                                                                        a
                                                                                            .gerancia_account
                                                                                            .number
                                                                                    }
                                                                                </b>
                                                                            )}
                                                                        {!a.gerancia_account_id &&
                                                                            a.name}
                                                                        <span
                                                                            className="import_date text-xs"
                                                                            style={{
                                                                                marginLeft: 20,
                                                                            }}
                                                                        >
                                                                            (Dernière
                                                                            synchronisation
                                                                            :{" "}
                                                                            {
                                                                                a.synced_date
                                                                            }
                                                                            )
                                                                        </span>
                                                                    </div>
                                                                    {a.gerancia_account_id && (
                                                                        <button
                                                                            className={
                                                                                !a.enabled_at
                                                                                    ? "cursor-pointer bg-transparent hover:bg-green-600 text-red-600 font-semibold hover:text-white py-2 px-4 border border-red-600 hover:border-transparent rounded"
                                                                                    : "cursor-pointer bg-transparent hover:bg-red-600 text-green-600 font-semibold hover:text-white py-2 px-4 border border-green-600 hover:border-transparent rounded"
                                                                            }
                                                                            style={{
                                                                                marginLeft: 10,
                                                                            }}
                                                                            onClick={() =>
                                                                                handleEnableAccount(
                                                                                    a._id
                                                                                )
                                                                            }
                                                                        >
                                                                            {!a.enabled_at
                                                                                ? "Inactif"
                                                                                : "Actif"}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        )
                                    )}
                                <div className="row layout-center-center">
                                    <button
                                        type="button"
                                        className="m-2 cursor-pointer bg-transparent hover:bg-green-600 text-green-600 font-semibold hover:text-white py-2 px-4 border border-green-600 hover:border-transparent rounded"
                                        onClick={() => goToFintectureWidget()}
                                    >
                                        Ajouter une connexion avec Fintecture
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Linxo */}
                        <div className="column">
                            <div className="column w-100">
                                <FullBarTitle
                                    sticky="top-90"
                                    text="Connexions ouvertes avec Linxo"
                                />
                                {!syncLinxoConnections && (
                                    <div className="w-100 alert alert-info text-center">
                                        Chargement de vos connexions et comptes
                                        en cours...
                                    </div>
                                )}
                                {syncLinxoConnections &&
                                    syncLinxoConnections.length === 0 && (
                                        <div className="w-100 alert alert-info text-center">
                                            Vous n'avez pas encore de
                                            connexions...
                                        </div>
                                    )}
                                {syncLinxoConnections &&
                                    syncLinxoConnections.length > 0 &&
                                    syncLinxoConnections.map(
                                        (connection: any) => (
                                            <div
                                                key={connection.id}
                                                className="card_bank"
                                            >
                                                <div className="layout-space-between-center row bg-slate-200">
                                                    <div className="w-full">
                                                        <div className="flex justify-between p-2">
                                                            <div>
                                                                <b>
                                                                    {
                                                                        connection.name
                                                                    }
                                                                </b>
                                                                <br />
                                                                Propriétaire :{" "}
                                                                {
                                                                    connection
                                                                        .owner
                                                                        .name
                                                                }
                                                            </div>
                                                            <div>
                                                                {
                                                                    <span className="leading-9">
                                                                        {
                                                                            connection.status
                                                                        }
                                                                    </span>
                                                                }
                                                                <i
                                                                    className="material-icons cursor-pointer"
                                                                    style={{
                                                                        color: "grey",
                                                                    }}
                                                                    onClick={() =>
                                                                        handleDeleteLinxoConnection(
                                                                            connection.id
                                                                        )
                                                                    }
                                                                >
                                                                    delete_outline
                                                                </i>
                                                            </div>
                                                        </div>
                                                        {connection.accounts.map(
                                                            (a: any) => (
                                                                <div
                                                                    key={a.id}
                                                                    className="account row bg-white"
                                                                >
                                                                    <div
                                                                        className={
                                                                            !a.enabled_at &&
                                                                            !a.isSync
                                                                                ? "stroke account_content leading-9 m-1 "
                                                                                : !a.enabled_at &&
                                                                                  a.isSync
                                                                                ? "leading-9"
                                                                                : "line-through"
                                                                        }
                                                                    >
                                                                        <b>
                                                                            Compte
                                                                            n°{" "}
                                                                            {a.account_number +
                                                                                " - "}
                                                                            {
                                                                                a.name
                                                                            }
                                                                        </b>
                                                                    </div>
                                                                    <SyncAccountOption
                                                                        accountNumber={
                                                                            a.account_number
                                                                        }
                                                                        accountIsSync={(
                                                                            isSync: boolean
                                                                        ) => {
                                                                            a.isSync =
                                                                                isSync;
                                                                            setIsSync(
                                                                                isSync
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                <div className="row layout-center-center">
                                    <button
                                        type="button"
                                        className="m-2 cursor-pointer bg-transparent hover:bg-green-600 text-green-600 font-semibold hover:text-white py-2 px-4 border border-green-600 hover:border-transparent rounded"
                                        onClick={() => goToLinxoWidget()}
                                    >
                                        Ajouter une connexion avec Linxo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="subBar_actions layout-space-between-center row">
                    <span className="subBar_actions_title">
                        Les sous catégories
                    </span>
                </div>

                {!listOfSubCategories &&
                    !locks &&
                    !listSubCategoriesForSelect &&
                    !listOfLinkedLabels && <Loader />}

                {listOfSubCategories &&
                    locks &&
                    listSubCategoriesForSelect &&
                    listOfLinkedLabels && (
                        <div>
                            <div className="row p-2">
                                <FormAddSubCategories
                                    getSelectedCategory={(c: string) =>
                                        getSelectedCategory(c)
                                    }
                                    submit={(
                                        category: number,
                                        subCategory: string
                                    ) =>
                                        createSubCategory(category, subCategory)
                                    }
                                />
                                <ListSubCategories
                                    listOfSubCategories={listOfSubCategories}
                                    remove={(l: any) => removeSubCategory(l)}
                                    editSubCategory={(l: any) =>
                                        showEditSubcategory(l)
                                    }
                                    updateSubCategory={(
                                        newSubcategoryName: string,
                                        categoryToEdit: string
                                    ) =>
                                        updateSubCategory(
                                            newSubcategoryName,
                                            categoryToEdit
                                        )
                                    }
                                    subCategoryInEdit={editingSubCategoryValue}
                                    cancel={() =>
                                        setEditingSubCategoryValue(null)
                                    }
                                />
                            </div>
                            <Divider />
                            <LockAccount
                                locks={locks}
                                actualiseLocks={() => actualiseLocks()}
                            />
                            <Divider />

                            <FullBarTitle text="Relier des labels aux libellés des opérations bancaires" />

                            <div className="row  p-2" style={{ marginTop: 10 }}>
                                <FormAddLabelWithSubCategories
                                    getSubCategoriesFormLinkedLabel={(
                                        c: string,
                                        label?: string
                                    ) => getSelectedLinkedSubCat(c, label)}
                                    listSubCategoriesForSelect={
                                        listSubCategoriesForSelect
                                    }
                                    submit={(
                                        m: number,
                                        c: number,
                                        s: string,
                                        l: string
                                    ) => createLinkedLabel(m, c, s, l)}
                                    selectedSubCategory={selectedSubCategory}
                                />
                                <ListLinkLabelsWithCategories
                                    list={listOfLinkedLabels}
                                    remove={(category: any) =>
                                        removeLinkedLabelWithCategory(category)
                                    }
                                    editLinkedLabelWithCategory={(
                                        l: ILinkedLabelWithCategory
                                    ) => showEditLinkedLabelWithCategory(l)}
                                    updateLinkedLabelWithCategory={(
                                        newLinkLabelsWithCategory: string,
                                        amount: number,
                                        id: string
                                    ) =>
                                        updateLinkedLabelWithCategory(
                                            newLinkLabelsWithCategory,
                                            amount,
                                            id
                                        )
                                    }
                                    linkedLabelWithCategoryInEdit={
                                        editingLinkedLabelWithCategory
                                    }
                                    cancel={() =>
                                        setEditingLinkedLabelWithCategoryValue(
                                            null
                                        )
                                    }
                                />
                            </div>
                        </div>
                    )}
            </div>
        </>
    );
};
export default AccountingsSettings;
