import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { createControl } from "../../../../_api/Controle";
import { getCourts } from "../../../../_api/Court";
import { Button } from "../../../../_shared/Button";
import { DefaultDropdown } from "../../../../_shared/Dropdown";
import { InputForm } from "../../../../_shared/InputField";

export const CreateMandat = () => {
    const { addToast } = useToasts();

    const history = useHistory();
    const [tribunals, setTribunals] = useState([]);

    const [tribunal, setTribunal] = useState(null);
    const [numeroRG, setNumeroRG] = useState("");
    const [portalis, setPortalis] = useState("");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [firstLastName, setFirstLastName] = useState("");
    const [name, setName] = useState("");

    const [agentName, setAgentName] = useState("");
    const [agentMail, setAgentMail] = useState("");

    const [subRogeName, setSubRogeName] = useState("");
    const [subRogeMail, setSubRogeMail] = useState("");

    useEffect(() => {
        getCourts(addToast).then((resp) => {
            setTribunal(resp.data[0].id);
            setTribunals(
                resp.data.map((res: any) => {
                    return { value: res.id, label: res.city };
                })
            );
        });
    }, []);

    return (
        <div className="p-4">
            <Button
                type={"empty"}
                name={"Retour"}
                action={() => {
                    history.push("/list_mandats");
                }}
            />
            <div className="mt-2">
                <DefaultDropdown
                    name={"Tribunal"}
                    options={tribunals}
                    value={tribunal}
                    onChange={(e) => setTribunal(e.target.value)}
                />
            </div>
            <hr className="m-4" />

            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Numéro de RG"
                        set={setNumeroRG}
                        value={numeroRG}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Numéro Portalis"
                        set={setPortalis}
                        value={portalis}
                        required={true}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Nom et prénom"
                        set={setName}
                        value={name}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Nom de jeune fille"
                        set={setFirstLastName}
                        value={firstLastName}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Début de nomination"
                        type="date"
                        set={setStartDate}
                        value={startDate}
                        required={true}
                    />
                </div>
                {/* <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Fin de controle"
                        type="date"
                        set={setEndDate}
                        value={endDate}
                        required={true}
                    />
                </div> */}
            </div>

            <hr className="m-4" />
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Nom du tuteur/mandataire"
                        set={setAgentName}
                        value={agentName}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Email du tuteur/mandataire"
                        type="email"
                        set={setAgentMail}
                        value={agentMail}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Nom du subrogé"
                        set={setSubRogeName}
                        value={subRogeName}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Email du subroge"
                        type="email"
                        set={setSubRogeMail}
                        value={subRogeMail}
                    />
                </div>
            </div>
            <div className="flex justify-end">
                <Button
                    type={"empty"}
                    name={"Enregistrer"}
                    action={() => {
                        const payload = {
                            tribunal: parseInt(tribunal),
                            rg_number: numeroRG,
                            portalis_number: portalis,
                            start_period: new Date(startDate),
                            end_period: new Date(endDate),
                            name: firstLastName,
                            second_name: name,
                            tutor_name: agentName,
                            tutor_mail: agentMail,
                            subroge_name: subRogeName,
                            subroge_mail: subRogeMail,
                        };

                        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (agentMail.length > 0 && !regex.test(agentMail)) {
                            addToast("Votre email est invalide", {
                                appearance: "warning",
                            });
                            return;
                        }

                        if (numeroRG.length < 3 && portalis.length < 3) {
                            addToast(
                                "Votre numéro de RG ou portalis est invalide.",
                                {
                                    appearance: "warning",
                                }
                            );
                            return;
                        }
                        createControl(addToast, payload).then((resp) => {
                            addToast(resp.data.message, {
                                appearance: "success",
                            });
                            history.push("/list_mandats");
                        });
                    }}
                />
            </div>
        </div>
    );
};
