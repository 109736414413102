import React from "react";

const IconAction: React.FC<{ icon: string; action: any; src: string }> = (
    props
) => {
    return props.src ? (
        <img
            style={{ cursor: "pointer", maxWidth: 26 }}
            height={26}
            width={26}
            onClick={props.action}
            src={props.src}
        />
    ) : (
        <i className="material-icons" onClick={props.action}>
            {props.icon}
        </i>
    );
};

export default IconAction;
