import React, { useEffect, useRef, useState } from "react";
import {
    defaultMajeurFields,
    majeurFields,
    majeurFieldsCustom,
    mesureFields,
} from "../../../constants/Utils";
import IFicheAdmin from "../../../interfaces/IFicheAdmin";
import InputSwitch from "../forms/InputSwitch";

const FicheAdministrativeModal: React.FC<IFicheAdmin> = (props) => {
    // Hooks for changing variables
    const [majeurs, setMajeurs] = useState<Array<any> | null>(props.majeurs);
    const [ids, setIds] = useState<any>();
    const [isOpened, setIsOpened] = useState(false);

    // Contain all fields representing those which will be displayed later
    const state: any = {
        ...majeurFields,
        ...mesureFields,
        majeurs: majeurs,
        ids: ids,
    };

    const getObjectsIds = (majeur: any) => {
        if (!majeur) {
            return {};
        }

        const IDS: any = {};
        const keys = Object.keys(majeur);

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = majeur[key];
            if (value) {
                if (
                    (Array.isArray(value) || typeof value === "object") &&
                    value.length
                ) {
                    value.forEach((v: any) => {
                        IDS[v._id] = true;
                    });
                }
            }
        }

        setIds(IDS);
    };

    // Change state of an ids (true or false) in an Object
    const onChangeObjects = (name: string, id: string) => (value: boolean) => {
        state.ids[id] = value;
    };

    // Change state of an ids (true or false)
    const onChange = (name: string) => (value: any) => {
        state[name] = value;
    };

    // Return label of an object
    const getLabel = (object: any) => {
        return (
            object.label ||
            object.nature ||
            object.number ||
            `${object.last_name} ${object.first_name} ${object.profession} ${object.organisme}`
        );
    };

    // Send back datas to display in the pdf when we push confirm button
    const onValidate = () => {
        setIsOpened(false);

        if (majeurs.length > 1) {
            const fields = defaultMajeurFields
                .filter((f: any) => state[f])
                .map((f: any) => f);
            props.onValidate(fields, majeurs, state.ids);
        } else {
            const fields = Object.keys(state)
                .filter((f) => state[f])
                .map((f) => f);
            props.onValidate(fields, majeurs, state.ids);
        }
    };

    // Cancel the modal
    const onClosed = () => {
        setIsOpened(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    const mounted = useRef(props.show);

    useEffect(() => {
        setIsOpened(props.show);
        // do componentDidMount logic (& mounted.current => the componentDidUpdate logic)
        if (!mounted.current && props.show) {
            setMajeurs(props.majeurs);
            getObjectsIds(props.majeurs[0]);
        }
    }, [props]);

    if (isOpened) {
        return (
            <>
                <div className="modal-back-space layout-center-center column">
                    <div
                        data-reactroot=""
                        className="modal-back-space layout-center-center column"
                    >
                        <div className="modal-container">
                            {/* Title of the modal */}
                            <div
                                className="question layout-center-center column"
                                style={{ height: "auto" }}
                            >
                                {props.title && <h2>{props.title}</h2>}
                                <span className="question-text">
                                    {props.message}
                                </span>
                            </div>

                            <div
                                style={props.params.styles}
                                className="page_content question layout-start-center column"
                            >
                                {/* All switch buttons corresponding to fields availables */}
                                <div className="my-input-container fiche-admin-modal">
                                    {defaultMajeurFields.map(
                                        (field: string) => (
                                            <div key={field + Math.random()}>
                                                <InputSwitch
                                                    id="label"
                                                    className="column-item-flex-1"
                                                    onChange={onChange(field)}
                                                    label={
                                                        majeurFieldsCustom[
                                                            field
                                                        ] || mesureFields[field]
                                                    }
                                                    value={true}
                                                />
                                            </div>
                                        )
                                    )}
                                    {majeurs &&
                                        majeurs.length == 1 &&
                                        Object.keys(majeurs[0])
                                            .filter((f) => f !== "users_id")
                                            .map((field: string) => {
                                                const fieldValue =
                                                    majeurs[0][field];
                                                if (
                                                    typeof fieldValue ==
                                                        "object" &&
                                                    field == "contacts"
                                                ) {
                                                    return (
                                                        <div
                                                            key={
                                                                fieldValue +
                                                                Math.random()
                                                            }
                                                        >
                                                            {Object.values(
                                                                fieldValue
                                                            ).length ? (
                                                                <h3
                                                                    style={{
                                                                        margin: 10,
                                                                    }}
                                                                >
                                                                    {majeurFieldsCustom[
                                                                        field
                                                                    ] ||
                                                                        mesureFields[
                                                                            field
                                                                        ] ||
                                                                        ""}
                                                                </h3>
                                                            ) : (
                                                                false
                                                            )}
                                                            {Object.values(
                                                                fieldValue
                                                            ).length
                                                                ? Object.values(
                                                                      fieldValue
                                                                  ).map(
                                                                      (
                                                                          value: any
                                                                      ) => (
                                                                          <div
                                                                              key={
                                                                                  value +
                                                                                  Math.random()
                                                                              }
                                                                          >
                                                                              <InputSwitch
                                                                                  id="label"
                                                                                  label={getLabel(
                                                                                      value
                                                                                  )}
                                                                                  className="column-item-flex-1"
                                                                                  onChange={onChangeObjects(
                                                                                      field,
                                                                                      value._id
                                                                                  )}
                                                                                  value={
                                                                                      true
                                                                                  }
                                                                              />
                                                                          </div>
                                                                      )
                                                                  )
                                                                : false}
                                                        </div>
                                                    );
                                                } else if (
                                                    Array.isArray(fieldValue)
                                                ) {
                                                    return (
                                                        <div
                                                            key={
                                                                field +
                                                                Math.random()
                                                            }
                                                        >
                                                            {fieldValue.length ? (
                                                                <h3
                                                                    key={field}
                                                                    style={{
                                                                        margin: 10,
                                                                    }}
                                                                >
                                                                    {majeurFieldsCustom[
                                                                        field
                                                                    ] ||
                                                                        mesureFields[
                                                                            field
                                                                        ] ||
                                                                        ""}
                                                                </h3>
                                                            ) : (
                                                                false
                                                            )}
                                                            {fieldValue.length
                                                                ? fieldValue.map(
                                                                      (
                                                                          value
                                                                      ) => (
                                                                          <div
                                                                              key={
                                                                                  value +
                                                                                  Math.random()
                                                                              }
                                                                          >
                                                                              <InputSwitch
                                                                                  id="label"
                                                                                  label={getLabel(
                                                                                      value
                                                                                  )}
                                                                                  className="column-item-flex-1"
                                                                                  onChange={onChangeObjects(
                                                                                      field,
                                                                                      value._id
                                                                                  )}
                                                                                  value={
                                                                                      true
                                                                                  }
                                                                              />
                                                                          </div>
                                                                      )
                                                                  )
                                                                : false}
                                                        </div>
                                                    );
                                                }
                                            })}
                                </div>
                            </div>

                            <div className="divider"></div>

                            {/* Validate and cancel buttons */}
                            <div className="actions layout-center-center row">
                                <div onClick={() => onClosed()}>Annuler</div>
                                <span className="dividerV"></span>
                                <div onClick={() => onValidate()}>Valider</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return <></>;
    }
};

export default FicheAdministrativeModal;
