import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ITemplate from "../../../interfaces/ITemplate";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import Help from "../Documents/Help";

const Templates: React.FC = () => {
    const [templatesMail, setTemplatesMail] = useState<Array<ITemplate> | null>(
        null
    );
    const { addToast } = useToasts();

    useEffect(() => {
        if (!templatesMail) {
            request({ addToast, url: `/api/mails` }).then(({ data }) => {
                setTemplatesMail(data.templates);
            }).catch();
        }
    }, [templatesMail]);

    return (
        <>
            <div className="list_templates_mail">
                <SubBar title="Vos courriers et requêtes types">
                    <Link to={`/list_templates_mail/nouveau`}>
                        <Icon name="add" />
                    </Link>
                    <Help color="white" url="/pdf/TUTO_Courriers.pdf" />
                </SubBar>

                <div className="page_content">
                    <p className="title">Courriers</p>

                    {!templatesMail && <Loader />}
                    <div className="page_content">
                        {templatesMail &&
                            templatesMail.length > 0 &&
                            templatesMail.map((t) => {
                                if (t.type === "Courrier") {
                                    return (
                                        <div
                                            className="item_template_mail"
                                            key={t._id}
                                        >
                                            <Link
                                                to={
                                                    "/list_templates_mail/" +
                                                    t._id +
                                                    "/lecture"
                                                }
                                                className="layout-center-start column"
                                            >
                                                <h6>{t.type}</h6>
                                                <p>{t.label}</p>
                                            </Link>
                                            <div className="divider"></div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                    </div>
                    <p className="title">Requêtes</p>
                    <div className="page_content">
                        {!templatesMail && <Loader />}

                        {templatesMail &&
                            templatesMail.length > 0 &&
                            templatesMail.map((t) => {
                                if (t.type === "Requête") {
                                    return (
                                        <div
                                            className="item_template_mail"
                                            key={t._id}
                                        >
                                            <Link
                                                to={
                                                    "/list_templates_mail/" +
                                                    t._id +
                                                    "/lecture"
                                                }
                                                className="layout-center-start column"
                                            >
                                                <h6>{t.type}</h6>
                                                <p>{t.label}</p>
                                            </Link>
                                            <div className="divider"></div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Templates;
