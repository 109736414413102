import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../constants/Utils";
import IProtectedAdult from "../../interfaces/IProtectedAdult";

const ItemMajeur: React.FC<{ majeur: IProtectedAdult; index: number }> = (
    props
) => {
    const [endDate, setEndDate] = useState<string>("Date de fin manquante");

    useEffect(() => {
        if (
            props.majeur.current_protection &&
            props.majeur.current_protection.end_date
        ) {
            const date: string = formatDate(
                new Date(
                    moment(
                        props.majeur.current_protection.end_date.$date
                            ? parseInt(
                                  props.majeur.current_protection.end_date.$date
                                      .$numberLong
                              )
                            : props.majeur.current_protection.end_date
                    ).format()
                )
            );
            setEndDate(`Date de fin de mesure : ${date}`);
        }
    }, [endDate]);

    // After upgrading M. CLOUET from MP to GP, a "majeur" had the field "current_protection" to null.
    let protectionNature = props.majeur.current_protection
        ? props.majeur.current_protection.nature
        : "";

    let protectionType = props.majeur.current_protection
        ? props.majeur.current_protection.protection
        : "";

    return (
        <>
            <div
                className={
                    props.index > 0
                        ? "item-list-avatar layout-start-center row ItemMajeur space-up"
                        : "item-list-avatar layout-start-center row ItemMajeur"
                }
            >
                <div className="item_account column" style={{ width: "100%" }}>
                    <div className="row layout-space-between-center item_three_lines_link">
                        <Link
                            to={`/single_majeur/${props.majeur._id}`}
                            className="layout-start-center row name_container"
                        >
                            <div className={`avatar ${protectionNature}`}>
                                {protectionNature}
                            </div>
                            {(props.majeur.last_name ===
                                props.majeur.familly_name ||
                                !props.majeur.familly_name) && (
                                <div>
                                    <b>
                                        {props.majeur.last_name},{" "}
                                        {props.majeur.first_name}{" "}
                                    </b>
                                    <div
                                        className="margin-top hide-xs"
                                        style={{
                                            fontSize: "0.8em",
                                            color: "#ababab",
                                        }}
                                    >
                                        Nature de la protection :{" "}
                                        {protectionType}
                                    </div>
                                </div>
                            )}
                            {props.majeur.familly_name &&
                                props.majeur.last_name !==
                                    props.majeur.familly_name && (
                                    <div>
                                        <b>
                                            {props.majeur.last_name}{" "}
                                            {props.majeur.familly_name},{" "}
                                            {props.majeur.first_name}{" "}
                                        </b>
                                        <div
                                            className="margin-top hide-xs"
                                            style={{
                                                fontSize: "0.8em",
                                                color: "#ababab",
                                            }}
                                        >
                                            Nature de la protection :{" "}
                                            {protectionType}
                                        </div>
                                    </div>
                                )}
                        </Link>
                        {props.majeur.account && (
                            <div className="row" style={{ width: "70%" }}>
                                <Link
                                    to={`/single_account/${props.majeur.account._id}/operations`}
                                    className="account_container"
                                    style={{
                                        width: "80%",
                                    }}
                                >
                                    <div className="row">
                                        <div
                                            className="hide-xs"
                                            style={{ width: "40%" }}
                                        >
                                            {props.majeur.account
                                                .bank_label && (
                                                <h6
                                                    className="font-semibold text-xs"
                                                    style={{
                                                        alignSelf: "center",
                                                        marginBottom: "0px",

                                                        paddingRight: "20px",
                                                    }}
                                                >
                                                    {
                                                        props.majeur.account
                                                            .bank_label
                                                    }
                                                </h6>
                                            )}
                                        </div>
                                        <div className="column align-content-start">
                                            <h6
                                                className={
                                                    props.majeur.account
                                                        .balance < 0
                                                        ? "red account_balance"
                                                        : "account_balance"
                                                }
                                            >
                                                {props.majeur.account?.number
                                                    ? props.majeur.account
                                                          ?.number
                                                    : "Solde "}{" "}
                                                :{" "}
                                                {(
                                                    props.majeur.account
                                                        .balance || 0
                                                ).toLocaleString("fr-FR", {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                })}{" "}
                                                €
                                            </h6>
                                            <div
                                                className="hide-ipad account_sync_at"
                                                style={{
                                                    fontSize: "0.7em",
                                                    color: "#ababab",
                                                }}
                                            >
                                                Dernière mise à jour :{" "}
                                                {moment(
                                                    props.majeur.account
                                                        ?.sync_type === "ebics"
                                                        ? props.majeur.account
                                                              .sync_at
                                                        : props.majeur.account
                                                              .updated_at
                                                ).format("DD/MM/YYYY")}
                                            </div>
                                            <h6
                                                className="hide-xl account_sync_at"
                                                style={{
                                                    fontSize: "0.7em",
                                                    color: "#ababab",
                                                }}
                                            >
                                                MAJ :{" "}
                                                {moment(
                                                    props.majeur.account
                                                        ?.sync_type === "ebics"
                                                        ? props.majeur.account
                                                              .sync_at
                                                        : props.majeur.account
                                                              .updated_at
                                                ).format("DD/MM/YYYY")}
                                            </h6>
                                        </div>
                                    </div>
                                </Link>
                                <div
                                    className="column fixed-right-center"
                                    style={{ maxWidth: "20%" }}
                                >
                                    {!props.majeur.account.sync_type && (
                                        <span className="ebics-image" />
                                    )}
                                    {props.majeur.account.sync_type && (
                                        <img
                                            className={
                                                props.majeur.account.sync_type +
                                                "-image"
                                            }
                                            src={`/imgs/${props.majeur.account.sync_type}_logo.png`}
                                            alt="extension"
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        <div
                            className="column fixed-right-center"
                            style={{ maxWidth: "20%" }}
                        >
                            {props.majeur.crg_is_controled && (
                                <span className="crg-image" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ItemMajeur;
