import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { CreateCourrierMajeur } from "../../../_api/Courrier";
import { ToolbarMajeurItems } from "../../../constants/ToolbarMajeurItems";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import IToolBarMajeurItem from "../../../interfaces/IToolBarMajeurItem";
import { request } from "../../../utils/axios-utils";
import ArrowBack from "../../layout/ArrowBack";
import SettingsMajeur from "../Settings/SettingsMajeur";
import AccountsMajeur from "./Account/AccountsMajeur";
import { default as AlarmsMajeur } from "./AlarmsMajeur";
import MobImmoMajeur from "./Assets_Debts/MobImmoMajeur";
import ListClouds from "./Cloud/ListClouds";
import ContactsMajeur from "./ContactsMajeur";
import SocialMajeur from "./Contract_Droit/SocialMajeur";
import AddCourrier from "./Courrier/AddCourrier";
import ListCourriers from "./Courrier/ListCourriers";
import InfosMajeur from "./InfosMajeur";
import MesureMajeur from "./MesureMajeur";
import RessourcesMajeur from "./Ressources/RessourcesMajeur";
import StatsMajeurs from "./StatsMajeurs";
import SuivisMajeurs from "./Suivi/SuivisMajeurs";

const SingleMajeur: React.FC = () => {
    const [role, setRole] = useState<string>(null);

    const [majeur, setMajeur] = useState<IProtectedAdult>(null);

    const [showCourrierModal, setShowCourrierModal] = useState(false);

    const { majeurId }: { majeurId?: string } = useParams();
    const { module }: { module?: string } = useParams();

    const [moduleSelected, setModuleSelected] = useState<string>(
        module ? module : "infos"
    );

    const [shouldUpdate, setShouldUpdate] = useState(false);
    const history = useHistory();
    const { addToast } = useToasts();

    useEffect(() => {
        if (!role) {
            request({ addToast, url: `/api/user` })
                .then(({ data }) => {
                    setRole(data.roles[0]);
                })
                .catch();
        }
    }, [role]);

    const items = ToolbarMajeurItems.filter(
        (x: any) => x.roles.indexOf(role) > -1
    );
    useEffect(() => {
        if (majeurId && !majeur && items.length > 0) {
            request({ addToast, url: `/api/majeur/${majeurId}` })
                .then(({ data }) => {
                    setMajeur(data);
                    if (module) {
                        setModuleSelected(module);
                    }
                    console.log(majeur, items);
                    // setModuleSelected(items[0]._id);
                })
                .catch();
        }
        localStorage.setItem("location", `/single_majeur/${majeurId}/alarms`);
    }, [majeur, items]);

    const create = (payload: any) => {
        CreateCourrierMajeur(addToast, { mail: payload })
            .then(() => {
                addToast("Le courrier a bien été généré.", {
                    appearance: "success",
                });
                setShouldUpdate(true);
            })
            .catch();
    };

    const createMail = (
        contactsSelected: [],
        templateOption: string,
        label: string,
        payload: object
    ) => {
        let realMail;

        if (!templateOption) {
            let newPayload = { ...payload, docx: false };
            if ((payload as any).body_value == "") {
                addToast("Votre courrier ne contient aucun texte.", {
                    appearance: "error",
                });
                return false;
            }
            create(newPayload);
        } else {
            request({
                addToast,
                url: `/api/mails/isDocx/${templateOption}`,
            })
                .then(({ data }) => {
                    realMail = data.isDocX;
                    if (
                        data.isDocX == false &&
                        (payload as any).body_value == ""
                    ) {
                        addToast("Votre courrier ne contient aucun texte.", {
                            appearance: "error",
                        });
                        return false;
                    }
                    let newPayload = { ...payload, docx: realMail };
                    create(newPayload);
                })
                .catch();
        }
    };

    const nameDescription = `${majeur?.first_name} ${majeur?.last_name}`;
    return (
        <>
            <div className="client">
                <ArrowBack
                    title={
                        !majeur?.archived
                            ? majeur
                                ? nameDescription
                                : ""
                            : majeur
                            ? `${nameDescription}, protégé archivé !`
                            : ""
                    }
                    back={() => history.push("/list_majeurs")}
                />

                <div className="tool_bar_menu_container sticky top-40 no_print">
                    <div className="toolbar_menu layout-space-around-center row">
                        {items.map((item: IToolBarMajeurItem, index) => (
                            <button
                                key={item._id}
                                onClick={() => {
                                    setModuleSelected(item._id);
                                    history.push(
                                        `/single_majeur/${majeurId}/${item._id}`
                                    );
                                }}
                                className={`toolbar_menu_item column pointer layout-center-center ${
                                    moduleSelected == item._id ? "selected" : ""
                                }`}
                            >
                                <i className="material-icons">{item.icon}</i>
                                <span className="title-action">
                                    {item.title}
                                </span>
                            </button>
                        ))}
                    </div>
                </div>

                <div className="client_container">
                    {moduleSelected === "infos" && (
                        <InfosMajeur majeur={majeur} />
                    )}
                    {majeur && moduleSelected === "mesure" && (
                        <MesureMajeur majeurId={majeurId} majeur={majeur} />
                    )}
                    {majeur && moduleSelected === "social" && (
                        <SocialMajeur majeurId={majeur._id} majeur={majeur} />
                    )}
                    {majeur && moduleSelected === "mobImmo" && (
                        <MobImmoMajeur majeurId={majeur._id} majeur={majeur} />
                    )}
                    {majeur && moduleSelected === "suivis" && (
                        <SuivisMajeurs majeurId={majeur._id} majeur={majeur} />
                    )}
                    {majeur && moduleSelected === "comptes" && (
                        <AccountsMajeur majeurId={majeur._id} majeur={majeur} />
                    )}
                    {majeur && moduleSelected === "ressources" && (
                        <RessourcesMajeur
                            majeurId={majeur._id}
                            majeur={majeur}
                        />
                    )}
                    {majeur && moduleSelected === "alarms" && (
                        <AlarmsMajeur majeurId={majeur._id} majeur={majeur} />
                    )}
                    {majeur && moduleSelected === "mails" && (
                        <ListCourriers
                            majeurId={majeur._id}
                            majeur={majeur}
                            shouldUpdate={shouldUpdate}
                            changeUpdate={(value: boolean) => {
                                setShouldUpdate(value);
                            }}
                            setShowCourrierModal={(closing: boolean) =>
                                setShowCourrierModal(closing)
                            }
                        />
                    )}
                    {majeur && moduleSelected === "contacts" && (
                        <ContactsMajeur majeurId={majeur._id} majeur={majeur} />
                    )}
                    {majeur && moduleSelected === "cloud" && (
                        <ListClouds majeurId={majeur._id} majeur={majeur} />
                    )}
                    {majeur && moduleSelected === "stats" && (
                        <StatsMajeurs majeurId={majeur._id} majeur={majeur} />
                    )}
                    {majeur && moduleSelected === "settings" && (
                        <SettingsMajeur
                            majeurId={majeur._id}
                            majeur={majeur}
                            changeMajeur={setMajeur}
                        />
                    )}
                </div>
                {majeur && (
                    <div className="list_mails_majeur">
                        <AddCourrier
                            show={showCourrierModal}
                            majeurId={majeur._id}
                            createMail={createMail}
                            setShowCourrierModal={(closing: boolean) =>
                                setShowCourrierModal(closing)
                            }
                        />
                    </div>
                )}
            </div>
        </>
    );
};
export default SingleMajeur;

function addToast(arg0: string, arg1: { appearance: string }) {
    throw new Error("Function not implemented.");
}
