import React from 'react';
import ITextarea from '../../../interfaces/forms/ITextarea';

const Textarea: React.FC<ITextarea> = (props) => {

  return (
    <div className="column my-input-container">
      <label htmlFor={props.id}>
        {props.label} :
            {props.required && <span className="red_asterix"> *</span>}
      </label>
      <textarea
        id={props.id}
        className="my-textarea"
        onChange={(e) => props.onChange(e)}
        placeholder={`${props.label}...`}
        value={props.value}
        required={props.required}
        maxLength={props.maxLength}
      />
    </div>
  );
}

export default Textarea;
