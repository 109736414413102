
import React from 'react';

const ListElement: React.FC<{ nodivider?: string }> = (props) => {
  return (
    <>
        {props.children}
        {
          (props.nodivider == null) &&
          (
              <div className="divider"></div>
          )
        }
    </>
  )
}

export default ListElement;