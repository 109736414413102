import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IFormProtection from "../../../interfaces/forms/IFormProtection";
import InputDatePicker from "../../layout/forms/InputDatePicker";
import Select from "../../layout/forms/Select";

const InnerProtectionForm: React.FC<IFormProtection> = (props) => {
    const [protection, setProtection] = useState<IFormProtection>(props);

    const setValue = (field: string, value: any) => {
        const newProtection = { ...protection, [field]: value };
        setProtection(newProtection);
        props.onChange(newProtection);
    };

    useEffect(() => {}, []);

    const periodOptions = [
        //{ value: "Ouverture", label: "Ouverture" },
        { value: "Normale", label: "Normale" },
        //{ value: "Fin", label: "Fin" },
    ];

    const protectionOptions = [
        { value: "B", label: "Biens" },
        { value: "P", label: "Personne" },
        { value: "B+P", label: "B + P " },
    ];

    const natureOptions = [
        { value: "T", label: "Tutelle" },
        { value: "CS", label: "Curatelle Simple" },
        { value: "CR", label: "Curatelle Renforcée" },
        { value: "MS", label: "Mandat Spécial" },
        { value: "MAJ", label: "Mesure d’accompagnement judiciaire" },
        { value: "MPF", label: "Mandat de protection future" },
        { value: "SC", label: "Subrogé Curateur" },
        { value: "ST", label: "Subrogé Tuteur" },
    ];

    const locationOptions = [
        { value: "D", label: "Domicile" },
        { value: "E", label: "Etablissement" },
        { value: "E+D", label: "E+D" },
    ];

    const ppsOptions = [
        {
            label: "Aucune prestation sociale listée",
            value: "Aucune prestation sociale listée",
        },
        {
            label: "RSA de base ou majoré / CAF ou MSA",
            value: "RSA de base ou majoré / CAF ou MSA",
        },
        {
            label: "APA perçue directement par la personne",
            value: "APA perçue directement par la personne",
        },
        {
            label: "PCH ou allocation compensatrice",
            value: "PCH ou allocation compensatrice",
        },
        { label: "AAH / CAF", value: "AAH / CAF" },
        {
            label: "ALS et APL perçues directement par la personne / CAF",
            value: "ALS et APL perçues directement par la personne / CAF",
        },
        { label: "AAH / MSA", value: "AAH / MSA" },
        {
            label: "ASPA ou Minimum vieillesse / MSA",
            value: "ASPA ou Minimum vieillesse / MSA",
        },
        {
            label: "ALS et APL perçues directement par la personne / MSA",
            value: "ALS et APL perçues directement par la personne / MSA",
        },
        {
            label: "Allocation supplémentaire d'invalidité / MSA",
            value: "Allocation supplémentaire d'invalidité / MSA",
        },
        {
            label: "Allocation supplémentaire d'invalidité / CARSAT",
            value: "Allocation supplémentaire d'invalidité / CARSAT",
        },
        {
            label: "ASPA ou Minimum vieillesse / Service de l'ASPA",
            value: "ASPA ou Minimum vieillesse / Service de l'ASPA",
        },
        {
            label: "ASPA ou Minimum vieillesse / CARSAT",
            value: "ASPA ou Minimum vieillesse / CARSAT",
        },
        {
            label: "Allocation supplémentaire d'invalidité / Service de l'ASPA",
            value: "Allocation supplémentaire d'invalidité / Service de l'ASPA",
        },
        {
            label: "Allocation supplémentaire d'invalidité / CPAM",
            value: "Allocation supplémentaire d'invalidité / CPAM",
        },
        {
            label: "Allocation supplémentaire d'invalidité / Régime spécial",
            value: "Allocation supplémentaire d'invalidité / Régime spécial",
        },
        {
            label: "ASPA ou Minimum vieillesse / Régime Spécial",
            value: "ASPA ou Minimum vieillesse / Régime Spécial",
        },
        {
            label: "MAJ (RSA ou APA ou PCH) / Département",
            value: "MAJ (RSA ou APA ou PCH) / Département",
        },
    ];

    const establishmentOptions = [
        { value: "P.H : Etablissement", label: "P.H : Etablissement" },
        { value: "P.H : Services", label: "P.H : Services" },
        { value: "P.A : EHPAD", label: "P.A : EHPAD" },
        {
            value: "P.A : Autre établissement",
            label: "P.A : Autre établissement",
        },
        { value: "C.H.R.S", label: "C.H.R.S" },
        { value: "E.H : Service de soin", label: "E.H : Service de soin" },
        {
            value: "E.H : Service psychiatrique",
            label: "E.H : Service psychiatrique",
        },
        { value: "E.H : Autre service", label: "E.H : Autre service" },
    ];

    const reasonOptions = [
        { value: "", label: "" },
        { value: "Décès", label: "Décès" },
        { value: "Mainlevée", label: "Mainlevée" },
        { value: "Dessaisissement", label: "Dessaisissement" },
        { value: "Caducité", label: "Caducité" },
        { value: "Autres", label: "Autres" },
    ];

    return (
        <div className="layout-center-start column">
            <div className="layout-start-center row full-width">
                {props.isDeletable && (
                    <div className="remove-button layout-center-center row pointer">
                        <Link
                            to={"#"}
                            onClick={() => props.removeProtection(protection)}
                        >
                            <i className="material-icons">delete</i>
                        </Link>
                    </div>
                )}
                <div
                    className={
                        props.isDeletable
                            ? "grow layout-start-start column"
                            : "grow layout-start-start column adjust-padding"
                    }
                >
                    <div className="row layout-xs-column">
                        <InputDatePicker
                            id={`${props.id}-start_date`}
                            className="flex-1"
                            label="Date de début"
                            inputDate={
                                protection.start_date
                                    ? new Date(
                                          protection.start_date
                                              ? moment(
                                                    protection.start_date.$date
                                                        ? parseInt(
                                                              protection
                                                                  .start_date
                                                                  .$date
                                                                  .$numberLong
                                                          )
                                                        : protection.start_date
                                                ).format()
                                              : moment().format()
                                      )
                                    : null
                            }
                            onChange={(date: Date) =>
                                setValue("start_date", date)
                            }
                        />
                        <div className="flex005 hide-xs" />
                        <InputDatePicker
                            id={`${props.id}-end_date`}
                            className="flex-1"
                            label="Date de fin"
                            inputDate={
                                protection.end_date
                                    ? new Date(
                                          moment(
                                              protection.end_date.$date
                                                  ? parseInt(
                                                        protection.end_date
                                                            .$date.$numberLong
                                                    )
                                                  : protection.end_date
                                          ).format()
                                      )
                                    : null
                            }
                            onChange={(date: Date) =>
                                setValue("end_date", date)
                            }
                        />
                    </div>
                    <div
                        className="layout-space-around-center row layout-xs-column full-width"
                        style={{ marginTop: 10 }}
                    >
                        {/*{props.user.type !== "_ASSOCIATION_" && */}
                        {/*    (*/}
                        {/*    <div className="my-input-container flex-1">*/}
                        {/*        <Select*/}
                        {/*            id={`${props.id}-period`}*/}
                        {/*            label="Période"*/}
                        {/*            needDefaultValue={false}*/}
                        {/*            required*/}
                        {/*            options={periodOptions.filter(*/}
                        {/*                (po: any, index: number) => {*/}
                        {/*                    if (*/}
                        {/*                        protection.start_date >=*/}
                        {/*                            new Date("09-01-2018") &&*/}
                        {/*                        index !== 1*/}
                        {/*                    ) {*/}
                        {/*                        return false;*/}
                        {/*                    }*/}

                        {/*                    if (*/}
                        {/*                        protection.end_date >=*/}
                        {/*                            new Date("09-01-2018") &&*/}
                        {/*                        index === 2*/}
                        {/*                    ) {*/}
                        {/*                        return false;*/}
                        {/*                    }*/}

                        {/*                    return true;*/}
                        {/*                }*/}
                        {/*            )}*/}
                        {/*            value={"Normale" /*protection.period*!/*/}
                        {/*            onChange={(e) => {*/}
                        {/*                setValue("period", e.target.value);*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {/*{props.user.type !== "_ASSOCIATION_" && (*/}
                        {/*    <div className="flex005 hide-xs" />*/}
                        {/*)}*/}
                        <div className="my-input-container flex-1">
                            <Select
                                id={`${props.id}-nature`}
                                label="Mission"
                                needDefaultValue={true}
                                required
                                options={natureOptions}
                                value={protection.nature}
                                onChange={(e) => {
                                    setValue("nature", e.target.value);
                                }}
                            />
                        </div>

                        <div className="flex005 hide-xs" />

                        <div className="my-input-container flex-1">
                            <Select
                                id={`${props.id}-protection`}
                                label="Protection"
                                required
                                needDefaultValue={true}
                                options={protectionOptions}
                                value={protection.protection}
                                onChange={(e) => {
                                    setValue("protection", e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex005 hide-xs" />
                        <div className="my-input-container flex-1">
                            <Select
                                id={`${props.id}-location`}
                                label="Lieu de vie"
                                required
                                needDefaultValue={true}
                                options={locationOptions}
                                value={protection.location}
                                onChange={(e) => {
                                    setValue("location", e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="my-input-container flex-1">
                        <Select
                            id={`${props.id}-pps`}
                            label="Principale prestation sociale"
                            required
                            options={ppsOptions}
                            needDefaultValue={true}
                            value={protection.pps}
                            onChange={(e) => {
                                setValue("pps", e.target.value);
                            }}
                        />
                    </div>
                    {(protection.location === "E" ||
                        protection.location === "E+D") && (
                        <div className="my-input-container flex-1">
                            <Select
                                id={`${props.id}-establishment_`}
                                label="Etablissement"
                                required
                                needDefaultValue={true}
                                options={establishmentOptions}
                                value={protection.establishment}
                                onChange={(e) => {
                                    setValue("establishment", e.target.value);
                                }}
                            />
                        </div>
                    )}
                    {props.isDeletable && (
                        <div className="my-input-container flex-1">
                            <Select
                                id={`${props.id}-reason_`}
                                label="Raison (si sortie)"
                                required
                                needDefaultValue={true}
                                options={reasonOptions}
                                value={protection.reason}
                                onChange={(e) => {
                                    setValue("reason", e.target.value);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            {!props.isDeletable && <div className="divider" />}
        </div>
    );
};

export default InnerProtectionForm;
