import React from "react";
import { Link } from "react-router-dom";
import Help from "./Help";
import Icon from "./Icon";

const FullBarTitle: React.FC<{
    text: string;
    sticky?: string;
    helpUrl?: string;
    addLink?: string;
}> = (props) => {
    return (
        <>
            <div
                className={`subBar_actions ${
                    props.sticky ? `sticky ${props.sticky}` : ""
                } layout-space-between-center row`}
            >
                <span className="subBar_actions_title">{props.text}</span>
                <div>
                    {props.helpUrl && <Help url={props.helpUrl} />}
                    {props.addLink && (
                        <Link to={props.addLink}>
                            <Icon name="add" />
                        </Link>
                    )}
                </div>
            </div>
        </>
    );
};
export default FullBarTitle;
