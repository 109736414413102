//Represent the side bar items
export const SideNavItems = [
    {
        _id: 'dashboard',
        name: 'Accueil',
        icon: 'home',
        app: 'tutelle',
        href: '/dashboard',
        roles: ['super-admin', 'user', 'INVOICE_ACCOUNT', 'MANAGER', 'REP_MJPM', 'ACCOUNTANT', 'FAMILLE', 'CRG_VERIF'],
    },
    {
        _id: 'listMajeurs',
        name: 'Protégés',
        icon: 'people',
        app: 'tutelle',
        href: '/list_majeurs',
        roles: ['super-admin', 'user', 'REP_MJPM', 'ACCOUNTANT', 'MANAGER', 'FAMILLE', 'CRG_VERIF'],
    },
    {
        _id: 'listContacts',
        name: "Carnet d'adresses",
        icon: 'contacts',
        href: '/contacts',
        app: 'tutelle',
        roles: ['super-admin', 'user', 'REP_MJPM', 'ACCOUNTANT', 'MANAGER'],
    },
    {
        _id: 'Documents',
        name: 'Générateur docs',
        icon: 'insert_drive_file',
        app: 'tutelle',
        href: '/documents',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER'],
    },
    {
        _id: 'listAlarms',
        name: 'Alertes',
        app: 'tutelle',
        icon: 'notifications',
        href: '/alertes',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', 'CRG_VERIF', 'FAMILLE'],
    },
    {
        _id: 'tribunaux',
        name: 'Tribunaux',
        icon: 'gavel',
        app: 'tutelle',
        href: '/tribunaux',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER'],
    },
    {
        _id: 'listBanks',
        name: 'Banques',
        icon: 'business',
        app: 'tutelle',
        href: '/banques',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', "ACCOUNTANT"],
    },
    {
        _id: 'statistiques',
        name: 'Statistiques',
        icon: 'trending_up',
        app: 'tutelle',
        href: '/statistiques',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', 'tester'],
    },
     {
         _id: 'listMajeursCRG',
         name: "Dépot d'un dossier",
         app: 'verificateur',
         icon: 'home',
         href: '/depot-crg',
         roles: ['depot_crg'],
    },
    {
        _id: 'listDossiersCRG',
        name: 'Liste des mandats',
        app: 'verificateur',
        icon: 'home',
        href: '/list_mandats',
        roles: ['verificateur_crg'],
    },
    {
        _id: 'listCRG',
        name: 'Liste des controles',
        app: 'verificateur',
        icon: 'business',
        href: '/list_controls',
        roles: ['verificateur_crg', 'depot_crg'],
    },
    {
        _id: 'listUsers',
        name: 'Utilisateurs',
        icon: 'group',
        app: ['tutelle'],
        href: '/utilisateurs',
        roles: ['super-admin', 'INVOICE_ACCOUNT', 'MANAGER', 'tester'],
    },
    {
        _id: 'listTemplatesMail',
        name: 'Courriers type',
        icon: 'mail',
        app: 'tutelle',
        href: '/list_templates_mail',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', "ACCOUNTANT"],
    },
    {
        _id: 'listFacturation',
        name: 'Facturation',
        app: 'verificateur',
        icon: 'trending_up',
        href: '/list_invoices',
        roles: ['verificateur_crg'],
    },
    {
        _id: 'list_parametre_crg',
        name: 'Paramètres',
        app: 'verificateur',
        icon: 'settings',
        href: '/settings_crg',
        roles: ['verificateur_crg'],
    },
    {
        _id: 'listLetters',
        name: 'La Poste',
        app: 'tutelle',
        icon: 'markunread_mailbox',
        href: '/lettres',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', "ACCOUNTANT"],
    },
    {
        _id: 'settings',
        name: 'Paramètres',
        app: 'tutelle',
        icon: 'settings',
        href: '/settings',
        roles: ['super-admin', 'user', 'INVOICE_ACCOUNT', 'MANAGER', 'ACCOUNTANT'],
    }
];
