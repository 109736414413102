import React from 'react';

const TagColor: React.FC <{color: string, label:string}> = (props) => {
  
    return (
        <>
           <p className="row layout-start-center">
                <span
                    style={{
                        backgroundColor: props.color,
                        marginRight: 10,
                        height: 10,
                        width: 10,
                        borderRadius: 5,
                    }}
                />
                <span style={{ color: '#000000' }}>{props.label}</span>
            </p>
        </>
    );
}
export default TagColor;
