import React, { useState } from "react";
import Modal from "../../../layout/modals/Modal";
import { PartnerCard } from "./PartnerCard";

const PartnerSettings: React.FC = () => {
    const [open, setOpen] = useState(false);
    return (
        <div className="flex-1">
            <PartnerCard
                src={"/imgs/partner/avantgarde_investment_logo.jpeg"}
                title={"Gestion de patrimoine"}
                description={
                    "Pensé pour vous simplifier votre quotidien, l’assistance Avant-Garde Family Office vous permet de vous appuyer sur un interlocuteur de confiance pour faire avancer vos projets."
                }
                tags={["comptabilité & administratif"]}
                cta_title="Prendre rendez-vous"
                cta_click={() => setOpen(!open)}
            />
            <PartnerCard
                src={"/imgs/partner/unnamed.png"}
                title={"Gestion des paies"}
                description={
                    "Si vous recherchez une gestion de paie simplifiée et sécurisée, découvrez comment externaliser les paies de façon complète ou sur mesure peut transformer votre quotidien profesionnel."
                }
                tags={["gestion", "paie", "externalisation"]}
                cta_title="Visiter le site"
                cta_click={() =>
                    window.open(
                        " https://lab-clever.fr/externalisation-de-paie"
                    )
                }
            />
            <PartnerCard
                src={"/imgs/partner/plateo_fr_logo.jpeg"}
                title={"Gestion des travaux"}
                description={
                    "Rénovation énergétique, remise en état en fin de bail ou entre 2 locataires, adaptation du logement... Tel un Maître d'Oeuvre, un accompagnement indépendant pour réduire les dépenses du Majeur Protégé."
                }
                tags={["gestion", "travaux", "externalisation"]}
                cta_title="Demande d'intervention"
                cta_click={() => window.open("https://tally.so/r/3y695p")}
            />
            <PartnerCard
                src={"/imgs/partner/finense.png"}
                title={"Gestion bancaire"}
                description={
                    "Solutions pour l'entraide et le soutien à travers une carte bancaire adapté pour la protection des majeurs. Recevez des aides, maitrisez les budgets et développez l'autonomie de vos proches."
                }
                tags={["gestion", "bancaire", "carte pour majeur"]}
                cta_title="Visiter le site"
                cta_click={() => window.open("https://www.finense.fr/")}
            />

            <Modal show={open} onClose={() => setOpen(!open)}>
                <div className="w-full">
                    <iframe
                        src="https://app.acuityscheduling.com/schedule.php?owner=28916682&appointmentType=67062239"
                        width="100%"
                        height="600"
                    ></iframe>
                </div>
            </Modal>
        </div>
    );
};

export default PartnerSettings;
