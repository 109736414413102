import { request } from "../utils/axios-utils";

export const createFile = (addToast: any, payload: any) => {
    return request({
        addToast,
        url: "/api/files/create",
        method: "post",
        data: payload,
    });
};

export const getUnclassifiedFile = (addToast: any, payload: any) => {
    return request({
        addToast,
        url: "/api/files/unclassified",
        method: "post",
        data: payload,
    });
};

export const assignAdult = (addToast: any, payload: any) => {
    return request({
        addToast,
        url: "/api/files/assign",
        method: "post",
        data: payload,
    });
};
