import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
    capitalizeFirst,
    checkPrevious,
    returnOnlyNumbers,
} from "../../../constants/Utils";
import IAdvisor from "../../../interfaces/IAdvisors";
import IBank from "../../../interfaces/IBank";
import { request } from "../../../utils/axios-utils";
import Divider from "../../layout/Divider";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import FormButton from "../../layout/forms/FormButton";
import InputAddress from "../../layout/forms/InputAddress";
import InputComponent from "../../layout/forms/InputComponent";
import InnerAdvisorForm from "./InnerAdvisorForm";

const CreateUpdate: React.FC = () => {
    const history = useHistory();

    const [bank, setBank] = useState<IBank | null>(null);
    const [advisors, setAdvisors] = useState<Array<IAdvisor>>([]);
    const [advisorIndex, setAdvisorIndex] = useState<number>(0);

    // One hook for each form field for the bank
    const [bankName, setBankName] = useState<string>("");
    const [bankAgency, setBankAgency] = useState<string>("");
    const [bankAddress, setBankAddress] = useState<string>("");
    const [bankZip, setBankZip] = useState<string>("");
    const [bankCity, setBankCity] = useState<string>("");
    const [bankEmail, setBankEmail] = useState<string>("");
    const [bankPhone, setBankPhone] = useState<string>("");
    const [bankFax, setBankFax] = useState<string>("");
    const [bankNotes, setBankNotes] = useState<string>("");

    const { bankId }: { bankId?: string } = useParams();
    const { addToast } = useToasts();

    useEffect(() => {
        // If it is a modification, it loads the bank to modify.
        if (bankId && !bank) {
            request({ addToast, url: `/api/banks/${bankId}` }).then(
                ({ data }) => {
                    setBank(data);
                    setBankName(data.name);
                    setBankAgency(data.agency);
                    setBankAddress(data.address);
                    setBankZip(data.zip_code);
                    setBankCity(data.city);
                    setBankEmail(data.email);
                    setBankPhone(data.phone);
                    setBankFax(data.fax);
                    setBankNotes(data.notes);
                    if (data.advisors && data.advisors.length > 0) {
                        data.advisors.forEach(function (ad: any, index: any) {
                            ad["id"] = `advisor-${index}`;
                        });

                        setAdvisors(data.advisors);
                        setAdvisorIndex(data.advisors.length);
                    }
                }
            ).catch();
        }
    }, [bank]);

    const handleSelection = (suggestion: any) => {
        setBankAddress(suggestion.name);
        setBankZip(returnOnlyNumbers(suggestion.postcode));
        setBankCity(capitalizeFirst(suggestion.city));
    };

    //Add an Advisor
    const addAdvisor = () => {
        const advisorsList = [...advisors];
        if (checkPrevious(advisorsList, "advisors")) {
            advisors.forEach((ad) => (ad.isDeletable = false));
            advisors.push({
                id: `advisor-${advisorIndex}`,
                isDeletable: true,
            });
            setAdvisorIndex(advisorIndex + 1);
        } else {
            addToast(
                "Remplissez d'abord les champs obligatoire de la précédente personne associée.",
                {
                    appearance: "warning",
                }
            );
        }
    };

    //Modify an Advisor
    const setAdvisorValue = (advisor: IAdvisor) => {
        let index = advisors.findIndex((a) => a.id === advisor.id);
        let newAdvisors = advisors;
        newAdvisors[index] = advisor;
        setAdvisors(newAdvisors);
    };

    //Delete an Advisor
    const removeAdvisor = (advisor: IAdvisor) => {
        let index = advisors.findIndex((a) => a.id === advisor.id);
        let newAdvisors = advisors;
        newAdvisors.splice(index, 1);
        newAdvisors.forEach(
            (ad, index) => (ad.isDeletable = index === newAdvisors.length - 1)
        );
        setAdvisors(newAdvisors);
        setAdvisorIndex(advisorIndex - 1);
    };

    /**
     * Save an bank (it can be a saving as a modification).
     */
    const saveBank = () => {
        const url = bankId
            ? `/api/banks/${bankId}/edit`
            : `/api/banks/create/${bank}`;

        request({
            addToast,
            url: url,
            method: "post",
            data: {
                name: bankName,
                agency: bankAgency ? capitalizeFirst(bankAgency.trim()) : "",
                address: bankAddress,
                zip_code: bankZip,
                city: bankCity ? capitalizeFirst(bankCity.trim()) : "",
                email: bankEmail,
                phone: bankPhone,
                fax: bankFax,
                notes: bankNotes,
                advisors: advisors,
            },
        }).then((data) => {
            // The data has been saved.
            addToast(`La banque a bien été ${bankId ? "modifiée" : "créée"}`, {
                appearance: "success",
            });
            bankId
                ? history.push(`/banques/${bankId}/show`)
                : history.push("/banques");
        }).catch();
    };

    return (
        <>
            <div className="form_bank">
                {!bankId && !bank && (
                    <SubBar title={"Ajouter une banque"} back="/banques" />
                )}

                {bankId && bank && (
                    <SubBar
                        title={`Modifier la banque`}
                        back={`/banques/${bankId}/show`}
                    />
                )}

                <div className="page_content">
                    {bankId && !bank && <Loader />}

                    {(!bankId || bank) && (
                        <form className="column">
                            <div className="row layout-xs-column">
                                <div className="my-input-container column-item-flex-1">
                                    <div className="flex005 hide-xs" />

                                    <div className="row layout-xs-column">
                                        <InputComponent
                                            id="label"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="Nom de la banque"
                                            required
                                            value={bankName.toUpperCase() || ""}
                                            onChange={(e) =>
                                                setBankName(
                                                    e.target.value.toUpperCase()
                                                )
                                            }
                                            maxLength={50}
                                        />

                                        <div className="flex005 hide-xs" />

                                        <InputComponent
                                            id="label"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="Agence"
                                            required
                                            value={bankAgency || ""}
                                            onChange={(e) =>
                                                setBankAgency(e.target.value)
                                            }
                                            maxLength={50}
                                        />
                                    </div>

                                    <div className="flex005 hide-xs" />

                                    <InputAddress
                                        handleSelection={handleSelection}
                                    />

                                    <label>Adresse :</label>
                                    <textarea
                                        className="my-textarea"
                                        value={bankAddress || ""}
                                        onChange={(e) =>
                                            setBankAddress(e.target.value)
                                        }
                                        placeholder="Adresse..."
                                    ></textarea>

                                    <div className="flex005 hide-xs" />

                                    <div className="row layout-xs-column">
                                        <InputComponent
                                            id="label"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="Code postal"
                                            value={bankZip || ""}
                                            onChange={(e) =>
                                                setBankZip(
                                                    returnOnlyNumbers(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            maxLength={5}
                                        />

                                        <div className="flex005 hide-xs" />

                                        <InputComponent
                                            id="label"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="Ville"
                                            value={bankCity || ""}
                                            onChange={(e) =>
                                                setBankCity(e.target.value)
                                            }
                                            maxLength={50}
                                        />
                                    </div>

                                    <div className="flex005 hide-xs" />

                                    <div className="row layout-xs-column">
                                        <InputComponent
                                            id="label"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="Email"
                                            value={bankEmail || ""}
                                            onChange={(e) =>
                                                setBankEmail(e.target.value)
                                            }
                                            maxLength={50}
                                        />

                                        <div className="flex005 hide-xs" />

                                        <InputComponent
                                            id="label"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="Téléphone"
                                            value={bankPhone || ""}
                                            onChange={(e) =>
                                                setBankPhone(
                                                    returnOnlyNumbers(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            maxLength={15}
                                        />

                                        <div className="flex005 hide-xs" />

                                        <InputComponent
                                            id="label"
                                            type="text"
                                            className="column-item-flex-1"
                                            label="Fax"
                                            value={bankFax || ""}
                                            onChange={(e) =>
                                                setBankFax(
                                                    returnOnlyNumbers(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            maxLength={15}
                                        />
                                    </div>

                                    <div className="flex005 hide-xs" />

                                    <label>Notes :</label>
                                    <textarea
                                        className="my-textarea"
                                        value={bankNotes || ""}
                                        onChange={(e) =>
                                            setBankNotes(e.target.value)
                                        }
                                        placeholder="Notes..."
                                    ></textarea>

                                    <div className="flex005 hide-xs" />

                                    <div className="block">
                                        <div className="my-sub-title-f">
                                            <SubBar title="Personnes associées">
                                                <Link
                                                    to={"#"}
                                                    onClick={addAdvisor}
                                                >
                                                    <Icon name="add" />
                                                </Link>
                                            </SubBar>
                                        </div>

                                        <div className="layout-center-start column">
                                            {advisors.map((advisor, index) => (
                                                <div
                                                    key={index}
                                                    className="column full-width"
                                                >
                                                    <InnerAdvisorForm
                                                        key={advisor.id}
                                                        id={advisor.id}
                                                        removeAdvisor={(
                                                            advisor: IAdvisor
                                                        ) =>
                                                            removeAdvisor(
                                                                advisor
                                                            )
                                                        }
                                                        onChange={(
                                                            advisor: IAdvisor
                                                        ) =>
                                                            setAdvisorValue(
                                                                advisor
                                                            )
                                                        }
                                                        isDeletable={
                                                            advisors.length -
                                                                1 ===
                                                            index
                                                        }
                                                        {...advisor}
                                                    />
                                                    {advisors.length !=
                                                        index + 1 && (
                                                        <Divider />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <FormButton
                                        onClick={saveBank}
                                        style={{ marginTop: 20 }}
                                        text={!bankId ? "Ajouter" : "Modifier"}
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </>
    );
};
export default CreateUpdate;
