import React from 'react';
import TagColor from '../../layout/TagColor';

const ListTags: React.FC <{remove: Function, listOfTags:any}> = (props) => {
  
    return (
        <>
            <div className="tags_container">
            {
                props.listOfTags.map((l:any, index:number) => (
                    <div
                        key={index}
                        className={ `item_tags layout-space-between-center row ${!(index % 2) ? 'even' : ''}`}
                    >
                        <TagColor color={l.color.value} label={l.label} />
                        <i
                            onClick={() => props.remove(l)}
                            className="material-icons button-icon"
                        >
                            delete
                        </i>
                    </div>
                ))
            }
            </div>
        </>
    );
}
export default ListTags;
