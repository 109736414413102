import {uniqueId} from "lodash";
import React, {useState} from "react";
import {scorePassword} from "../../constants/Utils";
import InputComponent from "./forms/InputComponent";
import PasswordGenerator from "./PasswordGenerator";
import '../../../sass/majeurs/password_input.scss'

const InputPassword: React.FC<{
    id: string;
    className: string;
    label: string;
    autoComplete?: string;
    value: string;
    decryptFunction: Function;
    disabled?: boolean;
    onChange: Function;
    disablePasswordGenerator?: boolean;
}> = (props) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [result, setResult] = useState<number>(0);

    const [isPasswordGeneratorOpen, setIsPasswordGeneratorOpen] =
        useState<boolean>(false);

    const setPasswordFromGenerator = (password: any) => {
        onChange({
            target: {
                value: password,
            },
        });
        setIsPasswordGeneratorOpen(false);
    };

    const changeVisibilityAccounts = () => {
        if (!visible) {
            props.decryptFunction();
        }
        setVisible(!visible);
    };

    const onChange = (e: any) => {
        setResult(scorePassword(e.target.value));
        props.onChange(e);
    };

    return (
        <div className="password_input">
            <PasswordGenerator
                close={() => setIsPasswordGeneratorOpen(false)}
                isOpen={isPasswordGeneratorOpen}
                onChange={setPasswordFromGenerator}
            />
            <div className="password_group">
                <div className="password_input_group">
                    <InputComponent
                        id={uniqueId()}
                        type={visible ? "text" : "password"}
                        {...props}
                        onChange={onChange}
                    />
                    {!visible ? (
                        <i
                            style={{marginRight: 15, marginLeft: 15}}
                            className="material-icons pointer"
                            onClick={changeVisibilityAccounts}
                        >
                            visibility
                        </i>
                    ) : (
                        <i
                            style={{marginRight: 15, marginLeft: 15}}
                            className="material-icons pointer"
                            onClick={changeVisibilityAccounts}
                        >
                            visibility_off
                        </i>
                    )}
                    {!props.disablePasswordGenerator && (
                        <i
                            className="material-icons pointer"
                            onClick={() => setIsPasswordGeneratorOpen(true)}
                            style={{marginRight: 10}}
                        >
                            vpn_key
                        </i>
                    )}
                </div>
                {result !== 0  && (
                    <div className="resultPassword">
                        Niveau de sécurité du mot de passe :{" "}
                        <span
                            className={
                                result <= 60
                                    ? "red"
                                    : result <= 80
                                        ? "orange"
                                        : "green"
                            }
                        >
                                {result}%
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InputPassword;
