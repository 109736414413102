import {uniqueId} from "lodash";
import React, {useState} from "react";
import Switch from "react-bootstrap-switch";
import InputComponent from "./forms/InputComponent";
import Modal from "./modals/Modal";
import {generate} from '@wcj/generate-password';


const PasswordGenerator: React.FC<{
    isOpen: boolean;
    close: Function;
    onChange: Function;
}> = (props) => {
    const [uppercase, setUpperCase] = useState<boolean>(false);
    const [lowercase, setLowercase] = useState<boolean>(false);
    const [digits, setDigits] = useState<boolean>(false);
    const [symbols, setSymbols] = useState<boolean>(false);
    const [length, setLength] = useState<number>(1);

    const onClick = () => {
        let password = generate({
            lowerCase: lowercase,
            upperCase: uppercase,
            numeric: digits,
            special: symbols,
            length: length
        }); // => !%:#_#*&^!

        props.onChange(password)
    }

    return (
        <Modal
            show={props.isOpen}
            onValidate={onClick}
            onClose={props.close}
            message="Generateur de mot de passe"
            /*             params={{
                styles: {
                    height: "50vh",
                    overflowY: "scroll",
                },
            }} */
        >
            <div className="full-width body">
                <div className="page_content full-width">
                    <div className="column">
                        <div className="password-generator">
                            <InputComponent
                                label="Longueur du mot de passe"
                                type={"number"}
                                minLength={0}
                                value={length}
                                onChange={(e) => setLength(e.target.value)}
                                id={uniqueId()}
                            />
                            <div className="switch_password">
                                <Switch
                                    onChange={(e) => e.props.value === false ? setUpperCase(true) : setUpperCase(false)}
                                    labelText="Majuscules"
                                    bsSize="small"
                                    onText="OUI"
                                    onColor="success"
                                    offColor="danger"
                                    offText="NON"
                                    value={uppercase}
                                />
                                <Switch
                                    onChange={(e) => e.props.value === false ? setLowercase(true) : setLowercase(false)}
                                    labelText="Minuscule"
                                    bsSize="small"
                                    onText="OUI"
                                    onColor="success"
                                    offColor="danger"
                                    offText="NON"
                                    value={lowercase}
                                />
                            </div>
                            <div className="switch_password">
                                <Switch
                                    onChange={(e) => e.props.value === false ? setDigits(true) : setDigits(false)}
                                    labelText="Chiffres"
                                    bsSize="small"
                                    onText="OUI"
                                    onColor="success"
                                    offColor="danger"
                                    offText="NON"
                                    value={digits}
                                />
                                <Switch
                                    onChange={(e) => e.props.value === false ? setSymbols(true) : setSymbols(false)}
                                    labelText="Charactère spéciaux"
                                    bsSize="small"
                                    onText="OUI"
                                    onColor="success"
                                    offColor="danger"
                                    offText="NON"
                                    value={symbols}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <i className="indications">
                    Une fois validé, le mot de passe généré sera automatiquement
                    reporté dans le champ mot de passe
                </i>
            </div>
        </Modal>
    );
};

export default PasswordGenerator;
