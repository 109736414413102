import React from 'react';

const Row: React.FC <{style?: Object, activated?:boolean}> = (props) => {

  return (
    <>
        <div style={props.style}
            className={ `layout-space-between-center row ${props.activated ? 'activated' : 'desactivated'}` }
        >
            {props.children}
        </div>
    </>
  );
}

export default Row;