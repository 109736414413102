import React, { useEffect, useState } from "react";
import IModal from "../../../interfaces/modals/IModal";

/**
 * Create an input.
 *
 * @param props Input properties.
 */
const Modal: React.FC<IModal> = (props) => {
    const [isOpened, setIsOpened] = useState(false);

    const onValidate = () => {
        setIsOpened(false);
        props.onValidate();
    };

    const onClosed = () => {
        setIsOpened(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    useEffect(() => {
        setIsOpened(props.show);
    }, [props]);

    if (isOpened) {
        return (
            <div
                data-reactroot=""
                className="modal-back-space layout-center-center column"
            >
                <div className="modal-container">
                    {props.children && (
                        <div
                            className="question layout-center-center column"
                            style={{ height: "auto" }}
                        >
                            <span className="question-text">
                                {props.message}
                            </span>
                            {props.children}
                        </div>
                    )}

                    {!props.children && (
                        <div
                            className="question layout-center-center column"
                            style={{ height: "auto" }}
                        >
                            {props.title && <h2>{props.title}</h2>}
                            <span className="question-text">
                                {props.message}
                            </span>
                        </div>
                    )}

                    <div className="divider"></div>

                    <div className="actions layout-center-center row">
                        <div onClick={() => onClosed()}>Annuler</div>
                        {props.onValidate && (
                            <>
                                <span className="dividerV"></span>
                                <div onClick={() => onValidate()}>Valider</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default Modal;
