import React from "react";

const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Set to true for 12-hour format
};

export const HistoryDepot = (props) => {
    const { data } = props;

    return (
        <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="max-h-96 overflow-y-scroll">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Intitulé
                                </th>

                                <th scope="col" className="px-6 py-3">
                                    Effectué par
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr
                                    key={index}
                                    className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                >
                                    <td className="px-6 py-4">
                                        {new Date(
                                            item.created_at
                                        ).toLocaleString("fr-FR", options)}
                                    </td>
                                    <td className="px-6 py-4">{item.text}</td>
                                    <td className="px-6 py-4">
                                        {item.user.profile.infos.last_name +
                                            " " +
                                            item.user.profile.infos.first_name}
                                    </td>
                                </tr>
                            ))}
                            {data.length === 0 && (
                                <tr className=" text-center odd:bg-white font-bold border-b dark:border-gray-700 h-12">
                                    <td colSpan={5}>
                                        Il n'y a actuellement aucun historique.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
