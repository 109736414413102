import { default as JsPDF, default as jsPDF } from "jspdf";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import wrap from "word-wrap";
import IAccount from "../../../interfaces/IAccount";
import IBank from "../../../interfaces/IBank";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import IUser from "../../../interfaces/IUser";
import ItemTwoLines from "../../layout/ItemTwoLines";
import {momentUpdateLocale} from "../../../constants/Utils";

const InfosAccount: React.FC<{
    account: IAccount;
    majeurId: string;
    majeur: IProtectedAdult;
    user: IUser;
    bank: IBank;
}> = (props) => {
    const { addToast } = useToasts();

    momentUpdateLocale()

    const ribWidth = 120;
    const ribHeight = 125;

    function getTextDimensions(doc: jsPDF, text: string) {
        const dim = doc.getTextDimensions(text);

        const numberLineBreak = (text.match(/\n/g) || []).length + 1;

        return { w: dim.w, h: dim.h * numberLineBreak };
    }

    function w(text: string) {
        return wrap(text, {
            width: ribWidth - 40,
        });
    }

    function ibanToJson(iban: string) {
        const ibanToParse = iban.replace(/ /g, ""); // Remove all user spaces
        const country = ibanToParse.substring(0, 3);
        const establishmentCode = ibanToParse.substring(4, 9);
        const officeCode = ibanToParse.substring(9, 14);
        const accountNumber = ibanToParse.substring(14, 25);
        const ribKey = ibanToParse.substring(25);

        return {
            country,
            officeCode,
            establishmentCode,
            accountNumber,
            ribKey,
        };
    }

    function drawRib(
        doc: JsPDF,
        account: IAccount,
        majeur: IProtectedAdult,
        bank: IBank,
        user: IUser,
        x = 0,
        y = 0
    ) {
        doc.rect(x, y, ribWidth, ribHeight);

        doc.setFontSize(9);
        // First div
        doc.text("RELEVÉ D'IDENTITÉ BANCAIRE", x + 36, y + 10);
        doc.text(
            w(
                "Ce relevé est destiné à tout organisme souhaitant connaître vos références bancaires pour domicilier des virements ou des prélèvements sur votre compte."
            ),
            x + 1,
            y + 17
        );

        doc.setLineWidth(0.1);
        doc.line(x + 1, y + 25, ribWidth + 9, y + 25);

        let currentLineY = y + 25 + 5;
        doc.text(account.bank_label, x + 1, currentLineY);
        doc.text(moment().format("L"), x + (ribWidth - 17), currentLineY);
        currentLineY += 5;
        doc.setFont(undefined, "bold");
        if (!bank.city) {
            throw new Error("Ville de la banque manquante");
        }
        doc.text(bank.city, x + 1, currentLineY);
        doc.setFont(undefined, "normal");
        if (!bank.zip_code) {
            throw new Error("Code postale de la banque manquante");
        }
        doc.text(bank.zip_code, x + (ribWidth - 10), currentLineY);
        currentLineY += 5;

        doc.setFont(undefined, "bold");
        doc.text("Tél.", x + 1, currentLineY);
        doc.setFont(undefined, "normal");
        doc.text(bank.phone || "", x + 8, currentLineY);
        currentLineY += 5;

        doc.line(x + 1, currentLineY, ribWidth + 9, currentLineY);

        currentLineY += 5;

        doc.setFont(undefined, "bold");
        doc.text("Intitulé du Compte :", x + 1, currentLineY);
        doc.setFont(undefined, "normal");
        doc.text(
            `${majeur.civility} ${majeur.last_name} ${majeur.first_name}`,
            x + 33,
            currentLineY
        );
        currentLineY += 5;
        doc.text("Sous protection", x + 33, currentLineY);
        // currentLineY += 5;
        doc.text(
            `${user.profile.infos.civility} ${user.profile.infos.last_name} ${user.profile.infos.first_name}`,
            x + 56,
            currentLineY
        );
        currentLineY += 5;
        const { h } = getTextDimensions(
            doc,
            user.profile.infos.address ?? "Adresse non renseignée"
        );
        doc.text(
            user.profile.infos.address ?? "Adresse non renseignée",
            x + 33,
            currentLineY
        );
        currentLineY += h + 2;
        doc.text(
            `${user.profile.infos.zip_code ?? "Code postal non renseigné"} ${
                user.profile.infos.city ?? "Ville non renseignée"
            }`,
            x + 33,
            currentLineY
        );
        currentLineY += 5;
        doc.line(x + 1, currentLineY, ribWidth + 9, currentLineY);
        currentLineY += 5;
        doc.setFont(undefined, "bold");
        doc.text("DOMICILIATION", x + 1, currentLineY);
        doc.setFont(undefined, "normal");
        currentLineY += 5;
        doc.text(`Code établissement`, x + 7, currentLineY);
        doc.text(`Code guichet`, x + 42, currentLineY);
        doc.text(`Numéro de compte`, x + 67, currentLineY);
        doc.text(`Clé RIB`, x + 100, currentLineY);
        const extractedIban = ibanToJson(account.iban);
        currentLineY += 5;
        doc.text(extractedIban.establishmentCode, x + 18, currentLineY);
        doc.text(extractedIban.officeCode, x + 46, currentLineY);
        doc.text(extractedIban.accountNumber, x + 71, currentLineY);
        doc.text(extractedIban.ribKey, x + 104, currentLineY);
        currentLineY += 10;
        doc.setFont(undefined, "bold");
        doc.text("IBAN", x + 1, currentLineY);
        doc.setFont(undefined, "normal");
        doc.text("(International Bank Account Number)", x + 10, currentLineY);

        currentLineY += 5;
        let currentX = x + 2;

        for (let i = 0; i < 7; i++) {
            doc.text(
                account.iban.replace(/ /g, "").substring(4 * i, 4 * (i + 1)),
                currentX,
                currentLineY
            );
            currentX += 10;
        }

        currentLineY += 10;
        doc.setFont(undefined, "bold");
        doc.text("Code BIC", x + 1, currentLineY);
        doc.setFont(undefined, "normal");
        doc.text(
            " (Bank Identification Code) - Code swift :",
            x + 15,
            currentLineY
        );

        currentLineY += 5;
        doc.text(account.bic, x + 2, currentLineY);
    }

    const generateRib = (
        account: IAccount,
        majeur: IProtectedAdult,
        bank: IBank,
        user: IUser
    ) => {
        if (
            !user.profile.infos.address ||
            !user.profile.infos.city ||
            !user.profile.infos.zip_code
        ) {
            addToast(
                "Il manque des informations sur la domiciliation du MJPM pour pouvoir éditer un RIB",
                { appearance: "warning" }
            );
            return false;
        }

        try {
            const doc = new JsPDF();
            drawRib(doc, account, majeur, bank, user, 10, 10);
            drawRib(doc, account, majeur, bank, user, 10, 140);

            doc.save("rib.pdf");
        } catch (err) {
            const { message } = err;
            addToast(message, { appearance: "error" });
        }
    };

    return (
        <>
            {props.account && (
                <div className="infos_account">
                    <div className="subBar_actions sticky top-90 layout-space-between-center row">
                        <span className="subBar_actions_title">
                            Les informations générales
                        </span>
                        <div style={{ display: "flex" }}>
                            <div className="subBar_actions_item">
                                <Link
                                    to={`/form_account/${props.account._id}/modifier`}
                                >
                                    <i className="material-icons">edit</i>
                                </Link>
                            </div>
                            {!!props.account.iban && !!props.account.bic && (
                                <div
                                    style={{
                                        marginLeft: 5,
                                        marginRight: 5,
                                        cursor: "pointer",
                                        color: "white",
                                    }}
                                    className="hide-xs action-button"
                                    onClick={() =>
                                        generateRib(
                                            props.account,
                                            props.majeur,
                                            props.bank,
                                            props.user
                                        )
                                    }
                                >
                                    <i className="material-icons">
                                        file_download
                                    </i>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="page_content">
                        {props.account.closed && (
                            <div className="title closed_account row layout-center-center">
                                Compte cloturé le{" "}
                                {moment(props.account.date_closed).format("L")}
                            </div>
                        )}
                        {props.account.principal && (
                            <div className="title row layout-center-center">
                                Compte principal
                            </div>
                        )}
                        <ItemTwoLines
                            title="Banque"
                            content={props.account.bank_label}
                        />
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Catégorie"
                                content={props.account.category}
                                flex={"1"}
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Type"
                                content={
                                    props.account.type
                                        ? props.account.type
                                        : "Non renseigné"
                                }
                                flex={"1"}
                            />
                            <ItemTwoLines
                                title="Complement"
                                content={
                                    props.account.additional_information
                                        ? props.account.additional_information
                                        : "Non renseigné"
                                }
                                flex={"1"}
                            />
                        </div>
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Numéro"
                                content={props.account.number}
                                flex={"1"}
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Iban"
                                content={props.account.iban || "Non renseigné"}
                                flex={"2"}
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="BIC"
                                content={props.account.bic || "Non renseigné"}
                                flex={"1"}
                            />
                        </div>
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Solde du compte"
                                content={`${props.account.balance.toFixed(2) !== '-0.00' ? props.account.balance.toFixed(2) : 0 || 0} €`}
                                flex={"1"}
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Argent de vie"
                                content={`${props.account.life_money_amount ? props.account.life_money_amount + '€' : "Non renseigné" }${props.account.life_money_period ? ' / ' + (props.account.life_money_period && props.account.life_money_period == 'week' ? 'Semaine' : 'Mois') : "" }`}
                                flex={"1"}
                            />
                        </div>

                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Date de prise en charge du compte"
                                content={moment(
                                    props.account.date_start_balance
                                ).format("L")}
                                flex={"1"}
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Solde de départ"
                                content={`${(
                                    props.account.start_balance || 0
                                ).toLocaleString()} €`}
                                flex={"1"}
                            />
                        </div>
                        <ItemTwoLines
                            title="Notes"
                            content={props.account.notes || ""}
                        />
                        {/* !account.sync_to &&
                    <div>
                        <h5 className="label_title" style={{ marginTop: 20 }}>Labels pour opérations :</h5>
                        <div className="labels_container">
                            {
                                (account && account.labels && account.labels.length) > 0 &&
                                account.labels.map((a, index) => (
                                    <div
                                        key={index}
                                        className={`label layout-space-between-center row ${((index % 2) ? '' : 'even')}`}
                                    >
                                        <span>{a}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                */}
                    </div>
                </div>
            )}
        </>
    );
};
export default InfosAccount;
