import * as Sentry from "@sentry/react";

let env = process.env.REACT_APP_SENTRY_DOMAIN.replace(".", "\\.");
let myRegexp = new RegExp(`^https:\/\/${env}\/api/`);

if (process.env.REACT_APP_ENV !== "local") {
    Sentry.init({
        environment: process.env.REACT_APP_ENV,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["localhost", myRegexp],
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
