import moment from "moment";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import IRepetition from "../../interfaces/IRepetition";
import { request } from "../../utils/axios-utils";
import InputComponent from "../layout/forms/InputComponent";
import InputDatePicker from "../layout/forms/InputDatePicker";
import Select from "../layout/forms/Select";
import ButtonConfirmModalIcon from "../layout/modals/ButtonConfirmModalIcon";

const ItemRepetition: React.FC<{
    key: number;
    repetition: IRepetition;
    nodivider: boolean;
    updateRepetitions: Function;
}> = (props) => {
    const [label, setLabel] = useState<string>(props.repetition.label);
    const [amount, setAmount] = useState<number>(props.repetition.amount);
    const [repetitionType, setRepetitionType] = useState<string>(
        props.repetition.period
    );
    const [dateNextJob, setDateNextJob] = useState<Date>(
        new Date(
            moment(
                props.repetition.date_next_job.$date
                    ? parseInt(props.repetition.date_next_job.$date.$numberLong)
                    : props.repetition.date_next_job
            ).format()
        )
    );

    const { addToast } = useToasts();

    // Return account label in string
    const returnAccountLabel = (cpt_cible: any, label: string) => {
        if (cpt_cible._id === "_EXTERNE_") {
            return `Compte externe ${label ? `| ${label}` : ""}`;
        }
        return `${cpt_cible.type} N° ${cpt_cible.number} ${
            label ? `| ${label}` : ""
        }`;
    };

    // Return account label in string
    const returnPeriod = (period: string) => {
        switch (period) {
            case "_HEBDOMADAIRE_":
                return "Hebdomadaire";
            case "_MENSUELLE_":
                return "Mensuelle";
            case "_BIMESTRIELLE_":
                return "Bimestrielle";
            case "_TRIMESTRIELLE_":
                return "Trimestrielle";
            case "_SEMESTRIELLE_":
                return "Semestrielle";
            default:
                return "Inconnu";
        }
    };

    // Reset repetition to props properties
    const resetRepetition = () => {
        setLabel(props.repetition.label);
        setAmount(props.repetition.amount);
        setRepetitionType(props.repetition.period);
        setDateNextJob(
            new Date(
                moment(
                    props.repetition.date_next_job.$date
                        ? parseInt(
                              props.repetition.date_next_job.$date.$numberLong
                          )
                        : props.repetition.date_next_job
                ).format()
            )
        );
    };

    // Verify repetition parameters before to update them
    const verifUpdateRepetition = () => {
        if (!amount.toString().trim()) {
            addToast("Il manque le montant.", { appearance: "warning" });
            return false;
        }
        const newAmount = +amount.toString().replace(",", ".");
        if (!newAmount) {
            addToast("Le montant doit être un chiffre", {
                appearance: "warning",
            });
            return false;
        } else if (newAmount === 0) {
            addToast("Le montant ne peut pas être égal à 0", {
                appearance: "warning",
            });
            return false;
        } else if (newAmount < 0) {
            addToast("Le montant ne peut pas être négatif.", {
                appearance: "warning",
            });
            return false;
        }

        return true;
    };

    // Update repetition in the DB
    const updateRepetition = () => {
        const newAmount = +amount.toString().replace(",", ".");

        const repet = {
            _id: props.repetition._id,
            label: label ? label.toString().trim() : "",
            amount: newAmount,
            date_next_job: dateNextJob,
            period: repetitionType,
        };

        request({
            addToast,
            url: `/api/operations/repetitions/update`,
            method: "post",
            data: { repetition: repet },
        }).then(() => {
            addToast("La répétition a bien été modifiée.", {
                appearance: "success",
            });
            props.updateRepetitions();
        }).catch();
    };

    // Delete permanently the repetition
    const deleteRepetition = () => {
        request({
            addToast,
            url: `/api/operations/repetitions/delete/${props.repetition._id}`,
            method: "post",
            data: {},
        }).then(() => {
            addToast("La répétition a bien été supprimée.", {
                appearance: "success",
            });
            props.updateRepetitions();
        }).catch();
    };

    return (
        <>
            <div className="item_repetition column">
                <div className=" layout-start-center row">
                    <div className="row flex-1">
                        <div className="column flex-1 layout-center-start">
                            <div className="title">
                                Prochaine éxecution le{" "}
                                {moment(props.repetition.date_next_job).format(
                                    "L"
                                )}
                                {props.repetition.category &&
                                    props.repetition.sub_category &&
                                    props.repetition.sub_category.name && (
                                        <p className="text">
                                            {props.repetition.sub_category.name}{" "}
                                            {props.repetition.label
                                                ? "| " + props.repetition.label
                                                : ""}
                                        </p>
                                    )}
                                {props.repetition.category &&
                                    props.repetition.sub_category &&
                                    !props.repetition.sub_category.name && (
                                        <p className="text">
                                            {returnAccountLabel(
                                                props.repetition.cpt_cible,
                                                props.repetition.label
                                            )}
                                        </p>
                                    )}
                            </div>
                        </div>
                        <div className="column layout-center-center flex-1">
                            <span className="period">
                                {returnPeriod(props.repetition.period)}
                            </span>
                        </div>
                    </div>
                    <div className="actions row">
                        <div
                            className={
                                "amount " +
                                (props.repetition.mouvement.value == 1 ||
                                props.repetition.mouvement.value == 3
                                    ? "red"
                                    : "")
                            }
                        >
                            {props.repetition.mouvement.value == 1 ||
                            props.repetition.mouvement.value == 3
                                ? "-"
                                : ""}{" "}
                            {(props.repetition.amount || 0).toLocaleString()} €
                        </div>
                        <ButtonConfirmModalIcon
                            id={"modifyRepetition"}
                            onValidate={() => updateRepetition()}
                            onClose={() => resetRepetition()}
                            iconClassName="update-icon"
                            icon="edit"
                            question="Modifier la répétition ?"
                            beforeValidate={() => verifUpdateRepetition()}
                        >
                            <div className="layout-space-around-center column full-width">
                                <div className="page_content column form_add_operation">
                                    <InputDatePicker
                                        label={"Prochaine execution"}
                                        id="next_job"
                                        className="my-input flex-1"
                                        required
                                        inputDate={dateNextJob}
                                        onChange={(date: Date) =>
                                            setDateNextJob(date)
                                        }
                                    />
                                    <div className="flex005 hide-xs" />
                                    <InputComponent
                                        id="repetition_label"
                                        type="text"
                                        className="my-input flex-1"
                                        label="Label"
                                        value={label}
                                        onChange={(e) =>
                                            setLabel(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                    <div className="flex005 hide-xs" />
                                    <InputComponent
                                        id="repetition_amount"
                                        type="number"
                                        className="my-input flex-1"
                                        label="Montant"
                                        required
                                        value={amount}
                                        onChange={(e) =>
                                            setAmount(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                    <div className="flex005 hide-xs" />
                                    <Select
                                        id="repetition"
                                        value={repetitionType}
                                        className="my-input flex-1"
                                        label="Répétition"
                                        required
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                setRepetitionType(
                                                    e.target.value
                                                );
                                            }
                                        }}
                                        options={[
                                            {
                                                value: "_HEBDOMADAIRE_",
                                                label: "Hebdomadaire",
                                            },
                                            {
                                                value: "_MENSUELLE_",
                                                label: "Mensuelle",
                                            },
                                            {
                                                value: "_BIMESTRIELLE_",
                                                label: "Bimestrielle",
                                            },
                                            {
                                                value: "_TRIMESTRIELLE_",
                                                label: "Trimestrielle",
                                            },
                                            {
                                                value: "_SEMESTRIELLE_",
                                                label: "Semestrielle",
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </ButtonConfirmModalIcon>
                        <ButtonConfirmModalIcon
                            id={"delete_repetition"}
                            question="Voulez vous supprimer cette répétition ?"
                            onValidate={() => deleteRepetition()}
                            iconClassName="remove-icon"
                            icon="delete_forever"
                        />
                    </div>
                </div>
                {!props.nodivider && <div className="divider"></div>}
            </div>
        </>
    );
};
export default ItemRepetition;
