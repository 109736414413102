import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import ISuivis from "../../../../interfaces/ISuivis";
import { request } from "../../../../utils/axios-utils";
import ArrowBackActions from "../../../layout/ArrowBackAction";
import ItemTwoLines from "../../../layout/ItemTwoLines";
import Loader from "../../../layout/Loader";
import {momentUpdateLocale} from "../../../../constants/Utils";

const SingleSuivi: React.FC = (props) => {
    const [suivi, setSuivi] = useState<ISuivis>();
    const [majeur, setMajeur] = useState<IProtectedAdult>(null);

    const { suiviID }: { suiviID?: string } = useParams();

    const { addToast } = useToasts();
    const history = useHistory();

    momentUpdateLocale()

    useEffect(() => {
        if (!suivi) {
            request({ addToast, url: `/api/suivis/${suiviID}` }).then(
                ({ data }) => {
                    setSuivi(data);
                    request({
                        addToast,
                        url: `/api/majeur/${data.majeur_id}`,
                    }).then(({ data }) => {
                        setMajeur(data);
                    }).catch();
                }
            );
        }
    }, []);

    const onRemove = () => {
        request({
            addToast,
            url: `/api/suivis/${suiviID}/delete`,
            method: "post",
            data: {},
        }).then(({ data }) => {
            history.push(`/single_majeur/${majeur._id}/suivis`);
            addToast("Le suivi a bien été supprimé", { appearance: "success" });
        }).catch();
    };

    const actionsArrowBack = [
        {
            title: "edit",
            icon: "edit",
            action: () =>
                history.push(`/form_suivi/${majeur._id}/edit/${suiviID}`),
        },
        {
            title: "delete",
            icon: "delete_forever",
            question: "Voulez vous supprimer le suivi ?",
            action: onRemove,
        },
    ];

    let title = "";
    if (majeur && window.screen.width > 600) {
        title = `${majeur.first_name} ${majeur.last_name} | `;
    }
    return (
        <div className="single_suivi">
            {majeur && (
                <ArrowBackActions
                    title={`${title} Suivi`}
                    actions={actionsArrowBack}
                    back={() =>
                        history.push(`/single_majeur/${majeur._id}/suivis`)
                    }
                    classNameTitle={""}
                />
            )}
            <div className="page_content">
                {!suivi && <Loader />}

                {suivi && majeur && (
                    <>
                        <ItemTwoLines
                            title="Date"
                            content={`${moment(
                                parseInt(suivi.date.$date.$numberLong)
                            ).format("L")} (${suivi.hours} h ${
                                suivi.minutes
                            } mn)`}
                        />
                        <ItemTwoLines title="Objet" content={suivi.label} />
                        <ItemTwoLines
                            title="Tags"
                            content={
                                suivi.tags.length > 0
                                    ? suivi.tags
                                          .map((tag) => tag.label)
                                          .join(", ")
                                    : "Indeterminé"
                            }
                        />
                        <div className="flex005 hide-xs">
                            <ItemTwoLines
                                flex="1"
                                title="Nb de Km"
                                content={suivi.nb_km ?? "0"}
                            />
                            <ItemTwoLines
                                flex="1"
                                title="Nb heures"
                                content={suivi.hours}
                            />
                            <ItemTwoLines
                                flex="1"
                                title="Nb minutes"
                                content={suivi.minutes}
                            />
                        </div>
                        <div className="item">
                            <h6>Contenu</h6>
                            <div
                                className="content"
                                dangerouslySetInnerHTML={{
                                    __html: suivi.content,
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default SingleSuivi;
