import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IUsers from "../../../interfaces/IUsers";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import ListElement from "../../layout/lists/ListElement";

const Users: React.FC = () => {
    const [users, setUsers] = useState<Array<IUsers> | null>(null);
    const { addToast } = useToasts();

    useEffect(() => {
        if (!users) {
            request({ addToast, url: `/api/users` })
                .then(({ data }) => {
                    setUsers(data.end_users);
                })
                .catch();
        }
    }, []);

    const returnLabelRole = (role: string) => {
        switch (role) {
            case "super-admin":
                return "Administrateur";
            case "INVOICE_ACCOUNT":
                return "Compte de facturation";
            case "MANAGER":
                return "Chef de service";
            case "REP_MJPM":
                return "Délégué MJPM";
            case "user":
                return "Utilisateur";
            case "ACCOUNTANT":
                return "Service Comptabilité";
            case "FAMILLE":
                return "Famille";
            case "CRG_VERIF":
                return "Vérificateur de CRG";
            default:
                return "ERREUR";
        }
    };

    const isPlurial = (text: string, number: number) => {
        if (number > 1) {
            return text + "s";
        } else {
            return text;
        }
    };

    const UnModifiableAccount = ["super-admin", "INVOICE_ACCOUNT"];

    const userJson = sessionStorage.getItem("user");
    const user = JSON.parse(userJson);
    if (user && user?.roles[0] === "MANAGER") {
        UnModifiableAccount.push("MANAGER");
    }
    return (
        <>
            <div className="list_users">
                <SubBar title="Les utilisateurs">
                    <Link to={`/utilisateurs/nouveaux`}>
                        <Icon name="add" />
                    </Link>
                </SubBar>

                <div className="page_content list">
                    {!users && <Loader />}

                    {users &&
                        users.map((u: IUsers) => (
                            <div key={u._id} className="item_three_lines">
                                <div
                                    className={
                                        UnModifiableAccount.includes(u.roles[0])
                                            ? "row layout-space-between-center"
                                            : "row layout-space-between-center item_highlited"
                                    }
                                >
                                    <ListElement nodivider="true" key={u._id}>
                                        <div className="layout-center-start column">
                                            {UnModifiableAccount.includes(
                                                u.roles[0]
                                            ) ? (
                                                <div className="layout-center-start column container">
                                                    <h3>
                                                        {
                                                            u.profile.infos
                                                                .first_name
                                                        }{" "}
                                                        {
                                                            u.profile.infos
                                                                .last_name
                                                        }
                                                    </h3>
                                                    <h5>
                                                        <a
                                                            href={`mailto:${u.emails[0].address}`}
                                                        >
                                                            {
                                                                u.emails[0]
                                                                    .address
                                                            }
                                                        </a>
                                                    </h5>
                                                    <h5>
                                                        {returnLabelRole(
                                                            u.roles[0]
                                                        )}{" "}
                                                        {u.activeProtectedLength +
                                                            u.archivedProtectedLength >=
                                                        0
                                                            ? "| " +
                                                              (u.activeProtectedLength +
                                                                  u.archivedProtectedLength) +
                                                              isPlurial(
                                                                  " protégé",
                                                                  u.activeProtectedLength +
                                                                      u.archivedProtectedLength
                                                              ) +
                                                              " (" +
                                                              u.activeProtectedLength +
                                                              isPlurial(
                                                                  " actif",
                                                                  u.activeProtectedLength
                                                              ) +
                                                              " et " +
                                                              u.archivedProtectedLength +
                                                              isPlurial(
                                                                  " archivé",
                                                                  u.archivedProtectedLength
                                                              ) +
                                                              ")"
                                                            : ""}
                                                    </h5>
                                                </div>
                                            ) : (
                                                <Link
                                                    className="layout-center-start column container clickable_user"
                                                    to={`/utilisateurs/${u._id}/show`}
                                                >
                                                    <h3>
                                                        {
                                                            u.profile.infos
                                                                .first_name
                                                        }{" "}
                                                        {
                                                            u.profile.infos
                                                                .last_name
                                                        }
                                                    </h3>
                                                    <h5>
                                                        <a
                                                            href={`mailto:${u.emails[0].address}`}
                                                        >
                                                            {
                                                                u.emails[0]
                                                                    .address
                                                            }
                                                        </a>
                                                    </h5>
                                                    <h5>
                                                        {returnLabelRole(
                                                            u.roles[0]
                                                        )}{" "}
                                                        {u.activeProtectedLength +
                                                            u.archivedProtectedLength >=
                                                        0
                                                            ? "| " +
                                                              (u.activeProtectedLength +
                                                                  u.archivedProtectedLength) +
                                                              isPlurial(
                                                                  " protégé",
                                                                  u.activeProtectedLength +
                                                                      u.archivedProtectedLength
                                                              ) +
                                                              " (" +
                                                              u.activeProtectedLength +
                                                              isPlurial(
                                                                  " actif",
                                                                  u.activeProtectedLength
                                                              ) +
                                                              " et " +
                                                              u.archivedProtectedLength +
                                                              isPlurial(
                                                                  " archivé",
                                                                  u.archivedProtectedLength
                                                              ) +
                                                              ")"
                                                            : ""}
                                                    </h5>
                                                </Link>
                                            )}
                                        </div>
                                    </ListElement>
                                </div>
                                <div className="divider"></div>
                            </div>
                        ))}
                    {users && users.length === 0 ? (
                        <div className={"alert alert-info"}>
                            Aucune utilisateur n'est actuellement disponible sur
                            votre compte. Ajoutez en dans l'espace dédié pour
                            profiter pleinement de la plateforme !
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
};

export default Users;
