import React from "react";
import IHelp from "../../../interfaces/IHelp";

const Help: React.FC<IHelp> = (props) => {
    const goToUrl = () => {
        window.open(props.url, "_blank");
    };

    return (
        (!props.onlyText && (
            <i
                className="material-icons"
                style={{
                    cursor: "pointer",
                    color: props.color || "white",
                    height: props.height || 26,
                    width: props.width || 26,
                }}
                onClick={goToUrl}
            >
                live_help
            </i>
        )) || (
            <i
                className="material-icons"
                style={{
                    cursor: "pointer",
                    color: props.color || "white",
                    height: props.height || 26,
                    width: props.width || 26,
                }}
                onMouseDown={() => props.displayText(!props.displayed)}
            >
                live_help
            </i>
        )
    );
};
export default Help;
