import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { momentUpdateLocale } from "../../../../constants/Utils";
import IImmobilier from "../../../../interfaces/IImmobilier";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import { request } from "../../../../utils/axios-utils";
import ArrowBackActions from "../../../layout/ArrowBackAction";
import ItemTwoLines from "../../../layout/ItemTwoLines";
import Loader from "../../../layout/Loader";
import IFile from "../Cloud/IFile";

const SingleImmobilier: React.FC = (props) => {
    const [immobilier, setImmobilier] = useState<IImmobilier>(null);
    const [majeur, setMajeur] = useState<IProtectedAdult>();
    const [fileList, setFileList] = useState<Array<IFile>>(null);

    const { immobilierId }: { immobilierId?: string } = useParams();
    const { addToast } = useToasts();
    const history = useHistory();

    momentUpdateLocale();

    const download = (key: string) => {
        request({
            addToast,
            url: `/api/files/downloadWithKey`,
            method: "POST",
            data: { key: key },
            responseType: "blob",
        })
            .then((response) => {
                const blob = response.data;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", key.split("/").pop());
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((data) => {
                const { message } = data;
                addToast(message, { appearance: "error" });
            });
    };
    const removeImmobilier = () => {
        request({
            addToast,
            url: `/api/immovables/${immobilierId}/delete`,
            method: "post",
            data: {},
        })
            .then(() => {
                history.push(`/single_majeur/${majeur._id}/mobImmo`);
                addToast("L'immobilier a bien été supprimé.", {
                    appearance: "success",
                });
            })
            .catch();
    };
    const actionsArrowBack =
        majeur && !majeur.archived
            ? [
                  {
                      title: "edit",
                      icon: "edit",
                      action: () =>
                          history.push(
                              `/form_mob_immo/${majeur._id}/edit/${immobilierId}/IMMOBILIER`
                          ),
                  },
                  {
                      title: "delete",
                      icon: "delete_forever",
                      question: "Voulez vous supprimer ce bien immobilier ?",
                      action: removeImmobilier,
                  },
              ]
            : [];

    useEffect(() => {
        if (!immobilier) {
            request({ addToast, url: `/api/immovables/${immobilierId}` }).then(
                ({ data }) => {
                    setImmobilier(data);
                    if (data.files) {
                        request({
                            url: "/api/files/list/from-ids",
                            method: "POST",
                            data: { filesIds: data.files },
                        })
                            .then(({ data }) => {
                                setFileList(data);
                            })
                            .catch();
                    }
                    request({
                        addToast,
                        url: `/api/majeur/${data.majeur_id}`,
                    })
                        .then(({ data }) => {
                            setMajeur(data);
                        })
                        .catch();
                }
            );
        }
    }, []);

    const title =
        majeur && window.screen.width > 600
            ? `${majeur.first_name} ${majeur.last_name} | `
            : "";
    return (
        <div className="single_immobilier">
            <ArrowBackActions
                title={`${title} Bien immobilier`}
                actions={actionsArrowBack}
                back={() => window.history.back()}
                classNameTitle={
                    "arrow_back_actions sticky layout-start-center row"
                }
            />
            <div className="page_content">
                {immobilier === null && <Loader />}
                {immobilier && (
                    <>
                        <ItemTwoLines
                            title="Label"
                            content={immobilier.label || "Non renseigné."}
                        />
                        <ItemTwoLines
                            title="Adresse"
                            content={
                                immobilier.address +
                                    "\n" +
                                    immobilier.zip_code +
                                    " " +
                                    immobilier.city || "Non renseigné."
                            }
                        />
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Date d'inventaire"
                                content={
                                    immobilier.date_inventaire &&
                                    immobilier.date_inventaire !== ""
                                        ? moment(
                                              immobilier.date_inventaire
                                          ).format("L")
                                        : "Non renseignée."
                                }
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Estimation"
                                content={
                                    immobilier.estimation
                                        ? parseFloat(immobilier.estimation)
                                            ? (
                                                  immobilier.estimation || 0
                                              ).toLocaleString()
                                            : immobilier.estimation
                                        : "Non renseigné"
                                }
                                flex="1"
                            />
                        </div>
                        <div className="my-input-container">
                            <label htmlFor="immobilier">Notes :</label>
                            {immobilier && immobilier.notes && (
                                <div className="text_zone">
                                    {immobilier.notes}
                                </div>
                            )}
                            {!immobilier.notes && <div>Non renseignées.</div>}
                        </div>
                        <div className="my-input-container">
                            <label>Document :</label>
                            {fileList &&
                                fileList.length > 0 &&
                                fileList.map((f) => (
                                    <div className="notes_zone layout-start-center">
                                        {f.name}{" "}
                                        <i
                                            onClick={() => download(f.key)}
                                            className="material-icons pointer"
                                        >
                                            cloud_download
                                        </i>
                                    </div>
                                ))}
                            {fileList && fileList.length === 0 && (
                                <div>Non renseignées.</div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default SingleImmobilier;
