import React, { useState } from "react";
import { Button } from "../../../../../_shared/Button";
import { DefaultDropdown } from "../../../../../_shared/Dropdown";
import { InputForm } from "../../../../../_shared/InputField";

export const Revenus = (props: { onClose: any }) => {
    const { onClose } = props;
    const checkInformation = () => {};

    const _onValidation = () => {
        checkInformation();
        onClose();
    };

    const [year, setYear] = useState("");
    const [revenus, setRevenus] = useState("");
    const [wealth, setWealth] = useState("");

    return (
        <>
            <div>
                <InputForm
                    name="Revenus N - 1"
                    type={"number"}
                    set={setRevenus}
                    value={revenus}
                    required={true}
                />
                <InputForm
                    name="Patrimoine financier"
                    type={"number"}
                    set={setWealth}
                    value={wealth}
                    required={true}
                />
                <DefaultDropdown
                    name={"Année"}
                    options={[
                        { label: "détaillé", value: "week" },
                        { label: "mensuel", value: "monthly" },
                        { label: "annuel", value: "annual" },
                    ]}
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                />
                <div className="mt-4 flex justify-end">
                    <Button name={"Suivant"} action={() => _onValidation()} />
                </div>
            </div>
        </>
    );
};
