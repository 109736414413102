import React from "react";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import IconAction from "./IconAction";
import ButtonConfirmModalIcon from "./modals/ButtonConfirmModalIcon";

const ArrowBackActions: React.FC<{
    back: Function;
    title: string;
    actions: any;
    classNameTitle: string;
}> = (props) => {
    const back = () => {
        const { back } = props;
        if (back) {
            back();
        } else {
            window.history.back();
        }
    };

    return (
        <div className="arrow_back_actions sticky layout-start-center row">
            <i className="material-icons no_print" onClick={back}>
                arrow_back
            </i>
            <div className="layout-center-center row full-width">
                {props.title !== "null" && (
                    <h3 className={props.classNameTitle}>{props.title}</h3>
                )}
            </div>
            {props.actions && (
                <div className="actions layout-space-around-center row no_print">
                    {props.actions.map((a: any) => {
                        return (
                            <div className="action_arrow_back" key={a.title}>
                                {a.question && (
                                    <ButtonConfirmModalIcon
                                        question={a.question}
                                        onValidate={a.action}
                                        icon={a.icon}
                                        id={""}
                                    />
                                )}
                                {!a.question && a.action && (
                                    <IconAction
                                        src={a.src}
                                        icon={a.icon}
                                        action={a.action}
                                    />
                                )}
                                {!a.question && !a.action && (
                                    <Link
                                        to={a.link}
                                        className="layout-center-start column"
                                    >
                                        <Icon name={a.icon}></Icon>
                                    </Link>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ArrowBackActions;
