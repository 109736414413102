import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { updateCRGUser } from "../../../_api/User";
import { Button } from "../../../_shared/Button";
import ColorPicker from "../../../_shared/ColorPicker";
import FileUpload from "../../../_shared/FileUpload";
import { InputForm } from "../../../_shared/InputField";
import { request } from "../../../utils/axios-utils";
import { useAuth } from "../Login/auth.context";
import SubscriptionForm from "./SubscriptionForm";

export const SettingsCRG = () => {
    const { setCurrentUser, currentUser } = useAuth();

    const [companyName, setCompanyName] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.companyName : ""
    );
    const [city, setCity] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.city : ""
    );
    const [phone, setPhone] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.phone : ""
    );
    const [email, setEmail] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.email : ""
    );

    const [logo, setLogo] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.logo : null
    );
    const [signature, setSignature] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.signature : null
    );
    const [color, setColor] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.color : "#ffffff"
    );

    const { addToast } = useToasts();

    const UploadFile = async (file, type) => {
        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch(
            process.env.REACT_APP_BASE_URL +
                "api/user/modify/file/" +
                type +
                "/" +
                currentUser._id,
            {
                method: "POST",
                body: formData,
                headers: {
                    Accept: "application/json",
                },
            }
        );

        if (response.ok) {
            refreshUser();
            addToast("Fichier mis en ligne", { appearance: "success" });
        }
    };

    const loadFile = async (type: string, path: any) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_BASE_URL +
                    "api/user/" +
                    currentUser._id +
                    "/file/" +
                    type
            );
            if (response.ok) {
                const data = await response.json();
                if (type === "SIGNATURE") {
                    setSignature(data.url);
                }
                if (type === "LOGO") {
                    setLogo(data.url);
                }
            } else {
                console.log("Erreur lors du téléchargement du fichier.");
            }
        } catch (error) {
            console.error("Erreur lors de la récupération du fichier :", error);
        }
    };

    const refreshUser = () => {
        request({ addToast, url: `/api/user` }).then(({ data }) => {
            setCurrentUser(data);
        });
    };

    useEffect(() => {
        if (currentUser?.crg_infos?.signature) {
            loadFile("SIGNATURE", currentUser?.crg_infos?.signature);
        }
        if (currentUser?.crg_infos?.logo) {
            loadFile("LOGO", currentUser?.crg_infos?.logo);
        }
    }, []);

    const stripePromise = loadStripe("pk_test_z8LRVAmWA6FUWnHQjMuHm8mr");

    return (
        <div className="p-2">
            <h4>Informations</h4>
            <div className="flex flex-wrap -mx-3 mb-3 p-2">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Nom de société"
                        set={setCompanyName}
                        value={companyName}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Ville"
                        set={setCity}
                        value={city}
                        required={true}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3 p-2">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Téléphone"
                        set={setPhone}
                        value={phone}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Email"
                        set={setEmail}
                        value={email}
                        required={true}
                    />
                </div>
            </div>
            <div className="p-2">
                <ColorPicker
                    color={color}
                    setColor={setColor}
                    refreshUser={refreshUser}
                />
            </div>
            <div className="flex justify-end">
                <Button
                    type={"empty"}
                    name={"Enregistrer les modifications"}
                    action={() => {
                        const payload = {
                            color,
                            companyName,
                            email,
                            phone,
                            city,
                        };

                        updateCRGUser(addToast, currentUser._id, payload).then(
                            (resp) => {
                                addToast(resp.data.message, {
                                    appearance: "success",
                                });
                                setCurrentUser(resp.data.data);
                                refreshUser();
                            }
                        );
                    }}
                />
            </div>
            <div className="flex">
                <div className="p-2">
                    <h1>Logo</h1>
                    <FileUpload
                        file={logo}
                        set={setLogo}
                        type={"LOGO"}
                        UploadFile={UploadFile}
                    />
                    <img src={logo} alt="" height={100} width={175} />
                </div>
                <div className="p-2">
                    <h1>Signature</h1>
                    <FileUpload
                        file={signature}
                        set={setSignature}
                        type={"SIGNATURE"}
                        UploadFile={UploadFile}
                    />
                    <img src={signature} alt="" height={100} width={175} />
                </div>
            </div>
            <hr />
            <Elements stripe={stripePromise}>
                <SubscriptionForm />
            </Elements>
        </div>
    );
};
