import React from 'react';
import pick from 'lodash/pick';
import CardRow from '../../layout/lists/CardRow';

const CardList: React.FC <{cards: Array<any>}> = (props) => {
  
  return (
    <>
      <div>
          <p style={{ marginBottom: '1em' }}>Votre carte de paiement actuellement enregistrée :</p>
          {
            props.cards.map(card => <CardRow
                key={`card-${card.id}`}
                {...pick(card, ['brand', 'exp_month', 'exp_year', 'last4', 'name'])}
            />)
          }
      </div>
    </>
  );
}

export default CardList;