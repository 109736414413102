import React from "react";
import Help from "./Help";

const ArrowBack: React.FC<{
    title: string;
    back?: Function;
    classNameTitle?: string;
    helpUrl?: string;
}> = (props) => {
    const back = () => {
        const { back } = props;
        if (back) {
            back();
        } else {
            window.history.back();
        }
    };

    return (
        <div className="arrow_back layout-start-center row">
            <i className="material-icons" onClick={back}>
                arrow_back
            </i>
            <div className="layout-center-center row full-width font-bold text-slate-950">
                <h3 className={props.classNameTitle}>{props.title}</h3>
            </div>
            {props.helpUrl && <Help url={props.helpUrl} />}
        </div>
    );
};

export default ArrowBack;
