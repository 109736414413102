import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IContact from "../../../interfaces/IContact";
import IGroup from "../../../interfaces/IGroup";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import ItemContact from "../../layout/ItemContact";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import SearchBar from "../../layout/forms/SearchBar";
import ListElement from "../../layout/lists/ListElement";

const Contacts: React.FC = () => {
    const [groups, setGroups] = useState<Array<IGroup> | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const { addToast } = useToasts();

    useEffect(() => {
        setIsLoading(true);

        if (!groups) {
            request({ addToast, url: `/api/contacts` }).then(({ data }) => {
                setGroups(data);
                setIsLoading(false);
            }).catch();
        }
    }, []);

    //Refresh of banks displayed by search input
    const refreshContacts = (search: string) => {
        setGroups([]);
        setIsLoading(true);
        setSearchValue(search);
        if (search != "") {
            request({ addToast, url: `/api/contacts/search/${search}` }).then(
                ({ data }) => {
                    setGroups(data);
                    setIsLoading(false);
                }
            ).catch();
        } else {
            request({ addToast, url: `/api/contacts` }).then(({ data }) => {
                setGroups(data);
                setIsLoading(false);
            }).catch();
        }
    };

    return (
        <>
            <SubBar title="Les contacts">
                <Link to={`/contacts/nouveau`}>
                    <Icon name="add" />
                </Link>
            </SubBar>

            <SearchBar
                id="searchBar"
                type="text"
                needClassName={true}
                placeholder="Recherche..."
                value={searchValue}
                onChange={(e: any) => refreshContacts(e.target.value)}
                onClean={() => {
                    setSearchValue("");
                    refreshContacts("");
                }}
                cleanIconClass="clean_icon_fullwidth"
                maxLength={50}
            />

            <div className="list">
                {isLoading && <Loader />}
                {!isLoading && groups && groups.length == 0 && (
                    <div className="layout-center-center row div-no-items">
                        Aucun contacts.
                    </div>
                )}
                {!isLoading &&
                    groups &&
                    groups.length > 0 &&
                    groups.map(
                        (group) =>
                            group.contacts.length > 0 && (
                                <div key={group.letter}>
                                    <div className="titleLetter sticky top-80">
                                        {group.letter}
                                    </div>
                                    {group.contacts.map(
                                        (contact: IContact, index) => (
                                            <div key={contact._id}>
                                                <div className="item_three_lines_link_without_padding">
                                                    <ListElement>
                                                        {/* <Link
                                                            to={
                                                                (contact.agency &&
                                                                    `/banques/${contact._id}/show`) ||
                                                                `/contacts/${contact._id}/show`
                                                            }
                                                            className="layout-center-start column"
                                                        > */}
                                                        {/* Display this for contact with organism */}
                                                        {contact.organisme && (
                                                            <ItemContact
                                                                key={
                                                                    contact._id
                                                                }
                                                                contact={
                                                                    contact
                                                                }
                                                                last={
                                                                    index ===
                                                                    group
                                                                        .contacts
                                                                        .length -
                                                                        1
                                                                }
                                                            />
                                                        )}

                                                        {/* Display this for contact without organism */}
                                                        {!contact.organisme &&
                                                            !contact.agency && (
                                                                <ItemContact
                                                                    key={
                                                                        contact._id
                                                                    }
                                                                    contact={
                                                                        contact
                                                                    }
                                                                    last={
                                                                        index ===
                                                                        group
                                                                            .contacts
                                                                            .length -
                                                                            1
                                                                    }
                                                                />
                                                            )}

                                                        {/* Display this for banks */}
                                                        {contact.agency && (
                                                            <ItemContact
                                                                key={
                                                                    contact._id
                                                                }
                                                                contact={
                                                                    contact
                                                                }
                                                                last={
                                                                    index ===
                                                                    group
                                                                        .contacts
                                                                        .length -
                                                                        1
                                                                }
                                                            />
                                                        )}
                                                        {/* </Link> */}
                                                    </ListElement>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            )
                    )}
            </div>
        </>
    );
};

export default Contacts;
