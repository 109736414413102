import React, { useEffect, useState } from "react";
import IButtonModal from "../../../interfaces/modals/IButtonModal";
import Modal from "../../layout/modals/Modal";

const ButtonConfirmModalIcon: React.FC<IButtonModal> = (props) => {
    const [confirmDownload, setConfirmDownload] = useState<boolean>(false);

    useEffect(() => {
        setConfirmDownload(props.show);
    }, [props.show]);

    return (
        <div className={"button_confirm_modal_icon " + props.className}>
            {props.id !== "add_mails_modal" && (
                <div
                    id={`button_to_open_modal_${props.id}`}
                    className={
                        "layout-center-center row " + props.iconClassName
                    }
                    onClick={() => {
                        setConfirmDownload(true);
                        if (props.show) {
                            props.setShowCourrierModal(true);
                        }
                    }}
                >
                    {props.icon && (
                        <i onClick={() => {
                            props.onOpen ? props.onOpen() : null
                        } } className="material-icons pointer ">{props.icon}</i>
                    )}
                    {props.text && (
                        <div
                            className="pointer button_border"
                            style={{ color: "white" }}
                        >
                            {props.text}
                        </div>
                    )}
                </div>
            )}

            <Modal
                message={props.question}
                show={confirmDownload}
                onValidate={() => {
                    if (props.beforeValidate) {
                        const result = props.beforeValidate();
                        if (!result) return false;
                    }
                    if (props.show) {
                        props.setShowCourrierModal(false);
                    }
                    setConfirmDownload(false), props.onValidate();
                }}
                onClose={() => {
                    setConfirmDownload(false);
                    if (props.show) {
                        props.setShowCourrierModal(false);
                    }
                    if (props.onClose) {
                        props.onClose();
                    }
                }}
            >
                {props.children}
            </Modal>
        </div>
    );
};

export default ButtonConfirmModalIcon;
