import classNames from "classnames";
import fr from "date-fns/locale/fr";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import IDatePicker from "../../../interfaces/forms/IDatePicker";

/**
 * Create an datePicker.
 *
 * @param props Input properties.
 */
const InputDatePicker: React.FC<IDatePicker> = (props) => {
    registerLocale("fr", fr);

    const [date, setDate] = useState<Date>();

    const handleDate = (date: Date) => {
        setDate(date);
        props.onChange(date);
    };

    useEffect(() => {
        //setDate(props.inputDate);
    }, []);

    return (
        <div className="DatePickerForm flex-1">
            <div className="my-input-container">
                {props.label && (
                    <label htmlFor={props.id}>
                        {props.label} :
                        {props.required && (
                            <span className="red_asterix"> *</span>
                        )}
                    </label>
                )}
                <div className="layout-start-center row">
                    <DatePicker
                        id={props.id}
                        ref={props.getRef ? (ref) => props.getRef(ref) : null}
                        className={classNames(
                            "my-input-date",
                            props.inputClassName
                        )}
                        placeholderText="dd/mm/yyyy"
                        selected={date ? date : props.inputDate}
                        onChange={(date: Date) => handleDate(date)}
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={props.dropdownItemNumber || 15}
                        scrollableYearDropdown
                        locale={fr}
                        required={props.required}
                        {...props.inputOptions}
                    />
                    {!props.noIcon && (
                        <i className="material-icons date_icon">event</i>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InputDatePicker;
