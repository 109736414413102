import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { request } from "../../../utils/axios-utils";

const ForgotPassword: React.FC = () => {
    const { addToast } = useToasts();

    const [email, setEmail] = useState<string>("");

    // Request to ask to change password giving email
    const submitForgotEmail = () => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        request({
            addToast,
            url: "/api/password/resetPasswordMail",
            method: "post",
            data: { email: email },
        }).then(() => {
            addToast(
                "Le mail de réinitialisation a bien été envoyé. Consultez votre messagerie.",
                { appearance: "success" }
            );
        }).catch();
    };

    return (
        <>
            <div className="forgot_password layout-start-center column">
                <img
                    id="logo"
                    src="/imgs/logo-accel-gerancia-blanc.svg"
                    alt="logo"
                />
                <h1 className="welcome_message">
                    Réinitialiser votre mot de passe
                </h1>
                <form
                    onSubmit={() => submitForgotEmail()}
                    className="layout-start-center column"
                >
                    <div className="my-input-container">
                        <label htmlFor="email">
                            Email : <span className="red_asterix">*</span>
                        </label>
                        <input
                            className="my-input"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            placeholder="Email..."
                            required
                        />
                    </div>
                    <div className="forgot layout-end-center row">
                        <p className="small-text">
                            Pour se connecter c'est <Link to="/login">ici</Link>
                        </p>
                    </div>
                    <button type="submit">Réinitialiser</button>
                </form>
            </div>
        </>
    );
};
export default ForgotPassword;
