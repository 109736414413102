import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IAlert from "../../../interfaces/IAlert";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import { request } from "../../../utils/axios-utils";
import ItemTwoLinesLink from "../../layout/ItemTwoLinesLink";
import Loader from "../../layout/Loader";
import NoItem from "../../layout/NoItem";
import {momentUpdateLocale} from "../../../constants/Utils";

momentUpdateLocale()

const AlarmsMajeur: React.FC<{ majeurId: string; majeur: IProtectedAdult }> = (
    props
) => {
    const { addToast } = useToasts();

    const [alarms, setAlarms] = useState<Array<IAlert> | null>(null);

    useEffect(() => {
        if (!alarms) {
            request({
                addToast,
                url: `/api/majeurs/alarms/${props.majeurId}`,
            }).then(({ data }) => {
                setAlarms(data);
            }).catch();
        }
    }, []);

    return (
        <div>
            <div className="subBar_actions sticky top-90 layout-space-between-center row">
                <span className="subBar_actions_title">Liste des alertes</span>
                <div className="subBar_actions_item">
                    {!props.majeur.archived && (
                        <Link
                            to={"/alertes/nouvelle/" + props.majeur._id}
                            className="column layout-center-center add-button-href"
                        >
                            <i className="material-icons">add</i>
                        </Link>
                    )}
                </div>
            </div>
            <div className="page_content">
                {alarms === null && <Loader />}

                {alarms && alarms.length === 0 && (
                    <NoItem text="Vous n'avez pas encore d'alertes." />
                )}
                {alarms &&
                    alarms.length > 0 &&
                    alarms.map((a) => {
                        return (
                            <ItemTwoLinesLink
                                key={a._id}
                                link={`/alertes/${a._id}`}
                                title={`Echéance : ${moment(
                                    a.date_final
                                ).format("L")}
                                    | Alarme ${moment(a.date_alarm).format(
                                        "L"
                                    )}`}
                                content={a.label}
                                majeurId={props.majeur._id}
                            />
                        );
                    })}
            </div>
        </div>
    );
};
export default AlarmsMajeur;
