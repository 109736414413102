import { request } from "../utils/axios-utils";

const namespace = "/api/user";

export const getDepots = (addToast: any) => {
    return request({
        addToast,
        url: namespace + "/depots",
        method: "get",
    });
};

export const getDepot = (addToast: any, depot_id: string) => {
    return request({
        addToast,
        url: namespace + "/depot/" + depot_id,
        method: "get",
    });
};

export const changeStatus = (addToast: any, payload: any, _id: string) => {
    return request({
        addToast,
        url: namespace + `/depot/status/` + _id,
        method: "post",
        data: payload,
    });
};

export const createDepot = (addToast: any, payload: any) => {
    return request({
        addToast,
        url: namespace + "/depot",
        method: "post",
        data: payload,
    });
};
