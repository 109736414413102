import React from "react";
import { Link } from "react-router-dom";

const ItemThreeLinesLink: React.FC<{
    title: string;
    link: string;
    firstcontent?: string;
    lastcontent: string;
    nodivider?: boolean;
}> = (props) => (
    <div className="item_three_lines_link">
        <Link to={props.link} className="layout-center-start column">
            <h3>{props.title}</h3>
            <h5>{props.firstcontent}</h5>
            <h5>{props.lastcontent}</h5>
        </Link>
        {!props.nodivider && <div className="divider"></div>}
    </div>
);

export default ItemThreeLinesLink;
