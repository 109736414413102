import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { capitalizeFirst } from "../../../constants/Utils";
import IAlert from "../../../interfaces/IAlert";
import { request } from "../../../utils/axios-utils";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import FormButton from "../../layout/forms/FormButton";
import InputComponent from "../../layout/forms/InputComponent";
import InputDatePicker from "../../layout/forms/InputDatePicker";
import InputSwitch from "../../layout/forms/InputSwitch";
import Select from "../../layout/forms/Select";

const CreateUpdate: React.FC = () => {
    const [alert, setAlert] = useState<IAlert | null>(null);

    // One hook for each form field.
    const [alertTypeField, setAlertTypeField] = useState<string>("");
    const [alertRepetition, setAlertRepetition] = useState<string>("_AUCUNE_");
    const [alertNotes, setAlertNotes] = useState<string>("");

    // Dates.
    const [finalDate, setFinalDate] = useState<Date>(new Date());
    const [startDate, setStartDate] = useState<Date>(new Date());

    // One hook for each Switch button.
    const [alertEmailSending, setEmailSending] = useState<boolean>(false);
    const [alertDone, setAlertDone] = useState<boolean>(false);

    const { alertId }: { alertId?: string } = useParams();
    const { protectedAdultId }: { protectedAdultId?: string } = useParams();

    const { addToast } = useToasts();

    useEffect(() => {
        // If it is a modification, it loads the alert to modify.
        if (alertId && !alert) {
            request({ addToast, url: `/api/alarm/${alertId}` }).then(
                ({ data }) => {
                    setAlert(data);
                    setAlertTypeField(data.label);
                    setAlertNotes(data.notes);
                    setStartDate(data.date_alarm);
                    setFinalDate(data.date_final);
                    setEmailSending(data.sending_email);
                    setAlertDone(data.done);
                    setAlertRepetition(data.repetition);
                }
            ).catch();
        }
    }, [alert]);

    /**
     * Save an alert (it can be a saving as a modification).
     */
    const saveAlert = () => {
        const url = alertId
            ? `/api/alarms/${alertId}/edit`
            : `/api/alarms/create`;

        request({
            addToast,
            url: url,
            method: "post",
            data: {
                label: capitalizeFirst(alertTypeField.trim()),
                date_alarm: startDate,
                date_final: finalDate,
                done: alertDone,
                repetition: alertRepetition,
                sending_email: alertEmailSending,
                notes: alertNotes,
                majeur_id: alertId
                    ? alert.majeur_id
                    : protectedAdultId === "null"
                    ? null
                    : protectedAdultId,
            },
        }).then((data) => {
            // The data has been saved.
            addToast(`L'alerte a bien été ${alertId ? "modifiée" : "créée"}`, {
                appearance: "success",
            });
            window.history.back();
        }).catch();
    };

    return (
        <>
            {!alertId && !alert && (
                <SubBar
                    title={"Ajouter une alerte"}
                    back={
                        protectedAdultId && protectedAdultId !== "null"
                            ? `/single_majeur/${protectedAdultId}/alarms`
                            : "/alertes"
                    }
                />
            )}

            {alertId && alert && (
                <SubBar
                    title={
                        alert &&
                        ((alert.protected_adult &&
                            `${alert.protected_adult.first_name}  ${alert.protected_adult.last_name} | Modifier une alerte`) ||
                            "Modifier une alerte")
                    }
                    back={`/alertes/${alertId}`}
                />
            )}

            <div className="page_content alert_wrapper">
                {alertId && !alert && <Loader />}

                {(!alertId || alert) && (
                    <form className="column">
                        <div className="row layout-xs-column">
                            <div className="my-input-container column-item-flex-1">
                                <div className="flex005 hide-xs" />

                                <InputComponent
                                    id="label"
                                    type="text"
                                    className="column-item-flex-1"
                                    label="Objet"
                                    required
                                    value={alertTypeField}
                                    onChange={(e) =>
                                        setAlertTypeField(e.target.value)
                                    }
                                    maxLength={50}
                                />

                                <div className="flex005 hide-xs" />
                                <div className="row layout-xs-column">
                                    <InputDatePicker
                                        id="label"
                                        inputDate={
                                            alert && new Date(alert.date_final)
                                        }
                                        label="Echéance"
                                        required
                                        onChange={(date: Date) =>
                                            setFinalDate(date)
                                        }
                                    />

                                    <div className="flex005 hide-xs" />
                                    <InputDatePicker
                                        id="label"
                                        inputDate={
                                            alert && new Date(alert.date_alarm)
                                        }
                                        label="Date d'alarme"
                                        required
                                        onChange={(date: Date) =>
                                            setStartDate(date)
                                        }
                                    />
                                </div>

                                <div className="flex005 hide-xs margin-bottom" />

                                <Select
                                    value={alertRepetition}
                                    label="Répétition"
                                    className=""
                                    onChange={(e) => {
                                        setAlertRepetition(e.target.value);
                                    }}
                                    options={[
                                        { label: "Aucune", value: "_AUCUNE_" },
                                        {
                                            label: "Semaine",
                                            value: "_SEMAINE_",
                                        },
                                        { label: "Mois", value: "_MOIS_" },
                                        {
                                            label: "Trimestre",
                                            value: "_TRIMESTRE_",
                                        },
                                    ]}
                                />

                                <div className="flex005 hide-xs" />

                                <label>Notes :</label>
                                <textarea
                                    className="my-textarea"
                                    value={alertNotes || ""}
                                    onChange={(e) =>
                                        setAlertNotes(e.target.value)
                                    }
                                ></textarea>

                                <div className="flex005 hide-xs" />

                                <div
                                    className="switch-button"
                                    style={{ marginTop: 20 }}
                                >
                                    <InputSwitch
                                        id="label"
                                        className="column-item-flex-1"
                                        label="Email"
                                        required
                                        value={alertEmailSending}
                                        onChange={(isTrue: boolean) =>
                                            setEmailSending(isTrue)
                                        }
                                    />
                                </div>

                                <div className="flex005 hide-xs" />

                                <div
                                    className="switch-button"
                                    style={{ marginTop: 20 }}
                                >
                                    <InputSwitch
                                        id="label"
                                        className="column-item-flex-1"
                                        label="Effectuée"
                                        required
                                        value={alertDone}
                                        onChange={(isTrue: boolean) =>
                                            setAlertDone(isTrue)
                                        }
                                    />
                                </div>

                                <div className="flex005 hide-xs" />

                                <FormButton
                                    onClick={saveAlert}
                                    style={{ marginTop: 20 }}
                                    text={!alertId ? "Ajouter" : "Modifier"}
                                />
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </>
    );
};

export default CreateUpdate;
