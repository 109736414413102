import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IGroup from "../../../interfaces/IGroup";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import { request } from "../../../utils/axios-utils";
import ItemContact from "../../layout/ItemContact";
import Loader from "../../layout/Loader";
import NoItem from "../../layout/NoItem";
import SearchBar from "../../layout/forms/SearchBar";

const ContactsMajeur: React.FC<{
    majeurId: string;
    majeur: IProtectedAdult;
}> = (props) => {
    const [searchValue, setSearchValue] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [groups, setGroups] = useState<Array<IGroup>>([]);
    const addToast = useToasts();

    useEffect(() => {
        setIsLoading(true);
        request({
            addToast,
            url: `/api/majeurs/contacts/${props.majeurId}`,
        }).then(({ data }) => {
            setGroups(data);
            setIsLoading(false);
        }).catch();
    }, []);

    const refreshContacts = (search: string) => {
        setSearchValue(search);
        if (search != "") {
            request({
                addToast,
                url: `/api/majeurs/${props.majeurId}/contacts/search/${search}`,
            }).then(({ data }) => {
                setGroups(data);
                setIsLoading(false);
            }).catch();
        } else {
            request({
                addToast,
                url: `/api/majeurs/contacts/${props.majeurId}`,
            }).then(({ data }) => {
                setGroups(data);
                setIsLoading(false);
            }).catch();
        }
    };

    return (
        <div className="list_contacts_majeur">
            <div className="subBar_actions sticky top-90 layout-space-between-center row">
                <span className="subBar_actions_title">Les contacts</span>
                <div className="subBar_actions_item">
                    {!props.majeur.archived && (
                        <Link
                            to={`/contacts/nouveau/${props.majeurId}`}
                            className="column layout-center-center add-button-href"
                        >
                            <i className="material-icons">add</i>
                        </Link>
                    )}
                </div>
            </div>

            <SearchBar
                id="searchBar"
                type="text"
                needClassName={true}
                placeholder="Recherche..."
                value={searchValue}
                onChange={(e: any) => {
                    refreshContacts(e.target.value);
                }}
                onClean={() => {
                    setSearchValue("");
                    refreshContacts("");
                }}
                cleanIconClass="clean_icon_fullwidth"
                maxLength={50}
            />

            {isLoading && <Loader />}

            {groups && groups.length === 0 && !isLoading && (
                <NoItem text="Aucun contact." />
            )}

            {!isLoading &&
                groups.map(
                    (group) =>
                        group.contacts.length > 0 && (
                            <div key={group.letter}>
                                <div className="titleLetter sticky top-170">
                                    {group.letter}
                                </div>
                                {group.contacts.map((contact, index) => (
                                    <ItemContact
                                        key={contact._id}
                                        contact={contact}
                                        majeurId={props.majeurId}
                                        last={
                                            index === group.contacts.length - 1
                                        }
                                    />
                                ))}
                            </div>
                        )
                )}
        </div>
    );
};
export default ContactsMajeur;
