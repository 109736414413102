import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Button } from "../../../_shared/Button";
import { InputForm } from "../../../_shared/InputField";
import { RedirectLink } from "../../../_shared/RedirectLink";
import { request } from "../../../utils/axios-utils";
import { useAuth } from "./auth.context";

export const LoginCRG = (props) => {
    const { addToast } = useToasts();
    const history = useHistory();
    const { setCurrentUser } = useAuth();

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState(null);

    const onKeyUp = (event) => {
        if (event.key === "Enter") {
            const element = document.getElementById("buttonLogin");
            element.click();
            signIn(event);
        }
    };

    const signIn = async () => {
        request({
            addToast,
            url: "/api/login",
            method: "post",
            data: {
                user_email: email,
                user_password: password,
            },
        })
            .then(async ({ data }) => {
                // Set current logged in user in context
                setCurrentUser(data);

                sessionStorage.setItem("user", JSON.stringify(data));
                sessionStorage.setItem("userId", data._id);
                if (
                    data.roles[0] === "verificateur_crg" ||
                    data.roles[0] === "depot_crg"
                ) {
                    history.push("/list_controls");
                } else {
                    history.push("/dashboard");
                }

                addToast("Connexion réussi", { appearance: "success" });
            })
            .catch();
    };

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Section Gauche avec Image et Texte */}
            <div className="w-1/2 relative">
                <img
                    id="logo"
                    src={"/imgs/logo_crg.webp"}
                    alt="Chateau"
                    className="w-full h-full object-cover"
                />

                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-center p-10 text-white">
                    <h1
                        className="text-4xl font-bold cursor-pointer"
                        onClick={() => history.push("/connexion")}
                    >
                        Contrôle de Compte Rendu de Gestion (CRG)
                    </h1>
                    <p
                        className="text-lg mt-4 cursor-pointer"
                        onClick={() => history.push("/connexion")}
                    >
                        Par Accel Gerancia
                    </p>
                </div>
            </div>

            <div className="w-1/2 flex justify-center items-center">
                <div className="bg-white p-10 rounded-lg shadow-md w-3/4 max-w-md">
                    <div className="space-y-4">
                        <InputForm
                            name="Email"
                            value={email}
                            set={setEmail}
                            placeholder={"example.name@epv.fr"}
                            required={true}
                        />
                        <InputForm
                            type="password"
                            onKeyUp={onKeyUp}
                            name="Mot de passe"
                            value={password}
                            set={setPassword}
                            placeholder={"**********"}
                            required={true}
                        />
                        <div className="flex justify-between">
                            <div className="forgot layout-start-center row">
                                <p className="small-text">
                                    <RedirectLink
                                        to="/enregistrement"
                                        name="Créer un compte"
                                    />
                                </p>
                            </div>
                            <RedirectLink
                                to="/demande-mot-de-passe"
                                name="Mot de passe oublié ?"
                            />
                        </div>
                        {error && (
                            <p className="text-left text-red-500 text-xs italic">
                                {error}
                            </p>
                        )}
                        <div className="flex justify-end">
                            <Button
                                action={(e) => signIn(e)}
                                name={"Se connecter"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
