import moment from "moment";
import React from "react";
import {momentUpdateLocale, returnNature, returnProtection} from "../../constants/Utils";

const ItemProtectionMesure: React.FC<{
    key: string;
    protection: any;
    userType: string;
    divider: boolean;
}> = (props) => {
    momentUpdateLocale()

    return (
        <>
            <div className="column item_protection_majeur">
                <div className="column container">
                    {props.protection.reason && (
                        <div className="layout-start-center reason">
                            <span style={{ color: "red" }}>
                                Raison de sortie : {props.protection.reason}
                            </span>
                        </div>
                    )}
                    <div className="layout-start-center row item_protection_majeur_title">
                        {props.userType !== "_ASSOCIATION_" && (
                            <div>
                                {props.protection &&
                                    props.protection.period &&
                                    (props.protection.period === "Ouverture" ||
                                        props.protection.period === "Fin") && (
                                        <div>
                                            {props.protection.period} du{" "}
                                            {moment(
                                                props.protection.start_date
                                                    .$date
                                                    ? parseInt(
                                                          props.protection
                                                              .start_date.$date
                                                              .$numberLong
                                                      )
                                                    : props.protection
                                                          .start_date
                                            ).format("L")}
                                            au{" "}
                                            {moment(
                                                props.protection.end_date.$date
                                                    ? parseInt(
                                                          props.protection
                                                              .end_date.$date
                                                              .$numberLong
                                                      )
                                                    : props.protection.end_date
                                            ).format("L")}
                                        </div>
                                    )}
                                {props.protection &&
                                    props.protection.period &&
                                    props.protection.period === "Normale" &&
                                    props.protection.end_date && (
                                        <div>
                                            En période Normale du{" "}
                                            {moment(
                                                props.protection.start_date
                                                    .$date
                                                    ? parseInt(
                                                          props.protection
                                                              .start_date.$date
                                                              .$numberLong
                                                      )
                                                    : props.protection
                                                          .start_date
                                            ).format("L") + " "}
                                            au{" "}
                                            {moment(
                                                props.protection.end_date.$date
                                                    ? parseInt(
                                                          props.protection
                                                              .end_date.$date
                                                              .$numberLong
                                                      )
                                                    : props.protection.end_date
                                            ).format("L")}
                                        </div>
                                    )}
                                {props.protection &&
                                    props.protection.period &&
                                    props.protection.period === "Normale" &&
                                    !props.protection.end_date && (
                                        <div>
                                            En période Normale depuis le{" "}
                                            {moment(
                                                props.protection.start_date
                                                    .$date
                                                    ? parseInt(
                                                          props.protection
                                                              .start_date.$date
                                                              .$numberLong
                                                      )
                                                    : props.protection
                                                          .start_date
                                            ).format("L")}
                                        </div>
                                    )}
                            </div>
                        )}
                        {props.userType === "_ASSOCIATION_" && (
                            <div>
                                {props.protection && props.protection.end_date && (
                                    <div>
                                        Du{" "}
                                        {moment(
                                            props.protection.start_date.$date
                                                ? parseInt(
                                                      props.protection
                                                          .start_date.$date
                                                          .$numberLong
                                                  )
                                                : props.protection.start_date
                                        ).format("L")}
                                        au{" "}
                                        {moment(
                                            props.protection.end_date.$date
                                                ? parseInt(
                                                      props.protection.end_date
                                                          .$date.$numberLong
                                                  )
                                                : props.protection.end_date
                                        ).format("L")}
                                    </div>
                                )}
                                {props.protection &&
                                    !props.protection.end_date && (
                                        <div>
                                            Depuis le{" "}
                                            {moment(
                                                props.protection.start_date
                                                    .$date
                                                    ? parseInt(
                                                          props.protection
                                                              .start_date.$date
                                                              .$numberLong
                                                      )
                                                    : props.protection
                                                          .start_date
                                            ).format("L")}
                                        </div>
                                    )}
                            </div>
                        )}
                    </div>
                    <div className="layout-start-center row line_forte">
                        {returnNature(props.protection.nature)}{" "}
                        {returnProtection(props.protection.protection)}
                    </div>
                    <div className="layout-start-start column line">
                        <span className="pps">
                            PPS : {props.protection.pps}.
                        </span>
                        {props.protection.location &&
                            props.protection.location === "D" && (
                                <span>Réside à domicile.</span>
                            )}
                        {props.protection.location &&
                            props.protection.location === "E" && (
                                <span>
                                    Réside en Etablissement :{" "}
                                    {props.protection.establishment}
                                </span>
                            )}
                        {props.protection.location &&
                            props.protection.location === "E+D" && (
                                <span>
                                    Réside à domicile et en Etablissement :{" "}
                                    {props.protection.establishment}
                                </span>
                            )}
                    </div>
                </div>
                {props.divider && <div className="divider" />}
            </div>
        </>
    );
};
export default ItemProtectionMesure;
