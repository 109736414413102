import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { createDepot } from "../../../../_api/Depot";
import { Button } from "../../../../_shared/Button";
import { InputForm } from "../../../../_shared/InputField";

export const CreateControl = () => {
    const { addToast } = useToasts();

    const { mandat_id }: { mandat_id?: string } = useParams();

    const history = useHistory();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {}, []);

    return (
        <div className="p-4">
            <Button
                type={"empty"}
                name={"Retour"}
                action={() => {
                    history.push("/list_controls");
                }}
            />

            <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Début du controle"
                        type="date"
                        set={setStartDate}
                        value={startDate}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Fin de controle"
                        type="date"
                        set={setEndDate}
                        value={endDate}
                        required={true}
                    />
                </div>
            </div>

            <hr className="m-4" />
            <div className="flex justify-end">
                <Button
                    type={"empty"}
                    name={"Enregistrer"}
                    action={() => {
                        const payload = {
                            start_period: new Date(startDate),
                            end_period: new Date(endDate),
                            control_id: mandat_id,
                        };

                        createDepot(addToast, payload).then((resp) => {
                            addToast("Création du controle réalisée.", {
                                appearance: "success",
                            });
                            history.push("/list_controls");
                        });
                    }}
                />
            </div>
        </div>
    );
};
