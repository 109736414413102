import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { SideNavItems } from "../../constants/SideBarItems";
import ISideBarItem from "../../interfaces/ISideBarItem";
import { request } from "../../utils/axios-utils";

function DropdownMenu(props: {
    roles: any;
    app: string;
    name: string;
    setApp: any;
    color?: string;
}) {
    const { roles, setApp } = props;
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className="w-full h-16">
            <button
                onClick={toggleDropdown}
                className={
                    props.color +
                    " h-16 w-full border border-gray-300 shadow-sm px-4 py-2 text-sm font-medium text-white"
                }
            >
                <div className="flex justify-between">
                    <div className="flex font-bold text-base leading-7">
                        <div className="small-logo"></div>
                        <span className="pl-2 menuTitle">{props.name}</span>
                    </div>
                    <div className="leading-7">{isOpen ? "▲" : "▼"}</div>
                </div>{" "}
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="">
                        {roles.includes("super-admin") && (
                            <Link
                                to={"/dashboard"}
                                onClick={() => {
                                    setIsOpen(false);
                                    setApp("tutelle");
                                }}
                            >
                                <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                    Tutelle
                                </button>
                            </Link>
                        )}
                        {roles.includes("verificateur_crg") && (
                            <Link
                                to={"/list_controls"}
                                onClick={() => {
                                    setIsOpen(false);
                                    setApp("verificateur");
                                }}
                            >
                                <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                    Vérificateur CRG
                                </button>
                            </Link>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

const SideNav: React.FC<{
    close?: Function;
    name?: string;
    color?: string;
}> = (props) => {
    const [roles, setRoles] = useState<any>(null);
    const [items, setItems] = useState<any>([]);
    const [app, setApp] = useState("tutelle");
    const { addToast } = useToasts();

    useEffect(() => {
        if (!roles) {
            request({ addToast, url: `/api/user` })
                .then(({ data }) => {
                    if (
                        data.roles[0] === "verificateur_crg" ||
                        data.roles[0] === "depot_crg"
                    ) {
                        setApp("verificateur");
                    }
                    setRoles(data.roles);
                    setItems(
                        SideNavItems.filter((x: ISideBarItem) => {
                            let hasCommonString = x.roles.some(
                                (item) =>
                                    typeof item === "string" &&
                                    data.roles.includes(item) &&
                                    x.app === app
                            );
                            return hasCommonString;
                        })
                    );
                })
                .catch();
        } else {
            setItems(
                SideNavItems.filter((x: ISideBarItem) => {
                    let hasCommonString = x.roles.some(
                        (item) =>
                            typeof item === "string" &&
                            roles.includes(item) &&
                            x.app.includes(app)
                    );
                    return hasCommonString;
                })
            );
        }
    }, [roles, app]);

    //Return _id from the actual route
    const testRoute = () => {
        var actualRoute = "";
        for (let item of items) {
            if (window.location.href.indexOf(item.href) > -1) {
                actualRoute = item._id;
            }
        }

        // Specific cases for listMajeurs
        if (
            window.location.href.indexOf("single_account") > -1 ||
            window.location.href.indexOf("single_majeur") > -1
        ) {
            actualRoute = "listMajeurs";
        }

        return actualRoute;
    };

    console.log(props.name);

    return (
        <nav id="sidenav" className="Sidenav column no_print">
            <div className="layout-start-center row">
                <DropdownMenu
                    roles={roles}
                    name={props.name}
                    app={app}
                    setApp={setApp}
                    color={props.color}
                />
            </div>
            <div className="menuItemContainer">
                {items.map((item: ISideBarItem) => (
                    <div
                        key={item._id}
                        className={`sidenav_item ${
                            item._id === testRoute() ? "selected" : ""
                        }`}
                    >
                        {props.close && (
                            <Link
                                onClick={() => props.close()}
                                to={item.href}
                                className="ItemMenu layout-start-center row"
                            >
                                <i className="material-icons">{item.icon}</i>
                                <span className="menuTitle">{item.name}</span>
                            </Link>
                        )}

                        {!props.close && (
                            <Link
                                to={item.href}
                                className="ItemMenu layout-start-center row"
                            >
                                <i className="material-icons">{item.icon}</i>
                                <span className="menuTitle">{item.name}</span>
                            </Link>
                        )}

                        <div className="divider" />
                    </div>
                ))}
            </div>
        </nav>
    );
};
export default SideNav;
