import React from "react";
import { Button } from "../../../../../_shared/Button";

export const Depenses = (props: { onClose: any }) => {
    const { onClose } = props;
    const checkInformation = () => {};

    const _onValidation = () => {
        checkInformation();
        onClose();
    };
    return (
        <>
            <div className="flex justify-end">
                <Button name={"Suivant"} action={() => _onValidation()} />
            </div>
        </>
    );
};
