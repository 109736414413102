interface SubPriceObject {
    [key: string]: number;
}

interface PriceObject {
    [key: string]: SubPriceObject;
}

export const MailevaPrice: PriceObject = {
    'ECONOMIC': {
        50: 0.662,
        100: 2.16,
        250: 3.97,
    },
    'FAST': {
        50: 0.741,
        100: 2.62,
        250: 4.30,
        500: 5.94,
        1000: 7.55,
        2000: 9.14,
    },
    'URGENT': {
        100: 2.97,
        250: 4.65,
        500: 6.29,
        1000: 8.18,
        2000: 10,
    },
    'PRINT': {
        NB: 0.169,
        COLOR: 0.299,
        FIRST_PAGE_COLOR_C4: 0.469,
        FIRST_PAGE_COLOR_DL: 0.369,
        FIRST_PAGE_NB_C4: 0.369,
        FIRST_PAGE_NB_DL: 0.219,
    },
    'ENVELOPE': {
        C4: 5.1,
        DL: 14.6,
    },
};
