import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IMailevaDocument from "../../../interfaces/IMailevaDocument";
import IRecipient from "../../../interfaces/IRecipient";
import { request } from "../../../utils/axios-utils";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";

const InfosRecipient: React.FC = (props) => {
    const { addToast } = useToasts();

    const { letterId, recipientId }: { letterId: string; recipientId: string } =
        useParams();

    const [recipient, setRecipient] = useState<IRecipient>(null);
    const [letterDocuments, setLetterDocuments] =
        useState<Array<IMailevaDocument> | null>(null);

    if (!letterDocuments) {
        request({ url: `/api/maileva/get-documents/${letterId}` })
            .then(({ data }) => {
                setLetterDocuments(data.documents);
            })
            .catch(() =>
                addToast("Impossible de récupérer les documents", {
                    appearance: "error",
                })
            );
    }

    useEffect(() => {
        if (recipientId && !recipient) {
            request({
                addToast,
                url: `api/maileva/get-recipient/${recipientId}/${letterId}`,
            }).then(({ data }) => {
                setRecipient(data);
            }).catch();
        }
    }, []);

    return (
        <>
            <SubBar
                title={"Informations du destinataire"}
                back={`/lettres/${letterId}/detail`}
            >
                {recipient && recipient.status === "DRAFT" && (
                    <Link
                        to={`/lettres/${letterId}/destinataire/${recipientId}/modifier`}
                    >
                        <i className="material-icons">edit</i>
                    </Link>
                )}
            </SubBar>
            {!recipient && <Loader />}
            {recipient && (
                <>
                    <div className="row layout-xs-column">
                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Société/Organisme</h6>
                                {recipient
                                    ? recipient.address_line_1 ||
                                      "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Civilité, Prénom, Nom</h6>
                                {recipient
                                    ? recipient.address_line_2 ||
                                      "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>N° et libellé de la voie</h6>
                                {recipient
                                    ? recipient.address_line_4 ||
                                      "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row layout-xs-column">
                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Résidence, Bâtiement ...</h6>
                                {recipient
                                    ? recipient.address_line_3 ||
                                      "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Lieu dit, BP...</h6>
                                {recipient
                                    ? recipient.address_line_5 ||
                                      "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row layout-xs-column">
                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Code postal et ville</h6>
                                {recipient
                                    ? recipient.address_line_6 ||
                                      "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Code du pays</h6>
                                {recipient
                                    ? recipient.country_code || "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row layout-xs-column">
                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Type de postage</h6>
                                {recipient
                                    ? recipient.postage_class || "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Prix du timbre</h6>
                                {recipient
                                    ? recipient.postage_price || "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row layout-xs-column">
                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Statut</h6>
                                {recipient
                                    ? recipient.status || "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Statut détaillé</h6>
                                {recipient
                                    ? recipient.status_detail || "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row layout-xs-column">
                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Dernier status de livraison</h6>
                                {recipient
                                    ? recipient.last_delivery_status ||
                                      "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Date du dernier statut de livraison</h6>
                                {recipient
                                    ? recipient.last_delivery_status_date ||
                                      "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row layout-xs-column">
                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Pages</h6>
                                {recipient
                                    ? recipient.pages_count || "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column flex-1">
                            <div className="item_two_line">
                                <h6>Pages facturées</h6>
                                {recipient
                                    ? recipient.billed_pages_count ||
                                      "Non renseigné"
                                    : ""}
                                <div className="divider"></div>
                            </div>
                        </div>
                    </div>

                    <div className="maileva-table-wrapper maileva-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>pages</th>
                                    <th>Page départ</th>
                                    <th>Page fin</th>
                                </tr>
                            </thead>
                            <tbody>
                                {letterDocuments &&
                                    letterDocuments.map(
                                        (document: IMailevaDocument) => (
                                            <tr>
                                                <td>{document.name}</td>
                                                <td>{document.pages_count}</td>
                                                <td>
                                                    {recipient &&
                                                        recipient.documents_override &&
                                                        recipient.documents_override.map(
                                                            (doc: any) => {
                                                                if (
                                                                    doc &&
                                                                    doc.document_id ===
                                                                        document.id
                                                                ) {
                                                                    return doc.start_page;
                                                                }
                                                                return null;
                                                            }
                                                        )}
                                                </td>

                                                <td>
                                                    {recipient &&
                                                        recipient.documents_override &&
                                                        recipient.documents_override.map(
                                                            (doc: any) => {
                                                                if (
                                                                    doc &&
                                                                    doc.document_id ===
                                                                        document.id
                                                                ) {
                                                                    return doc.end_page;
                                                                }
                                                                return null;
                                                            }
                                                        )}
                                                </td>
                                            </tr>
                                        )
                                    )}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </>
    );
};

export default InfosRecipient;
