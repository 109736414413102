import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import IAdvisors from "../../../interfaces/IAdvisors";
import IBank from "../../../interfaces/IBank";
import { request } from "../../../utils/axios-utils";
import Icon from "../../layout/Icon";
import Loader from "../../layout/Loader";
import SubBar from "../../layout/SubBar";
import Modal from "../../layout/modals/Modal";

const Bank: React.FC = () => {
    const [bank, setBank] = useState<IBank | null>(null);
    const [confirmDeletion, setConfirmDeletion] = useState<boolean>(false);

    const history = useHistory();

    const { addToast } = useToasts();
    const { bankId }: { bankId: string } = useParams();

    useEffect(() => {
        if (!bank) {
            request({ addToast, url: `/api/banks/${bankId}` })
                .then(({ data }) => {
                    setBank(data);
                })
                .catch(() => history.push("/banques"));
        }
    }, [bank]);

    /*
     * Delete a Bank.
     */
    const deleteBank = () => {
        setConfirmDeletion(false);
        request({
            addToast,
            url: `/api/banks/${bankId}/delete`,
            method: "post",
            data: {},
        }).then((data) => {
            addToast(`La banque a bien été supprimée`, {
                appearance: "success",
            });
            history.push("/banques");
        }).catch();
    };

    return (
        <>
            <SubBar title={"Banque"} back="/banques">
                <Link to={"/banques/" + bankId + "/modifier"}>
                    <Icon name="edit" />
                </Link>
                <Link to="#" onClick={() => setConfirmDeletion(true)}>
                    <Icon name="delete_forever" />
                </Link>
            </SubBar>

            <Modal
                message="Attention : La suppression de la banque est définitive. Êtes vous sûr(e) ?"
                show={confirmDeletion}
                onValidate={deleteBank}
            />

            <div className="page_content">
                {!bank && <Loader />}

                {bank && (
                    <>
                        <div className="column">
                            <div className="item_two_line">
                                <h6>Nom de la banque :</h6>
                                <p className="notes_zone">
                                    {bank.name.toUpperCase()}
                                </p>
                            </div>
                            <div className="divider"></div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column">
                            <div className="item_two_line">
                                <h6>Nom de l'agence :</h6>
                                <p className="notes_zone">{bank.agency}</p>
                            </div>
                            <div className="divider"></div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column">
                            <div className="item_two_line">
                                <h6>Adresse :</h6>
                                <p className="notes_zone">
                                    {bank.address || "Non renseignée."}
                                </p>
                            </div>
                            <div className="divider"></div>
                        </div>
                        <div className="row layout-xs-column">
                            <div className="column flex-1">
                                <div className="item_two_line">
                                    <h6>Code postal</h6>
                                    <p className="notes_zone">
                                        {bank.zip_code || "Non renseignée."}
                                    </p>
                                </div>
                                <div className="divider"></div>
                            </div>

                            <div className="flex005 hide-xs"></div>

                            <div className="column flex-1">
                                <div className="item_two_line">
                                    <h6>Ville</h6>
                                    <p className="notes_zone">
                                        {bank.city || "Non renseignée."}
                                    </p>
                                </div>
                                <div className="divider"></div>
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column">
                            <div className="item_two_line">
                                <h6>Téléphone :</h6>
                                <p className="notes_zone">
                                    {bank.phone || "Non renseigné."}
                                </p>
                            </div>
                            <div className="divider"></div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column">
                            <div className="item_two_line">
                                <h6>Email :</h6>
                                <p className="notes_zone">
                                    <a href={`mailto:${bank.email}`}>
                                        {bank.email || "Non renseigné."}
                                    </a>
                                </p>
                            </div>
                            <div className="divider"></div>
                        </div>

                        <div className="flex005 hide-xs"></div>

                        <div className="column">
                            <div className="item_two_line">
                                <h6>Notes :</h6>
                                <p className="notes_zone">
                                    {bank.notes || "Non renseignées."}
                                </p>
                            </div>
                        </div>

                        <div className="flex005 hide-xs"></div>
                        {bank.advisors.length > 0 && (
                            <div className="my-sub-title-f">
                                <SubBar title="Personnes associées"></SubBar>
                            </div>
                        )}
                        <div className="list">
                            {bank.advisors &&
                                bank.advisors.length > 0 &&
                                bank.advisors.map(
                                    (advisor: IAdvisors, index) => (
                                        <div
                                            key={index}
                                            className="item_three_lines"
                                        >
                                            <div className="container">
                                                <h3>
                                                    {advisor.type +
                                                        " : " +
                                                        advisor.civility +
                                                        " " +
                                                        advisor.last_name +
                                                        " " +
                                                        advisor.first_name}
                                                </h3>
                                                {advisor.email && (
                                                    <h5>
                                                        <a
                                                            href={`mailto:${advisor.email}`}
                                                        >
                                                            {"Email : " +
                                                                advisor.email}
                                                        </a>
                                                    </h5>
                                                )}
                                                {advisor.phone &&
                                                    !advisor.fax && (
                                                        <h5>
                                                            {"Tél : " +
                                                                advisor.phone}
                                                        </h5>
                                                    )}
                                                {advisor.fax &&
                                                    !advisor.phone && (
                                                        <h5>
                                                            {"Fax : " +
                                                                advisor.fax}
                                                        </h5>
                                                    )}
                                                {advisor.phone &&
                                                    advisor.fax && (
                                                        <h5>
                                                            {"Tél : " +
                                                                advisor.phone +
                                                                " / " +
                                                                "Fax : " +
                                                                advisor.fax}
                                                        </h5>
                                                    )}
                                            </div>
                                            {index !==
                                                bank.advisors.length - 1 && (
                                                <div className="divider"></div>
                                            )}
                                        </div>
                                    )
                                )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Bank;
