import React from "react";
import { useDropzone } from "react-dropzone";

const DropZoneArea: React.FC<{ onDrop: any; accept?: any; open?: any }> = (
    props
) => {
    const { onDrop, accept, open } = props;
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
        useDropzone({
            accept,
            onDrop,
        });

    return (
        <div>
            <div {...getRootProps({ className: "dropzone" })}>
                <input className="input-zone" {...getInputProps()} />
                <div className="text-center">
                    {isDragActive ? (
                        <p className="dropzone-content">
                            Déposer les fichiers ici
                        </p>
                    ) : (
                        <p className="dropzone-content">
                            Déposer les fichiers ici
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DropZoneArea;
