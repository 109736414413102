import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { createDebt, editDebt } from "../../../../_api/Debt";
import { uploadLinkDoc } from "../../../../_api/S3";
import {
    capitalizeFirst,
    formatTelephone,
    stringToFloat,
} from "../../../../constants/Utils";
import IContact from "../../../../interfaces/IContact";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import { request } from "../../../../utils/axios-utils";
import ArrowBack from "../../../layout/ArrowBack";
import Loader from "../../../layout/Loader";
import InputComponent from "../../../layout/forms/InputComponent";
import InputDatePicker from "../../../layout/forms/InputDatePicker";
import InputSwitch from "../../../layout/forms/InputSwitch";
import Textarea from "../../../layout/forms/Textarea";
import SelectWithSearch from "../../../layout/forms/selectWithSearch";
import IFile from "../Cloud/IFile";
import UploadDocument from "../UploadDocument";

const FormDebt: React.FC = (props) => {
    const [majeur, setMajeur] = useState<IProtectedAdult>();

    /* FORM DATA */
    const [nature, setNature] = useState<string>("");
    const [label, setLabel] = useState<string>("");
    const [montant, setAmount] = useState<string>(null);
    const [notes, setNotes] = useState<string>("");
    const [date, setDate] = useState<Date>();
    const [listContacts, setListContacts] = useState<Array<IContact>>([]);
    const [contact, setContact] = useState<IContact>(null);

    const { debtId }: { debtId?: string } = useParams();
    const { majeurId }: { majeurId?: string } = useParams();

    const [isArchived, setIsArchived] = useState(false);

    const [filesSelected, setFilesSelected] = useState(null);
    const [files, setFiles] = useState<Array<string>>(null);
    const [fileList, setFileList] = useState<Array<IFile>>(null);
    const { addToast } = useToasts();
    const history = useHistory();

    const checkform = () => {
        if (nature === "" || label === "" || montant === "" || !contact) {
            addToast("Veuillez remplir les champs obligatoires.", {
                appearance: "warning",
            });
            return false;
        }
        return true;
    };

    const createOrEdit = (debt: any) => {
        if (!debtId) {
            createDebt(addToast, majeurId, { debt: debt })
                .then(({ data }) => {
                    history.push(`/single_majeur/${majeurId}/mobImmo`);
                    addToast("La dette a bien été créée.", {
                        appearance: "success",
                    });
                })
                .catch();
            return;
        }
        editDebt(addToast, debtId, { debt: debt })
            .then(({ data }) => {
                window.history.back();
                addToast("La dette a bien été modifiée.", {
                    appearance: "success",
                });
            })
            .catch();
    };
    const submitFormDebt = (e: any) => {
        e.preventDefault();

        const debt = {
            nature: capitalizeFirst(nature.trim()),
            label: capitalizeFirst(label.trim()),
            contact: contact,
            date: date || null,
            amount: montant,
            notes: notes || "",
            files: files,
            is_archived: isArchived,
        };

        if (!debt.contact._id) {
            addToast("Veullez selectionner un organisme.", {
                appearance: "warning",
            });
            return;
        }
        if (checkform()) {
            if (!filesSelected) {
                createOrEdit(debt);
                return;
            }

            const formData = new FormData();
            for (let i = 0; i < filesSelected.length; i++) {
                formData.append("documents[]", filesSelected[i]);
            }
            formData.append("majeur_id", majeurId);
            formData.append("to", "Dettes");

            uploadLinkDoc(addToast, formData)
                .then(({ data }) => {
                    debt.files = debt.files ? debt.files.concat(data.data.filesIds) : data.data.filesIds;
                    createOrEdit(debt);
                })
                .catch();
        }
    };

    const selectContact = (event: any) => {
        request({ addToast, url: `/api/contacts/${event.value}` })
            .then(({ data }) => {
                setContact(data);
            })
            .catch();
    };

    const title =
        majeur && window.screen.width > 600
            ? `${majeur.first_name} ${majeur.last_name} | `
            : "";
    const isNew = debtId === undefined;

    useEffect(() => {
        if (!majeur) {
            request({ addToast, url: `/api/majeur/${majeurId}` })
                .then(({ data }) => {
                    setMajeur(data);
                })
                .catch();
        }

        if (majeurId && listContacts.length == 0) {
            request({ addToast, url: `/api/users/organisms` })
                .then(({ data }) => {
                    setListContacts(data);
                })
                .catch();
        }

        if (debtId) {
            request({ addToast, url: `/api/debts/${debtId}` })
                .then(({ data }) => {
                    setDate(
                        data?.date
                            ? new Date(
                                  data?.date?.$date
                                      ? parseInt(data?.date.$date.$numberLong)
                                      : data?.date
                              )
                            : null
                    );
                    setContact(data.contact);
                    selectContact({
                        value: data.contact._id,
                        label: data.contact.organisme,
                    });
                    setNature(data.nature);
                    setLabel(data.label);
                    setAmount(data.amount);
                    setNotes(data.notes);
                    setFiles(data.files ?? []);
                    setIsArchived(data.is_archived || false);

                    if (data.files) {
                        request({
                            url: "/api/files/list/from-ids",
                            method: "POST",
                            data: { filesIds: data.files },
                        })
                            .then(({ data }) => {
                                setFileList(data);
                            })
                            .catch();
                    }
                })
                .catch();
        }
    }, []);

    return (
        <div className="Debt">
            <ArrowBack
                title={`${title} ${isNew ? "Ajouter" : "Modifier"} une dette`}
            />
            <div className="page_content">
                {!listContacts && <Loader />}

                {listContacts && (
                    <form onSubmit={submitFormDebt} className="column">
                        <div className="row layout-xs-column flex-1">
                            <InputComponent
                                id="nature"
                                className="flex-1"
                                label="Nature"
                                required
                                value={nature}
                                onChange={(e: any) => setNature(e.target.value)}
                            />
                            <div className="flex005 hide-xs" />
                            <InputComponent
                                id="label"
                                className="flex-1"
                                label="Label"
                                required
                                value={label}
                                onChange={(e) => setLabel(e.target.value)}
                            />
                            <div className="flex005 hide-xs" />
                            <SelectWithSearch
                                id="select_organisme"
                                label="Organisme"
                                className="flex-1"
                                needDefaultValue={true}
                                required
                                options={listContacts}
                                value={{
                                    value: contact?._id,
                                    label: contact?.organisme,
                                }}
                                onChange={(option) => selectContact(option)}
                            />
                        </div>
                        <div className="row layout-xs-column flex-1">
                            <InputDatePicker
                                id="date"
                                label="Date"
                                inputDate={date}
                                onChange={(newDate: Date) => setDate(newDate)}
                            />
                            <div className="flex005 hide-xs" />
                            <InputComponent
                                id="montant"
                                className="flex-1"
                                label="Montant"
                                required
                                value={montant}
                                needBlur={true}
                                onBlur={() => {
                                    setAmount(
                                        String(parseFloat(montant)) || "0"
                                    );
                                }}
                                onChange={(e) =>
                                    setAmount(stringToFloat(e.target.value))
                                }
                            />
                        </div>
                        {contact && contact._id && (
                            <div
                                className="informations_generales_container"
                                style={{ padding: "0 5px" }}
                            >
                                {contact.addresses &&
                                    contact.addresses.length > 0 && (
                                        <div className="block_container">
                                            <h5>Adresses :</h5>
                                            <div className="layout-center-start column">
                                                {contact.addresses.map((a) => (
                                                    <div
                                                        key={a.label}
                                                        className="items"
                                                    >
                                                        <h6>{a.label}.</h6>
                                                        <p className="address_zone">
                                                            {a.address}
                                                        </p>
                                                        <p>
                                                            {a.zip_code}{" "}
                                                            {a.city}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                {contact.phones &&
                                    contact.phones.length > 0 && (
                                        <div className="block_container">
                                            <h5>Téléphones :</h5>
                                            <div className="layout-center-start column">
                                                {contact.phones.map((p) => (
                                                    <div
                                                        key={p.label}
                                                        className="items"
                                                    >
                                                        <h6>{p.label}.</h6>
                                                        <p>
                                                            {formatTelephone(
                                                                p.phone
                                                            )}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                {contact.emails &&
                                    contact.emails.length > 0 && (
                                        <div className="block_container">
                                            <h5>Emails :</h5>
                                            <div className="layout-center-start column">
                                                {contact.emails.map((e) => (
                                                    <div
                                                        key={e.label}
                                                        className="items"
                                                    >
                                                        <h6>{e.label}.</h6>
                                                        <p>{e.email}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                            </div>
                        )}
                        <Textarea
                            id="notes"
                            label="Notes"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />

                        <InputSwitch
                            id="isArchived"
                            onChange={() => setIsArchived(!isArchived)}
                            label="Actifs"
                            isSwitchMajeur={true}
                            onText="NON"
                            offText="OUI"
                            value={isArchived}
                        />

                        <div
                            className="divider"
                            style={{ marginBottom: "20px" }}
                        />

                        <UploadDocument
                            isMultiple={true}
                            filesSelected={filesSelected}
                            fileList={fileList}
                            setFilesSelected={setFilesSelected}
                            files={files}
                            setFiles={setFiles}
                            setFileList={setFileList}
                        />
                        <button type="submit" className="valid-button">
                            {isNew ? "Ajouter" : "Valider"}
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};
export default FormDebt;
