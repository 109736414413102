import axios from "axios";
import { request } from "../utils/axios-utils";

const namespace = "/api/user";

export const getInvoicingMajeur = (addToast: any) => {
    return request({
        addToast,
        url: namespace + "/invoices",
        method: "get",
    });
};

export const downloadInvoice = (_id: any, payload: any) => {
    return axios.post(namespace + "/invoice/" + _id, payload, {
        responseType: "blob", // Indiquer que la réponse est un blob
    });
};
