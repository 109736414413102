import React from "react";
import { Link } from "react-router-dom";
const SepaRow: React.FC<{
    last4: string;
    ownerName: string;
    mandateUrl: string;
}> = (props) => {
    const hiddenLine = "•••• •••• •••• •••• •••• •••";

    return (
        <>
            <div>
                Propriétaire :
                <strong>
                    <pre style={{ display: "inline", fontSize: "1.5em" }}>
                        {props.ownerName}
                    </pre>
                </strong>
                ,&nbsp; IBAN :
                <strong>
                    <pre style={{ display: "inline", fontSize: "1.5em" }}>
                        {`${hiddenLine}${props.last4.charAt(
                            0
                        )} ${props.last4.substr(0, 3)}`}
                    </pre>
                </strong>
                ,&nbsp;
                <small>
                    <Link
                        to={props.mandateUrl}
                        rel="noreferrer noopener"
                        target="_blank"
                        style={{ color: "#2196f3" }}
                    >
                        Voir le mandat
                    </Link>
                </small>
            </div>
        </>
    );
};

export default SepaRow;
