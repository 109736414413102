import IbanLib from 'iban';
import Payment from 'payment';
import Row from "../../layout/Row";
import React, {useEffect, useState} from 'react';
import InputComponent from "../../layout/forms/InputComponent";

const FormSepa: React.FC<{ selected: boolean, onDisabled: Function, updateSepa: Function }> = (props) => {

    const [iban, setIban] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');

    let sepa: any = {iban: '', name: '', postalCode: '', city: ''};

    useEffect(() => {
        const refZipCode: any = document.getElementById('postalCode');
        Payment.restrictNumeric(refZipCode);
    }, []);

    // Check dynamicly the validity of the sepa
    const checkValidity = () => {
        let isValid = true;

        props.updateSepa(sepa);

        //First we check if each input lenghts are not to 0 ...
        Object.keys(sepa).map(propName => {
            if (sepa[propName].length == 0)
                isValid = false
        });

        if (isValid) {
            // ... next we use IbanLib to check validity
            if (IbanLib.isValid(sepa.iban)) {
                props.onDisabled(!isValid);
            } else {
                props.onDisabled(isValid);
            }
        } else {
            props.onDisabled(!isValid);
        }
    }


    return (
        <>
            <div>
                <Row style={{marginTop: '.5em'}}>
                    <form className="form-sepa" style={{width: '100%'}} autoComplete="on">
                        <InputComponent
                            id='iban'
                            className='iban'
                            label='IBAN'
                            required
                            value={iban}
                            onChange={(e) => {
                                setIban(e.target.value)
                                sepa = {iban: e.target.value, name: name, postalCode: postalCode, city: city}
                                checkValidity()
                            }}
                            maxLength={34}
                        />
                        <InputComponent
                            id='name'
                            className='name'
                            label='Nom du propriétaire'
                            required
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                                sepa = {iban: iban, name: e.target.value, postalCode: postalCode, city: city}
                                checkValidity()
                            }}
                            maxLength={50}
                        />
                        <InputComponent
                            id='postalCode'
                            className='postalCode'
                            label='Code postal'
                            required
                            value={postalCode}
                            onChange={(e) => {
                                setPostalCode(e.target.value)
                                sepa = {iban: iban, name: name, postalCode: e.target.value, city: city}
                                checkValidity()
                            }}
                            maxLength={5}
                        />
                        <InputComponent
                            id='city'
                            className='city'
                            label='Ville'
                            required
                            value={city}
                            onChange={(e) => {
                                setCity(e.target.value)
                                sepa = {iban: iban, name: name, postalCode: postalCode, city: e.target.value}
                                checkValidity()
                            }}
                            maxLength={50}
                        />
                    </form>
                </Row>
            </div>
        </>
    );
}

export default FormSepa;
