import React from "react";

const RightMenu: React.FC<{
    folderId: string;
    changeColor: Function;
}> = (props) => {
    return (
        <ul id={`rightMenu_${props.folderId}`} className="rightMenu">
            <li onClick={() => props.changeColor(props.folderId, "grey")}>
                <span className="circle grey" />
                gris
            </li>
            <li className="divider" />
            <li onClick={() => props.changeColor(props.folderId, "dark_grey")}>
                <span className="circle dark_grey" />
                gris foncé
            </li>
            <li className="divider" />
            <li onClick={() => props.changeColor(props.folderId, "red")}>
                <span className="circle red" />
                rouge
            </li>
            <li className="divider" />
            <li onClick={() => props.changeColor(props.folderId, "orange")}>
                <span className="circle orange" />
                orange
            </li>
            <li className="divider" />
            <li onClick={() => props.changeColor(props.folderId, "brown")}>
                <span className="circle brown" />
                marron
            </li>
            <li className="divider" />
            <li onClick={() => props.changeColor(props.folderId, "beige")}>
                <span className="circle beige" />
                beige
            </li>
            <li className="divider" />
            <li onClick={() => props.changeColor(props.folderId, "yellow")}>
                <span className="circle yellow" />
                jaune
            </li>
            <li className="divider" />
            <li
                onClick={() => props.changeColor(props.folderId, "green_light")}
            >
                <span className="circle green_light" />
                vert clair
            </li>
            <li className="divider" />
            <li onClick={() => props.changeColor(props.folderId, "green")}>
                <span className="circle green" />
                vert
            </li>
            <li className="divider" />
            <li onClick={() => props.changeColor(props.folderId, "blue")}>
                <span className="circle blue" />
                bleu
            </li>
            <li className="divider" />
            <li onClick={() => props.changeColor(props.folderId, "dark_blue")}>
                <span className="circle dark_blue" />
                bleu foncé
            </li>
            <li className="divider" />
            <li onClick={() => props.changeColor(props.folderId, "purple")}>
                <span className="circle purple" />
                violet
            </li>
            <li className="divider" />
            <li onClick={() => props.changeColor(props.folderId, "pink")}>
                <span className="circle pink" />
                rose
            </li>
            <li className="divider" />
            <li onClick={() => props.changeColor(props.folderId, "black")}>
                <span className="circle black" />
                noir
            </li>
            <li className="divider" />
        </ul>
    );
};
export default RightMenu;
